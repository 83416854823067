import Button from "@mui/material/Button";
import { VerityFieldCI } from "./VerityFieldCI";

export function StatusCell({
  onClick,
  value,
  row,
}: {
  onClick: (_d: VerityFieldCI) => void;
  org: string;
  value?: string;
  row: { original: VerityFieldCI };
  seasonId: string;
  programId: string;
}) {
  const key = row?.original?.statusEn;
  return (
    <div>
      <Button
        disabled={!value || key === "certified" || key === "enrolled"}
        onClick={() => {
          return onClick(row.original);
        }}
        variant="contained"
      >
        {value ?? "--"}
      </Button>
    </div>
  );
}
