import { useCallback } from "react";
import useFetchWithToken from "components/useFetchWithToken";
import { useQuery } from "@tanstack/react-query";
import subfieldKeys from "./subfields/subfieldKeys";

interface ISubfieldSummaryItem {
  subfieldType: string;
  subfieldTypeEn: string;
  count: number;
  totalArea: number;
  totalAreaUom: string;
  color: string;
}

export default function useSubfieldsSummary(org: string, seasonId: string) {
  const { fetchData: fetchSummary } = useFetchWithToken<
    ISubfieldSummaryItem[]
  >();

  const getSubfieldsSummary = useCallback(async () => {
    const res = await fetchSummary(
      `/${org}/subfields/season/${seasonId}/summary`
    );

    // TODO: add up all subfield types to get totals
    const totalArea = res?.data?.reduce(
      (count: number, item: ISubfieldSummaryItem) => {
        return count + item.totalArea;
      },
      0
    );
    const totalCount = res?.data?.reduce(
      (count: number, item: ISubfieldSummaryItem) => {
        return count + item.count;
      },
      0
    );
    // pull uom off the first subfield type summary
    const totalAreaUom = res?.data?.length ? res.data[0].totalAreaUom : "";
    return {
      totalArea: totalArea.toLocaleString(),
      totalCount,
      totalAreaUom,
    };
  }, [fetchSummary, seasonId, org]);

  return useQuery({
    queryKey: subfieldKeys.summary(org, seasonId),
    queryFn: getSubfieldsSummary,
  });
}
