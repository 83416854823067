import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import lookupKeys from "../lookups/lookupKeys";

export function useConservationStatuses(org: string) {
  const getStatuses = useCallback(async () => {
    const res = await axios.get<
      {
        id: string;
        name: string;
        label: string;
      }[]
    >(`${org}/lookups/conservationstatuses`);
    res.data.forEach((d) => {
      d.label = d.name;
    });
    return res.data;
  }, [org]);

  return useQuery({
    queryKey: lookupKeys.byType(org, "conservationstatuses"),
    queryFn: getStatuses,
    enabled: !!org,
  });
}
