import Stack from "@mui/material/Stack";
import OperationEventForm from "./OperationEventForm";
import { useOrgState } from "providers";
import { useParams } from "react-router-dom";
import { IUrlParams } from "types";
import { useOperationEvents } from "api/operations/useOperationEvents";
import { OperationType } from "types/OperationType";

export default function OperationEventEdit({
  operationId,
}: {
  // event?: OperationEvent;
  operationId: string;
}) {
  const { eventId, type } = useParams<IUrlParams & { type: OperationType }>();
  const { org, season } = useOrgState();
  const eventsQ = useOperationEvents({
    org,
    seasonId: season?.id,
    type,
    operationId,
  });

  return (
    <Stack sx={{ pt: 1, overflow: "auto" }}>
      <OperationEventForm
        event={eventsQ.data?.find((d) => d.id === eventId)}
        operationId={operationId}
      />
    </Stack>
  );
}
