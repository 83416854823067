import turfBbox from "@turf/bbox";
import turfArea from "@turf/area";
import { feature, round } from "@turf/helpers";
import center from "@turf/center";
import pointOnFeature from "@turf/point-on-feature";
import combine from "@turf/combine";
import turfFlatten from "@turf/flatten";
import turfBuffer from "@turf/buffer";
import turfDifference from "@turf/difference";
import turfSimplify from "@turf/simplify";
// import turfRewind from '@turf/rewind';
// import turfDissolve from '@turf/dissolve';
// import turfUnion from '@turf/union';
import turfBooleanClockwise from "@turf/boolean-clockwise";
import turfLength from "@turf/length";

const geojsonGeomTypes = [
  "Polygon",
  "MuliPolygon",
  "LineString",
  "MultiLineString",
  "Point",
  "MultiPoint",
];
export function bbox(data) {
  let ftr = data;
  // if geometry passed, convert to feature
  if (data?.type && geojsonGeomTypes.includes(data.type)) {
    ftr = feature(data);
  }
  return turfBbox(ftr);
}

const defaultFitBoundsOpts = { padding: 50, animate: false, maxZoom: 18 };
export function fitBounds({
  bbox: boundingBox,
  geojson,
  map,
  options = defaultFitBoundsOpts,
}) {
  try {
    if (boundingBox) {
      map.fitBounds(boundingBox, { ...defaultFitBoundsOpts, ...options });
    } else if (geojson) {
      const bb = bbox(geojson);
      map.fitBounds(bb, { ...defaultFitBoundsOpts, ...options });
    }
    return true;
  } catch (e) {
    return e;
  }
}

const UNIT_CONVERSION_TABLE = {
  distance: {
    ft: { scale: 0.3048, offset: 0 },
    in: { scale: 0.0254, offset: 0 },
    cm: { scale: 0.01, offset: 0 },
    km: { scale: 1000, offset: 0 },
    mm: { scale: 0.001, offset: 0 },
    mi: { scale: 1609.344, offset: 0 },
    m: { scale: 1, offset: 0 },
  },
  area: {
    ac: { scale: 0.4046856422, offset: 0 },
    ft2: { scale: 0.0000092903, offset: 0 },
    mm2: { scale: 0.0000000001, offset: 0 },
    m2: { scale: 0.0001, offset: 0 },
    ha: { scale: 1, offset: 0 },
    cuerda: { scale: 0.393, offset: 0 },
  },
};

function convertUnit(fromScale, toScale, val, offset) {
  return (fromScale * val) / toScale + (offset ?? 0);
}

export function convertArea(m2, outUnits) {
  try {
    const areaOpts = UNIT_CONVERSION_TABLE.area;
    const areaObj = areaOpts[outUnits];
    if (!areaObj?.scale) {
      throw Error(`Invalid output unit: ${outUnits}`);
    }
    return convertUnit(areaOpts.m2.scale, areaObj.scale, m2, areaObj.offset);
  } catch (e) {
    console.error(`Failed converting area to: ${outUnits}`);
  }
}

export function convertLength(km, outUnits) {
  try {
    const distanceOpts = UNIT_CONVERSION_TABLE.distance;
    const distObj = distanceOpts[outUnits];
    if (!distObj?.scale) {
      throw Error(`Invalid output unit: ${outUnits}`);
    }
    return convertUnit(
      distanceOpts.km.scale,
      distObj.scale,
      km,
      distObj.offset
    );
  } catch (e) {
    console.error(`Failed converting length to: ${outUnits}`);
  }
}

/**
 *
 * outUnits: see UNIT_CONVERSION_TABLE above*/
export function getArea({ geometry, outUnits = "ac" }) {
  let area;
  try {
    const areaMeters = turfArea(geometry);
    let areaConverted = areaMeters;
    if (outUnits !== "meters") {
      areaConverted = convertArea(areaMeters, outUnits);
    }
    area = round(areaConverted, 2);
  } catch (e) {
    console.error(e);
  }
  return area;
}

/**
 *
 * outUnits: see UNIT_CONVERSION_TABLE above
 */
export function getLength(fc, { outUnits = "mi" }) {
  // defaults to km
  const km = turfLength(fc);
  let finalLength = km;

  if (outUnits !== "kilometers") {
    finalLength = convertLength(km, outUnits);
  }
  return round(finalLength, 2);
}

export function getCenter(input) {
  let centerPoint;
  try {
    centerPoint = center(input);
  } catch (e) {
    console.error(e);
  }
  return centerPoint;
}

export function getPointOnFeature(input) {
  let point;
  try {
    point = pointOnFeature(input);
  } catch (e) {
    console.error(e);
  }
  return point;
}

export function combineFc(fc) {
  return combine(fc);
}

export function flatten(fc) {
  return turfFlatten(fc);
}

/**
 *
 * @param {(FeatureCollection|Geometry|Feature)} geojson input to be buffered
 * @param {number} radius distance to draw the buffer
 * @param {*} [opts] turf.buffer options
 * @param {string} [opts.units] supported are miles, feet, kilometers, meters and degrees
 * @param {number} [opts.steps=64] number of steps
 */
export function buffer(geojson, radius, opts) {
  if (opts?.units === "meters") {
    opts.units = "kilometers";
    radius /= 1000;
  }
  if (opts?.units === "feet") {
    opts.units = "miles";
    radius /= 5280;
  }
  return turfBuffer(geojson, radius, opts);
}

export function difference(poly1, poly2) {
  return turfDifference(poly1, poly2);
}

export function booleanClockwise(ring) {
  return turfBooleanClockwise(ring);
}

export function simplify(geojson, opts) {
  return turfSimplify(geojson, opts);
}
