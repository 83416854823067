export const es_locale = {
  "AttributionControl.ToggleAttribution": "Alternar atribución",
  "AttributionControl.MapFeedback": "Comentarios sobre el mapa",
  "FullscreenControl.Enter": "Entrar en pantalla completa",
  "FullscreenControl.Exit": "Salir de pantalla completa",
  "GeolocateControl.FindMyLocation": "Buscar mi ubicación",
  "GeolocateControl.LocationNotAvailable": "Ubicación no disponible",
  "LogoControl.Title": "Logotipo de Mapbox",
  "Map.Title": "Mapa",
  "NavigationControl.GoToDefaultExtent": "Ir a la extensión por defecto",
  "NavigationControl.ResetBearing": "Reajustar el rumbo al norte",
  "NavigationControl.ZoomIn": "Ampliar",
  "NavigationControl.ZoomOut": "Alejar",
  "ScrollZoomBlocker.CtrlMessage": "Usa ctrl + scroll para ampliar el mapa",
  "ScrollZoomBlocker.CmdMessage": "Usa ⌘+ scroll para ampliar el mapa",
  "TouchPanBlocker.Message": "Usa dos dedos para mover el mapa",
  "ToggleableControl.Title": "Conmutar el control del mapa",
};

export const fr_locale = {
  "AttributionControl.ToggleAttribution": "Basculer l'attribution",
  "AttributionControl.MapFeedback": "Retour à la carte",
  "FullscreenControl.Enter": "Entrer en plein écran",
  "FullscreenControl.Exit": "Quitter le plein écran",
  "GeolocateControl.FindMyLocation": "Trouver mon emplacement",
  "GeolocateControl.LocationNotAvailable": "Localisation non disponible",
  "LogoControl.Title": "Logo Mapbox",
  "Map.Title": "Carte",
  "NavigationControl.GoToDefaultExtent": "Aller à l'étendue par défaut",
  "NavigationControl.ResetBearing": "Remettre le cap au nord",
  "NavigationControl.ZoomIn": "Zoom avant",
  "NavigationControl.ZoomOut": "Zoom arrière",
  "ScrollZoomBlocker.CtrlMessage":
    "Utilisez ctrl + scroll pour zoomer sur la carte",
  "ScrollZoomBlocker.CmdMessage":
    "Utilisez ⌘ + scroll pour zoomer sur la carte",
  "TouchPanBlocker.Message": "Utilisez deux doigts pour déplacer la carte",
  "ToggleableControl.Title": "Basculer le contrôle de la carte",
};

export const en_locale = {
  "NavigationControl.GoToDefaultExtent": "Go to default extent",
  "ToggleableControl.Title": "Toggle map control",
};

const mapboxLocales = {
  es_locale,
  fr_locale,
  en_locale,
};

export default mapboxLocales;
