import { Route } from "react-router-dom";
import CompareView from "./Compare";
import Box from "@mui/material/Box";
import { ImageryExtract } from "./Extract";
import { ImageryImport } from "./Import";
import Prescription from "./RX";
import ManageImagery from "./Manage/ManageImagery";

export default function ImageryView() {
  return (
    <Box style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Route path="/:org/:season/imagery/compare" exact>
        <CompareView />
      </Route>
      <Route path="/:org/:season/imagery/extract" exact>
        <ImageryExtract />
      </Route>
      <Route path="/:org/:season/imagery/import" exact>
        <ImageryImport />
      </Route>
      <Route path="/:org/:season/imagery/manage" exact>
        <ManageImagery />
      </Route>
      <Route path="/:org/:season/imagery/rx" exact>
        <Prescription />
      </Route>
    </Box>
  );
}
