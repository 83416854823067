import { useOperationsSummary } from "api/operations/useOperationsSummary";
import { useOrgState } from "providers";
import Skeleton from "@mui/material/Skeleton";
import SummaryCardView from "lib/Card/SummaryCardView";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
// import { AppliedIcon, HarvestedIcon, PlantedIcon, TilledIcon } from "lib/Icons";

export default function OperationsSummaryView({
  onSelect,
  activeType,
}: {
  onSelect: (_type: string) => void;
  activeType: string;
}) {
  const { org, season } = useOrgState();
  const summaryQ = useOperationsSummary(org, season?.id);
  return (
    <>
      <Grid container spacing={2}>
        {summaryQ.isLoading ? (
          <>
            {[1, 2, 3, 4].map((i) => (
              <Grid key={i} xs={6} lg={3}>
                <SummaryCardView
                  variant="outlined"
                  value={<Skeleton />}
                  label={<Skeleton />}
                />
              </Grid>
            ))}
          </>
        ) : summaryQ.data ? (
          summaryQ.data?.map((d) => {
            const opType = d?.operationEn?.toLowerCase();
            return (
              <Grid key={d.operation} xs={12} sm={6} xl={3}>
                <SummaryCardView
                  variant="outlined"
                  label={
                    <Stack
                      spacing={1}
                      direction={"row"}
                      sx={{ alignItems: "center" }}
                    >
                      {/* {opType === "planted" ? (
                        <PlantedIcon />
                      ) : opType === "harvested" ? (
                        <HarvestedIcon />
                      ) : opType === "applied" ? (
                        <AppliedIcon />
                      ) : opType === "tilled" ? (
                        <TilledIcon />
                      ) : null} */}
                      <Typography>{d.operation}</Typography>
                    </Stack>
                  }
                  onClick={() => {
                    onSelect(opType);
                  }}
                  value={`${d?.totalArea?.toLocaleString()} ${d?.totalAreaUom?.toUpperCase()}`}
                  sx={{
                    backgroundColor:
                      activeType === opType ? `${d.color}40` : undefined,
                    height: "100%",
                    borderColor: d.color,
                  }}
                />
              </Grid>
            );
          })
        ) : null}
      </Grid>
    </>
  );
}
