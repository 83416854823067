import axios from "axios";
import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { BBox, MultiPolygon, Polygon } from "geojson";
import { ICollectEvent } from "types";

export const collectEventKeys = {
  all: (org: string) => ["organization", org, "collectEvents"] as const,
  allBy: (
    org: string,
    geometry?: Polygon | MultiPolygon | BBox,
    sensorTypeId?: string
  ) =>
    [
      "organization",
      org,
      "collectEvents",
      "geometry",
      geometry,
      sensorTypeId,
    ] as const,
  byDemAndDsm: (org: string, demId: string, dsmId: string) =>
    [
      "organization",
      org,
      "collectEvents",
      "plant-height",
      demId,
      dsmId,
    ] as const,
  rasters: (org: string) =>
    ["organization", org, "collectEvents", "rasters"] as const,
};

export function useCollectEventsByGeometry(
  org: string,
  geometry: Polygon | MultiPolygon,
  sensorTypeId?: string
) {
  const getCollectEvents = useCallback(async () => {
    const res = await axios.post<ICollectEvent[]>(
      `/${org}/collectevents/geometry`,
      {
        geometry: JSON.stringify(geometry),
        sensorTypeId,
      }
    );

    return res?.data?.map((item) => {
      const beginDate = new Date(item.beginOnUtc).toLocaleDateString();
      return {
        label: `${beginDate}${item.name ? ` (${item.name})` : ""}`,
        ...item,
      };
    });
  }, [geometry, org, sensorTypeId]);

  return useQuery({
    queryKey: collectEventKeys.allBy(org, geometry, sensorTypeId),
    queryFn: getCollectEvents,
    enabled: !!org && !!geometry && !!sensorTypeId,
  });
}

export function useCollectEvents({
  bbox,
  enabled,
  org,
  sensorTypeId,
}: {
  bbox?: BBox;
  enabled?: boolean;
  org: string;
  sensorTypeId?: string;
}) {
  const queryFn = useCallback(async () => {
    const res = await axios.get<(ICollectEvent & { slug?: string })[]>(
      `/${org}/collectevents?${bbox ? `bbox=${bbox}` : ""}${
        sensorTypeId ? `${bbox ? "&" : ""}sensorTypeId=${sensorTypeId}` : ""
      }`
    );
    const mapped = res?.data?.map((item) => {
      const beginDate = new Date(item.beginOnUtc).toLocaleDateString();
      return {
        value: item.id || item.slug,
        label: `${beginDate}${item.name ? ` (${item.name})` : ""}`,
        extent: item.extent,
        ...item,
      };
    });
    return mapped;
  }, [bbox, org, sensorTypeId]);

  return useQuery({
    queryKey: collectEventKeys.allBy(org, bbox, sensorTypeId),
    queryFn,
    enabled: !!org && !!(enabled !== false),
  });
}
