import useFetchWithToken from "components/useFetchWithToken";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { IFetchResponse } from "types";
import { FeatureCollection } from "geojson";

export function useAssetsUpload({
  org,
  seasonId,
  onSettled,
  type,
}: {
  org: string;
  seasonId: string;
  type: "plant" | "fixed" | "animal";
} & UseMutationOptions<
  IFetchResponse<{ count: number; success: boolean }>,
  Error,
  FeatureCollection
>) {
  const { fetchData } = useFetchWithToken<{
    count: number;
    success: boolean;
  }>();

  const mutation = useMutation<
    IFetchResponse<{ count: number; success: boolean }>,
    Error,
    FeatureCollection
  >({
    mutationFn: async (body: FeatureCollection) => {
      const url = `/${org}/assets/${type}/geodata/season/${seasonId}`;
      return fetchData(url, {
        method: "POST",
        body: JSON.stringify(body),
      });
      // if (res.isError) {
      //   throw Error(res.errorMessage);
      // }
      // return res?.data;
    },
    onSettled,
  });
  return { uploadAssets: mutation.mutateAsync, ...mutation };
}
