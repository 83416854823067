const base = process.env.REACT_APP_TILESERVER_ORIGIN;

export function generateCollectEventTileUrl({
  collectEventId,
  tenant,
  org,
  rasterType,
  sensorType,
}: {
  collectEventId: string;
  tenant: string;
  org: string;
  rasterType: string;
  sensorType: string;
}) {
  const url = `${base}/cog/noauth/tile/${tenant}/${org}/${collectEventId}/${sensorType}/${rasterType}/{z}/{x}/{y}`;
  return url;
}
