import { useCallback, useMemo } from "react";
import useFetchWithToken from "components/useFetchWithToken";
import { useQuery } from "@tanstack/react-query";
import { useInventoryMapThemes } from "api/useInventoryMapThemes";
import { FeatureCollection } from "geojson";

function mergeProps(
  fc: FeatureCollection,
  themes: { id: string }[],
  list?: { id: string }[]
) {
  fc.features.forEach((ftr) => {
    const listItem = list?.find((li) => li.id === ftr.id);
    const themeItem = themes?.find((li) => li.id === ftr.id);
    if (themeItem) {
      Object.assign(ftr.properties, themeItem);
    }
    if (listItem) {
      Object.assign(ftr.properties, listItem);
    }
  });
  return fc;
}

export function useImagerySubfileds({
  org,
  seasonId,
  fieldId,
}: {
  org: string;
  seasonId?: string;
  fieldId?: string;
}) {
  const { fetchData: fetchGeodata } = useFetchWithToken<FeatureCollection>();
  const mapThemesQuery = useInventoryMapThemes(org, "subfields", seasonId);
  const queryKey = useMemo(
    () => [
      "organization",
      org,
      "subfields",
      "geodata",
      "fields",
      fieldId,
      "imagery",
      "sb",
    ],
    [fieldId, org]
  );

  const fetchGeodataFn = useCallback(async () => {
    const res = await fetchGeodata(
      `/${org}/subfields/geodata/fields/${fieldId}/imagery/sb`
    );
    if (mapThemesQuery.data) {
      mergeProps(res.data, mapThemesQuery.data.raw);
    }
    return res?.data ?? null;
  }, [fetchGeodata, fieldId, mapThemesQuery.data, org]);

  return useQuery<FeatureCollection>({
    queryKey,
    queryFn: fetchGeodataFn,
    enabled: !!fieldId,
  });
}
