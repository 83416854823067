import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { featureCollection } from "@turf/helpers";
import { fitBounds } from "components/Map/utils";

const InventoryStateContext = React.createContext();
const InventoryDispatchContext = React.createContext();

function InventoryProvider({
  children,
  fieldsGeodata,
  inventoryMap,
  inventoryFtrs,
  drawData,
  setInventoryFtrs,
  setIsMapLoading,
  onHideMap,
  onShowMap,
  onShowFields,
  onHideFields,
  onToggleFieldLabels,
  setDrawOptions,
  setOutsideDrawData,
  onEnableDraw,
  onDisableDraw,
  setColSizes,
  setTitle,
  setLabelProp,
}) {
  const inventoryMapRef = useRef(inventoryMap);
  const [ftrsClicked, setFtrsClicked] = useState();
  const fitInventoryBounds = useCallback(
    ({ geojson, bbox }) => {
      fitBounds({
        map: inventoryMap,
        geojson,
        bbox,
        options: {
          padding: 50,
          animate: false,
        },
      });
    },
    [inventoryMap]
  );
  // TODO: handle zoom via this method for all inventory views
  const zoomToField = useCallback(
    (id) => {
      if (inventoryMap && id && fieldsGeodata?.features) {
        const geojson = fieldsGeodata?.features.find((f) => f.id === id);
        fitInventoryBounds({ geojson });
        return geojson;
      }
    },
    [fieldsGeodata?.features, inventoryMap, fitInventoryBounds]
  );
  const getFieldGeodata = useCallback(
    (id) => {
      if (id && fieldsGeodata?.features) {
        const geojson = fieldsGeodata?.features.find((f) => f.id === id);
        return geojson;
      }
    },
    [fieldsGeodata?.features]
  );

  const zoomToFtrIds = useCallback(
    (ids) => {
      if (inventoryMap && ids && inventoryFtrs) {
        const ftrs = inventoryFtrs?.features.filter((f) => ids.includes(f.id));
        fitInventoryBounds({ geojson: featureCollection(ftrs) });
      }
    },
    [fitInventoryBounds, inventoryFtrs, inventoryMap]
  );

  // TODO: use this method for all inventory views with selection layers
  const highlightLayerFeatures = useCallback((ids) => {
    if (inventoryMapRef.current && ids) {
      const lineFilter = [
        "match",
        ["geometry-type"],
        ["LineString", "MultiLineString", "Polygon", "MultiPolygon"],
        true,
        false,
      ];
      const pntFilter = [
        "match",
        ["geometry-type"],
        ["Point", "MultiPoint"],
        true,
        false,
      ];
      const idFilter = ["in", ["get", "id"], ["literal", ids]];
      const lineLayer = inventoryMapRef.current.getLayer("inventoryActiveLine");
      const pntLayer = inventoryMapRef.current.getLayer("inventoryActivePnt");
      if (inventoryMapRef.current) {
        // update filter
        if (lineLayer) {
          inventoryMapRef.current.setFilter("inventoryActiveLine", [
            "all",
            lineFilter,
            idFilter,
          ]);
        }
        if (pntLayer) {
          inventoryMapRef.current.setFilter("inventoryActivePnt", [
            "all",
            pntFilter,
            idFilter,
          ]);
        }
      }
    }
  }, []);
  const [legendData, setLegendData] = useState();
  useEffect(() => {
    inventoryMapRef.current = inventoryMap;
  }, [inventoryMap]);
  return (
    <InventoryStateContext.Provider
      value={{
        legendData,
        drawData,
        fieldsGeodata,
        inventoryMap,
        ftrsClicked,
      }}
    >
      <InventoryDispatchContext.Provider
        value={{
          setInventoryFtrs,
          setLegendData,
          setIsMapLoading,
          highlightLayerFeatures,
          zoomToField,
          getFieldGeodata,
          zoomToFtrIds,
          fitInventoryBounds,
          hideMap: useCallback(onHideMap, [onHideMap]),
          showMap: useCallback(onShowMap, [onShowMap]),
          hideFields: useCallback(onHideFields, [onHideFields]),
          toggleFieldLabels: useCallback(onToggleFieldLabels, [
            onToggleFieldLabels,
          ]),
          showFields: useCallback(onShowFields, [onShowFields]),
          enableDraw: useCallback(onEnableDraw, [onEnableDraw]),
          disableDraw: useCallback(onDisableDraw, [onDisableDraw]),
          setDrawOptions: useCallback(setDrawOptions, [setDrawOptions]),
          setColSizes: useCallback(setColSizes, [setColSizes]),
          setOutsideDrawData: useCallback(setOutsideDrawData, [
            setOutsideDrawData,
          ]),
          setTitle: useCallback(setTitle, [setTitle]),
          setLabelProp: useCallback(setLabelProp, [setLabelProp]),
          setFeaturesClicked: useCallback((ftrs) => {
            setFtrsClicked(ftrs);
          }, []),
        }}
      >
        {children}
      </InventoryDispatchContext.Provider>
    </InventoryStateContext.Provider>
  );
}
InventoryProvider.defaultProps = {
  fieldsGeodata: null,
  inventoryMap: null,
  drawData: null,
  inventoryFtrs: null,
};

InventoryProvider.propTypes = {
  children: PropTypes.any,
  fieldsGeodata: PropTypes.shape({
    features: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  inventoryMap: PropTypes.shape({
    getSource: PropTypes.func,
    getLayer: PropTypes.func,
    addLayer: PropTypes.func,
    setFilter: PropTypes.func,
  }),
  drawData: PropTypes.shape({}),
  setInventoryFtrs: PropTypes.func.isRequired,
  setIsMapLoading: PropTypes.func.isRequired,
  onHideMap: PropTypes.func.isRequired,
  onShowMap: PropTypes.func.isRequired,
  onShowFields: PropTypes.func.isRequired,
  onHideFields: PropTypes.func.isRequired,
  onToggleFieldLabels: PropTypes.func.isRequired,
  setDrawOptions: PropTypes.func.isRequired,
  setColSizes: PropTypes.func.isRequired,
  onEnableDraw: PropTypes.func.isRequired,
  onDisableDraw: PropTypes.func.isRequired,
  setOutsideDrawData: PropTypes.func.isRequired,
  setTitle: PropTypes.func.isRequired,
  setLabelProp: PropTypes.func.isRequired,
  inventoryFtrs: PropTypes.shape({
    features: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

function useInventoryState() {
  const context = React.useContext(InventoryStateContext);
  if (context === undefined) {
    throw new Error(
      "useInventoryState must be used within a InventoryProvider"
    );
  }
  return context;
}

function useInventoryDispatch() {
  const context = React.useContext(InventoryDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useInventoryDispatch must be used within a InventoryProvider"
    );
  }
  return context;
}

export { InventoryProvider, useInventoryState, useInventoryDispatch };
