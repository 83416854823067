import React, { useState } from "react";
import { User, useAuth0 } from "@auth0/auth0-react";
import { IOrganization } from "types";
import { useUserData } from "api/useUserData";
import { UseQueryResult } from "@tanstack/react-query";
import { UserData } from "api/types";

interface IAppState {
  user: User;
  organizations?: IOrganization[];
  tenant?: string;
  userDataState?: UseQueryResult<UserData, Error>;
  userRole?: string;
  sidebarOpen: boolean;
  setSidebarOpen: (_open: boolean) => void;
}

const AppStateContext = React.createContext({} as IAppState);

function AppProvider({ children }: { children: React.ReactNode }) {
  const userQ = useUserData();
  const { user } = useAuth0();
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(true);

  return (
    <AppStateContext.Provider
      value={{
        user: userQ.data?.tenantId && userQ.data?.organizations ? user : null,
        tenant: userQ.data?.tenantId,
        userDataState: userQ,
        userRole: userQ.data?.role,
        organizations: userQ.data?.organizations,
        sidebarOpen,
        setSidebarOpen,
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
}

function useAppState() {
  const context = React.useContext(AppStateContext);
  if (context === undefined) {
    throw new Error("useAppState must be used within an AppProvider");
  }
  return context;
}

export { AppProvider, useAppState };
