import { useTranslation } from "react-i18next";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { IBaseAsset } from "types/IAssetType";

interface ICellProps {
  value: string;
  row: {
    original: {
      count: number;
      assetGroup: string;
      farm: string;
    };
  };
}

export function useAssetColumns() {
  const { t } = useTranslation();
  function getDateHeader(header?: string, prop?: string) {
    return {
      Header: header || t("common.date"),
      id: prop || "bornOnUtc",
      accessor: (row: IBaseAsset) => {
        return new Date(row.bornOnUtc).toLocaleDateString();
      },
      disableGlobalFilter: true,
    };
  }
  // const fillerDate = (header) => {
  //   return { Header: header || t('common.date'), id: 'date', accessor: 'null' };
  // };

  const cidHeader = {
    Header: "ID",
    accessor: "cid",
  };
  const statusHeader = {
    Header: t("common.status"),
    accessor: "assetStatus",
    disableGlobalFilter: true,
  };
  const bornOnUtcDateHeader = {
    Header: t("common.date"),
    id: "bornOnUtc",
    accessor: (row: IBaseAsset) => {
      return new Date(row.bornOnUtc).toLocaleDateString();
    },
    disableGlobalFilter: true,
  };
  const breedHeader = {
    Header: t("common.breed"),
    accessor: "assetAnimalBreed",
    disableGlobalFilter: true,
  };
  const nameHeader = { Header: t("common.name"), accessor: "name" };
  const initCostHeader = {
    Header: t("common.initialCost"),
    accessor: "initialCost",
    disableGlobalFilter: true,
  };
  const ageHeader = {
    Header: t("common.age"),
    accessor: "age",
    disableGlobalFilter: true,
  };

  const typeCount = {
    Header: t("common.type"),
    accessor: "subtype",
    Cell: ({ value, row }: ICellProps) => {
      return (
        <>
          {value}{" "}
          <Chip
            sx={{ textTransform: "uppercase", ml: 1 }}
            size="small"
            color="primary"
            label={
              <Typography variant="caption">
                {`${row?.original?.count?.toLocaleString()} ${t(
                  "common.records"
                )}`}
              </Typography>
            }
          />
        </>
      );
    },
  };
  const fieldCount = {
    Header: t("common.field"),
    accessor: "field",
    Cell: ({ value, row }: ICellProps) => {
      return (
        <>
          <Box
            sx={{ mr: 1 }}
            style={{ display: "flex", flexDirection: "column", float: "left" }}
          >
            <span>{`${value}`}</span>
            {row?.original?.farm ? <small>{row?.original?.farm}</small> : null}
            {row?.original?.assetGroup ? (
              <small>{row?.original?.assetGroup}</small>
            ) : null}
          </Box>
          <Chip
            sx={{ textTransform: "uppercase", ml: 1 }}
            size="small"
            color="primary"
            label={
              <Typography variant="caption">
                {`${row?.original?.count?.toLocaleString()} ${t(
                  "common.records"
                )}`}
              </Typography>
            }
          />
        </>
      );
    },
  };
  const areaDistance = {
    Header: `${t("common.area")} / ${t("common.distance")}`,
    id: "areaLength",
    accessor: function FieldAccessor(row: IBaseAsset) {
      return (
        <>
          {row?.geometryType === "line"
            ? `${row?.length?.toLocaleString()} ${row?.lengthUom}`
            : row?.area
            ? `${row?.area?.toLocaleString()} ${row?.areaUom}`
            : ""}
        </>
      );
    },
    disableGlobalFilter: true,
  };

  return {
    bornOnUtcDateHeader,
    nameHeader,
    initCostHeader,
    ageHeader,
    cidHeader,
    subtype: {
      plant: [typeCount],
      fixed: [typeCount, areaDistance],
      equipment: [typeCount],
      animal: [typeCount],
    },
    subtypeAndFieldGroup: {
      plant: [
        fieldCount,
        // fillerDate(), cidHeader, statusHeader, ageHeader
      ],
      animal: [
        fieldCount,
        // fillerDate(t('inventory.assets.bornOn')),
        // cidHeader,
        // statusHeader,
        // ageHeader,
        // breedHeader,
        // hidden for search
        { Header: "", accessor: "assetGroup" },
      ],
      fixed: [
        fieldCount,
        // fillerDate(),
        // nameHeader,
        // initCostHeader,
        // ageHeader,
        // areaDistance,
      ],
      equipment: [fieldCount],
    },
    subtypeAndFieldItems: {
      plant: [getDateHeader(), cidHeader, statusHeader, ageHeader],
      animal: [
        getDateHeader(t("inventory.assets.bornOn")),
        nameHeader,
        cidHeader,
        statusHeader,
        ageHeader,
        breedHeader,
      ],
      fixed: [getDateHeader(), nameHeader, initCostHeader, ageHeader],
      equipment: [getDateHeader()],
    },
  };
}
