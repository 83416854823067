import React from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "lib";
// import { useTranslation } from 'react-i18next';

export const TotalMassTooltip = ({ active, payload }) => {
  // const { t } = useTranslation();
  if (active && payload?.length) {
    const { name, totalMass, totalMassUom } = payload[0]?.payload;
    return (
      <ThemeProvider mode="light">
        <Paper
          elevation={4}
          // sx={{ p: 1, bgcolor: "Background", color: "common.black" }}
          style={{ padding: "0.5rem", opacity: 0.9 }}
        >
          <Typography
            variant="h6"
            style={{ textAlign: "center", fontWeight: "bold" }}
          >{`${name}`}</Typography>
          {totalMass ? (
            <>
              <div>{`${Number(
                totalMass
              ).toLocaleString()} ${totalMassUom}`}</div>
            </>
          ) : null}
        </Paper>
      </ThemeProvider>
    );
  }

  return null;
};
export default TotalMassTooltip;

TotalMassTooltip.defaultProps = {
  active: false,
  payload: null,
};

TotalMassTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      payload: PropTypes.shape({}),
    })
  ),
};
