import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { ThemeProvider } from "lib";
import AddIcon from "@mui/icons-material/Add";
import HomeIcon from "@mui/icons-material/Home";
import RemoveIcon from "@mui/icons-material/Remove";

function ZoomComponent({ showHome, defaultBounds, map }) {
  const zoomInText = map?._getUIString
    ? map?._getUIString("NavigationControl.ZoomIn")
    : "Zoom in";
  const zoomOutText = map?._getUIString
    ? map?._getUIString("NavigationControl.ZoomOut")
    : "Zoom out";
  const defaultExtentText = map?._getUIString
    ? map?._getUIString("NavigationControl.GoToDefaultExtent")
    : "Go to default extent";
  const [zoomInDisabled, setZoomInDisabled] = useState();
  const [zoomOutDisabled, setZoomOutDisabled] = useState();
  function zoomIn() {
    map.zoomIn();
  }
  function zoomOut() {
    map.zoomOut();
  }
  function zoomHome() {
    if (defaultBounds) {
      map.fitBounds(defaultBounds, {
        padding: 50,
      });
    }
  }

  useEffect(() => {
    function updateZoom() {
      const zoom = map.getZoom();
      const isMaxZoom = zoom === map.getMaxZoom();
      const isMinZoom = zoom === map.getMinZoom();
      setZoomInDisabled(isMaxZoom);
      setZoomOutDisabled(isMinZoom);
    }
    map?.on("zoom", updateZoom);
    return () => {
      map.off("zoom", updateZoom);
    };
  }, [map]);

  return (
    <Box
      sx={(t) => {
        return {
          display: "flex",
          flexDirection: "column",
          button: {
            borderRadius: "0.25rem",
            "&:hover": {
              backgroundColor:
                t.palette.mode === "dark"
                  ? "#23272b !important"
                  : "#d9d9d9 !important",
            },
            backgroundColor:
              t.palette.mode === "dark"
                ? "#343a40 !important"
                : "#fff !important",
          },
          svg: {
            fontSize: "1rem",
          },
        };
      }}
    >
      <IconButton
        sx={{
          borderBottomRightRadius: "0 !important",
          borderBottomLeftRadius: "0 !important",
        }}
        title={zoomInText}
        disabled={zoomInDisabled}
        onClick={zoomIn}
      >
        <AddIcon />
      </IconButton>
      {showHome ? (
        <IconButton
          sx={{
            borderTop: (t) =>
              `solid 1px ${t.palette.mode === "dark" ? "#000" : "#ccc"}`,
            borderRadius: "0 !important",
          }}
          title={defaultExtentText}
          disabled={zoomOutDisabled}
          onClick={zoomHome}
        >
          <HomeIcon />
        </IconButton>
      ) : null}
      <IconButton
        sx={{
          borderTop: (t) =>
            `solid 1px ${t.palette.mode === "dark" ? "#000" : "#ccc"}`,
          borderTopRightRadius: "0 !important",
          borderTopLeftRadius: "0 !important",
        }}
        title={zoomOutText}
        onClick={zoomOut}
      >
        <RemoveIcon />
      </IconButton>
    </Box>
  );
}

ZoomComponent.defaultProps = {
  map: null,
  defaultBounds: null,
  showHome: null,
};

ZoomComponent.propTypes = {
  map: PropTypes.shape({
    zoomIn: PropTypes.func,
    zoomOut: PropTypes.func,
    getZoom: PropTypes.func,
    getMaxZoom: PropTypes.func,
    getMinZoom: PropTypes.func,
    fitBounds: PropTypes.func,
    on: PropTypes.func,
    off: PropTypes.func,
    _getUIString: PropTypes.func,
  }),
  showHome: PropTypes.bool,
  defaultBounds: PropTypes.arrayOf(PropTypes.number, PropTypes.number),
};
/**
 * See here for default nav control code:
 * https://github.com/mapbox/mapbox-gl-js/blob/main/src/ui/control/navigation_control.js
 */
class ZoomControl {
  constructor(options) {
    this.options = { ...ZoomComponent.defaultProps, ...options };
    this.container = document.createElement("div");
    this.container.className = "mapboxgl-ctrl cai-mapboxgl-ctrl";
    this.container.addEventListener("contextmenu", (e) => e.preventDefault());
    return this;
  }

  renderReactComponent() {
    ReactDOM.render(
      <ThemeProvider theme={this.options.theme}>
        <ZoomComponent
          map={this.map}
          showHome={this.options.showHome}
          defaultBounds={this.options.defaultBounds}
        />
      </ThemeProvider>,
      this.container
    );
  }

  onAdd(map) {
    this.map = map;
    this.renderReactComponent();
    return this.container;
  }

  updateOptions(options) {
    this.options = { ...this.options, ...options };
    if (this.container) {
      this.renderReactComponent();
    }
  }

  onRemove() {
    ReactDOM.unmountComponentAtNode(this.container);
    this.container.parentNode.removeChild(this.container);
    this.map = undefined;
  }
}

export default ZoomControl;
