import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  DatePicker as DP,
  DatePickerProps,
} from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/es";
import "dayjs/locale/fr";
import { useTheme } from "@mui/material";

export default function DatePicker({
  defaultValue,
  onChange,
  minDate,
  maxDate,
  value,
  ...props
}: {
  defaultValue?: Date;
  minDate?: Date;
  maxDate?: Date;
  onChange?: (_d?: Date) => void;
  value?: Date;
} & Omit<
  DatePickerProps<Dayjs>,
  "defaultValue" | "onChange" | "minDate" | "maxDate" | "value"
>) {
  const val = value && dayjs(value);
  const defaultVal = defaultValue && dayjs(defaultValue);
  const minVal = minDate && dayjs(minDate);
  const maxVal = maxDate && dayjs(maxDate);
  const theme = useTheme();
  const locale =
    theme.locale === "esES" ? "es" : theme.locale === "frFR" ? "fr" : "en";
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      <DP
        {...props}
        value={val}
        defaultValue={defaultVal}
        {...(minVal
          ? {
              minDate: minVal,
            }
          : {})}
        {...(maxVal
          ? {
              maxDate: maxVal,
            }
          : {})}
        onChange={(d) => {
          onChange && onChange(d?.toDate());
        }}
      />
    </LocalizationProvider>
  );
}
