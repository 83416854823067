import { useRef, useEffect } from "react";
import mapboxgl, {
  LngLatBoundsLike,
  Map,
  MapboxEvent,
  MapboxOptions,
} from "mapbox-gl";
import MapboxLanguage from "@mapbox/mapbox-gl-language";
import { useTheme, SxProps, Theme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import useMapboxGl from "./useMapboxGl";
import ZoomControl from "./controls/ZoomControl";
import mapboxLocales from "./mapbox-locales";
import BasemapControl from "components/Map/controls/BasemapControl";
import "./map-styles.scss";

export type BasemapStyleId =
  | "satellite-streets-v12"
  | "streets-v12"
  | "outdoors-v12"
  | "dark-v11"
  | "light-v11"
  | "satellite-v9";

interface MapViewProps
  extends Omit<MapboxOptions, "accessToken" | "container" | "style"> {
  accessToken: string;
  events?: {
    [key: string]: (_e: MapboxEvent) => void;
  };
  onBasemapSelect?: (_b: BasemapStyleId) => void;
  sx?: SxProps;
  showBasemapSelect?: boolean;
  showFullScreen?: boolean;
  showHome?: boolean;
  defaultBounds?: LngLatBoundsLike;
  style?: BasemapStyleId;
}

const mapboxButtonStyleOverrides = {
  backgroundColor: (theme: Theme) =>
    theme.palette.mode === "light" ? "#f2f2f2" : "#343a40",
  "&:hover": {
    backgroundColor: (theme: Theme) =>
      theme.palette.mode === "light" ? "#fff" : "#23272b",
  },
};

const fullScreenStyleOverrides = {
  ...mapboxButtonStyleOverrides,
  ".mapboxgl-ctrl-icon": (theme: Theme) => ({
    height: "25px",
    filter: `brightness(0) invert(${
      theme.palette.mode === "light" ? "0" : "1"
    })`,
  }),
};

export default function MapView({
  events,
  onBasemapSelect,
  sx = { height: "100%", flexGrow: 1 },
  showBasemapSelect,
  showFullScreen,
  showHome,
  defaultBounds,
  style,
  ...rest
}: MapViewProps) {
  const theme = useTheme();
  const ref = useRef<Map>();
  const divRef = useRef(null);
  const zoomControlRef = useRef<ZoomControl>();
  const langCtrlRef = useRef<MapboxLanguage>();
  const basemapCtrlRef = useRef<BasemapControl>();
  const fullScreenControlRef = useRef<mapboxgl.FullscreenControl>();
  const lang = (theme?.locale?.slice(0, 2) || "en") as "es" | "en" | "fr";
  const { initMap } = useMapboxGl(
    {
      container: divRef.current ?? undefined,
      style: `mapbox://styles/mapbox/satellite-streets-v12`,
      maxZoom: 24,
      locale: lang ? mapboxLocales[`${lang}_locale`] : mapboxLocales.en_locale,
      bounds: defaultBounds,
      ...rest,
    },
    {
      ...events,
      "style.load": (e) => {
        // add layer to target if wanting to add layers below all map layers,
        // but above basemaps
        // and call events["style.load"] passed in options after
        const map = e.target;
        map.addSource("z-index-source", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [],
          },
        });
        map.addLayer({
          id: "z-index-layer",
          type: "fill",
          source: "z-index-source",
        });

        // add sky styling with `setFog` that will show when the map is highly pitched
        map.setFog({
          "horizon-blend": 0,
          ["color"]: "#B2FFFF",
          "high-color": "#333",
          "space-color": "#000",
          "star-intensity": 0.1,
        });
        events["style.load"] && events["style.load"](e);
      },
    }
  );

  useEffect(() => {
    const m = ref.current;
    return () => {
      if (m) {
        m.remove();
      }
    };
  }, []);

  useEffect(() => {
    if (divRef.current && !ref.current && mapboxgl.supported()) {
      const map = initMap(divRef.current);
      ref.current = map;
      const langCtrl = new MapboxLanguage({
        defaultLanguage: lang,
      });
      langCtrlRef.current = langCtrl;
      basemapCtrlRef.current = new BasemapControl({
        theme,
        onChange: (s) => {
          // MapboxLanguage throws an error when switching to satellite-v9
          // so removing the control in that instance
          if (s === "satellite-v9") {
            if (ref.current.hasControl(langCtrl)) {
              ref.current.removeControl(langCtrl);
            }
          } else {
            if (!ref.current.hasControl(langCtrl)) {
              ref.current.addControl(langCtrl);
            }
          }
          onBasemapSelect && onBasemapSelect(s);
        },
      });
      if (style !== "satellite-v9") {
        map.addControl(langCtrl);
      }
      const zCtrl = new ZoomControl({
        showHome: showHome ?? (defaultBounds && showHome !== false)!,
        defaultBounds,
        theme,
        map,
      });
      map.addControl(zCtrl, "top-left");
      zoomControlRef.current = zCtrl;
      map
        .once("load")
        .then(() => {
          if (showBasemapSelect !== false) {
            map.addControl(basemapCtrlRef.current, "top-left");
          }
        })
        .catch((e) => {
          console.error("Failed to load mapbox map", e);
        });
      // add compass/pitch control (hide zoom in lieu of custom zoom control)
      const nav = new mapboxgl.NavigationControl({
        showZoom: false,
        visualizePitch: true,
      });
      map.addControl(nav, "top-left");
    }
  }, [
    initMap,
    divRef,
    showBasemapSelect,
    showFullScreen,
    showHome,
    defaultBounds,
    theme,
    lang,
    style,
    onBasemapSelect,
  ]);

  // useEffect(() => {
  //   if (mapInstance) {
  //     mapRef(mapInstance);
  //   }
  // }, [mapInstance, mapRef]);

  // add full screen control
  useEffect(() => {
    if (showFullScreen && !fullScreenControlRef.current && ref.current) {
      fullScreenControlRef.current = new mapboxgl.FullscreenControl();
      ref.current.addControl(fullScreenControlRef.current, "bottom-left");
    } else if (!showFullScreen && ref.current) {
      if (
        fullScreenControlRef.current &&
        ref.current.hasControl(fullScreenControlRef.current)
      ) {
        ref.current.removeControl(fullScreenControlRef.current);
        fullScreenControlRef.current = undefined;
      }
    }
  }, [showFullScreen]);

  // update map style if it changes
  useEffect(() => {
    if (style && ref.current) {
      if (style === "satellite-v9") {
        if (
          langCtrlRef.current &&
          ref.current.hasControl(langCtrlRef.current)
        ) {
          ref.current.removeControl(langCtrlRef.current);
          langCtrlRef.current = null;
        }
      } else {
        if (!langCtrlRef.current) {
          const langCtrl = new MapboxLanguage({
            defaultLanguage: lang,
          });
          langCtrlRef.current = langCtrl;
          ref.current.addControl(langCtrl);
        }
      }
      ref.current.setStyle(`mapbox://styles/mapbox/${style}`);
    }
  }, [lang, style]);

  // update zoom control when defaultBounds changes
  useEffect(() => {
    if (ref.current && zoomControlRef.current && defaultBounds) {
      ref.current.fitBounds(defaultBounds);
      zoomControlRef.current.updateOptions({ defaultBounds });
    }
  }, [defaultBounds]);

  useEffect(() => {
    if (ref.current && zoomControlRef.current) {
      zoomControlRef.current.updateOptions({ theme });
    }
  }, [theme]);

  return !mapboxgl.supported() ? (
    <>Not supported</>
  ) : (
    <Box
      data-testid="mapbox-test"
      sx={{
        ".mapboxgl-ctrl-attrib.mapboxgl-compact": {
          minHeight: "24px",
        },
        ".mapboxgl-ctrl-group": {
          background: "none",
        },
        "button.mapboxgl-ctrl-fullscreen": {
          ...fullScreenStyleOverrides,
        },
        "button.mapboxgl-ctrl-shrink": {
          ...fullScreenStyleOverrides,
        },
        "button.mapboxgl-ctrl-compass": {
          height: 32,
          width: 32,
          ...mapboxButtonStyleOverrides,
        },
        ".mapboxgl-ctrl-cai": {
          height: 32,
          width: 32,
          ...mapboxButtonStyleOverrides,
        },
        ...sx,
      }}
      ref={divRef}
    />
  );
}
