import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import { ArrowLeftCircle, Printer } from "react-feather";
import dataToCsv from "components/dataToCsv";
import DataTable from "components/DataTable";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import DownloadCSVIcon from "../../../icons/DownloadCSVIcon";
import CAITextStackedIcon from "../../../icons/CAITextStackedIcon";

const MAX_TABLE_ROWS_PRINT = 200;
const MAX_TABLE_ROWS_NO_PAGING = 200;

export default function ReportView({
  org,
  season,
  data,
  title,
  expanded,
  columns,
  sortBy,
  color,
  titleIcon: TitleIcon,
  descriptionDetail,
  goBackUrl,
  isLoading,
  groupBy,
}) {
  const { t } = useTranslation();
  return (
    <Paper
      style={{
        flex: 1,
        overflow: "auto",
        borderRadius: "0.5rem 0.5rem 0 0",
        backgroundColor: "#fff",
        color: "#000",
        minHeight: "500px",
      }}
    >
      <Stack style={{ overflow: "hidden" }} className="report-container">
        <Stack className="report-header">
          <Stack
            sx={{ py: 1, px: 3 }}
            spacing={2}
            direction={"row"}
            alignItems={"center"}
            style={{
              fontSize: "2.25rem",
              color: color ? "#fff" : "#000",
              backgroundColor: color,
            }}
          >
            {TitleIcon ? (
              <TitleIcon size={48} color={color ? "#fff" : "#000"} />
            ) : null}
            <Typography
              variant="h5"
              component={"h2"}
              sx={{ px: 2 }}
              style={TitleIcon ? { borderLeft: "solid 1px" } : null}
            >
              {title || null}
            </Typography>
            <CAITextStackedIcon
              style={{ marginLeft: "auto" }}
              size={64}
              fill={color ? "#fff" : "#000"}
            />
          </Stack>
          <Box
            sx={{ px: 2, py: 1 }}
            style={{ alignItems: "center", display: "flex" }}
            className="sub-header"
          >
            <dl style={{ textTransform: "uppercase" }}>
              <span style={{ display: "flex" }}>
                <dt>{t("common.org")}: &nbsp;</dt>
                <dd>{org}</dd>
              </span>
              <span style={{ display: "flex" }}>
                <dt>{t("common.season")}: &nbsp;</dt>
                <dd>{season?.name}</dd>
              </span>
              {descriptionDetail ? (
                <span style={{ display: "flex" }}>
                  <dt>{descriptionDetail.dt}: &nbsp;</dt>
                  <dd>{descriptionDetail.dd}</dd>
                </span>
              ) : null}

              {goBackUrl ? (
                <div className="hide-print">
                  <Link to={goBackUrl}>
                    <ArrowLeftCircle />
                  </Link>
                </div>
              ) : null}
            </dl>
            <Box
              sx={{
                "*": {
                  color: "#000",
                },
                ml: "auto",
              }}
            >
              <IconButton
                size="small"
                sx={{ pointerEvents: "auto" }}
                disabled={data?.length > MAX_TABLE_ROWS_PRINT}
                title={
                  data?.length > MAX_TABLE_ROWS_PRINT
                    ? t("insights.cannotPrintDataOver", {
                        count: MAX_TABLE_ROWS_PRINT,
                      })
                    : t("insights.printOrSavePDF")
                }
                className="hide-print"
                onClick={() => {
                  window.print();
                }}
              >
                <Printer size={28} />
              </IconButton>
              <IconButton
                size="small"
                title={t("saveAsCSV")}
                className="hide-print"
                onClick={() => {
                  if (!data.length) {
                    return;
                  }
                  let cols = [...columns];
                  if (data[0]) {
                    const hasFarm = Object.prototype.hasOwnProperty.call(
                      data[0],
                      "farm"
                    );
                    if (hasFarm && !cols.find((c) => c.accessor === "farm")) {
                      cols = [
                        { Header: t("common.farm"), accessor: "farm" },
                      ].concat(columns);
                    }
                  }
                  // remove records button col from CSV download
                  if (
                    cols &&
                    cols.length &&
                    cols[cols.length - 1].id === "records"
                  ) {
                    cols.pop();
                  }
                  dataToCsv({
                    filename: `${org}-${season?.name}-${title
                      ?.replace(/\s/g, "-")
                      ?.toLowerCase()}`,
                    data,
                    columns: cols,
                  });
                }}
              >
                <DownloadCSVIcon />
              </IconButton>
            </Box>
          </Box>
        </Stack>
        <Box
          sx={{
            "*": { color: "#000" },
            ".MuiChip-label": { color: "#fff" },
          }}
        >
          <DataTable
            // this flag prevents overriding grouped/aggregated cells
            preventGroupedRowColspanExpand
            paging={data?.length > MAX_TABLE_ROWS_NO_PAGING}
            isLoading={isLoading}
            isFilterable={false}
            sortBy={sortBy}
            expanded={expanded}
            data={data}
            columns={columns}
            groupBy={groupBy ?? ["field", "farm"]}
          />
        </Box>
      </Stack>
    </Paper>
  );
}

ReportView.defaultProps = {
  org: null,
  season: null,
  data: null,
  title: null,
  expanded: null,
  sortBy: null,
  columns: null,
  color: "",
  titleIcon: null,
  descriptionDetail: null,
  goBackUrl: null,
  isLoading: false,
  groupBy: null,
};

ReportView.propTypes = {
  org: PropTypes.string,
  season: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
  }),
  color: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  expanded: PropTypes.shape({}),
  sortBy: PropTypes.arrayOf(PropTypes.shape({})),
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  titleIcon: PropTypes.elementType,
  descriptionDetail: PropTypes.shape({
    dd: PropTypes.string,
    dt: PropTypes.string,
  }),
  goBackUrl: PropTypes.string,
  isLoading: PropTypes.bool,
  groupBy: PropTypes.arrayOf(PropTypes.string),
};
