import { useEffect } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useOrgState } from "providers/OrgProvider";
import { useInventoryDispatch } from "providers";
import { SubfieldFormView } from "./SubfieldFormView";
import { useSubfieldsGeodata } from "api/useSubfieldsGeodata";
import { IUrlParams } from "types";

export function CreateOrEditSubfield() {
  const { org, rootUrl, season } = useOrgState();
  const { t } = useTranslation();
  const { subfieldType, editId } = useParams<IUrlParams>();
  const { enableDraw, disableDraw, setInventoryFtrs } = useInventoryDispatch();

  const query = useSubfieldsGeodata({
    org,
    type: subfieldType,
    seasonId: season?.id,
    subfieldId: editId,
  });

  useEffect(() => {
    if (query.data) {
      setInventoryFtrs(query.data);
    }
  }, [query.data, setInventoryFtrs]);

  useEffect(() => {
    enableDraw();
    return disableDraw;
  }, [enableDraw, disableDraw]);
  return (
    <Box sx={{ position: "relative", height: "100%", p: 3, overflow: "auto" }}>
      <Breadcrumbs sx={{ textTransform: "uppercase", mb: 2 }}>
        <Link
          underline="hover"
          href={`${rootUrl}/inventory/subfields/${subfieldType}`}
        >
          {subfieldType} {t("inventory.subfields.title")}
        </Link>
        {editId ? (
          <Typography>{`${t("common.edit")}`}</Typography>
        ) : (
          <Typography>{`${t("common.add")}`}</Typography>
        )}
      </Breadcrumbs>
      {!query.isFetching ? (
        <SubfieldFormView feature={query.data?.features[0]} />
      ) : (
        <LinearProgress
          style={{ top: 0, left: 0, right: 0, position: "absolute" }}
        />
      )}
    </Box>
  );
}
