import { OperationCalculatedProps } from "./types/OperationCalculatedProps";
import { OperationPlantedOrAppliedMapTheme } from "./types/OperationPlantedOrAppliedMapTheme";

export function parseOperationData({
  data,
  dataProp,
  colorProp,
}: {
  data: (OperationPlantedOrAppliedMapTheme & {
    totalMass: number;
    totalMassUom: string;
    depthUom: string;
    tilledType: string;
    tilledTypeColor: string;
  })[];
  dataProp: "productType" | "product" | "crop" | "tilledType";
  colorProp:
    | "productTypeColor"
    | "productColor"
    | "cropColor"
    | "tilledTypeColor";
}) {
  const calcProps: OperationCalculatedProps[] = [];
  const unique: { id: string | number; label: string | number }[] = [];
  let totalArea = 0;
  let totalMass: number;
  data.forEach((item) => {
    if (item.totalMass) {
      if (!totalMass) {
        totalMass = 0;
      }
      totalMass += item.totalMass;
    }
    totalArea += item.area;
    if (!unique.find((u) => u.id === item[dataProp])) {
      unique.push({
        id: item[dataProp],
        label: `${item[dataProp]}`,
      });
    }
  });

  unique.forEach((type) => {
    const items = data.filter((i) => i[dataProp] === type.id);
    let itemArea = 0;
    let itemMass: number;
    items.forEach((i) => {
      if (i.totalMass) {
        if (!itemMass) {
          itemMass = 0;
        }
        itemMass += i.totalMass;
      }
      if (i.area) {
        itemArea += i.area;
      }
    });
    const depthUom = items[0]?.depthUom;
    calcProps.push({
      count: items.length,
      name: type.label ?? "N/A",
      percent: Number(((items.length / data.length) * 100).toFixed(2)),
      color: items[0] ? (items[0][colorProp] as string) : "",
      area: itemArea ?? Number(itemArea.toFixed(2)),
      areaPercent: totalArea && ((itemArea / totalArea) * 100).toFixed(2),
      areaUom: items[0]?.areaUom,
      ...(itemMass
        ? {
            totalMass: itemMass,
            totalMassUom: items[0]?.totalMassUom,
          }
        : {}),
      ...(depthUom
        ? {
            depthUom,
          }
        : {}),
    });
  });
  return calcProps;
}

export function addAreaPercent(data: OperationPlantedOrAppliedMapTheme[]) {
  const total = data.reduce((item, next) => {
    const cnt = item + next.area;
    return cnt;
  }, 0);
  const items = data.reduce((item, next) => {
    const percent = Number(((next.area / total) * 100).toFixed(2));
    item.push({
      percent,
      ...next,
    });
    return item;
  }, []);
  return items;
}
