import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { conservationKeys } from "./conservationKeys";

export function useConservationSummary({
  org,
  seasonId,
}: {
  org: string;
  seasonId: string;
}) {
  const getSummary = useCallback(async () => {
    const res = await axios.get<{
      aoiCount: number;
      totalArea: number;
      totalAreaUom: string;
    }>(`/${org}/aois/conservation/season/${seasonId}/summary`);

    return res.data;
  }, [org, seasonId]);

  return useQuery({
    queryKey: conservationKeys.summaryBySeason(org, seasonId),
    queryFn: getSummary,
    enabled: !!org && !!seasonId,
  });
}
