import { useCallback } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { eventKeys } from "./assetKeys";
import { AssetEventPostData } from "./AssetEventPostData";

export function useAssetEventsSave(org: string, assetId: string) {
  const queryClient = useQueryClient();

  const saveEvent = useCallback(
    async (d: AssetEventPostData) => {
      const method = d.id ? "put" : "post";
      const res = await axios[method](`/${org}/assets/${assetId}/events`, d);
      return res.data;
    },
    [assetId, org]
  );

  return useMutation({
    mutationFn: saveEvent,
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: eventKeys.allById(org, assetId),
      });
    },
  });
}
