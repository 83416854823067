import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import lookupKeys from "./lookupKeys";
import { ICropType } from "types";

export function useCropTypes(org: string) {
  const getCropTypes = useCallback(async () => {
    const res = await axios.get<ICropType[]>(`/${org}/lookups/croptypes`);
    res.data.forEach((d) => {
      d.label = d.name;
      d.value = d.id;
    });
    return res.data;
  }, [org]);

  return useQuery({
    queryKey: lookupKeys.byType(org, `croptypes`),
    queryFn: getCropTypes,
    enabled: !!org,
    staleTime: 10 * (60 * 1000), // 10 mins
  });
}
