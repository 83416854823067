import axios from "axios";
import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { FeatureCollection } from "geojson";
import { mergeProps } from "lib";
import { useOperationMapThemes } from "./useOperationMapThemes";
import { OperationType } from "types/OperationType";
import operationKeys from "./operationKeys";

export function useOperationsGeodataByFields({
  fieldIds,
  org,
  seasonId,
  type,
}: {
  fieldIds: string[];
  org: string;
  seasonId: string;
  type: OperationType;
}) {
  const mapThemesQuery = useOperationMapThemes({
    org,
    type,
    seasonId,
  });

  const getGeodata = useCallback(async () => {
    const promises = fieldIds.map((fieldId) => {
      return axios.get<FeatureCollection>(
        `/${org}/operations/${type}/geodata/fields/${fieldId}`
      );
    });
    const responses = await Promise.all(promises);
    const fc = { type: "FeatureCollection", features: [] } as FeatureCollection;
    responses.forEach((res) => {
      fc.features.push(...res.data.features);
    });
    return mergeProps(fc, mapThemesQuery.data.raw);
  }, [fieldIds, mapThemesQuery.data, org, type]);

  return useQuery({
    queryFn: getGeodata,
    queryKey: operationKeys
      .byFields(org, type, fieldIds)
      .concat([`${mapThemesQuery.isFetching}`]),
    enabled:
      !!org && !!type && !!seasonId && !!fieldIds && !!mapThemesQuery.data,
  });
}
