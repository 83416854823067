import React, { useEffect, useRef } from "react";
import { useOrgState } from "providers/OrgProvider";
import usePrevious from "components/usePrevious";
import BaseMapView from "../../BaseMapView";
import { useFieldsState } from "providers/FieldsProvider";
import { useFieldLegendLayer, useLegendControl } from "components/Map";
import { LegendSymbol } from "lib/MapboxMap";
import { useTheme } from "@mui/material/styles";
import LayersDrawer from "views/InventoryView/MapView/LayersDrawer";

const emptyFc = {
  type: "FeatureCollection",
  features: [],
};

export default function DashboardMapView() {
  const { season, rasterLegendState } = useOrgState();
  const theme = useTheme();
  const { fieldsGeodataState } = useFieldsState();
  const prevFields = usePrevious(fieldsGeodataState?.data);
  const mapRef = useRef();
  const fieldLegendLayer = useFieldLegendLayer();

  const { legendCtrl } = useLegendControl({
    theme,
    location: "bottom-right",
    map: mapRef.current,
    layers: [
      fieldLegendLayer,
      {
        id: "layers-ctrl-layer",
        isStatic: true,
        title: rasterLegendState?.data?.type || "none",
        data: rasterLegendState?.data?.data?.map((i) => ({
          name: i.label,
          Symbol: function Symbol() {
            return (
              <LegendSymbol geometryType="Polygon" backgroundColor={i.color} />
            );
          },
        })),
      },
    ],
  });

  useEffect(() => {
    if (fieldsGeodataState?.data !== prevFields) {
      if (mapRef.current) {
        const src = mapRef.current.getSource("fields");
        if (src) {
          src.setData(fieldsGeodataState.data || emptyFc);
        }
      }
    }
  }, [fieldsGeodataState, prevFields]);
  return (
    <>
      <BaseMapView
        showHome
        defaultBounds={season?.extent?.bbox}
        showBasemapSelect={false}
        fitBoundsOptions={{ padding: 50, animate: false }}
        bounds={season?.extent?.bbox}
        events={{
          load: ({ target: map }) => {
            mapRef.current = map;
            map.addControl(legendCtrl, "bottom-right");

            map.addSource("fields", {
              type: "geojson",
              data: fieldsGeodataState?.data || emptyFc,
            });

            map.addLayer({
              id: "fields-lyr",
              type: "line",
              source: "fields",
              paint: {
                "line-width": 3,
                "line-color": "#fff",
              },
            });
          },
        }}
      />
      <LayersDrawer mapRef={mapRef.current} />
    </>
  );
}
