import booleanValid from "@turf/boolean-valid";
import {
  buffer,
  simplify,
  difference,
  // flatten,
  // booleanClockwise,
} from "components/Map";
import { flatten } from "lib/MapboxMap";

export function getBufferGeom({ size, geojson, tolerance = 0.00001, units }) {
  let ftr;
  try {
    if (!geojson) {
      throw new Error("GeoJSON is required.");
    }
    const simplified = simplify(geojson, { tolerance });
    // const flattened = flatten(simplified);
    // const outerRings = flattened.features.map((f) => {
    //   return f.geometry.coordinates.filter((coord) => {
    //     return booleanClockwise(coord);
    //   });
    // });
    // const ftrToBuffer = {
    //   type: 'Feature',
    //   geometry: { type: 'MultiPolygon', coordinates: outerRings },
    // };
    const bufferedGeom = buffer(simplified, size, { units });
    const bufferedFtr =
      bufferedGeom?.type === "Feature"
        ? bufferedGeom
        : bufferedGeom?.type === "FeatureCollection" &&
          bufferedGeom.features?.length
        ? bufferedGeom.features[0]
        : null;
    const simpleFtr =
      simplified?.type === "Feature"
        ? simplified
        : simplified?.type === "FeatureCollection" &&
          simplified.features?.length
        ? simplified.features[0]
        : null;
    if (bufferedFtr && size) {
      if (size > 0) {
        ftr = difference(bufferedFtr, simpleFtr);
      } else {
        ftr = difference(simpleFtr, bufferedFtr);
      }
    }
    return ftr;
  } catch (e) {
    console.error(e);
    return e;
  }
}

export function getBufferGeomFlattened({
  size,
  geojson,
  tolerance = 0.00001,
  units,
}) {
  try {
    if (!geojson) {
      throw new Error("GeoJSON is required.");
    }
    const simplified = simplify(geojson, { tolerance });
    const flattened = flatten(simplified);
    const bufferedGeom = buffer(simplified, size, { units });
    const bufferedFtr =
      bufferedGeom?.type === "Feature"
        ? bufferedGeom
        : bufferedGeom?.type === "FeatureCollection" &&
          bufferedGeom.features?.length
        ? bufferedGeom.features[0]
        : null;
    let diffed = bufferedFtr;
    if (bufferedFtr && size) {
      flattened.features?.forEach((f) => {
        if (size > 0) {
          diffed = difference(diffed, f);
        } else {
          diffed = difference(f, diffed);
        }
      });
    }
    if (booleanValid(diffed)) {
      return diffed;
    }
  } catch (e) {
    console.error(e);
  }
}

export const controlColor = "#0071C1";

export function parseChartData(res, items, yieldType) {
  const propName = yieldType === "productapplied" ? "productId" : "operationId";
  const processedFtrs = [];
  const arr = [];
  res.forEach((d) => {
    d?.operations?.forEach((operation, i) => {
      const product = items.find((p) => p.id === operation[propName]);
      operation.fieldName = d.fieldName;
      operation.color = operation.operationId.includes("control")
        ? controlColor
        : operation.productColor ?? product?.color;
      const { geometry, ...rest } = operation;
      if (geometry) {
        processedFtrs.push({
          type: "Feature",
          properties: rest,
          geometry,
        });
      }
      d[`operation-${i}-avg`] = operation.average;
      d[`operation-${i}-name`] = operation.name;
      d[`operation-${i}-count`] = operation.count;
    });
    if (d?.operations) {
      arr.push(...d.operations);
    }
  });
  return { chartData: arr, processedFtrs };
}
