import axios from "axios";
import { useCallback } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import operationKeys from "./operationKeys";
import { FeatureCollection, MultiPolygon, Polygon } from "geojson";

export function useOperationMutations<T extends { id?: string }>({
  org,
  type,
}: {
  org: string;
  type: string;
}) {
  const queryClient = useQueryClient();

  const invalidate = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: operationKeys.byType(org, type),
    });
    queryClient.invalidateQueries({
      queryKey: operationKeys.summary(org),
    });
  }, [org, queryClient, type]);

  const saveOperation = useCallback(
    async (d: T) => {
      const method = d.id ? "put" : "post";
      const res = await axios[method]<
        FeatureCollection<Polygon | MultiPolygon, T>
      >(`/${org}/operations/${type}/geodata`, d);
      return res.data;
    },
    [org, type]
  );

  const deleteOperation = useCallback(
    async (id: string) => {
      const res = await axios.delete(
        `/${org}/operations/${type}/geodata/${id}`
      );
      return res.data;
    },
    [org, type]
  );

  const saveMutation = useMutation({
    mutationFn: saveOperation,
    onSuccess: () => {
      invalidate();
    },
  });

  const deleteMutation = useMutation({
    mutationFn: deleteOperation,
  });

  return {
    saveMutation,
    deleteMutation,
    invalidate,
  };
}
