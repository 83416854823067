import { useCallback } from "react";
import { Route, Switch, useParams } from "react-router-dom";
import { useOrgState } from "providers/OrgProvider";
import Stack from "@mui/material/Stack";
import PlantedOperationForm from "./Planted/PlantedOperationForm";
import AppliedOperationForm from "./Applied/AppliedOperationForm";
import HarvestOperationForm from "./Harvested/HarvestOperationForm";
import TilledOperationForm from "./Tilled/TilledOperationForm";
import { OperationType } from "types/OperationType";
import OperationBreadcrumbs from "../OperationDetails/OperationBreadcrumbs";

export default function CreateOrEditOperation() {
  const { rootUrl } = useOrgState();
  const { type, itemId } = useParams<{ itemId: string; type: OperationType }>();
  const baseRoute = `${rootUrl}/inventory/operations/${type}`;

  const getCancelHref = useCallback(
    (onDelete) => {
      if (itemId && !onDelete) {
        return `${baseRoute}/items/${itemId}`;
      }
      return baseRoute;
    },
    [baseRoute, itemId]
  );

  return (
    <>
      <Stack spacing={2} sx={{ p: 3, overflow: "auto" }}>
        <OperationBreadcrumbs type={type} />
        <Switch>
          <Route path={`/:org/:season/inventory/operations/planted/add`}>
            <PlantedOperationForm getCancelHref={getCancelHref} />
          </Route>
          <Route
            path={
              "/:org/:season/inventory/operations/planted/items/:itemId/edit"
            }
          >
            <PlantedOperationForm getCancelHref={getCancelHref} />
          </Route>
          <Route path={"/:org/:season/inventory/operations/applied/add"}>
            <AppliedOperationForm getCancelHref={getCancelHref} />
          </Route>
          <Route
            path={
              "/:org/:season/inventory/operations/applied/items/:itemId/edit"
            }
          >
            <AppliedOperationForm getCancelHref={getCancelHref} />
          </Route>
          <Route path={"/:org/:season/inventory/operations/harvested/add"}>
            <HarvestOperationForm getCancelHref={getCancelHref} />
          </Route>
          <Route
            path={
              "/:org/:season/inventory/operations/harvested/items/:itemId/edit"
            }
          >
            <HarvestOperationForm getCancelHref={getCancelHref} />
          </Route>
          <Route path={"/:org/:season/inventory/operations/tilled/add"}>
            <TilledOperationForm />
          </Route>
          <Route
            path={
              "/:org/:season/inventory/operations/tilled/items/:itemId/edit"
            }
          >
            <TilledOperationForm />
          </Route>
        </Switch>
      </Stack>
    </>
  );
}
