import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useURLSearchParams } from "components";
import AssetForm from "./AssetForm";
import AssetUpload from "./AssetUpload";
import AssetsBreadcrumbs from "../ListView/AssetsBreadcrumbs";
import ErrorBoundary from "components/ErrorBoundary";

export default function CreateOrEditAsset() {
  const { t } = useTranslation();
  const location = useLocation();
  const isAdd = location.pathname.includes("/add");
  const query = useURLSearchParams();

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "auto",
      }}
    >
      <Stack direction={"row"} sx={{ p: 3, pb: 2, alignItems: "center" }}>
        <AssetsBreadcrumbs />
      </Stack>
      <TabContext value={query.get("formType") ?? "/"}>
        {isAdd ? (
          <Box
            sx={{
              mx: 3,
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <TabList
              sx={{
                ".MuiTabPanel-root": {
                  p: 0,
                },
                a: {
                  textDecoration: "none",
                },
              }}
              onChange={() => {}}
              aria-label="create type tabs"
            >
              <Tab label={t("common.form")} value="/" href={`./add`} />
              <Tab
                label={t("common.upload")}
                value="upload"
                href={`./add?formType=upload`}
              />
            </TabList>
          </Box>
        ) : null}
        <ErrorBoundary>
          <TabPanel value="/" sx={{ height: "100%", overflow: "auto" }}>
            <AssetForm />
          </TabPanel>
          <TabPanel value="upload" sx={{ height: "100%", overflow: "auto" }}>
            <AssetUpload />
          </TabPanel>
        </ErrorBoundary>
      </TabContext>
    </Paper>
  );
}
