import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "components/layout/Grid";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { Paper } from "@mui/material";

export function ImageryImport() {
  const { t } = useTranslation();
  const tilerOrigin = process.env.REACT_APP_TILER_ORIGIN;

  return (
    <>
      <h2>{t("imagery.import.importImagery")}</h2>
      <Divider />
      <Grid container height="100%">
        <Grid sm={12} height="100%">
          <Stack
            height={"100%"}
            p={3}
            component={Paper}
            style={{ textTransform: "uppercase" }}
            spacing={3}
          >
            <div>
              <p>{t("imagery.import.uploadTifImage")}</p>
              <Button
                LinkComponent={"a"}
                style={{ color: "inherit" }}
                variant="contained"
                href={tilerOrigin}
                target="_blank"
                rel="noreferrer"
              >
                {t("imagery.import.orthoUploader")}
              </Button>
            </div>
            <div>
              <p>{t("imagery.import.uploadLargeTifImage")}</p>
              <Button color="primary" variant="contained" disabled>
                {t("imagery.import.largeOrthoUploader")}
              </Button>
            </div>
            <div>
              <p>{t("imagery.import.aipUpload")}</p>
              <Button color="primary" variant="contained" disabled>
                {t("imagery.import.droneAip")}
              </Button>
            </div>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
