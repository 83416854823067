import { FeatureCollection } from "geojson";
import { create } from "zustand";

interface EnterpriseLayer {
  id: string;
  color: string;
  featureCollection?: FeatureCollection;
  url?: string;
  name: string;
  geometryType: string;
  visible?: boolean;
  // bbox: [number, number, number, number];
}

export const useEnterpriseLayersStore = create<{
  layers?: EnterpriseLayer[];
  actions: {
    setLayers: (l: EnterpriseLayer[]) => void;
  };
}>((set) => ({
  layers: undefined,
  actions: {
    setLayers: (layers) => {
      return set(() => ({ layers }));
    },
  },
}));
