import React, { useEffect, useState } from "react";
import { Route, Switch, useLocation, useParams } from "react-router-dom";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import { useInventoryDispatch } from "providers/InventoryProvider";
import CardStack from "../../CardStack";
import SubfieldsSummaryView from "./SubfieldsSummaryView";
import SubfieldsMapThemesView from "./SubfieldsMapThemesView";
import SubfieldsListView from "./SubfieldsListView";
import { CreateOrEditSubfield } from "./CreateOrEditSubfield";

export function SubfieldsView() {
  const { t } = useTranslation();
  const location = useLocation();
  const [expanded, setExpanded] = useState();
  const {
    setTitle,
    setColSizes,
    setLegendData,
    setInventoryFtrs,
    setLabelProp,
  } = useInventoryDispatch();
  const { editId } = useParams();
  const isAdd = location.pathname.endsWith("/add");

  useEffect(() => {
    setTitle(t("inventory.subfields.title"));
    setColSizes([7, 5]);
    setLabelProp("cid");
    setLegendData({
      title: t("common.type"),
      colorProp: "subfieldTypeColor",
      dataProp: "subfieldType",
    });
  }, [setTitle, setColSizes, setLegendData, setLabelProp, t]);

  useEffect(() => {
    return () => {
      setInventoryFtrs(null);
    };
  }, [setInventoryFtrs]);

  return (
    <>
      <CardStack
        // topHeight={"400px"}
        expanded={Boolean(editId || isAdd || expanded)}
        topChildren={
          <>
            <Paper>
              <SubfieldsSummaryView />
            </Paper>
            <Paper>
              <SubfieldsMapThemesView />
            </Paper>
          </>
        }
      >
        <Paper sx={{ height: "100%", overflow: "auto" }}>
          <Switch>
            <Route
              path={
                "/:org/:season/inventory/subfields/:subfieldType/:editId/edit"
              }
              exact
            >
              <CreateOrEditSubfield />
            </Route>
            <Route
              path={"/:org/:season/inventory/subfields/:subfieldType/add"}
              exact
            >
              <CreateOrEditSubfield />
            </Route>
            <Route path="/:org/:season/inventory/subfields/:subfieldType?">
              <SubfieldsListView
                onToggle={() => {
                  setExpanded(!expanded);
                }}
                toggled={expanded}
              />
            </Route>
          </Switch>
        </Paper>
      </CardStack>
    </>
  );
}

SubfieldsView.defaultProps = {};

SubfieldsView.propTypes = {};
