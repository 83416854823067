import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
// import mapboxgl from 'mapbox-gl';
import { useTheme } from "@mui/material/styles";
import { useFieldLegendLayer, useLegendControl } from "components/Map";
import MapView from "../BaseMapView";
import { usePrevious } from "components";
import { LegendSymbol } from "lib/MapboxMap";

const emptyFc = { type: "FeatureCollection", features: [] };

export default function InsightsMapView({
  fieldsGeodataState,
  operationsGeodata,
  rasterLegendState,
  onMapLoad,
}) {
  const fieldLegendLayer = useFieldLegendLayer();
  const mapRef = useRef();
  const prevFields = usePrevious(fieldsGeodataState?.data);
  const prevOperations = usePrevious(operationsGeodata);
  const theme = useTheme();
  const { legendCtrl } = useLegendControl({
    theme,
    location: "bottom-right",
    map: mapRef.current,
    layers: [
      fieldLegendLayer,
      {
        id: "raster-lyr",
        isStatic: true,
        title: rasterLegendState?.data?.type || "none",
        data: rasterLegendState?.data?.data?.map((i) => ({
          name: i.label,
          Symbol: function Symbol() {
            return (
              <LegendSymbol geometryType="Polygon" backgroundColor={i.color} />
            );
          },
        })),
      },
    ],
  });
  useEffect(() => {
    if (
      fieldsGeodataState.data &&
      fieldsGeodataState.data !== prevFields &&
      mapRef.current
    ) {
      mapRef.current.getSource("fields").setData(fieldsGeodataState.data);
    }
  }, [fieldsGeodataState, prevFields]);

  useEffect(() => {
    if (
      operationsGeodata &&
      operationsGeodata !== prevOperations &&
      mapRef.current
    ) {
      mapRef.current.getSource("operations").setData(operationsGeodata);
    }
  }, [operationsGeodata, prevOperations]);

  return (
    <MapView
      showBasemapSelect={false}
      events={{
        load: ({ target: map }) => {
          mapRef.current = map;
          map.addControl(legendCtrl, "bottom-right");
          map.addSource("field-buff-src", {
            type: "geojson",
            data: emptyFc,
          });
          map.addSource("operation-buff-src", {
            type: "geojson",
            data: emptyFc,
          });
          map.addSource("fields", {
            type: "geojson",
            data: fieldsGeodataState?.data || emptyFc,
          });
          map.addSource("operations", {
            type: "geojson",
            data: operationsGeodata || emptyFc,
          });
          map.addSource("processed-geoms", {
            type: "geojson",
            data: emptyFc,
          });
          map.addLayer({
            id: "processed-geoms-layer-fill",
            type: "fill",
            source: "processed-geoms",
            paint: {
              "fill-color": ["get", "color"],
              "fill-opacity": 0.25,
            },
          });
          map.addLayer({
            id: "processed-geoms-layer",
            type: "line",
            source: "processed-geoms",
            paint: {
              "line-color": ["get", "color"],
              "line-width": 3,
            },
          });
          mapRef.current.addLayer({
            id: "fields-lyr",
            type: "line",
            source: "fields",
            paint: {
              "line-width": 3,
              "line-color": "#fff",
            },
          });
          mapRef.current.addLayer({
            id: "operations-layer",
            type: "fill",
            source: "operations",
            // filter: ["==", "1", "2"],
            paint: {
              "fill-color": ["get", "color"],
              // 'fill-opacity': 0.75,
            },
          });
          map.addLayer({
            id: "field-buff",
            type: "fill",
            source: "field-buff-src",
            paint: {
              "fill-color": "#808080",
              "fill-opacity": 0.75,
            },
          });
          // map.addLayer({
          //   id: 'field-buff-line',
          //   type: 'line',
          //   source: 'field-buff-src',
          //   paint: {
          //     'line-color': '#808080',
          //     'line-width': 2,
          //   },
          // });
          map.addLayer({
            id: "operation-buff",
            type: "fill",
            source: "operation-buff-src",
            paint: {
              "fill-color": "#00ff95",
              "fill-opacity": 0.75,
            },
          });
          // map.addLayer({
          //   id: 'operation-buff-line',
          //   type: 'line',
          //   source: 'operation-buff-src',
          //   paint: {
          //     'line-color': '#00ff95',
          //     'line-width': 2,
          //   },
          // });
          // Create a popup, but don't add it to the map yet.
          // const popup = new mapboxgl.Popup({
          //   closeButton: false,
          //   closeOnClick: false,
          // });

          // map.on('mouseenter', 'operations-layer', (e) => {
          //   const { description } = e.features[0].properties;
          //   // Populate the popup and set its coordinates
          //   // based on the feature found.
          //   popup.setLngLat(e.lngLat).setHTML(description).addTo(map);
          // });
          // map.on('mouseleave', 'operations-layer', () => {
          //   popup.remove();
          // });
          onMapLoad(map);
        },
      }}
    />
  );
}

InsightsMapView.defaultProps = {
  onMapLoad: () => {},
  operationsGeodata: null,
  rasterLegendState: null,
};

InsightsMapView.propTypes = {
  fieldsGeodataState: PropTypes.shape({
    data: PropTypes.shape({
      features: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
  operationsGeodata: PropTypes.shape({
    features: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  onMapLoad: PropTypes.func,
  rasterLegendState: PropTypes.shape({
    data: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          color: PropTypes.string,
        })
      ),
      type: PropTypes.string,
      // types: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};
