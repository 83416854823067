import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import fieldKeys from "./fieldKeys";

interface FieldSummaryItem {
  fieldCount: number;
  totalArea: number;
  totalAreaUom: string;
}

export function useFieldsSummary(org: string, seasonId: string) {
  const getFieldsSummary = useCallback(async () => {
    const res = await axios.get<FieldSummaryItem>(
      `/${org}/fields/season/${seasonId}/summary`
    );
    return res.data;
  }, [seasonId, org]);

  return useQuery({
    queryKey: fieldKeys.summaryBySeason(org, seasonId),
    queryFn: getFieldsSummary,
  });
}
