import React from "react";
import Stack from "@mui/material/Stack";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";

export default function CardStack({
  children,
  topChildren,
  expanded,
}: // bottomMinHeight,
// topHeight = "auto",
// delay = 500,
// bottomClasses = "mt-3",
{
  children: React.ReactNode;
  topChildren: React.ReactNode;
  expanded?: boolean;
  // bottomMinHeight?: string;
  // topHeight?: string;
  // delay?: number;
  // bottomClasses?: string;
}) {
  return (
    <Stack display="flex" spacing={expanded ? 0 : 2} flex={1}>
      <Collapse in={!expanded}>
        <Stack spacing={2}>{topChildren}</Stack>
      </Collapse>
      <Box sx={{ flex: 1, overflow: "auto" }}>{children}</Box>
    </Stack>
  );
}
