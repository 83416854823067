import { FormWrapper } from "components";
import { Controller, useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "lib/Select/Autocomplete";
import { useOrgState } from "providers";
import { useYieldFilters } from "api/useYieldFilters";
import { ICropType, IUrlParams } from "types";
import { useYieldFilterMutations } from "api/useYieldFilterMutations";
import { useHistory, useParams } from "react-router-dom";

const defaultValues = {
  speedMin: "",
  speedMax: "",
  yieldMin: "",
  yieldMax: "",
  cropTypeId: "",
};

export function YieldFilter({
  cancelHref,
  cropTypes,
}: {
  cancelHref: string;
  cropTypes: (ICropType & { disabled?: boolean })[];
}) {
  const { org, season } = useOrgState();
  const { itemId } = useParams<IUrlParams>();
  const history = useHistory();
  const yieldFiltersQ = useYieldFilters({ org, seasonId: season?.id });
  const currentItem =
    yieldFiltersQ.data && itemId
      ? yieldFiltersQ.data.find((i) => i.cropTypeId === itemId)
      : null;
  const { saveMutation, deleteMutation } = useYieldFilterMutations({
    org,
    seasonId: season?.id,
  });
  const methods = useForm({
    defaultValues: {
      id: itemId ?? "",
      ...defaultValues,
      ...currentItem,
      cropTypeId: itemId ?? "",
    },
  });

  const { handleSubmit, register, control, reset } = methods;

  return (
    <FormWrapper
      methods={methods}
      data={{ ...currentItem }}
      ignoreGeom
      cancelHref={cancelHref}
      saveState={{
        ...saveMutation,
        errorMessage: saveMutation.error,
      }}
      deleteState={{
        ...deleteMutation,
        errorMessage: deleteMutation.error,
      }}
      onDelete={() => {
        deleteMutation.mutate(currentItem, {
          onSuccess: () => {
            reset({});
            history.push(cancelHref);
          },
        });
      }}
      onSubmit={handleSubmit((d) => {
        saveMutation.mutate(
          {
            ...(itemId
              ? {
                  id: itemId,
                }
              : {}),
            ...d,
            ...{
              speedUom: "mi/hr",
              yieldUom: "bu/ac",
              yieldUomId: "bu1ac-1",
              speedUomId: "mi1hr-1",
            },
          },
          {
            onSuccess: () => {
              reset({});
              history.push(cancelHref);
            },
          }
        );
      })}
    >
      <Stack gap={2}>
        <Controller
          control={control}
          name="cropTypeId"
          rules={{ required: true }}
          render={({ onChange, value }) => {
            return (
              <Autocomplete
                inputProps={{ required: true }}
                value={cropTypes?.find((d) => d.id === value) ?? null}
                label="Crop Type"
                options={
                  itemId
                    ? cropTypes?.filter((ct) => ct.id === itemId)
                    : cropTypes
                }
                getOptionDisabled={(option) => option.disabled}
                onChange={(_e, opt) => {
                  const option = opt as ICropType;
                  onChange(option?.id);
                }}
              />
            );
          }}
        />

        <Stack direction="row" spacing={2}>
          <TextField
            inputProps={{ required: true, name: "yieldMin", ref: register }}
            label={"Min Yield"}
            type="number"
          />
          <TextField
            inputProps={{ required: true, name: "yieldMax", ref: register }}
            label={"Max Yield"}
            type="number"
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            inputProps={{ required: true, name: "speedMin", ref: register }}
            label={"Min Speed"}
            type="number"
          />
          <TextField
            inputProps={{ required: true, name: "speedMax", ref: register }}
            label={"Max Speed"}
            type="number"
          />
        </Stack>
      </Stack>
    </FormWrapper>
  );
}
