import axios from "axios";
import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { OperationType } from "types/OperationType";
import operationKeys from "./operationKeys";
import { AppliedOperationDetails } from "./types/AppliedOperationDetails";
import { PlantedOperationDetails } from "./types/PlantedOperationDetails";
import { HarvestedOperationDetails } from "./types/HarvestedOperationDetails";
import { TilledOperationDetails } from "./types/TilledOperationDetails";

export function useOperationDetails<
  T =
    | AppliedOperationDetails
    | PlantedOperationDetails
    | HarvestedOperationDetails
    | TilledOperationDetails
>({
  operationId,
  org,
  type,
}: {
  operationId: string;
  org: string;
  type: OperationType;
}) {
  const getOperationDetails = useCallback(async () => {
    const res = await axios.get<T>(
      `/${org}/operations/${type}/${operationId}/details`
    );
    return res.data;
  }, [operationId, org, type]);

  return useQuery({
    queryFn: getOperationDetails,
    queryKey: operationKeys.byId(org, type, operationId),
  });
}
