import { useCallback, useEffect, useMemo } from "react";
import Alert from "@mui/material/Alert";
import { useOrgState } from "providers/OrgProvider";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { useProductGeometryIntersection } from "./useProductGeometryIntersection";
import { useYieldInsightsActions } from "./Yield/useYieldInsightsStore";
import Autocomplete from "lib/Select/Autocomplete";
import { ProductLookup } from "./types";
import { Typography } from "@mui/material";

export default function ApplicationAndSeedSelect({
  applicationProducts,
  seedProducts,
  onChange,
  value,
}: {
  applicationProducts: ProductLookup[];

  onChange?: (_d: { [index: string]: ProductLookup }) => void;
  seedProducts: ProductLookup[];
  value: { application?: ProductLookup; seed?: ProductLookup };
}) {
  const { t } = useTranslation();
  const { org } = useOrgState();
  const actions = useYieldInsightsActions();

  const selectedApp = useMemo(() => {
    return (
      applicationProducts?.find((s) => s.id === value.application?.id) ?? null
    );
  }, [applicationProducts, value.application?.id]);

  const selectedSeed = useMemo(() => {
    return seedProducts?.find((s) => s.id === value.seed?.id) ?? null;
  }, [seedProducts, value.seed?.id]);

  const productGeomQ = useProductGeometryIntersection({
    application: value.application,
    seed: value.seed,
    org,
  });

  useEffect(() => {
    actions.setFeatures(productGeomQ.data?.features);
    actions.setIntersectGeometry(productGeomQ.data?.geometry);
  }, [actions, productGeomQ.data]);

  const handleApplicationChange = useCallback(
    async (_e, item) => {
      actions.setApplication(item);
      onChange({
        application: item,
      });
    },
    [actions, onChange]
  );

  const handleSeedChange = useCallback(
    async (_e, item) => {
      actions.setSeed(item);
      onChange({
        seed: item,
      });
    },
    [actions, onChange]
  );

  return (
    <>
      <Stack spacing={1}>
        <Autocomplete
          inputProps={{
            required: !selectedSeed,
          }}
          loading={productGeomQ.isFetching}
          label={`${t("common.application")}`}
          id="application-select"
          value={selectedApp}
          options={applicationProducts || []}
          groupBy={({ date }: ProductLookup) => date}
          getOptionLabel={(o) => o.product}
          onChange={handleApplicationChange}
          disableClearable={false}
        />
        <Typography variant="body2">{t("common.andOr")}</Typography>
        <Autocomplete
          label={`${t("common.seed")}`}
          id="seed-select"
          loading={productGeomQ.isFetching}
          value={selectedSeed}
          options={seedProducts || []}
          groupBy={({ date }: ProductLookup) => date}
          getOptionLabel={(o) => o.product}
          onChange={handleSeedChange}
          disableClearable={false}
        />
      </Stack>
      {productGeomQ.isError ? (
        <Alert severity="error">
          {productGeomQ.error.message || t("common.somethingUnexpected")}
        </Alert>
      ) : null}
      {productGeomQ.data?.noIntersect ? (
        <Alert severity="error">
          {t("insights.yield.operationsDoNotIntersect")}
        </Alert>
      ) : null}
    </>
  );
}
