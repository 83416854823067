import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";

export default function NotFoundView({ children, message }) {
  const { t } = useTranslation();
  return (
    <div
      style={{ textAlign: "center", display: "flex", flexDirection: "column" }}
    >
      <Typography component="h3" variant={"h4"}>
        {message || t("common.viewNotFound")}
      </Typography>
      {children}
    </div>
  );
}

NotFoundView.defaultProps = {
  children: null,
  message: "",
};

NotFoundView.propTypes = {
  children: PropTypes.node,
  message: PropTypes.string,
};
