import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function EquipmentIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="337.4"
      height="281.19"
      version="1.1"
      viewBox="0 0 337.4 281.19"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="m253.02 161.67a35.14 35.14 0 1 0 35.15 35.14 35.15 35.15 0 0 0-35.15-35.14zm0 56.22a21.09 21.09 0 1 1 21.08-21.08 21.1 21.1 0 0 1-21.08 21.08z" />
      <path d="m312.41 136.89-15.39-122.82h5.25a7.035 7.035 0 1 0 0-14.07h-154.65a7.035 7.035 0 0 0 0 14.07h9.69l-30.65 98.69h-3c-6.2 0-21.46 0.21-39.33 2v-16.23h14.09v-56.36h-14.06v-14h-0.07a21.09 21.09 0 0 0-21.08-21.09 7.03 7.03 0 1 0 0 14.06 7 7 0 0 1 7 7h0.07v14h-14v56.36h14v17.92c-29.2 4.17-60.73 13-70.28 31.62v69.84a63.26 63.26 0 0 0 126.12 7h47.36a84.36 84.36 0 1 0 138.91-88zm-242.11-80.65h14.06v28.24h-14.06zm41.72 168.79a48.72 48.72 0 0 1-9.4 22.39 7.0074 7.0074 0 0 0-9.93 9.89 48.59 48.59 0 0 1-22.41 9.26 7 7 0 0 0-14 0 48.58 48.58 0 0 1-22.42-9.26 7.0039 7.0039 0 0 0-9.92-9.89 48.72 48.72 0 0 1-9.35-22.42 7 7 0 0 0 0-14 48.52 48.52 0 0 1 9.26-22.52 7.018 7.018 0 0 0 9.91-9.94 48.68 48.68 0 0 1 22.53-9.36 7 7 0 0 0 14 0 48.68 48.68 0 0 1 22.53 9.36 7.018 7.018 0 0 0 9.91 9.94 48.59 48.59 0 0 1 9.29 22.52 7 7 0 0 0 0 14zm57.91-14.17h-43.79a63.25 63.25 0 0 0-111.91-32.92l-0.13-25.92c6.67-8.62 24.6-16 50.1-20.4 25.77-4.48 52-4.83 59.49-4.83h2.7l10.56 0.19 3.13-10.08 31.94-102.83h110.8l13.88 110.55a83.4 83.4 0 0 0-15.07-7.2l-11.21-89.3h-88l-34.96 112.46h42.72a84.47 84.47 0 0 0-20.3 70.28zm97.09-97.24a84.35 84.35 0 0 0-60.57 12.9h-39.91l26.2-84.35h65.28zm50.16 112.1a7.0068 7.0068 0 0 0-7 12.14 72.55 72.55 0 0 1-7.37 8.64 71.77 71.77 0 0 1-8.66 7.38 7.0024 7.0024 0 0 0-12.13 7 69.89 69.89 0 0 1-21.9 5.84 7 7 0 0 0-14 0 69.82 69.82 0 0 1-21.9-5.84 7.0024 7.0024 0 0 0-12.13-7 71.11 71.11 0 0 1-16-16 7.0068 7.0068 0 0 0-7-12.14 69.9 69.9 0 0 1-5.85-21.9 7 7 0 0 0 0-14 69.84 69.84 0 0 1 5.85-21.89 7.0068 7.0068 0 0 0 7-12.14 70.74 70.74 0 0 1 7.36-8.65 71.68 71.68 0 0 1 8.66-7.37 7.0068 7.0068 0 0 0 12.14-7 69.6 69.6 0 0 1 21.9-5.83 7 7 0 0 0 14 0 69.87 69.87 0 0 1 21.9 5.83 7.0024 7.0024 0 0 0 12.13 7 71.11 71.11 0 0 1 16 16 7.0068 7.0068 0 0 0 7 12.14 69.91 69.91 0 0 1 5.84 21.86 7 7 0 0 0 0 14 69.9 69.9 0 0 1-5.87 21.93z" />
      <path d="m63.28 196.81a21.09 21.09 0 1 0 21.08 21.08 21.08 21.08 0 0 0-21.08-21.08zm0 28.11a7 7 0 1 1 7-7 7 7 0 0 1-7 7z" />
    </SvgIcon>
  );
}
