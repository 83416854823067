import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { DataTable, useURLSearchParams } from "components";
import { useOrgState } from "providers";
import Box from "@mui/material/Box";
import { IUrlParams } from "types";
import { IAssetRecord } from "types/IAssetRecord";
import { AddIconButton } from "components/AddIconButton";
import { useAssetEvents } from "api/assets/useAssetEvents";

export default function AssetRecordsListView() {
  const { t } = useTranslation();
  const { org, rootUrl } = useOrgState();
  const { itemId, type, subtype, fieldId } = useParams<IUrlParams>();
  const query = useURLSearchParams();
  const eventsQ = useAssetEvents(org, itemId);

  const assetGroupId = query.get("groupId");

  const recordsCols = [
    {
      Header: "",
      id: "actions",
      isSortable: false,
      accessor: function Cell(row: IAssetRecord) {
        return (
          <IconButton
            size="small"
            href={`${rootUrl}/inventory/assets/${type}/${subtype}/${fieldId}/items/${itemId}/records/${
              row.id
            }${assetGroupId ? `?groupId=${assetGroupId}` : ""}`}
          >
            <VisibilityIcon />
          </IconButton>
        );
      },
    },
    {
      Header: t("common.date"),
      id: "dateUtc",
      accessor: (row: IAssetRecord) => {
        return new Date(row.dateUtc).toLocaleDateString();
      },
    },
    { Header: t("common.type"), accessor: "eventType" },
    { Header: t("common.weight"), accessor: "weightLbs" },
    { Header: t("common.photos"), accessor: "photoCount" },
  ];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <AddIconButton
        sx={{ ml: "auto" }}
        href={`${rootUrl}/inventory/assets/${type}/${subtype}/${fieldId}/items/${itemId}/records/add${
          assetGroupId ? `?groupId=${assetGroupId}` : ""
        }`}
      />
      <Box sx={{ overflow: "auto", height: "100%" }}>
        <DataTable
          isFilterable={false}
          data={eventsQ.data}
          columns={recordsCols}
        />
      </Box>
    </Box>
  );
}
