import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useCallback } from "react";
import { VerityFieldCI } from "./VerityFieldCI";

export function useVerityFieldEnroll({
  org,
  seasonId,
  programId,
}: {
  org: string;
  seasonId: string;
  programId: string;
}) {
  const queryClient = useQueryClient();
  const mutationFn = useCallback(
    async (fieldId: string) => {
      const res = await axios.post<VerityFieldCI[]>(`${org}/verity/ci/enroll`, {
        fieldId,
        programId,
      });
      return res.data;
    },
    [org, programId]
  );

  return useMutation({
    mutationFn,
    onSuccess: (res, fieldId) => {
      queryClient.setQueryData(
        ["organization", org, "verity", seasonId],
        (oldData: VerityFieldCI[]) => {
          // console.log(oldData, res);
          return [
            ...oldData.map((od) => {
              return od.fieldId === fieldId ? res : od;
            }),
          ];
        }
      );
    },
  });
}
