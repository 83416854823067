import { useCallback } from "react";
import { sortObjArr } from "components/utils";
import useFetchWithToken from "components/useFetchWithToken";

function formatResponse(res) {
  if (res && res.length) {
    res.forEach((r) => {
      r.value = r.id || r.slug;
      r.label = r.name;
    });
    sortObjArr(res, "name");
  }

  return res;
}

export function useCollectEventUtils() {
  const {
    state: collectEventsState,
    fetchData: fetchCollectEvents,
    abort: abortFetchCollectEvents,
    resetFetchState: resetCollectEvents,
  } = useFetchWithToken([]);
  const {
    state: defaultCollectEventsState,
    fetchData: fetchDefaultCollectEvents,
  } = useFetchWithToken();

  const {
    state: rasterInfoState,
    fetchData: fetchRasterInfo,
    abort: abortFetchRasterInfo,
    resetFetchState: resetRasterInfo,
  } = useFetchWithToken({});

  return {
    collectEventsState,
    rasterInfoState,
    defaultCollectEventsState,
    collectEvents: collectEventsState?.data,
    rasterTypes: rasterInfoState?.data?.types,
    rasterBands: rasterInfoState?.data?.bands,
    fetchDefaultCollectEvents: useCallback(
      (org) => {
        return fetchDefaultCollectEvents(`/${org}/collectevents/default`, {
          transformResponse: (res) => {
            return res?.map((item) => {
              const beginDate = new Date(item.beginOnUtc).toLocaleDateString();
              return {
                value: item.id,
                label: `${beginDate}${item.name ? ` (${item.name})` : ""}`,
                ...item,
              };
            });
          },
        });
      },
      [fetchDefaultCollectEvents]
    ),
    fetchCollectEvents: useCallback(
      ({ bbox, org, sensorTypeId }) => {
        return fetchCollectEvents(
          `/${org}/collectevents?${bbox ? `bbox=${bbox}` : ""}${
            sensorTypeId ? `${bbox ? "&" : ""}sensorTypeId=${sensorTypeId}` : ""
          }`,
          {
            transformResponse: (res) => {
              return res?.map((item) => {
                const beginDate = new Date(
                  item.beginOnUtc
                ).toLocaleDateString();
                return {
                  value: item.id || item.slug,
                  label: `${beginDate}${item.name ? ` (${item.name})` : ""}`,
                  extent: item.extent,
                  ...item,
                };
              });
            },
          }
        );
      },
      [fetchCollectEvents]
    ),
    fetchRasterInfo: useCallback(
      (org, sensorTypeId) => {
        return fetchRasterInfo(`/${org}/sensors/${sensorTypeId}/rasterinfo`, {
          transformResponse: (res) => {
            if (res) {
              res.types = formatResponse(res.types);
              res.bands = formatResponse(res.bands);
            }
            return res;
          },
        });
      },
      [fetchRasterInfo]
    ),
    resetCollectEvents,
    abortFetchCollectEvents,
    resetRasterInfo,
    abortFetchRasterInfo,
  };
}
export default useCollectEventUtils;
