import Autocomplete from "lib/Select/Autocomplete";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import DataTable from "../DataTable";
import { useTranslation } from "react-i18next";
import { useOrgState } from "providers";
import { useMemo, useState } from "react";
import { VerityFieldCI } from "./VerityFieldCI";
import { ApplicationsCell } from "./ApplicationsCell";
import {
  useVerityFieldsBySeason,
  useVerityFieldsRefresh,
} from "./useVerityFieldsBySeason";
import { StatusCell } from "./StatusCell";
import { useVerityFieldEnroll } from "./useVerityFieldEnroll";
// import { useVerityFieldUpdate } from "./useVerityFieldUpdate";

const availablePrograms = [
  {
    value: "a18c10f5-22e9-488e-a344-dd939fc794c9",
    label: "Corn to Ethanol - FDCIC-2023",
  },
];

export function VerityModule() {
  const { t } = useTranslation();
  const { org, season } = useOrgState();

  const [selectedProgram, setSelectedProgram] = useState(availablePrograms[0]);
  const [shouldRefresh, setShouldRefresh] = useState<boolean>();
  const verityQ = useVerityFieldsBySeason({ org, seasonId: season?.id });
  const verityRefreshQ = useVerityFieldsRefresh({
    org,
    seasonId: season?.id,
    enabled: shouldRefresh,
    onSettled: () => {
      // reset refresh state when settled
      setShouldRefresh(false);
    },
  });
  const verityFieldEnrollMutation = useVerityFieldEnroll({
    org,
    seasonId: season?.id,
    programId: selectedProgram?.value,
  });

  const columns = useMemo(
    () => [
      {
        Header: t("common.status"),
        accessor: "status",
        Cell: (data: { row: { original: VerityFieldCI }; value: string }) => (
          <div>
            <StatusCell
              {...data}
              org={org}
              seasonId={season?.id}
              programId={selectedProgram?.value}
              onClick={() => {
                verityFieldEnrollMutation.mutate(data.row.original.fieldId);
              }}
            />
            {verityFieldEnrollMutation.isError ? (
              <Alert
                onClose={() => {
                  verityFieldEnrollMutation.reset();
                }}
                style={{ position: "absolute", zIndex: 9 }}
                severity="error"
              >
                {verityFieldEnrollMutation.error}
              </Alert>
            ) : null}
          </div>
        ),
      },
      {
        Header: t("common.field"),
        accessor: "field",
        Cell: ({
          row,
          value,
        }: {
          row: { original: VerityFieldCI };
          value: string;
        }) => {
          return (
            <Box
              sx={{ mr: 1 }}
              style={{
                display: "flex",
                flexDirection: "column",
                float: "left",
              }}
            >
              <span>{`${value}`}</span>
              <small>{row.original?.farm}</small>
            </Box>
          );
        },
      },
      {
        Header: t("common.crop"),
        accessor: "plantedCrop",
      },
      {
        Header: t("verity.coverCrop"),
        accessor: "plantedCoverCrop",
        Cell: ({ value }: { value: string }) => {
          return value || "--";
        },
      },
      {
        Header: t("verity.tillage"),
        accessor: "tilledOperations",
        Cell: ({ value }: { value?: VerityFieldCI["tilledOperations"] }) => {
          return value?.length ? (
            <Box sx={{ mr: 1 }} style={{ display: "flex", float: "left" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>{`${value[0].type}`}</span>
                <small>
                  {value[0].depth} {value[0].depthUom}
                </small>
              </div>
              {value.length > 1 ? (
                <IconButton>
                  <InfoOutlined />
                </IconButton>
              ) : null}
            </Box>
          ) : (
            "--"
          );
        },
      },
      {
        Header: t("verity.manureApplications"),
        accessor: "manureApplications",
        Cell: ApplicationsCell,
      },
      {
        Header: t("verity.otherApplications"),
        accessor: "otherApplications",
        Cell: ApplicationsCell,
      },
      {
        Header: t("verity.harvestedYieldAvg"),
        accessor: "harvestedYieldAvg",
        Cell: ({ row }: { row: { original: VerityFieldCI } }) => {
          return row.original.harvestedYieldAvg
            ? `${row.original.harvestedYieldAvg} ${
                row.original.harvestedYieldUom ?? ""
              }`
            : "--";
        },
      },
      {
        Header: t("verity.ciScore"),
        accessor: "score",
        Cell: ({ row }: { row: { original: VerityFieldCI } }) => {
          const textColor =
            row.original.statusEn === "certified" ? "success.main" : undefined;
          return row.original.score ? (
            <Box color={textColor}>{`${row.original.score} ${
              row.original.scoreUom ?? ""
            }`}</Box>
          ) : (
            "--"
          );
        },
      },
    ],
    [org, season?.id, selectedProgram?.value, t, verityFieldEnrollMutation]
  );
  return (
    <Stack style={{ height: "100%" }} spacing={1}>
      <Typography component="h2" variant="h5">
        Verity Module
      </Typography>
      <Paper
        style={{
          height: "100%",
        }}
        spacing={3}
        component={Stack}
        sx={{ p: 3 }}
      >
        <Typography>
          This software module allows a user to select from various
          sustainability programs, then view projected carbon intensity (CI)
          scores. CI scores will adjust depending on the sustainable management
          practices implemented. After fields and programs are reviewed, the
          user may submit a request to enroll to certify the selected field.
        </Typography>
        {verityQ.isError || verityRefreshQ.isError ? (
          <Alert severity="error">
            {verityRefreshQ.error || verityQ.error}
          </Alert>
        ) : null}
        <DataTable
          isLoading={verityQ.isFetching || verityRefreshQ.isFetching}
          components={{
            HeaderLeft: (
              <div style={{ paddingTop: "1px", flex: 1 }}>
                <Autocomplete
                  label={t("verity.program")}
                  value={selectedProgram ?? null}
                  options={availablePrograms}
                  onChange={(_e, opt: { label: string; value: string }) => {
                    setSelectedProgram(opt);
                  }}
                />
              </div>
            ),
            HeaderRight: (
              <Button
                disabled={verityRefreshQ.isFetching}
                onClick={async () => {
                  verityFieldEnrollMutation.reset();
                  setShouldRefresh(true);
                }}
                variant="outlined"
              >
                {t("verity.refresh")}
              </Button>
            ),
          }}
          columns={columns}
          data={verityQ.data}
        />
      </Paper>
    </Stack>
  );
}
