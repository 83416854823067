import { useParams } from "react-router-dom";
import AssetRecordForm from "./AssetRecordForm";
import AssetPhotosStack from "../AssetDetails/AssetPhotosStack";
import { IUrlParams } from "types";
import { useAssetEventTypes } from "api/assets/useAssetEventTypes";
import Grid from "@mui/material/Unstable_Grid2";
import { useAssetEvents } from "api/assets/useAssetEvents";
import { useOrgState } from "providers";
import { useAssetGeodataById } from "api/assets/useAssetGeodataById";

export default function CreateOrEditRecord() {
  const { type, itemId, recordId } = useParams<
    IUrlParams & { type: "animal" | "fixed" | "plant" }
  >();
  const { org, season } = useOrgState();
  const geodataQ = useAssetGeodataById({
    type,
    org,
    seasonId: season?.id,
    id: itemId,
  });
  const currentEditFtr = geodataQ.data?.features[0];
  const assetEventTypesQ = useAssetEventTypes(org);
  const assetsEventsQ = useAssetEvents(org, itemId);
  const assetTypeId = currentEditFtr?.properties?.assetTypeId;
  // filter types by asset type(planted/applied/harvested/tilled)
  const selectedAssetEventTypes = assetEventTypesQ.data?.filter(
    (at) => at.type === assetTypeId
  );
  const currentEditRecord = recordId
    ? assetsEventsQ.data?.find((r) => r.id === recordId)
    : null;
  return (
    <Grid container spacing={2}>
      <Grid sm={12}>
        <AssetRecordForm
          assetEventTypes={selectedAssetEventTypes}
          currentEditRecord={currentEditRecord}
        />
      </Grid>
      {currentEditRecord ? (
        <Grid sm={12}>
          <AssetPhotosStack type="event" />
        </Grid>
      ) : null}
    </Grid>
  );
}
