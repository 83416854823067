import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { useInventoryDispatch } from "providers";
import PlantAssetUpload from "./PlantAssetUpload";
import FixedAssetUpload from "./FixedAssetUpload";
import AnimalAssetUpload from "./AnimalAssetUpload";
import { useQueryClient } from "@tanstack/react-query";
import { useOrgState } from "providers";

export default function AssetUpload() {
  const { org } = useOrgState();
  const { disableDraw, setInventoryFtrs } = useInventoryDispatch();
  const queryClient = useQueryClient();

  const invalidateQueryKey = React.useMemo(
    () => ["organization", org, "assets"],
    [org]
  );

  function invalidateQueries() {
    queryClient.invalidateQueries({
      queryKey: invalidateQueryKey,
    });
  }

  useEffect(() => {
    disableDraw();
  }, [disableDraw]);

  useEffect(() => {
    return () => {
      setInventoryFtrs(null);
    };
  }, [setInventoryFtrs]);

  return (
    <Switch>
      <Route path="/:org/:season/inventory/assets/plant">
        <PlantAssetUpload onSuccess={invalidateQueries} />
      </Route>
      <Route path="/:org/:season/inventory/assets/fixed">
        <FixedAssetUpload onSuccess={invalidateQueries} />
      </Route>
      <Route path="/:org/:season/inventory/assets/animal">
        <AnimalAssetUpload onSuccess={invalidateQueries} />
      </Route>
    </Switch>
  );
}
