import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";
import { blobToImage } from "lib";
import { ParsedImage } from "api/types";
import operationKeys from "./operationKeys";

export function useOperationEventPhotos({
  org,
  eventId,
}: {
  org: string;
  eventId?: string;
}) {
  const fetchItemPhotos = useCallback(async () => {
    const res = await axios.get<string[]>(
      `/${org}/operationevents/${eventId}/photos`
    );
    const all: ParsedImage[] = [];
    const photoIds: string[] = [];
    const reqs: Promise<AxiosResponse>[] = [];
    res.data.forEach(async (photoUrl) => {
      const imgReq = axios.get(photoUrl, {
        baseURL: "",
        responseType: "blob",
      });
      reqs.push(imgReq);
      photoIds.push(photoUrl.split("photos/")[1]);
    });
    const x = await Promise.all(reqs);
    const imgPromises: Promise<HTMLImageElement>[] = [];
    x.forEach((value) => {
      imgPromises.push(blobToImage(value.data));
    });
    const imgResponses = await Promise.all(imgPromises);
    imgResponses.forEach((image, i) => {
      const pid = photoIds[i];
      // create object to pass to the image viewer
      // but also include source image
      all.push({
        image,
        src: image.src,
        w: image.width,
        h: image.height,
        id: pid,
      });
    });
    return all;
  }, [eventId, org]);

  const query = useQuery({
    queryKey: operationKeys.eventPhotos(org, eventId),
    queryFn: fetchItemPhotos,
    enabled: !!org && !!eventId,
  });

  return query;
}
