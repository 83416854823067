import { SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import Autocomplete from "lib/Select/Autocomplete";
import { IField } from "types";
import { useFields } from "api/fields/useFields";
import { useOrgState } from "providers";

export function FieldsByFarm({
  value,
  defaultValue,
  onChange,
  required = true,
}: {
  value?: string;
  defaultValue?: string;
  onChange?: (_e: SyntheticEvent, item: IField) => void;
  required?: boolean;
}) {
  const { t } = useTranslation();
  const { org, season } = useOrgState();
  const fieldsQ = useFields({ org, seasonId: season?.id });
  const [fieldId, setFieldId] = useState<string>(defaultValue);

  return (
    <Autocomplete
      label={`${t("common.field")} *`}
      disableClearable={false}
      id="field-select"
      getOptionLabel={(o) => (typeof o !== "string" ? o.name : o)}
      groupBy={(o) => o.farm}
      options={
        fieldsQ.data?.sort((a, b) =>
          a.farm > b.farm ? 1 : b.farm > a.farm ? -1 : 0
        ) || []
      }
      InputProps={{
        required,
      }}
      onChange={(_e, item) => {
        const fld = item as IField;
        onChange(_e, fld);
        setFieldId(fld?.id);
      }}
      value={
        fieldsQ.data?.find((d: IField) => [value ?? fieldId].includes(d.id)) ??
        null
      }
    />
  );
}
