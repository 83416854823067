import React from "react";
import { Theme, ThemeProvider as TP } from "@mui/material/styles";
import * as locales from "@mui/material/locale";
import useMediaQuery from "@mui/material/useMediaQuery";
import getTheme from "./getTheme";

export type SupportedLocale = keyof typeof locales;

declare module "@mui/material/styles" {
  interface Theme {
    locale: SupportedLocale;
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    locale?: SupportedLocale;
  }
  interface Palette {
    danger: Palette["primary"];
    alt: Palette["primary"];
  }

  interface PaletteOptions {
    danger?: PaletteOptions["primary"];
    alt?: PaletteOptions["primary"];
  }
}

export default function ThemeProvider({
  children,
  locale,
  mode,
  theme,
}: {
  children: React.ReactNode;
  locale?: SupportedLocale;
  mode?: "dark" | "light";
  theme?: Theme;
}) {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const defaultMode = prefersDarkMode ? "dark" : "light";
  const themeToUse = theme ?? getTheme(locale ?? "enUS", mode ?? defaultMode);

  return (
    <TP theme={themeToUse}>
      <>{children}</>
    </TP>
  );
}
