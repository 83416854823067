import React, {
  useEffect,
  useState,
  useCallback,
  ChangeEvent,
  // useRef,
  // createRef,
} from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useOrgState } from "providers";
import { useTranslation } from "react-i18next";
import {
  Controller,
  FieldValues,
  useForm,
  UseFormMethods,
} from "react-hook-form";
import ImageStack from "components/ImageStack";
import { useOperationEventPhotos } from "api/operations/useOperationEventPhotos";
import { useOperationEventPhotoPost } from "api/operations/useOperationEventPhotoPost";
import { useOperationEventPhotoDelete } from "api/operations/useOperationEventPhotoDelete";
import { useOperationEventMutations } from "api/operations/useOperationEventMutations";
import { OperationEvent } from "api/operations/types/OperationEvent";
import { FormWrapper } from "components";
import Autocomplete from "lib/Select/Autocomplete";
import { useEventTemplates } from "api/operations/useEventTemplates";
import { DatePicker } from "lib/DatePicker";
import { useHistory, useParams } from "react-router-dom";
import { IUrlParams } from "types";

function getStepFromDecimals(arr: number[]) {
  return !arr.length
    ? ""
    : arr.reduce((curr, next, idx) => {
        const val = idx === arr.length - 1 ? "1" : "0";
        return curr + val;
      }, "0.");
}

function getMaxFromDigitsAndStep(digits: number, step: number) {
  const max = [...Array.from(Array(digits).keys())].reduce((curr) => {
    return `${curr}9`;
  }, "");
  return Number(max) + 1 - step;
}

interface IUom {
  label: string;
  digits: number;
  decimals: number;
}

interface IInputValue {
  id: string;
  value: string | number;
}

interface IFormRules {
  required: boolean;
}

interface IOperationEventInput {
  id: string;
  label: string;
  type: "string" | "numeric" | "list" | "multiselectlist" | "date";
  isRequired: boolean;
  order: number;
  value?: string | string[] | number | number[] | IInputValue[];
  uom?: IUom;
  values?: { id: string; label: string; order: number }[]; // only for list/multiselectlist
}

interface IEventTemplate {
  id: string;
  inputs: IOperationEventInput[] | null;
}

interface IOperationEvent {
  id: string;
  operationId: string;
  templateId: string;
  dateUtc: string;
  notes?: string;
  inputs?: IOperationEventInput[] | null;
}

const defaultValues = {
  id: "",
  operationId: "",
  templateId: "",
  dateUtc: new Date().toISOString(),
  notes: "",
  inputs: null,
} as IOperationEvent;

export default function OperationEventForm({
  event,
  onCancel,
  onSave,
  operationId,
}: {
  event?: OperationEvent;
  onCancel?: () => void;
  onSave?: (_d?: OperationEvent) => void;
  operationId: string;
}) {
  const { t } = useTranslation();
  const { itemId, type } = useParams<IUrlParams>();
  const history = useHistory();
  const [selectedTemplate, setSelectedTemplate] = useState<IEventTemplate>();
  const { org, rootUrl } = useOrgState();
  const eventTemplatesQ = useEventTemplates({ org });
  const eventId = event?.id;
  const backUrl = `${rootUrl}/inventory/operations/${type}/items/${itemId}`;
  const { saveMutation, deleteMutation } = useOperationEventMutations({
    eventId: event?.id,
    org,
  });
  const photosQ = useOperationEventPhotos({ org, eventId });
  const savePhotoQ = useOperationEventPhotoPost({
    org,
    eventId,
    operationId,
  });
  const deletePhotoQ = useOperationEventPhotoDelete({
    org,
    eventId,
    operationId,
  });

  const methods: UseFormMethods = useForm<FieldValues>({
    defaultValues: { ...defaultValues },
  });
  const {
    handleSubmit,
    register,
    watch,
    control,
    reset,
    // errors,
    setValue,
    formState: { isDirty },
  } = methods;

  const { templateId, inputs, dateUtc } = watch();
  // const recordEvent: IOperationEvent = fetchEventsState?.data?.find(
  //   (d: IOperationEvent) => d.id === eventId
  // );
  const setInputVals = useCallback(
    (item, val) => {
      const data: IOperationEventInput[] = inputs ? [...inputs] : [];
      const exists = data.find((inp) => inp.id === item.id);
      if (exists) {
        const idx = data.indexOf(exists);
        if (idx >= 0) {
          data.splice(idx, 1, {
            id: item.id,
            value: val,
          } as IOperationEventInput);
        }
      } else {
        data.push({ ...item, value: val } as IOperationEventInput);
      }
      setValue("inputs", data, { shouldDirty: true });
    },
    [inputs, setValue]
  );

  const onSubmit = useCallback(
    async (data: { id: string; inputs: { id: string; value: unknown }[] }) => {
      if (!isDirty) {
        onCancel?.();
        history.push(backUrl);
        return;
      }
      if (!data.id) {
        delete data.id;
      }
      if (!data.inputs?.length) delete data.inputs;
      // if (!data.id) delete data.id;
      // loop the template inputs, and include any non-required items
      // with null values
      data.inputs &&
        selectedTemplate?.inputs.forEach((i) => {
          const exists = data.inputs?.find((di) => i.id === di.id);
          if (!exists) {
            data.inputs.push({ id: i.id, value: null });
          }
        });
      return saveMutation.mutateAsync(data, {
        onSuccess: (res) => {
          reset();
          const url = event ? backUrl : `${backUrl}/records/${res.id}`;
          onSave?.(res);
          history.push(url);
        },
      });
    },
    [
      isDirty,
      selectedTemplate?.inputs,
      saveMutation,
      onCancel,
      history,
      backUrl,
      reset,
      event,
      onSave,
    ]
  );

  // useEffect(() => {
  //   inputRefs.current =
  //     selectedTemplate?.inputs?.map(
  //       (el, idx) => inputRefs.current[idx] ?? createRef()
  //     ) || [];
  // }, [selectedTemplate]);

  useEffect(() => {
    if (event) {
      const copy = { ...event };
      copy.inputs =
        copy?.inputs?.map((i: IOperationEventInput) => {
          return {
            ...i,
            value: Array.isArray(i.value)
              ? [...i.value].map((v: IInputValue) => v.id)
              : i.value,
          };
        }) ?? [];
      reset(copy);
      const templateObj = eventTemplatesQ.data?.find(
        (e: IEventTemplate) => e.id === copy.templateId
      );
      setSelectedTemplate(templateObj);
    }
  }, [event, reset, eventTemplatesQ.data]);

  return (
    <>
      <FormWrapper
        geometryRequired={false}
        methods={methods}
        data={{ ...event }}
        ignoreGeom
        cancelHref={`${rootUrl}/inventory/operations/${type}/items/${itemId}`}
        onCancel={onCancel}
        saveState={{
          isLoading: saveMutation.isLoading,
          isError: saveMutation.isError,
          errorMessage: saveMutation.error,
        }}
        deleteState={{
          isLoading: deleteMutation.isLoading,
          isError: deleteMutation.isError,
          errorMessage: deleteMutation.error,
        }}
        onDelete={async () => {
          deleteMutation.mutate(eventId, {
            onSuccess: () => {
              reset();
              onCancel?.();
              history.push(backUrl);
            },
          });
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <input
          type="hidden"
          name="id"
          ref={register}
          required
          value={event?.id}
        />

        <input
          type="hidden"
          name="operationId"
          ref={register}
          required
          value={operationId || ""}
        />
        <Stack spacing={2}>
          <Controller
            name="templateId"
            control={control}
            rules={{ required: true }}
            render={(props: { onChange: (id: string) => void }) => (
              <Autocomplete
                label={`${t("common.type")} *`}
                inputProps={{ required: true }}
                disabled={Boolean(eventId)}
                options={eventTemplatesQ.data || []}
                getOptionLabel={(opt) => opt.name}
                // value={selectedTemplate ?? null}
                value={
                  eventTemplatesQ.data?.find(
                    (d: { id: string }) => d.id === templateId
                  ) ?? null
                }
                onChange={(_e, opt) => {
                  const item = opt as IEventTemplate;
                  setSelectedTemplate(item);
                  props.onChange(item?.id || "");
                }}
                disableClearable={false}
              />
            )}
          />
          {selectedTemplate ? (
            <Controller
              name="dateUtc"
              rules={{ required: true }}
              control={control}
              render={(props: { onChange: (id: string) => void }) => {
                return (
                  <DatePicker
                    label={`${t("common.date")}`}
                    value={dateUtc ? new Date(dateUtc) : new Date()}
                    onChange={(date: Date) => {
                      props.onChange(date?.toISOString());
                    }}
                    slotProps={{
                      textField: { required: true },
                    }}
                  />
                );
              }}
            />
          ) : null}
          <Controller
            name="inputs"
            control={control}
            render={() => {
              return (
                <>
                  {selectedTemplate?.inputs?.map((i: IOperationEventInput) => {
                    const uomLabel = i?.uom?.label;
                    const rules = {} as IFormRules;
                    if (i.isRequired) {
                      rules.required = true;
                    }
                    switch (i.type) {
                      case "date": {
                        const currValISO = inputs?.find(
                          (d: IInputValue) => d.id === i.id
                        )?.value as string;
                        return (
                          <DatePicker
                            key={i.id}
                            slotProps={{
                              textField: { required: i.isRequired },
                              field: { clearable: !i.isRequired },
                            }}
                            label={`${i.label}`}
                            value={currValISO ? new Date(currValISO) : null}
                            onChange={(date: Date) => {
                              setInputVals(
                                i,
                                date ? date?.toISOString() : null
                              );
                            }}
                          />
                        );
                      }
                      case "multiselectlist": {
                        const currInput = inputs?.find(
                          (d: IInputValue) => d.id === i.id
                        );
                        const opts: IInputValue[] = Array.isArray(i.values)
                          ? i.values?.map((v: IOperationEventInput) => ({
                              ...v,
                              value: v.id,
                            })) || []
                          : [];
                        const selectedOpts = opts.filter((f) => {
                          const currVal = Array.isArray(currInput?.value)
                            ? [...currInput?.value]
                            : [];
                          return currVal
                            ? currVal?.find((v: string) => v === f.id)
                            : null;
                        });
                        return (
                          <React.Fragment key={i.id}>
                            <Autocomplete
                              label={`${i.label}${rules.required ? " *" : ""}`}
                              value={selectedOpts ?? []}
                              // only setting required if we dont have selection
                              // to work around autocomplete issue
                              InputProps={{
                                required: !selectedOpts?.length
                                  ? i.isRequired
                                  : false,
                              }}
                              multiple
                              options={opts}
                              disableClearable={false}
                              onChange={(_e, items: IInputValue[]) => {
                                setInputVals(
                                  i,
                                  items && items.length
                                    ? items?.map((f) => f.value)
                                    : null
                                );
                              }}
                            />
                          </React.Fragment>
                        );
                      }
                      case "list": {
                        const currInput = inputs?.find(
                          (d: IInputValue) => d.id === i.id
                        );
                        const opts =
                          i.values?.map((v: IOperationEventInput) => ({
                            ...v,
                            value: v.id,
                          })) || [];
                        const selectedOpt = opts.find((f) => {
                          return currInput?.value?.includes(f.id);
                        });
                        return (
                          <React.Fragment key={i.id}>
                            <Autocomplete
                              label={`${i.label}${rules.required ? " *" : ""}`}
                              id={i.id}
                              options={opts}
                              InputProps={{
                                required: i.isRequired,
                              }}
                              value={selectedOpt ?? null}
                              onChange={(_e, item: IInputValue) => {
                                setInputVals(
                                  i,
                                  item?.value ? [item?.value] : null
                                );
                              }}
                              disableClearable={false}
                            />
                          </React.Fragment>
                        );
                      }
                      case "numeric": {
                        const { digits, decimals } = i?.uom || {};
                        const step = Number(
                          getStepFromDecimals([
                            ...Array.from(Array(decimals).keys()),
                          ])
                        );
                        const max = getMaxFromDigitsAndStep(digits, step);
                        const val = inputs?.find(
                          (d: IInputValue) => d.id === i.id
                        )?.value;
                        return (
                          <React.Fragment key={i.id}>
                            <TextField
                              label={`${i.label}${i.isRequired ? " *" : ""}`}
                              InputProps={{
                                required: i.isRequired,
                                endAdornment: (
                                  <Box sx={{ ml: 2 }}>{uomLabel}</Box>
                                ),
                              }}
                              inputProps={{
                                type: "number",
                                max,
                                step,
                              }}
                              defaultValue={val}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setInputVals(
                                  i,
                                  e.target.value ? Number(e.target.value) : null
                                );
                              }}
                            />
                          </React.Fragment>
                        );
                      }
                      // default: 'string'
                      default: {
                        const val = inputs?.find(
                          (d: IInputValue) => d.id === i.id
                        )?.value;
                        return (
                          <React.Fragment key={i.id}>
                            <TextField
                              label={`${i.label}`}
                              required={i.isRequired}
                              defaultValue={val}
                              id={i.id}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                setInputVals(i, e.target.value || null);
                              }}
                            />
                          </React.Fragment>
                        );
                      }
                    }
                  }) || null}
                </>
              );
            }}
          />
          {selectedTemplate ? (
            <TextField
              fullWidth
              multiline
              label={t("common.notes")}
              name="notes"
              inputProps={{ maxLength: 255, ref: register }}
              id="desc-notes"
            />
          ) : null}

          {event ? (
            <>
              <Typography component={"h6"}>
                {t("common.photos")} (Max 4)
              </Typography>
              <ImageStack
                photoCount={event?.photoCount}
                deletePhotoQ={deletePhotoQ}
                savePhotoQ={savePhotoQ}
                photosQ={photosQ}
              />
            </>
          ) : null}
        </Stack>
      </FormWrapper>
    </>
  );
}
