import { useState, useEffect, useRef, useCallback } from "react";
// import syncMaps from '@mapbox/mapbox-gl-sync-move';
import syncMaps from "./mapbox-gl-sync-move";
import { useFieldLegendLayer, useLegendControl } from "components/Map";
import Grid2 from "@mui/material/Unstable_Grid2";
import MapView from "../../BaseMapView";
import { featureCollection } from "@turf/helpers";
import { usePrevious } from "components";
import { LegendSymbol } from "lib/MapboxMap";
import { useTheme } from "@mui/material/styles";
import { MapData } from "./types";
import { BBox, FeatureCollection } from "geojson";
import { Map, MapSourceDataEvent, MapboxEvent } from "mapbox-gl";

function getMapLegend(data: MapData["legendData"], id: number) {
  return {
    id: `mapsrc-${id}-layer`,
    isStatic: true,
    title: data?.type ?? "",
    data: data?.data?.length
      ? data?.data.map((i) => ({
          name: i.label,
          Symbol: function Symbol() {
            return (
              <LegendSymbol geometryType="Polygon" backgroundColor={i.color} />
            );
          },
        }))
      : null,
  };
}

export default function SyncedMaps({
  mapData,
  defaultBounds,
  fields,
  onMapsSync,
}: {
  mapData: MapData[];
  defaultBounds: BBox;
  fields: FeatureCollection;
  onMapsSync: (_d: MapData[]) => void;
}) {
  const fieldLegendLayer = useFieldLegendLayer();
  const mapRefs = useRef([]);
  const [loadingIds, setLoadingIds] = useState([]);
  const prevMapData = usePrevious(mapData) as MapData[];
  const prevFields = usePrevious(fields);
  const unSyncMaps = useRef<() => void>();
  const theme = useTheme();

  // NOTE: originally tried to keep this component generic enough to support n number of maps.
  // however, now only three maps with legend components are supported
  // likely not an issue, unless we ever decide to do more than 3 map image compares
  const { legendCtrl } = useLegendControl({
    theme,
    location: "bottom-right",
    map: mapRefs.current[0],
    layers: [fieldLegendLayer, getMapLegend(mapData[0]?.legendData, 1)],
  });
  const { legendCtrl: legendCtrl2 } = useLegendControl({
    theme,
    location: "bottom-right",
    map: mapRefs.current[1],
    layers: [fieldLegendLayer, getMapLegend(mapData[1]?.legendData, 2)],
  });
  const { legendCtrl: legendCtrl3 } = useLegendControl({
    theme,
    location: "bottom-right",
    map: mapRefs.current[2],
    layers: [fieldLegendLayer, getMapLegend(mapData[2]?.legendData, 3)],
  });

  const sync = useCallback(() => {
    if (mapRefs.current.length > 1) {
      const newData = [...mapRefs.current];
      newData.forEach((mr) => {
        if (mr) {
          mr.resize();
        }
      });
      unSyncMaps.current = syncMaps(newData);
      onMapsSync(newData);
    }
  }, [onMapsSync]);

  const unsync = useCallback(() => {
    if (unSyncMaps.current) {
      unSyncMaps.current();
    }
  }, []);

  // update mapRefs when map removed
  useEffect(() => {
    if (prevMapData) {
      prevMapData.forEach((md) => {
        const exists = mapData.find((m) => m.id === md.id);
        if (!exists) {
          // map item no longer exists in the data,
          // so we unsync the maps, remove it, and then re-sync remaining maps
          const mapRef = mapRefs.current.find((mr) => mr === md.mapRef);
          const idx = mapRefs.current.indexOf(mapRef);
          unsync();
          // remove map ref
          if (mapRef) {
            mapRefs.current.splice(idx, 1);
          }
          md.mapRef = null;
          sync();
        }
      });
    }
  }, [mapData, prevMapData, sync, unsync]);

  // update fields source
  useEffect(() => {
    if (fields !== prevFields) {
      mapRefs.current.forEach((mr) => {
        if (mr) {
          const src = mr.getSource("fields");
          if (src) {
            src.setData(fields);
          }
        }
      });
    }
  }, [fields, prevFields]);

  // cleanup on exit
  useEffect(() => {
    return unsync;
  }, [unsync]);

  function handleMapRef(map: Map, id: number) {
    if (!mapRefs.current.includes(map)) {
      const mapObj = mapData.find((md) => md.id === id);
      mapObj.mapRef = map;
      mapRefs.current = mapRefs.current.concat([map]);
    }
    if (mapRefs.current.length === mapData.length) {
      // all maps should now be loaded
      unsync();
      sync();
      // forces new maps to take extent of first
      mapRefs.current[0].setZoom(mapRefs.current[0].getZoom());
    }
  }
  function removeId(id: number) {
    const ids = [...loadingIds];
    if (ids.includes(id)) {
      const idx = ids.indexOf(id);
      if (idx > -1) {
        ids.splice(idx, 1);
      }
      setLoadingIds([...ids]);
    }
  }
  return (
    <Grid2 container height="100%" spacing={3}>
      {mapData.map((md, i) => (
        <Grid2 xs key={md.id}>
          <MapView
            showBasemapSelect={false}
            isLoading={loadingIds.includes(md.id)}
            bounds={defaultBounds}
            defaultBounds={defaultBounds}
            showFullScreen
            showHome
            events={{
              load: (e: MapboxEvent) => {
                handleMapRef(e.target, md.id);
                if (i === 0) {
                  e.target.addControl(legendCtrl, "bottom-right");
                }
                if (i === 1) {
                  e.target.addControl(legendCtrl2, "bottom-right");
                }
                if (i === 2) {
                  e.target.addControl(legendCtrl3, "bottom-right");
                }
                e.target.addSource("fields", {
                  type: "geojson",
                  data: fields || featureCollection([]),
                });
                e.target.addLayer({
                  id: "fields-lyr",
                  type: "line",
                  source: "fields",
                  paint: {
                    "line-width": 3,
                    "line-color": "#fff",
                  },
                });
              },
              dataloading: (e: MapSourceDataEvent) => {
                if (e.sourceId === `mapsrc-${md.id}`) {
                  if (!loadingIds.includes(md.id)) {
                    const ids = [...loadingIds];
                    ids.push(md.id);
                    setLoadingIds(ids);
                  }
                }
              },
              data: (e: MapSourceDataEvent) => {
                if (e.sourceId === `mapsrc-${md.id}`) {
                  removeId(md.id);
                }
              },
              idle: () => {
                removeId(md.id);
              },
              error: (e: MapSourceDataEvent) => {
                console.error("Mapbox error occurred:", e);
                if (e.sourceId === `mapsrc-${md.id}`) {
                  removeId(md.id);
                }
              },
            }}
            containerStyles={{
              height: "100%",
              width: "100%",
            }}
          />
        </Grid2>
      ))}
    </Grid2>
  );
}
