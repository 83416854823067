import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import usePrevious from "../usePrevious";

const initialState = {
  data: [],
  left: "dataMin",
  right: "dataMax",
  refAreaLeft: "",
  refAreaRight: "",
  top: "dataMax",
  bottom: 0,
  //   top2: 'dataMax+20',
  //   bottom2: 'dataMin-20',
  //   animation: true,
};

export function closest(needle, haystack) {
  const x = haystack.reduce((a, b) => {
    const aDiff = Math.abs(a - needle);
    const bDiff = Math.abs(b - needle);

    if (aDiff === bDiff) {
      return a > b ? a : b;
    }
    return bDiff < aDiff ? b : a;
  });
  return haystack.indexOf(x);
}

export function getAxisYDomain(data, from, to, ref, offset) {
  const refData = data.slice(from, to);
  let [bottom, top] = [refData[0][ref], refData[0][ref]];
  refData.forEach((d) => {
    if (d[ref] > top) top = d[ref];
    if (d[ref] < bottom) bottom = d[ref];
  });

  return [(bottom || 0) - offset, (top || 0) + offset];
}

function getXDomain(item, dataKey) {
  if (!item) {
    return "";
  }
  if (typeof dataKey === "function") {
    return dataKey(item);
  }
  return dataKey ? item[dataKey] : null;
}

export function useZoomChart({ data, xDataKey }) {
  const prevData = usePrevious(data);
  const [state, setState] = useState({
    data,
    ...initialState,
  });

  useEffect(() => {
    if (data !== prevData) {
      setState({ ...state, data });
    }
  }, [data, prevData, state]);

  function zoomTo(l, r) {
    const { refAreaLeft, refAreaRight } = state;
    const left = l || l === 0 ? l : refAreaLeft;
    const right = r || refAreaRight;
    if (left === right || right === "") {
      setState({ ...state, refAreaLeft: "", refAreaRight: "" });
      return;
    }
    let leftX;
    let rightX;
    // xAxis domain
    if (left > right) {
      leftX = right;
      rightX = left;
    } else {
      leftX = left;
      rightX = right;
    }

    // yAxis domain
    const [b, t] = getAxisYDomain(data, leftX, rightX, "area", 10);
    // const [bottom2, top2] = getAxisYDomain(
    //   refAreaLeft,
    //   refAreaRight,
    //   'impression',
    //   50
    // );

    setState({
      data: data.slice(),
      refAreaLeft: "",
      refAreaRight: "",
      left: getXDomain(data[leftX], xDataKey),
      right: getXDomain(data[rightX], xDataKey),
      bottom: Math.max(0, Math.round(b)),
      top: t,
      //   bottom2,
      //   top2,
    });
  }

  function zoomDefault() {
    setState({
      ...initialState,
      data: state?.data.slice(),
    });
  }
  function zoomIn(amount = 10) {
    setState({
      ...state,
      left: `dataMin+${amount}`,
      right: `dataMax-${amount}`,
      top: `dataMax-${amount}`,
      bottom: `dataMin+${amount}`,
      data: state?.data.slice(),
    });
  }
  function zoomOut(amount = 10) {
    setState({
      ...state,
      left: `dataMin-${amount}`,
      right: `dataMax+${amount}`,
      top: `dataMax+${amount}`,
      bottom: `dataMin-${amount}`,
      data: state?.data.slice(),
    });
  }

  return {
    state,
    setState,
    setRefAreas: ({ left, right }) => {
      const obj = { ...state };
      if (left) {
        obj.refAreaLeft = left;
      }
      if (right) {
        obj.refAreaRight = right;
      }
      setState(obj);
    },
    zoomTo,
    zoomIn,
    zoomOut,
    zoomDefault,
  };
}

export function getEpoch(item, key) {
  if (!item || !item[key]) {
    return "";
  }
  return new Date(item[key]).getTime();
}

export function getDateString(item, key) {
  if (!item || !item[key]) {
    return "";
  }
  return new Date(item[key]).toLocaleDateString();
}

export function sortByDate(arr, dateProp) {
  return arr?.sort((a, b) => new Date(a[dateProp]) - new Date(b[dateProp]));
}

// function groupByArr(xs, key) {
//   return xs.reduce((rv, x) => {
//     const y = rv.find((r) => r[0][key] === x[key]);
//     if (!y) {
//       rv.push([x]);
//     } else {
//       y.push(x);
//     }
//     return rv;
//     // (rv[x[key]] = rv[x[key]] || []).push(x);
//     // return rv;
//   }, []);
// }

export function groupBy(arr, key) {
  return arr?.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export function groupByKeys(arr, keys) {
  return arr?.reduce((rv, x) => {
    const prop = keys.reduce((str, key) => {
      str += `${str ? "-" : ""}${x[key]}`;
      return str;
    }, "");
    (rv[prop] = rv[prop] || []).push(x);
    return rv;
  }, {});
}

export const CenteredBarLabel = (props) => {
  const { x, y, width, height, value, color, fontColor } = props;
  const radius = 10;

  return (
    <g>
      <circle cx={x + width / 2} cy={y - radius} fill={color} />
      <text
        x={x + width / 2}
        y={y + height / 2}
        fill={fontColor}
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {value}
      </text>
    </g>
  );
};
CenteredBarLabel.defaultProps = {
  x: null,
  y: null,
  width: null,
  height: null,
  value: null,
  color: null,
  fontColor: "#333",
};

CenteredBarLabel.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  fontColor: PropTypes.string,
};

export const TopBarLabel = (props) => {
  const { x, y, width, height, value, color } = props;
  const radius = 10;

  return (
    <g>
      <circle cx={x + width / 2} cy={y - radius} fill={color} />
      <text
        x={width < 32 ? x + 20 : x + width - (15 + value.toString().length)}
        y={y + height / 2}
        textAnchor="middle"
        dominantBaseline="middle"
        fontWeight="bold"
        fontSize={16}
      >
        {value}
      </text>
    </g>
  );
};
TopBarLabel.defaultProps = {
  x: null,
  y: null,
  width: null,
  height: null,
  value: null,
  color: null,
};

TopBarLabel.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
};
