import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { YieldFilter } from "./types";

export function useYieldFilters({
  org,
  seasonId,
}: {
  org: string;
  seasonId: string;
}) {
  const getData = useCallback(async () => {
    const yieldFiltersUrl = `/${org}/myaccount/yieldfilters/seasons/${seasonId}`;
    const res = await axios.get<YieldFilter[]>(yieldFiltersUrl);
    return res.data;
  }, [org, seasonId]);

  return useQuery({
    queryKey: ["organization", org, "season", seasonId, "yield-filters"],
    queryFn: getData,
    enabled: !!org && !!seasonId,
    staleTime: 10 * (60 * 1000), // 10 mins
  });
}
