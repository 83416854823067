import { CardProps } from "@mui/material/Card";
import React from "react";
import BaseCardView from "./BaseCardView";
import SummaryCardTitle from "./SummaryCardTitle";
import SummaryCardValue from "./SummaryCardValue";

export default function SummaryCardView({
  href,
  label,
  value,
  ...rest
}: {
  href?: string;
  label: React.ReactNode;
  value: React.ReactNode;
} & CardProps) {
  return (
    <BaseCardView href={href} {...rest}>
      <SummaryCardTitle>{label}</SummaryCardTitle>
      <SummaryCardValue>{value}</SummaryCardValue>
    </BaseCardView>
  );
}
