import { useQuery } from "@tanstack/react-query";
import { Feature, Geometry, MultiPolygon, Polygon } from "geojson";
import { useCallback } from "react";
import axios from "axios";
import { ProductLookup } from "./types";

const getIntersection = async (
  org: string,
  geometryA: Geometry,
  geometryB: Geometry
) => {
  const res = await axios.post<Polygon | MultiPolygon>(
    `/${org}/geometry/intersection`,
    {
      geometryA: JSON.stringify(geometryA),
      geometryB: JSON.stringify(geometryB),
    }
  );
  return res.data;
};

export function useProductGeometryIntersection({
  application,
  org,
  seed,
}: {
  application?: ProductLookup;
  seed?: ProductLookup;
  org: string;
}) {
  const doSomething = useCallback(async () => {
    const ftrs: Feature[] = [];
    if (application?.id) {
      const appliedGeomRes = await axios.get(
        `/${org}/operations/applied/geodata/${application.id}/boundary`
      );
      const x: Feature = {
        type: "Feature",
        properties: {
          id: application.id,
          color: application.color,
        },
        geometry: appliedGeomRes.data,
      };

      ftrs.push(x);
    }

    if (seed?.id) {
      const seedGeomRes = await axios.get(
        `/${org}/operations/planted/geodata/${seed.id}/boundary`
      );
      const x2: Feature = {
        type: "Feature",
        properties: {
          id: seed.id,
          color: seed.color,
        },
        geometry: seedGeomRes.data,
      };
      ftrs.push(x2);
    }
    let geom = ftrs[0].geometry;
    let noIntersect = false;
    if (application && seed) {
      const intersectQ = await getIntersection(
        org,
        ftrs[0]?.geometry,
        ftrs[1]?.geometry
      );

      if (intersectQ) {
        geom = intersectQ;
        if (intersectQ.type && !intersectQ.coordinates?.length) {
          noIntersect = true;
        }
      }
    }
    return {
      features: ftrs,
      geometry: geom,
      noIntersect,
    } as {
      features: Feature[];
      geometry: Polygon | MultiPolygon;
      noIntersect: boolean;
    };
  }, [application, org, seed]);

  return useQuery<
    {
      noIntersect: boolean;
      features: Feature[];
      geometry: Polygon | MultiPolygon;
    },
    Error
  >({
    queryKey: [
      "organization",
      org,
      "insights",
      "yield",
      "imagery-response",
      application?.id,
      seed?.id,
    ],
    queryFn: doSomething,
    enabled: !!org && !!(application ?? seed),
  });
}
