import axios from "axios";
import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { FeatureCollection, MultiPolygon, Polygon } from "geojson";
import { OperationType } from "types/OperationType";
import { Operation } from "./types/Operation";
import operationKeys from "./operationKeys";
import { useCropTypes } from "api/lookups/useCropTypes";
import { useProductsBySeason } from "api/products/useProductsBySeason";

export function useOperationsGeodataById<T = Operation>({
  id,
  org,
  seasonId,
  type,
}: {
  id: string;
  org: string;
  seasonId: string;
  type: OperationType;
}) {
  const cropTypesQ = useCropTypes(org);
  const productsQ = useProductsBySeason({ org, seasonId });

  const enabled =
    !!org &&
    !!type &&
    !!seasonId &&
    !!id &&
    !!productsQ.data &&
    !!cropTypesQ.data;
  const getGeodata = useCallback(async () => {
    const req1 = axios.get(`/${org}/operations/${type}/geodata/${id}`);
    const req2 = axios.get(`/${org}/operations/${type}/${id}/details`);
    const results = await Promise.all([req1, req2]);
    const [res, res2] = results;
    if (!res.data.features.length) {
      return null;
    }
    const props = res.data?.features[0]?.properties;
    const cropType = ["applied", "planted", "harvested"].includes(type)
      ? cropTypesQ.data?.find((d) => d.id === res2.data.cropTypeId)
      : null;
    const product = ["applied", "planted", "harvested"].includes(type)
      ? productsQ.data?.find((d) => d.id === res2.data.productId)
      : null;
    Object.assign(props, res2.data, {
      ...(cropType ? { crop: cropType.name, cropColor: cropType.color } : {}),
      ...(product
        ? { product: product.name, productColor: product.color }
        : {}),
    });
    return res.data as FeatureCollection<Polygon | MultiPolygon, T>;
  }, [cropTypesQ.data, id, org, productsQ.data, type]);

  return useQuery({
    queryFn: getGeodata,
    queryKey: operationKeys.geodataById(org, type, id),
    staleTime: 10 * (60 * 1000), // 10 mins
    enabled,
  });
}
