export default function dataToCsv({
  data,
  columns,
  filename = `data-to-csv-${new Date().toISOString()}`,
  headProp = "Header",
}) {
  if (!data || !columns) {
    return;
  }
  const rowDelim = '"\r\n"';
  const header = columns.map((c) => c[headProp]);
  const dataArr = data.map((d) => {
    const valueArr = [];
    columns.forEach((c) => {
      if (typeof c.accessor === "function") {
        const val = c.accessor(d);
        valueArr.push(val);
      } else if (typeof c.Cell === "function") {
        const y = c.Cell({ value: d[c.accessor], data });
        // fallback if the method returns object
        if (y.toString() !== "[object Object]") {
          valueArr.push(y);
        } else {
          valueArr.push(d[c.accessor]);
        }
      } else {
        valueArr.push(d[c.accessor]);
      }
    });
    return valueArr;
  });

  // add header row to beginning of data array
  dataArr.unshift(header);
  // build up csv text
  let csv = "";
  dataArr.forEach((r) => {
    csv += r.join(",");
    csv += rowDelim;
  });
  csv = dataArr
    .map((row) =>
      row
        .map((cell) => {
          cell = `"${cell}"`;
          return cell;
        })
        .join(",")
    )
    .join("\r\n");
  // const encodedUri = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
  // add file extension
  filename += ".csv";
  if (navigator.msSaveBlob) {
    // support IE
    navigator.msSaveBlob(new Blob([csv]), filename);
  } else {
    const csvData = new Blob([csv], { type: "text/csv" });
    const url = URL.createObjectURL(csvData);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(link.href);
    link.remove();
  }
}
