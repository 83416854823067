import Grid from "@mui/material/Unstable_Grid2";
import BaseCardView from "lib/Card/BaseCardView";
import { useTranslation } from "react-i18next";
import SummaryCardTitle from "lib/Card/SummaryCardTitle";
import SummaryCardValue from "lib/Card/SummaryCardValue";
import { Feature } from "geojson";

export default function PlantAssetDetails({ feature }: { feature: Feature }) {
  const currentEditFtr = feature;
  const { t } = useTranslation();
  const details = currentEditFtr?.properties;
  return (
    <>
      <Grid container gap={2}>
        <BaseCardView sx={{ bgcolor: "background.paper" }}>
          <SummaryCardTitle>{t("common.field")}</SummaryCardTitle>
          <SummaryCardValue>{details?.field}</SummaryCardValue>
        </BaseCardView>
        <BaseCardView sx={{ bgcolor: "background.paper" }}>
          <SummaryCardTitle>{t("common.crop")}</SummaryCardTitle>
          <SummaryCardValue>{details?.crop}</SummaryCardValue>
        </BaseCardView>
        <BaseCardView sx={{ bgcolor: "background.paper" }}>
          <SummaryCardTitle>{t("common.status")}</SummaryCardTitle>
          <SummaryCardValue>{details?.assetStatus}</SummaryCardValue>
        </BaseCardView>
        <BaseCardView sx={{ bgcolor: "background.paper" }}>
          <SummaryCardTitle>ID</SummaryCardTitle>
          <SummaryCardValue>{details?.cid}</SummaryCardValue>
        </BaseCardView>
        <BaseCardView sx={{ bgcolor: "background.paper" }}>
          <SummaryCardTitle>
            {t("inventory.assets.plantedDate")}
          </SummaryCardTitle>
          <SummaryCardValue>
            {currentEditFtr?.properties?.bornOnUtc
              ? new Date(
                  currentEditFtr?.properties?.bornOnUtc
                ).toLocaleDateString()
              : "--"}
          </SummaryCardValue>
        </BaseCardView>
        <BaseCardView>
          <SummaryCardTitle>{t("common.notes")}</SummaryCardTitle>
          <SummaryCardValue>
            {currentEditFtr?.properties?.description || "--"}
          </SummaryCardValue>
        </BaseCardView>
      </Grid>
    </>
  );
}
