import { useEffect } from "react";
import { useInventoryDispatch } from "providers/InventoryProvider";
import { useTranslation } from "react-i18next";
import { ScoutingView } from "./ScoutingView";

export function Scouting() {
  const { t } = useTranslation();
  const {
    setTitle,
    setColSizes,
    setLegendData,
    setLabelProp,
  } = useInventoryDispatch();

  useEffect(() => {
    setTitle(t("common.scouting"));
    setColSizes([8, 4]);
    setLabelProp("observedOnDateFormatted");
    setLegendData({
      title: t("common.observations"),
      geometryType: "Point",
    });
  }, [setTitle, setColSizes, setLegendData, setLabelProp, t]);

  return (
    <>
      <ScoutingView />
    </>
  );
}
