import { useCallback } from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import {
  ChartDataRaster,
  ChartDataRequestBody,
  ChartDataResponse,
  ProductLookup,
} from "../types";
import { Feature, Geometry } from "geojson";
import { parseChartData } from "views/InsightsView/Yield/utils";

const keys = {
  all: (org: string, type: string, data: ChartDataRequestBody) =>
    ["organization", org, "yield-insights", type, data] as const,
};

type YieldInsightsChartData = {
  chartData: ChartDataRaster[];
  processedFtrs: Feature[];
};

export function useProductAppliedOrPlanted({
  org,
  type,
  requestBody,
  yieldProducts,
  enabled,
}: {
  org: string;
  type: "productapplied" | "productplanted";
  requestBody?: ChartDataRequestBody & { dataProp: string };
  yieldProducts?: ProductLookup[];
  enabled?: boolean;
}) {
  const getData = useCallback(async () => {
    const toSend = { ...requestBody };
    delete toSend.dataProp;
    let fieldIds;
    // productplantedapplied / productapplied required fieldId
    // planted  required fieldIds
    if (type !== "productapplied") {
      fieldIds = [toSend.fieldId];
      delete toSend.fieldId;
    }

    const res = await axios.post<ChartDataResponse>(
      `/${org}/insights/yield/${type}`,
      {
        ...toSend,
        ...(fieldIds
          ? {
              fieldIds,
            }
          : {}),
      }
    );
    const { chartData: dataForChart, processedFtrs } = parseChartData(
      res.data,
      yieldProducts,
      type
    );
    return {
      chartData: dataForChart,
      processedFtrs,
    };
  }, [type, org, requestBody, yieldProducts]);

  return useQuery<YieldInsightsChartData, Error>({
    queryKey: keys.all(org, type, requestBody),
    queryFn: getData,
    enabled: !!(enabled !== false) && !!org && !!type && !!requestBody,
  });
}

export function useProductAppliedAndPlanted(
  org: string,
  requestBody?: ChartDataRequestBody,
  opts?: {
    enabled?: boolean;
  }
) {
  const getData = useCallback(async () => {
    const toSend = { ...requestBody };
    const res = await axios.post<{
      average: number;
      count: number;
      color: string;
      geometry: Geometry;
    }>(`/${org}/insights/yield/productplantedapplied`, {
      ...toSend,
    });
    const { geometry, ...rest } = res.data;
    const responseObj = {
      processedFtrs: [
        { type: "Feature", geometry, properties: rest },
      ] as Feature[],
      chartData: [rest],
    };

    return responseObj;
  }, [org, requestBody]);

  return useQuery<
    {
      processedFtrs: Feature[];
      chartData: {
        average: number;
        count: number;
        color: string;
      }[];
    },
    Error
  >({
    queryKey: keys.all(org, "productplantedapplied", requestBody),
    queryFn: getData,
    enabled: !!(opts?.enabled !== false) && !!org && !!requestBody,
  });
}
