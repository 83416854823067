import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { Switch, Route, useParams, useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useOrgState } from "providers/OrgProvider";
import { useInventoryDispatch } from "providers/InventoryProvider";
import { NotFoundView } from "components";
import AssetsSummaryView from "./SummaryView";
import AssetsListView from "./ListView";
import AssetMapThemes from "./MapThemes";
import CardStack from "../../CardStack";
import CreateOrEditAsset from "./CreateOrEditAsset";
import { IUrlParams } from "types";
import AssetView from "./AssetDetails/AssetView";

export function AssetsView() {
  const { t } = useTranslation();
  const location = useLocation();
  const [expanded, setExpanded] = useState<boolean>();
  const {
    setLegendData,
    setColSizes,
    setTitle,
    setLabelProp,
  } = useInventoryDispatch();
  const { rootUrl } = useOrgState();
  const { type, itemId } = useParams<IUrlParams>();
  const isAdd = location.pathname.includes("/add");
  const activeType = type?.toLowerCase();
  useEffect(() => {
    setTitle(t("inventory.assets.title"));
    setLabelProp("cid");
    setColSizes([7, 5]);
  }, [setColSizes, setTitle, setLabelProp, t]);

  // effects for updating legend/label prop
  useEffect(() => {
    const lowerType = type?.toLowerCase();
    let returnObj = {};
    if (lowerType === "plant") {
      setLabelProp("cid");
      returnObj = {
        title: t("common.crop"),
        colorProp: "color",
        dataProp: "crop",
      };
    }
    if (lowerType === "fixed") {
      setLabelProp("name");
      returnObj = {
        title: t("common.type"),
        colorProp: "color",
        dataProp: "fixedAssetType",
      };
    }
    if (lowerType === "animal") {
      setLabelProp("cid");
      returnObj = {
        title: t("common.breed"),
        colorProp: "color",
        dataProp: "assetAnimalBreed",
      };
    }
    setLegendData(returnObj);
  }, [type, setLegendData, setLabelProp, t]);

  return (
    <>
      <CardStack
        // bottomMinHeight="400px"
        // topHeight={"305px"}
        expanded={Boolean(itemId) || isAdd || expanded}
        topChildren={
          <>
            <Paper
              sx={{
                p: 3,
              }}
            >
              <AssetsSummaryView />
            </Paper>
            <Paper
              style={{
                flexGrow: 1,
                overflow: "visible", // let popover overflow without scroll
              }}
            >
              <AssetMapThemes assetType={activeType} />
            </Paper>
          </>
        }
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            height: "100%",
          }}
        >
          <Switch>
            <Route path={`/:org/:season/inventory/assets/:type/add`}>
              <CreateOrEditAsset />
            </Route>
            <Route
              path={`/:org/:season/inventory/assets/:type/:subtype/:fieldId/items/:itemId/edit`}
              exact
            >
              <CreateOrEditAsset />
            </Route>
            <Route
              path={`/:org/:season/inventory/assets/:type/:subtype/:fieldId/items/:itemId/records/:recordId`}
            >
              <AssetView />
            </Route>
            <Route
              path={`/:org/:season/inventory/assets/:type/:subtype/:fieldId/items/:itemId`}
            >
              <AssetView />
            </Route>
            {/* TODO: cleanup after react-router v6 update */}
            <Route
              path={
                "/:org/:season/inventory/assets/:type/:subtype?/:fieldId?/items?/:itemId?/photos?"
              }
            >
              <AssetsListView
                toggleExpanded={() => {
                  setExpanded(!expanded);
                }}
                expanded={expanded}
              />
            </Route>
            <Route
              path={
                "/:org/:season/inventory/assets/:type/:subtype/:fieldId/items/:itemId/photos/:photoId/edit"
              }
            >
              <AssetsListView
                toggleExpanded={() => {
                  setExpanded(!expanded);
                }}
                expanded={expanded}
              />
            </Route>
            <Route
              path={
                "/:org/:season/inventory/assets/:type/:subtype/:fieldId/items"
              }
            >
              <AssetsListView
                toggleExpanded={() => {
                  setExpanded(!expanded);
                }}
                expanded={expanded}
              />
            </Route>
            <Route path={"/:org/:season/inventory/assets/:type/:subtype"}>
              <AssetsListView
                toggleExpanded={() => {
                  setExpanded(!expanded);
                }}
                expanded={expanded}
              />
            </Route>
            <Route path={"/:org/:season/inventory/assets/:type"}>
              <AssetsListView
                toggleExpanded={() => {
                  setExpanded(!expanded);
                }}
                expanded={expanded}
              />
            </Route>
            <Route path="*">
              <NotFoundView>
                <Link to={`${rootUrl}/inventory/assets/${activeType}`}>
                  {t("common.back")}
                </Link>
              </NotFoundView>
            </Route>
          </Switch>
        </div>
      </CardStack>
    </>
  );
}
