import { useEffect } from "react";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useOrgState } from "providers/OrgProvider";
import { useInventoryDispatch } from "providers/InventoryProvider";
import { useDamageDispatch } from "providers/DamageProvider";
import DamageForm from "./DamageForm";
import { IUrlParams } from "types";

export default function CreateOrEditDamage() {
  const { t } = useTranslation();
  const { rootUrl } = useOrgState();
  const { editId } = useParams<IUrlParams>();
  const { resetSave, resetDelete } = useDamageDispatch();
  const { enableDraw, disableDraw, setDrawOptions } = useInventoryDispatch();

  // when leaving form, reset the fetch state
  useEffect(() => {
    return () => {
      resetSave();
      resetDelete();
    };
  }, [resetSave, resetDelete]);

  useEffect(() => {
    enableDraw();
    return disableDraw;
  }, [enableDraw, disableDraw]);

  useEffect(() => {
    setDrawOptions({
      controls: {
        trash: true,
        polygon: true,
        rectangle: true,
      },
      defaultMode: "draw_polygon",
    });
  }, [setDrawOptions]);

  return (
    <Box sx={{ height: "100%", overflow: "auto" }}>
      <Breadcrumbs sx={{ mb: 2, textTransform: "uppercase" }}>
        <Link underline="hover" href={`${rootUrl}/inventory/damage`}>
          {t("inventory.damage.damageList")}
        </Link>
        <Typography>
          {!editId ? t("common.add") : t("common.edit")}{" "}
          {t("inventory.damage.title")}
        </Typography>
      </Breadcrumbs>
      <DamageForm />
    </Box>
  );
}
