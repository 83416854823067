/* eslint-disable react/jsx-key */
import React, { useMemo, useEffect, useState, useCallback } from "react";
import Checkbox from "@mui/material/Checkbox";
import PropTypes from "prop-types";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import ChevronRight from "@mui/icons-material/ChevronRight";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Chip from "@mui/material/Chip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import {
  useTable,
  useGlobalFilter,
  useSortBy,
  useRowSelect,
  useGroupBy,
  useExpanded,
  usePagination,
} from "react-table";
import GlobalFilter from "./GlobalFilter";
import "./DataTable.scss";
import usePrevious from "components/usePrevious";
import TableLoadingRow from "./TableLoadingRow";

function useGetColumns(cols, data) {
  return useMemo(
    () =>
      cols ||
      (data.length > 0
        ? Object.keys(data[0]).map((key) => {
            return {
              Header: key,
              accessor: key,
            };
          })
        : []),
    [cols, data]
  );
}
/**
 *
 * @visibleName DataTable
 */

/**
 * 
 filterByText method is taken from the default react-table filter method:
 https://github.com/tannerlinsley/react-table/blob/6a0e81810685b4522b1d9be428418a86d5424996/src/filterTypes.js#L1
 */

const filterByText = (rows, ids, filterValue) => {
  rows = rows.filter((row) => {
    return ids.some((id) => {
      const rowValue = row.values[id];
      return String(rowValue)
        .toLowerCase()
        .includes(String(filterValue).toLowerCase());
    });
  });
  return rows;
};

export default function DataTable({
  isSortable,
  isFilterable,
  isSelectable,
  isLoading,
  paging,
  defaultPageSize,
  columns,
  hiddenColumns,
  data,
  filterPlaceholder,
  onRowClick,
  onRowMouseEnter,
  onRowMouseLeave,
  tableProps,
  filterInputProps,
  onSelectionChange,
  onRowExpand,
  onRowContract,
  groupBy,
  singleGroupExpand,
  sortBy,
  rowCountText,
  expanded,
  selectedRowIds: defaultSelectedRowIds,
  selectAllText,
  noDataText,
  preventGroupedRowColspanExpand,
  disableGroupSelect,
  validateRowSelect,
  // paginationProps,
  filterBtns,
  // filterBtnsLabelText,
  components,
  sortText = {
    ascending: "ascending",
    descending: "descending",
    sort: "Sort by",
  },
}) {
  const dataMemo = useMemo(() => data || [], [data]);
  const expandedMemo = useMemo(() => expanded || {}, [expanded]);
  const sortByMemo = useMemo(() => sortBy || [], [sortBy]);
  const selectedMemo = useMemo(() => defaultSelectedRowIds || {}, [
    defaultSelectedRowIds,
  ]);

  // const defaultBtnFilter = filterBtns
  //   ? filterBtns.find((f) => f.default)
  //   : null;
  const columnsMemo = useGetColumns(columns, dataMemo);
  // const [btnFilter, setBtnFilter] = useState(defaultBtnFilter);
  const globalFilterFn = useCallback((r, columnIds, globalFilterVal) => {
    let newData = r;
    // if (btnFilter) {
    //   newData = filterByText(r, btnFilter.columnIds, btnFilter.query);
    // }
    newData = filterByText(newData, columnIds, globalFilterVal);

    return newData;
  }, []);
  const [previousRow, setPreviousRow] = useState();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    selectedFlatRows,
    toggleAllRowsSelected,
    toggleAllRowsExpanded,
    isAllRowsSelected,
    page,
    // pageCount,
    gotoPage,
    setPageSize,
    state: { selectedRowIds, globalFilter, pageIndex, pageSize },
  } = useTable(
    {
      columns: columnsMemo,
      data: dataMemo,
      initialState: {
        hiddenColumns,
        groupBy,
        expanded: expandedMemo,
        selectedRowIds: selectedMemo,
        pageSize: defaultPageSize,
        sortBy: sortByMemo,
      },
      globalFilter: filterBtns ? globalFilterFn : "text",
    },
    useGlobalFilter,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );
  const rowsToUse = paging ? page : rows;
  const prevSelectedRowIds = usePrevious(selectedRowIds);
  const prevSelectedMemo = usePrevious(selectedMemo);

  // hook to handle onSelectionChange callback, since we could not
  // hook into listening for the selectedFlatRows directly (fired too often)
  useEffect(() => {
    const selectedIds = Object.keys(selectedRowIds);
    if (selectedRowIds !== prevSelectedRowIds && prevSelectedRowIds) {
      if (
        !Object.keys(selectedRowIds).length &&
        !Object.keys(prevSelectedRowIds).length
      ) {
        return;
      }
      const selectedData = selectedIds.map((si) => {
        const x = rows.find((d) => d.index === Number(si) && !d.isGrouped);
        return x;
      });
      onSelectionChange(selectedData);
    }
  }, [rows, selectedRowIds, prevSelectedRowIds, onSelectionChange]);

  // update page/selection when selectedRows changes
  useEffect(() => {
    let itemPage;
    let row;
    if (selectedMemo && selectedMemo !== prevSelectedMemo && prevSelectedMemo) {
      // both previous and current are empty, no actual change
      if (
        !Object.keys(selectedMemo).length &&
        !Object.keys(prevSelectedMemo).length
      ) {
        return;
      }
      const keys = Object.keys(selectedMemo);
      if (keys.length) {
        // currently only supporting single programmatic row selection
        // due to complexity of handling it with grouping and pagination
        const key = keys[0];
        row = rows.find((r) => r.id === key);
        const itemIndex = rows.indexOf(row);
        for (let i = itemIndex; i < rows.length; i += pageSize) {
          if (itemIndex >= i && itemIndex <= i + pageSize) {
            itemPage = Math.floor(i / pageSize);
          }
        }
        if (itemPage || itemPage === 0) {
          gotoPage(itemPage);
        }
        // if row isn't rendered, cannot call toggleRowSelected
        // wrapping in a setTimeout seems to fix the issue by allowing gotoPage
        // to finish. feels dirty but I have not been able to find
        // a more proper approach to handling pagination/programmatic row select
        setTimeout(() => {
          if (row?.toggleRowSelected) {
            toggleAllRowsSelected(false);
            row.toggleRowSelected(true);
          }
        }, 0);
      } else {
        toggleAllRowsSelected(false);
      }
    }
  }, [
    selectedMemo,
    toggleAllRowsSelected,
    gotoPage,
    pageSize,
    rows,
    prevSelectedMemo,
  ]);

  // a validate method can be passed to
  // allow user to prevent row selection
  function toggleRowClick(row, toggle, target) {
    if (validateRowSelect(row, target)) {
      row.toggleRowSelected(toggle);
    }
  }

  function handleRowClick(e, row) {
    onRowClick(e, row);
    if (row.isGrouped && disableGroupSelect) {
      return;
    }
    if (isSelectable) {
      if (isSelectable === "single") {
        if (row.isGrouped) {
          return;
        }
        toggleAllRowsSelected(false);
        toggleRowClick(row, !row.isSelected, e.currentTarget);
        // row.toggleRowSelected(!row.isSelected);
        return;
      }
      if (e.shiftKey && previousRow) {
        if (previousRow.index < row.index) {
          for (let i = previousRow.index; i <= row.index; i += 1) {
            if (rows[i]) {
              toggleRowClick(rows[i], true, e.currentTarget);
              // rows[i].toggleRowSelected(true);
            }
          }
        } else {
          for (let i = row.index; i <= previousRow.index; i += 1) {
            if (rows[i]) {
              toggleRowClick(rows[i], true, e.currentTarget);
              // rows[i].toggleRowSelected(true);
            }
          }
        }
      } else {
        toggleRowClick(row, !row.isSelected, e.currentTarget);
        // row.toggleRowSelected(!row.isSelected);
      }
      setPreviousRow(row);
    }
  }

  function getCellContents(cell, row) {
    let cellComponent;
    if (cell.isAggregated) {
      cellComponent = (
        <span className="aggregated-cell">{cell.render("Aggregated")}</span>
      );
    } else if (cell.isPlaceholder) {
      cellComponent = null;
    } else {
      cellComponent = cell.render("Cell");
    }

    return (
      <div className="cell-contents">
        {cell.isGrouped ? (
          <div style={{ marginRight: "0.5rem" }}>
            <IconButton
              size="small"
              className="row-expander toggle-btn-container"
              {...row.getToggleRowExpandedProps({
                onClick: (e) => {
                  const {
                    onClick: ogOnClick,
                  } = row.getToggleRowExpandedProps();
                  e.stopPropagation();
                  if (row.isGrouped) {
                    // row is expanding
                    // NOTE: checking for !row.isExpanded:
                    // prop hasn't updated at this point
                    if (!row.isExpanded) {
                      if (singleGroupExpand) {
                        toggleAllRowsExpanded(false);
                      }
                      onRowExpand(row);
                    } else {
                      onRowContract(row);
                    }
                  }
                  ogOnClick();
                },
                // variant: "link",
                // className: "float-left",
                // size: "sm",
                style: { color: "inherit" },
              })}
            >
              {row.isExpanded ? <ExpandMore /> : <ChevronRight />}
            </IconButton>
            {/* {isSelectable && isSelectable !== 'single' ? (
              <span className="row-group-selector">
                <input
                  title="Select group"
                  checked={row.isSelected}
                  type="checkbox"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    e.stopPropagation();
                    row.toggleRowSelected(e.target.checked);
                  }}
                />
              </span>
            ) : null} */}
          </div>
        ) : null}
        {cellComponent}
        {cell.isGrouped ? (
          <Chip
            className="subrows-count"
            size="small"
            sx={{ textTransform: "uppercase", ml: 2 }}
            color="primary"
            label={`${row.subRows.length} ${rowCountText}`}
          />
        ) : null}
      </div>
    );
  }
  return (
    <div
      className="cai-table"
      style={{
        // padding: "0.25rem",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
      }}
    >
      {isSortable ? (
        <span id="SortHint" style={{ display: "none" }}>
          {sortText.sort}
        </span>
      ) : null}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          marginBottom: "0.5rem",
        }}
        className="cai-table-header"
      >
        {components?.HeaderLeft ? components.HeaderLeft : null}

        {isFilterable ? (
          <GlobalFilter
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            placeholder={filterPlaceholder}
            {...(filterInputProps || null)}
          />
        ) : null}
        {components?.HeaderRight ? components.HeaderRight : null}
      </div>
      <TableContainer
        sx={{
          "tr:last-child td": {
            borderBottom: "none",
          },
          borderColor: "#ababab",
          borderWidth: 1,
          borderStyle: "solid",
        }}
      >
        <Table
          size="small"
          style={{ overflow: "auto" }}
          {...tableProps}
          {...getTableProps()}
          className={`${isSelectable ? "selectable" : ""}${
            selectedFlatRows?.length ? " has-selection" : ""
          }`}
        >
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {isSelectable && isSelectable !== "single" ? (
                  <TableCell
                    component="th"
                    style={{ width: "35px", paddingRight: 0 }}
                  >
                    {!disableGroupSelect ? (
                      <label
                        style={{ marginBottom: 0 }}
                        title="Select all rows"
                      >
                        <Checkbox
                          checked={isAllRowsSelected}
                          type="checkbox"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          onChange={(e) => {
                            e.stopPropagation();
                            if (e.target.checked) {
                              toggleAllRowsSelected();
                            } else {
                              toggleAllRowsSelected(false);
                            }
                          }}
                        />
                        {selectAllText}
                      </label>
                    ) : null}
                  </TableCell>
                ) : null}
                {headerGroup.headers.map((column) => {
                  const sortable = isSortable && column.isSortable !== false;

                  if (sortable) {
                    const sortByToggleProps = column.getSortByToggleProps();
                    const { onClick, style, ...rest } = sortByToggleProps;
                    const headerProps = { ...column.headerProps };
                    const toggleTitle = headerProps?.title ?? sortText.sort;
                    // NOTE: if sortable, we set title on the button instead of the <th>
                    if (headerProps?.title) {
                      delete headerProps.title;
                    }
                    // NOTE: getting rid of default "Toggle SortBy" text
                    // title can be manually defined via headerProps
                    delete rest.title;
                    return (
                      <TableCell
                        component="th"
                        {...column.getHeaderProps({
                          ...rest,
                          ...headerProps,
                        })}
                        // {...(column.headerProps ? column.headerProps : {})}
                        style={{ ...style, cursor: "initial" }}
                        {...(column.isSorted
                          ? {
                              ["aria-sort"]: column.isSortedDesc
                                ? sortText.descending
                                : sortText.ascending,
                            }
                          : {})}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span>{column.render("Header")}</span>
                          <IconButton
                            size="small"
                            disableRipple
                            title={toggleTitle}
                            onClick={onClick}
                            style={{ color: "inherit" }}
                            aria-describedby="SortHint"
                          >
                            <span
                              className={`sort-arrow${
                                column.isSorted ? " sorted" : ""
                              }`}
                            >
                              {column.isSortedDesc ? (
                                <ExpandMore />
                              ) : (
                                <ExpandLess />
                              )}
                            </span>
                          </IconButton>
                        </div>
                      </TableCell>
                    );
                  }
                  return (
                    <TableCell
                      component="th"
                      {...column.getHeaderProps(column.headerProps)}
                    >
                      {column.render("Header")}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {isLoading ? <TableLoadingRow /> : null}
            {!rows.length ? (
              <TableRow>
                <TableCell style={{ textAlign: "center" }} colSpan={100}>
                  {noDataText}
                </TableCell>
              </TableRow>
            ) : null}
            {rowsToUse.map((row) => {
              prepareRow(row);
              row.ref = React.createRef();
              return (
                <TableRow
                  ref={row.ref}
                  style={{ cursor: isSelectable ? "pointer" : undefined }}
                  className={`${row.isSelected ? "selected" : ""}${
                    row.isGrouped ? " grouped" : ""
                  }`}
                  {...row.getRowProps({
                    role: isSelectable && !row.isGrouped ? "button" : null,
                    tabIndex: isSelectable && !row.isGrouped ? 0 : null,
                    onKeyDown: (e) => {
                      e.stopPropagation();
                      const code = e.keyCode ? e.keyCode : e.which;
                      if (code === 13) {
                        handleRowClick(e, row);
                      }
                    },
                    onClick: (e) => {
                      e.stopPropagation();
                      handleRowClick(e, row);
                    },
                    onMouseEnter: (e) => {
                      e.currentTarget.classList.add("mouseenter");
                      if (onRowMouseEnter) {
                        onRowMouseEnter(row);
                      }
                    },
                    onMouseLeave: (e) => {
                      e.currentTarget.classList.remove("mouseenter");
                      if (onRowMouseLeave) {
                        onRowMouseLeave(row);
                      }
                    },
                  })}
                >
                  {isSelectable && isSelectable !== "single" ? (
                    <TableCell style={{ width: "35px", paddingRight: 0 }}>
                      {!(row.isGrouped && disableGroupSelect) ? (
                        <Checkbox
                          tabIndex={-1}
                          title="Select row"
                          disabled={row.isGrouped && !row.isExpanded}
                          checked={row.isSelected}
                          type="checkbox"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          onChange={(e) => {
                            e.stopPropagation();
                            handleRowClick(e, row);
                            // toggleRowClick(row, e.target.checked);
                            // row.toggleRowSelected(e.target.checked);
                          }}
                        />
                      ) : null}
                    </TableCell>
                  ) : null}
                  {row.cells.map((cell, idx) => {
                    let colSpan;
                    const style = {};
                    // if row is expandable (grouping) and cell === row depth
                    // this should be the row group toggle cell
                    // take up entire colspan
                    /** NOTE: enabling this overrides aggregated cells, hence the
                     * flag to allow disabling row colspan expanding
                     */
                    if (!preventGroupedRowColspanExpand) {
                      if (row.canExpand && idx === row.depth) {
                        colSpan = 100;
                      } else if (row.canExpand && idx > row.depth) {
                        // hide other cells AFTER exceeding row depth
                        // this allows placeholder cells to still exist
                        colSpan = 0;
                        style.display = "none";
                      }
                    }
                    const colStyles = columns ? columns[idx]?.styles : null;

                    return (
                      <TableCell
                        {...cell.getCellProps()}
                        {...{
                          colSpan,
                          style: { ...style, ...colStyles },
                          className: cell.isPlaceholder ? "placeholder" : "",
                        }}
                      >
                        {getCellContents(cell, row)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {paging ? (
          <TablePagination
            component="div"
            rowsPerPageOptions={[5, 10, 25, 50]}
            sx={{ "*": { margin: 0, color: "inherit" }, color: "inherit" }}
            count={data.length}
            page={pageIndex}
            rowsPerPage={pageSize}
            onPageChange={(_e, p) => {
              gotoPage(p);
            }}
            onRowsPerPageChange={(e) => {
              setPageSize(parseInt(e.target.value, 10));
              gotoPage(0);
            }}
          />
        ) : null}
      </TableContainer>
    </div>
  );
}

DataTable.defaultProps = {
  isSortable: true,
  isFilterable: true,
  isSelectable: false,
  isLoading: false,
  variant: "",
  columns: null,
  hiddenColumns: [],
  groupBy: [],
  sortBy: [],
  data: [],
  filterPlaceholder: "Search...",
  singleGroupExpand: false,
  onRowClick: () => {},
  onRowMouseEnter: () => {},
  onRowMouseLeave: () => {},
  onSelectionChange: () => {},
  onRowExpand: () => {},
  onRowContract: () => {},
  tableProps: null,
  filterInputProps: null,
  rowCountText: "records",
  noDataText: "No data",
  expanded: null,
  selectedRowIds: null,
  selectAllText: "",
  preventGroupedRowColspanExpand: false,
  disableGroupSelect: false,
  paging: false,
  defaultPageSize: 10,
  validateRowSelect: () => {
    return true;
  },
  paginationProps: null,
  filterBtns: null,
  filterBtnsLabelText: "Filter:",
  components: null,
};

DataTable.propTypes = {
  isSortable: PropTypes.bool,
  isFilterable: PropTypes.bool,
  isSelectable: PropTypes.oneOf([true, false, "single"]),
  isLoading: PropTypes.bool,
  variant: PropTypes.oneOf(["dark", ""]),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      isSortable: PropTypes.bool,
      Header: PropTypes.any,
      accessor: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
      styles: PropTypes.shape({}),
    })
  ),
  hiddenColumns: PropTypes.arrayOf(PropTypes.string),
  groupBy: PropTypes.arrayOf(PropTypes.string),
  sortBy: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      desc: PropTypes.bool,
    })
  ),
  data: PropTypes.arrayOf(PropTypes.shape({})),
  filterPlaceholder: PropTypes.string,
  singleGroupExpand: PropTypes.bool,
  onRowClick: PropTypes.func,
  onRowMouseEnter: PropTypes.func,
  onRowMouseLeave: PropTypes.func,
  onSelectionChange: PropTypes.func,
  onRowExpand: PropTypes.func,
  onRowContract: PropTypes.func,
  tableProps: PropTypes.shape({}),
  filterInputProps: PropTypes.shape({}),
  rowCountText: PropTypes.string,
  noDataText: PropTypes.string,
  expanded: PropTypes.shape({}),
  selectedRowIds: PropTypes.shape({}),
  selectAllText: PropTypes.string,
  preventGroupedRowColspanExpand: PropTypes.bool,
  disableGroupSelect: PropTypes.bool,
  paging: PropTypes.bool,
  defaultPageSize: PropTypes.oneOf([5, 10, 25, 50, 100]),
  validateRowSelect: PropTypes.func,
  paginationProps: PropTypes.shape({}),
  filterBtns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      columnIds: PropTypes.arrayOf(PropTypes.string),
      query: PropTypes.string,
      className: PropTypes.string,
    })
  ),
  filterBtnsLabelText: PropTypes.string,
  components: PropTypes.shape({
    HeaderLeft: PropTypes.any,
    HeaderRight: PropTypes.any,
  }),
  sortText: PropTypes.shape({
    ascending: PropTypes.string,
    descending: PropTypes.string,
    sort: PropTypes.string,
  }),
};
