import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Unstable_Grid2";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useURLSearchParams } from "components";
import { EditIconButton } from "components/EditIconButton";
import AssetPhotosStack from "./AssetPhotosStack";
import { useAssetEvents } from "api/assets/useAssetEvents";
import { IUrlParams } from "types";
import { useOrgState } from "providers";
import BaseCardView from "lib/Card/BaseCardView";
import SummaryCardTitle from "lib/Card/SummaryCardTitle";
import SummaryCardValue from "lib/Card/SummaryCardValue";

export default function AssetRecordDetails() {
  const { t } = useTranslation();
  const { itemId, recordId } = useParams<IUrlParams>();
  const query = useURLSearchParams();
  const { org } = useOrgState();
  const assetGroupId = query.get("groupId");
  const eventsQ = useAssetEvents(org, itemId);

  const currentEditRecord = recordId
    ? eventsQ.data?.find((r) => r.id === recordId)
    : null;

  return (
    <Stack gap={2}>
      <Stack direction={"row"}>
        <IconButton
          href={`..${assetGroupId ? `?groupId=${assetGroupId}` : ""}`}
        >
          <ArrowBackIcon />
        </IconButton>
        <EditIconButton
          sx={{ ml: "auto" }}
          href={`${recordId}/edit${
            assetGroupId ? `?groupId=${assetGroupId}` : ""
          }`}
        />
      </Stack>
      <Grid container gap={2}>
        <BaseCardView sx={{ bgcolor: "background.paper" }}>
          <SummaryCardTitle>{t("common.type")}</SummaryCardTitle>
          <SummaryCardValue>{currentEditRecord?.eventType}</SummaryCardValue>
        </BaseCardView>
        <BaseCardView sx={{ bgcolor: "background.paper" }}>
          <SummaryCardTitle>{t("common.date")}</SummaryCardTitle>
          <SummaryCardValue>
            {currentEditRecord?.dateUtc
              ? new Date(currentEditRecord.dateUtc).toLocaleDateString()
              : "--"}
          </SummaryCardValue>
        </BaseCardView>
        <BaseCardView sx={{ bgcolor: "background.paper" }}>
          <SummaryCardTitle>{t("common.weight")}</SummaryCardTitle>
          <SummaryCardValue>
            {currentEditRecord?.weightLbs ?? "--"}
          </SummaryCardValue>
        </BaseCardView>
        <BaseCardView sx={{ bgcolor: "background.paper" }}>
          <SummaryCardTitle>{t("common.notes")}</SummaryCardTitle>
          <SummaryCardValue>
            {currentEditRecord?.notes ?? "--"}
          </SummaryCardValue>
        </BaseCardView>
      </Grid>
      <AssetPhotosStack readonly type="event" />
    </Stack>
  );
}
