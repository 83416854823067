import { Theme } from "@mui/material/styles";
import { Map } from "mapbox-gl";
import ReactDOM from "react-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
// import ReactDOM, { Root } from "react-dom/client";
// import { ThemeProvider } from "../../../theme";
import ButtonControl from "../ButtonControl";
import DrawerControl from "../DrawerControl";
import PublicIcon from "@mui/icons-material/Public";
import { useCallback, useState } from "react";
import { ThemeProvider } from "lib";
import { BasemapStyleId } from "lib/MapboxMap/MapView";

interface BasemapControlOptions {
  theme: Theme;
  onChange?: (_v: BasemapStyleId) => void;
}
const mapboxStyleBase = "mapbox://styles/mapbox";

function BasemapControlView({
  map,
  onChange,
}: BasemapControlOptions & { map: Map }) {
  const [open, setOpen] = useState<boolean>();
  const toggleDrawer = useCallback(() => {
    setOpen(!open);
  }, [open]);
  return (
    <>
      <ButtonControl onClick={toggleDrawer}>
        <PublicIcon />
      </ButtonControl>
      <DrawerControl
        sx={{ position: "absolute", width: 200, height: 150 }}
        // onClose={() => {
        //   setOpen(false);
        // }}
        open={open}
      >
        {/* <Button
          variant="outlined"
          onClick={() => {
            onChange && onChange("streets-v12");
            map.setStyle(`${mapboxStyleBase}/streets-v12`);
          }}
        >
          streets
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            onChange && onChange("satellite-v9");
            map.setStyle(`${mapboxStyleBase}/satellite-v9`);
          }}
        >
          satellite
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            onChange && onChange("satellite-streets-v12");
            map.setStyle(`${mapboxStyleBase}/satellite-streets-v12`);
          }}
        >
          satellite streets
        </Button> */}
        <FormControl>
          <FormLabel
            sx={{
              "&.Mui-focused": {
                color: "text.secondary",
              },
            }}
            id="basemap-radio-select"
          >
            Basemap
          </FormLabel>
          <RadioGroup
            aria-labelledby="basemap-radio-select"
            defaultValue="satellite-streets-v12"
            name="radio-buttons-group"
            onChange={(e) => {
              const val = e.currentTarget.value as BasemapStyleId;
              onChange && onChange(val);
              map.setStyle(`${mapboxStyleBase}/${val}`);
            }}
          >
            <FormControlLabel
              value="streets-v12"
              control={<Radio />}
              label="Streets"
            />
            <FormControlLabel
              value="satellite-v9"
              control={<Radio />}
              label="Satellite"
            />
            <FormControlLabel
              value="satellite-streets-v12"
              control={<Radio />}
              label="Satellite-Streets"
            />
          </RadioGroup>
        </FormControl>
      </DrawerControl>
    </>
  );
}

class BasemapControl {
  options: BasemapControlOptions;
  container: HTMLElement;
  map?: Map;
  constructor(options: BasemapControlOptions) {
    this.options = { ...options };
    this.container = document.createElement("div");
    this.container.className = "mapboxgl-ctrl cai-mapboxgl-ctrl";
    this.container.addEventListener("contextmenu", (e) => e.preventDefault());
    return this;
  }

  renderReactComponent() {
    if (this.map) {
      ReactDOM.render(
        <ThemeProvider theme={this.options.theme}>
          <BasemapControlView {...this.options} map={this.map} />
        </ThemeProvider>,
        this.container
      );
    }
  }

  onAdd(map: Map) {
    this.map = map;
    this.renderReactComponent();
    return this.container;
  }

  updateOptions(options: Partial<BasemapControlOptions>) {
    this.options = { ...this.options, ...options };
    if (this.container) {
      this.renderReactComponent();
    }
  }

  onRemove() {
    if (this.container) {
      ReactDOM.unmountComponentAtNode(this.container);
    }

    this.map = undefined;
  }
}

export default BasemapControl;
