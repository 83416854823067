import React, { useEffect } from "react";
import PropTypes, { string } from "prop-types";
import { useAsyncDebounce } from "react-table";
import TextField from "@mui/material/TextField";

export default function GlobalFilter({
  globalFilter,
  setGlobalFilter,
  placeholder,
  onChange: defaultOnChange,
  value: propVal,
  ...rest
}) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((val) => {
    setGlobalFilter(val);
  }, 200);

  useEffect(() => {
    setGlobalFilter(propVal);
    setValue(propVal);
  }, [setGlobalFilter, propVal]);

  // update global filter if data changed and we still have a filter value
  useEffect(() => {
    if (!globalFilter && value) {
      setGlobalFilter(value);
    }
  }, [setGlobalFilter, globalFilter, value]);

  return (
    <div className="filter-container">
      <TextField
        className="cai-tablefilter"
        value={value || ""}
        placeholder={placeholder}
        {...rest}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
          if (defaultOnChange) {
            defaultOnChange(e.target.value);
          }
        }}
      />
    </div>
  );
}
GlobalFilter.defaultProps = {
  globalFilter: "",
  placeholder: "",
  value: "",
  onChange: null,
};
GlobalFilter.propTypes = {
  setGlobalFilter: PropTypes.func.isRequired,
  globalFilter: PropTypes.string,
  placeholder: PropTypes.string,
  value: string,
  onChange: PropTypes.func,
};
