import axios from "axios";
import { useCallback } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ParsedImage } from "api/types";
import operationKeys from "./operationKeys";

export function useOperationEventPhotoDelete({
  org,
  eventId,
  operationId,
}: {
  org: string;
  eventId?: string;
  operationId?: string;
}) {
  const queryClient = useQueryClient();

  const invalidate = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: operationKeys.eventsByOperation(org, eventId),
    });
    queryClient.invalidateQueries({
      queryKey: operationKeys.eventsByOperation(org, operationId),
    });
  }, [eventId, operationId, org, queryClient]);

  const deletePhoto = useCallback(
    async (id: string) => {
      const res = await axios.delete(
        `/${org}/operationevents/${eventId}/photos/${id}`
      );
      invalidate();
      return res;
    },
    [invalidate, eventId, org]
  );

  const deletePhotoMutation = useMutation({
    mutationFn: deletePhoto,
    onMutate: async () => {
      const key = operationKeys.eventPhotos(org, eventId);
      await queryClient.cancelQueries({ queryKey: key });
      // Snapshot the previous value
      const previousPhotos = queryClient.getQueryData(key) as ParsedImage[];
      // Return a context object with the snapshotted value
      return { previousPhotos };
    },
    onError: (err, deleteId, context) => {
      const z = context.previousPhotos?.find((y) => y.id === deleteId);
      z.errorMessage = err instanceof Error ? err.message : (err as string);
      queryClient.setQueryData(
        operationKeys.eventPhotos(org, eventId),
        context.previousPhotos
      );
    },
    onSuccess: (_d, id) => {
      queryClient.setQueryData(
        operationKeys.eventPhotos(org, eventId),
        (old: ParsedImage[]) => old?.filter((o) => o.id !== id)
      );
      invalidate();
    },
  });

  return deletePhotoMutation;
}
