import { useCallback, useRef, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";

export default function AuthNavItem() {
  const { logout, user } = useAuth0();
  const { t } = useTranslation();
  const divRef = useRef<HTMLDivElement>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl]
  );
  return (
    <>
      <IconButton
        id="auth-nav-button"
        aria-label="Toggle user menu"
        aria-controls={open ? "auth-nav-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Avatar alt={user?.name} src={user?.picture} />
      </IconButton>

      <Menu
        ref={divRef}
        id="auth-nav-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClick}
        MenuListProps={{
          "aria-labelledby": "auth-nav-button",
        }}
      >
        <MenuItem
          component={Button}
          onClick={() => {
            logout({ logoutParams: { returnTo: window.location.origin } });
          }}
        >
          {t("auth.signOut")}
        </MenuItem>
      </Menu>
    </>
  );
}
