import axios from "axios";
import { useCallback } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import fieldKeys from "./fieldKeys";
import { Feature } from "geojson";
import { IField } from "types";

export function useFieldMutations({
  org,
  seasonId,
}: {
  org: string;
  seasonId: string;
}) {
  const queryClient = useQueryClient();

  const invalidate = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: fieldKeys.allBySeason(org, seasonId),
    });
    queryClient.invalidateQueries({
      queryKey: fieldKeys.summary(org),
    });
  }, [org, queryClient, seasonId]);

  const saveField = useCallback(
    async (d: IField) => {
      const method = d.id ? "put" : "post";
      const res = await axios[method]<Feature<null, IField>>(
        `/${org}/fields/geodata`,
        d
      );
      return res.data;
    },
    [org]
  );

  const deleteField = useCallback(
    async (id: string) => {
      const res = await axios.delete(`/${org}/fields/geodata/${id}`);
      return res.data;
    },
    [org]
  );

  const saveMutation = useMutation({
    mutationFn: saveField,
    onSuccess: () => {
      invalidate();
    },
  });

  const deleteMutation = useMutation({
    mutationFn: deleteField,
    onSuccess: () => {
      invalidate();
    },
  });

  return {
    saveMutation,
    deleteMutation,
  };
}
