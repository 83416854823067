import React from "react";
import PropTypes from "prop-types";
import { ResponsiveContainer, PieChart, Pie, Tooltip, Cell } from "recharts";
import { CustomTooltip } from "./CustomTooltip";

export default function PieChartComponent({
  data,
  isActive,
  dataMapFn,
  dataKey,
  showTooltip,
  ...rest
}) {
  return (
    <>
      <ResponsiveContainer>
        <PieChart style={{ cursor: "pointer" }} {...rest}>
          <Pie
            style={{
              opacity: isActive ? "1" : "0.5",
            }}
            dataKey={dataKey}
            isAnimationActive={false}
            data={data}
          >
            {data?.map(dataMapFn)}
          </Pie>
          {showTooltip ? (
            <Tooltip
              content={<CustomTooltip />}
              allowEscapeViewBox={{ x: true, y: true }}
              wrapperStyle={{ zIndex: 3 }}
            />
          ) : null}
        </PieChart>
      </ResponsiveContainer>
    </>
  );
}
PieChartComponent.defaultProps = {
  data: null,
  isActive: true,
  showTooltip: true,
  dataMapFn: function CellComponent(entry) {
    return <Cell key={entry.name} fill={entry.color} />;
  },
};
PieChartComponent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  isActive: PropTypes.bool,
  showTooltip: PropTypes.bool,
  dataMapFn: PropTypes.func,
  dataKey: PropTypes.string.isRequired,
};
