/* eslint-disable react/prop-types */
import React from "react";

export default function SVGIconWrapper({
  size = 32,
  style,
  className,
  children,
}) {
  return (
    <div className={className} style={{ width: `${size}px`, ...style }}>
      {children}
    </div>
  );
}
