import { create } from "zustand";
import { RasterTileLayer } from "./useRasterLayers";

const STORAGE_ID = "agassessor:raster-layers";

export function getStorageId(org: string) {
  return `${STORAGE_ID}:${org}`;
}

export const useRasterLayersStore = create<{
  layers?: RasterTileLayer[];
  actions: {
    setLayers: (org: string, l: RasterTileLayer[]) => void;
  };
}>((set) => ({
  layers: undefined,
  actions: {
    setLayers: (org, layers) => {
      const ids = layers?.filter((l) => l.visible).map((l) => l.url);
      if (ids) {
        if (!ids.length) {
          localStorage.removeItem(getStorageId(org));
        } else {
          localStorage.setItem(getStorageId(org), ids.join(","));
        }
      }
      return set(() => ({ layers }));
    },
  },
}));
