import React from "react";
import { featureCollection } from "@turf/helpers";
import { Feature, FeatureCollection } from "geojson";
import { useTranslation } from "react-i18next";
import { parseFeatureCollectionPropKeys } from "app-utils";

function fileToText(file: File) {
  const reader = new window.FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = function () {
      const str = reader.result;
      resolve(str);
    };

    reader.onerror = function (err: ProgressEvent<FileReader>) {
      reject(err);
    };
    reader.readAsText(file);
  });
}
const GeodataUpload = React.forwardRef<
  HTMLInputElement,
  {
    onError: (_err: string) => void;
    onChange: (_geojson: FeatureCollection, _keys?: string[]) => void;
    type: "Polygon" | "Point" | "LineString";
    className?: string;
  }
>(({ onError, onChange, type, ...rest }, ref) => {
  const { t } = useTranslation();
  return (
    <input
      style={{ width: "100%" }}
      ref={ref}
      {...rest}
      type="file"
      accept=".geojson,.json"
      onChange={async (e: React.ChangeEvent) => {
        const target = e.target as HTMLInputElement;
        if (!target.files.length) {
          onChange(null);
          return;
        }
        try {
          const file = target.files[0];
          const { size } = file;
          if (size > 4000000) {
            onError(`${t("common.maxFileSize")}: 4mb`);
            return;
          }
          const x = (await fileToText(target.files[0])) as string;
          const geojson = JSON.parse(x || "{}") as FeatureCollection | Feature;
          if (geojson.type === "FeatureCollection") {
            const keys = parseFeatureCollectionPropKeys(geojson);
            const invalidGeomTypes = geojson.features?.find(
              (f: Feature) => !f.geometry.type.includes(type)
            );
            if (invalidGeomTypes) {
              onError(
                `${t("common.invalidGeometryTypes")}: ${
                  invalidGeomTypes.geometry.type
                }`
              );
              return;
            }
            if (geojson.features?.length > 1000) {
              onError(
                `${t("common.maxFeatureCount")}: ${(1000).toLocaleString()}`
              );
              return;
            }
            geojson.features.forEach((f, idx) => {
              const id =
                f.properties.id || f.properties.id === 0
                  ? f.properties.id
                  : `genId-${idx}`;
              f.properties.id = id;
              f.id = id;
            });
            onChange(geojson, keys);
          } else if (geojson.type === "Feature") {
            if (!geojson.geometry?.type.includes(type)) {
              onError(
                `${t("common.invalidGeometryTypes")}: ${geojson.geometry.type}`
              );
            } else {
              const fc = featureCollection([geojson]);
              const keys = parseFeatureCollectionPropKeys(fc);
              fc.features[0].id = fc.features[0].properties.id ?? `genId-0`;
              onChange(fc, keys);
            }
            //
          } else {
            onError(t("common.couldNotLoadFile"));
          }
        } catch (e) {
          onError(t("common.couldNotLoadFile"));
          console.error(e);
        }
      }}
    />
  );
});

GeodataUpload.displayName = "GeodataUpload";

export default GeodataUpload;
