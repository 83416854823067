import { useTheme } from "@mui/material";

export function VerityLogoText() {
  const theme = useTheme();
  const textFill = theme.palette.mode === "light" ? "#21578a" : "#fff";
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 359.03 85.67">
      <g>
        <polygon
          style={{ fill: textFill }}
          points="129.3 69.23 147.5 16.87 137.1 16.87 123.78 57.16 110.46 16.87 100.06 16.87 118.33 69.23 129.3 69.23"
        />
        <polygon
          style={{ fill: textFill }}
          points="188.12 60.8 162.89 60.8 162.89 47.16 186.71 47.16 186.71 38.73 162.89 38.73 162.89 25.31 188.12 25.31 188.12 16.87 153.33 16.87 153.33 69.23 188.12 69.23 188.12 60.8"
        />
        <path
          style={{ fill: textFill }}
          d="M223.33,48.22c2.9-1.22,5.21-3.09,6.92-5.62,1.71-2.53,2.56-5.6,2.56-9.21s-.77-6.5-2.32-8.96c-1.55-2.46-3.69-4.33-6.43-5.62-2.74-1.29-5.89-1.93-9.45-1.93h-19.47v52.36h9.56v-19.33h9l10.82,19.33h10.82l-12.02-21.01ZM222.21,37.61c-.7,1.22-1.69,2.17-2.95,2.85-1.27.68-2.77,1.02-4.5,1.02h-10.05v-16.16h10.05c1.73,0,3.23.34,4.5,1.02,1.26.68,2.25,1.62,2.95,2.81.7,1.2,1.05,2.61,1.05,4.25s-.35,3-1.05,4.22Z"
        />
        <rect
          style={{ fill: textFill }}
          x="241.6"
          y="16.87"
          width="9.56"
          height="52.36"
        />
        <polygon
          style={{ fill: textFill }}
          points="256.92 25.31 270.49 25.31 270.49 69.23 280.12 69.23 280.12 25.31 293.47 25.31 293.47 16.87 256.92 16.87 256.92 25.31"
        />
        <polygon
          style={{ fill: textFill }}
          points="328.96 16.87 317.46 36.37 305.91 16.87 295.02 16.87 312.66 46.04 312.66 69.23 322.28 69.23 322.28 45.97 339.85 16.87 328.96 16.87"
        />
        <path
          style={{ fill: "#fbb20d" }}
          d="M11.54,46.91l-.19-5.57-.5-14.35v-.06c-.06-1.76,1-3.43,2.74-4.06,2.16-.79,4.56.33,5.34,2.49l.14.48s.02.09.03.14l.03.18c.02.16.04.32.04.48v.15s0,.02,0,.02l.1,2.86.4,11.38.2,5.57c4.66,1.18,8.67,4.57,10.44,9.42.29.8.51,1.62.66,2.43l-12.8-3.67s0,0-.01,0l-.07-.02s-.03,0-.04,0c-1.36-.4-2.79-.34-4.12.14-2.62.95-4.39,3.47-4.39,6.25,0,.02,0,.04,0,.05v.12s0,.04,0,.05c.02.72.16,1.41.4,2.07.1.29.22.55.35.8.05.09.1.18.16.28.05.1.13.21.2.33,0,0,.03.04.03.05.07.11.15.22.23.32.07.09.14.17.21.25.15.19.34.39.57.59.01.01.02.02.03.03.29.26.6.5.91.69.13.08.24.15.36.21.37.2.79.38,1.24.53.04.02.09.03.13.04l.14.04s.06.02.09.03l12.72,3.65c-1.58,1.74-3.59,3.13-5.95,3.98-7.79,2.83-16.4-1.18-19.24-8.97-2.83-7.79,1.18-16.4,8.97-19.24.15-.06.31-.11.46-.16Z"
        />
        <path
          style={{ fill: "#fbb20d" }}
          d="M73.67,56.23c-3.27,1.19-6.67,1.17-9.74.18l-3.12,4.62-8.02,11.89-.05.07c-.47.7-1.17,1.27-2.03,1.59-.07.03-.14.05-.21.07-.04.02-.09.03-.14.04-.26.07-.53.12-.8.13-.05,0-.1,0-.15,0,0,0,0,0-.02,0,0,0,0,0-.01,0-.06,0-.12,0-.18,0-.06,0-.13,0-.19,0h-.12c-.07-.01-.14-.02-.2-.03-.06,0-.13-.02-.19-.03l-.09-.02c-.08-.02-.17-.04-.25-.06-.01,0-.02,0-.03,0l-.13-.04c-1.18-.38-2.18-1.29-2.64-2.54s-.26-2.69.46-3.76l.08-.12,1.61-2.39,6.37-9.44,3.12-4.62c-1.09-1.3-1.97-2.81-2.58-4.49-1.44-3.95-1.11-8.11.57-11.62l8.13,10.41c1.74,2.4,4.89,3.37,7.68,2.35,1.25-.45,2.33-1.27,3.11-2.34.02-.02.04-.05.05-.07l.08-.12c1.19-1.77,1.47-4.02.74-6.02-.24-.66-.58-1.28-1.01-1.83l-8.27-10.59c7.18-1.48,14.54,2.48,17.12,9.57,2.83,7.79-1.18,16.4-8.97,19.24Z"
        />
        <path
          style={{ fill: "#fbb20d" }}
          d="M59.51,23.81l3.43,4.39,8.86,11.34.02.02c.26.34.48.72.63,1.14.47,1.3.26,2.69-.46,3.76l-.08.12c-.47.65-1.14,1.17-1.95,1.46-1.8.66-3.76,0-4.82-1.5l-1.75-2.24-7.01-8.98-3.43-4.39c-.15.06-.3.12-.46.17-5.53,2.01-11.49.57-15.49-3.24l12.63-4.6c3.46-1.26,5.25-5.09,3.99-8.55-.23-.63-.55-1.22-.95-1.75,0-.01-.02-.03-.03-.04l-.04-.05c-1.77-2.27-4.8-3.13-7.52-2.14l-12.63,4.6c.62-5.48,4.25-10.42,9.78-12.43,7.79-2.83,16.4,1.18,19.24,8.97,1.77,4.85.87,10.03-1.94,13.93Z"
        />
        <path
          style={{ fill: "#fbb20d" }}
          d="M50.63,12.44l.04.05c.25.32.45.69.59,1.09.79,2.16-.33,4.56-2.49,5.34l-18.78,6.84c.4,5.8-2.63,11.42-7.85,14.22l-.46-13.22s0-.05,0-.07v-.15c-.01-.24-.04-.48-.07-.74,0-.02,0-.05-.01-.07l-.03-.18s-.01-.06-.02-.08l-.03-.14c-.01-.07-.03-.14-.05-.21l-.14-.48s-.03-.1-.05-.14c-1.26-3.46-5.09-5.25-8.55-3.99-2.72.99-4.48,3.6-4.39,6.5v.05s.47,13.39.47,13.39c-3.54-1.61-6.47-4.59-7.9-8.53-2.84-7.79,1.18-16.4,8.97-19.24,6.43-2.34,13.42-.01,17.26,5.25l18.78-6.84c1.74-.63,3.63-.04,4.71,1.35Z"
        />
        <path
          style={{ fill: "#fbb20d" }}
          d="M15.23,65.23h-.02s-.14-.05-.14-.05h0c-.31-.09-.61-.22-.89-.37-.07-.04-.14-.08-.21-.12-.2-.13-.4-.28-.58-.44-.13-.12-.25-.25-.37-.38-.04-.05-.09-.1-.13-.16-.05-.07-.1-.13-.14-.2-.05-.07-.09-.14-.13-.21-.04-.07-.08-.14-.11-.2-.08-.16-.15-.32-.21-.49-.15-.42-.24-.85-.25-1.28v-.03s0-.12,0-.12c0-1.7,1.05-3.29,2.74-3.91.86-.31,1.76-.32,2.58-.09l.08.02h.03s.01.01.01.01l2.79.8,10.95,3.14,5.36,1.54c1.71-2.73,4.31-4.93,7.58-6.12,2.36-.86,4.79-1.09,7.12-.77l-7.5,11.13c-1.2,1.78-1.48,4.03-.75,6.03.71,1.95,2.24,3.43,4.22,4.07.03,0,.05.02.08.02l.1.03s.07.02.1.03c.13.04.29.08.44.11h.06c.11.03.21.05.32.06.13.02.26.04.4.05h.1c.1.01.22.02.35.02.07,0,.15,0,.23,0,.05,0,.11,0,.16,0,.06,0,.11,0,.17-.01.4-.03.81-.09,1.22-.2.11-.03.21-.06.32-.09.09-.03.18-.06.27-.09,1.34-.49,2.46-1.36,3.25-2.54l.06-.09,7.45-11.05c.41.72.76,1.48,1.06,2.29,2.83,7.79-1.18,16.4-8.97,19.24-7.79,2.83-16.4-1.18-19.24-8.97-.61-1.68-.91-3.41-.91-5.1l-5.35-1.54-13.7-3.93Z"
        />
        <path
          style={{ fill: textFill }}
          d="M347.32,24.48v-6.43h-1.99s0-1.17,0-1.17h5.26v1.17h-1.96v6.43h-1.31ZM351.5,24.48v-7.61h1.23l2.86,3.91h-.61l2.81-3.91h1.24v7.61h-1.31v-6.09l.51.14-2.89,3.88h-.15l-2.81-3.88.44-.14v6.09h-1.31Z"
        />
      </g>
    </svg>
  );
}
