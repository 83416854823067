import { useRasters } from "api/useRasters";
import { getStorageId, useRasterLayersStore } from "./useRasterLayersStore";
import { useEffect, useMemo } from "react";
import { generateTileUrl } from "./utils";
import { useRasterLayersActions } from "./useRasterLayersActions";
import { useRasterLegends } from "./useRasterLegends";
import { useSensors } from "api/useSensors";
import { ISensor } from "types";
import { useOrgLookup } from "api/useOrgLookup";

export interface RasterTileLayer {
  visible: boolean;
  rasterType: string;
  url: string;
  beginOnUtc: string;
  id: string;
  name: string;
  sensorType: string;
  bbox: [number, number, number, number];
  legendInfo: { title: string; data: { color: string; label: string }[] };
  sensor: ISensor;
}

function getFromStorage(id: string) {
  try {
    const asStr = window.localStorage.getItem(id);
    if (asStr) {
      return asStr.split(",");
    }
  } catch (e) {
    console.error("Failed parsing raster layers from storage");
  }
}
export const useRasterLayers = (tenant: string, org: string) => {
  const rastersQ = useRasters(org);
  const storageId = getStorageId(org);
  const defaultSelection = getFromStorage(storageId);
  const { setLayers } = useRasterLayersActions();
  const sensorsQ = useSensors(org);
  const rasterTypesQ = useOrgLookup<{ name: string; slug: string }[]>(
    org,
    "rastertypes"
  );
  const legendsQ = useRasterLegends({
    tenant,
    org,
    types: [...new Set(rastersQ.data?.map((i) => [...i.rasterTypes]).flat())],
  });
  const layersFlattened = useMemo<RasterTileLayer[]>(() => {
    return !legendsQ.isFetched || !rasterTypesQ.isFetched
      ? null
      : rastersQ.data
          ?.map((i) => {
            const sensor = sensorsQ.data?.find((s) => s.slug === i.sensorType);
            return i.rasterTypes.map((rt) => {
              const legendInfo = legendsQ.data?.find((d) => d.title === rt);
              const url = generateTileUrl({
                tenant,
                org,
                collectEventId: i.id,
                sensorSlug: i.sensorType,
                rasterSlug: rt,
              });
              const rasterType = rasterTypesQ.data.find((r) => r.slug === rt);
              return {
                ...i,
                visible: defaultSelection?.includes(url),
                rasterType: rt,
                url,
                legendInfo,
                sensor,
                rasterTypeName: rasterType?.name,
              };
            });
          })
          .flat();
  }, [
    defaultSelection,
    legendsQ.data,
    legendsQ.isFetched,
    org,
    rasterTypesQ.data,
    rasterTypesQ.isFetched,
    rastersQ.data,
    sensorsQ.data,
    tenant,
  ]);

  useEffect(() => {
    setLayers(org, undefined);
  }, [org, setLayers]);

  return useRasterLayersStore((state) => {
    return state.layers ?? layersFlattened;
  });
};
