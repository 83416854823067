/**
 *
 * @param {Object[]} arr - the array of objects to sort
 * @param {string} prop - the property name to sort objects by
 * @param {string} lang - the language to instantiate collator with
 * @returns sorted array of objects
 */
export default function sortObjArr(
  arr: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  }[],
  prop: string,
  lang = "en"
) {
  const collator = new Intl.Collator(lang, {
    numeric: true,
    sensitivity: "base",
  });
  try {
    const sorted = arr.sort((a, b) => {
      return collator.compare(a[prop], b[prop]);
    });
    return sorted;
  } catch (e) {
    console.error(e);
    return arr;
  }
}
