import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useCallback } from "react";
import { VerityFieldCI } from "./VerityFieldCI";

export function useVerityFieldsBySeason({
  org,
  seasonId,
}: {
  org: string;
  seasonId: string;
}) {
  const queryFn = useCallback(async () => {
    const res = await axios.get<VerityFieldCI[]>(
      `${org}/verity/ci/fields/seasons/${seasonId}`
    );
    return res.data;
  }, [org, seasonId]);

  return useQuery({
    queryKey: ["organization", org, "verity", seasonId],
    queryFn,
    enabled: Boolean(org && seasonId),
  });
}

export function useVerityFieldsRefresh({
  org,
  seasonId,
  enabled,
  onSettled,
}: {
  org: string;
  seasonId: string;
  enabled: boolean;
  onSettled: () => void;
}) {
  const queryFn = useCallback(async () => {
    const res = await axios.get<VerityFieldCI[]>(
      `${org}/verity/ci/fields/seasons/${seasonId}/refresh`
    );
    return res.data;
  }, [org, seasonId]);

  return useQuery({
    queryKey: ["organization", org, "verity", seasonId],
    queryFn,
    enabled: Boolean(enabled && org && seasonId),
    onSettled,
  });
}
