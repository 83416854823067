import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { ChevronDown, ChevronUp, Plus } from "react-feather";

export default function InventoryCollapseAddButtons({
  addUrl,
  expanded,
  hideAdd,
  hideExpand,
  toggleExpanded,
}: {
  addUrl: string;
  expanded: boolean;
  hideAdd?: boolean;
  hideExpand?: boolean;
  toggleExpanded: () => void;
}) {
  const { t } = useTranslation();

  return (
    <Stack spacing={1} direction={"row"} sx={{ ml: "auto" }}>
      {!hideExpand ? (
        <IconButton
          title={expanded ? t("common.collapseTable") : t("common.expandTable")}
          onClick={toggleExpanded}
        >
          {expanded ? <ChevronDown /> : <ChevronUp />}
        </IconButton>
      ) : null}
      {!hideAdd ? (
        <IconButton
          color="success"
          sx={{ "&:hover": { color: "success.main" } }}
          href={addUrl}
        >
          <Plus />
        </IconButton>
      ) : null}
    </Stack>
  );
}
