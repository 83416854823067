import React from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";

export default function Tooltip({ children }) {
  return <Paper sx={{ p: 1 }}>{children}</Paper>;
}

Tooltip.defaultProps = {
  children: null,
};

Tooltip.propTypes = {
  children: PropTypes.any,
};
