import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { OperationPlantedOrAppliedMapTheme } from "./types/OperationPlantedOrAppliedMapTheme";
import { addAreaPercent, parseOperationData } from "./parseData";
import operationKeys from "./operationKeys";

export function useOperationAppliedMapThemes({
  org,
  seasonId,
  enabled,
}: {
  org: string;
  seasonId: string;
  enabled?: boolean;
}) {
  const fetchMapThemes = useCallback(async () => {
    const res = await axios.get<OperationPlantedOrAppliedMapTheme[]>(
      `/${org}/operations/applied/season/${seasonId}/mapthemes`
    );
    const items = addAreaPercent(res.data);
    return {
      raw: items,
      rollup: {
        product: parseOperationData({
          dataProp: "product",
          colorProp: "productColor",
          data: items,
        }),
        productType: parseOperationData({
          dataProp: "productType",
          colorProp: "productTypeColor",
          data: items,
        }),
      },
    };
  }, [org, seasonId]);

  return useQuery({
    queryKey: operationKeys.mapThemes(org, "applied", seasonId),
    queryFn: fetchMapThemes,
    enabled: !!(enabled !== false) && !!seasonId,
  });
}
