import React from "react";
import PropTypes from "prop-types";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  Label,
  XAxis,
  YAxis,
  Cell,
} from "recharts";
import { CenteredBarLabel } from "components/Charts/utils";
import { useTranslation } from "react-i18next";

export default function HerdSummaryBarChart({
  data,
  isActive,
  showActiveBorder,
}) {
  const { t } = useTranslation();
  let borderBottom = "solid 4px transparent";
  if (!showActiveBorder) {
    borderBottom = "none";
  } else if (isActive) {
    borderBottom = "solid 4px";
  }

  return (
    <>
      <div
        style={{
          height: "150px",
          width: "100%",
          borderBottom,
        }}
      >
        <ResponsiveContainer>
          <BarChart data={data}>
            <XAxis
              stroke="#fff"
              dataKey="name"
              tickLine={{ stroke: "#fff" }}
              tick={{ fill: "#fff" }}
            />
            <YAxis width={80} stroke="#fff">
              <Label
                dx={-10}
                value={t("common.count")}
                angle={-90}
                position="outside"
                fill="#fff"
                fontSize={14}
              />
            </YAxis>
            <Bar
              label={CenteredBarLabel}
              data={data}
              dataKey="count"
              fill="#000"
            >
              {data?.map((entry) => (
                <Cell key={entry.name} fill={entry.color} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
}
HerdSummaryBarChart.defaultProps = {
  data: null,
  isActive: false,
  showActiveBorder: true,
};
HerdSummaryBarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  isActive: PropTypes.bool,
  showActiveBorder: PropTypes.bool,
};
