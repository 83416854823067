import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Autocomplete from "lib/Select/Autocomplete";
import { IFieldLookup } from "../types";
import { useParams } from "react-router-dom";

export function filterOption(data: IFieldLookup, input: string) {
  const keys = ["cropType", "farm", "field"] as (
    | "cropType"
    | "farm"
    | "field"
  )[];
  const str = keys.reduce((prev, curr, idx) => {
    return (prev += `${!idx ? "" : " "}${data[curr]?.toLowerCase()}`);
  }, "");
  const parts = input.split(" ");
  const matches =
    parts.filter((part) => {
      return str.includes(part?.toLowerCase());
    }).length === parts.length;
  return matches ? data : null;
}

export default function CropFarmField({
  fields,
  onFieldChange,
}: {
  fields: IFieldLookup[];
  onFieldChange: (field: IFieldLookup) => void;
}) {
  const { t } = useTranslation();
  const { yieldType } = useParams<{ yieldType: string }>();
  const [selectedField, setSelectedField] = useState<IFieldLookup>();
  useEffect(() => {
    setSelectedField(null);
  }, [yieldType]);
  return (
    <>
      <Autocomplete
        name="fieldId"
        inputProps={{ value: selectedField?.fieldId, required: true }}
        label={`${t("common.field")} *`}
        placeholder={t("common.searchByCropFarmField")}
        filterOptions={(data: IFieldLookup[], input) => {
          return data.filter((d) => {
            return filterOption(d, input.inputValue);
          });
        }}
        getOptionLabel={(r) => (typeof r !== "string" ? r.field : r)}
        groupBy={(r) => {
          return `${r.farm ? `${r.farm} - ` : ""}${r.cropType}`;
        }}
        disableClearable={false}
        id="field-select"
        options={fields?.sort(
          (a, b) =>
            -b.farm?.localeCompare(a.farm) &&
            -b.cropType?.localeCompare(a.cropType)
        )}
        value={selectedField ?? null}
        onChange={(_e, item: IFieldLookup) => {
          onFieldChange(item);
          setSelectedField(item);
        }}
      />
    </>
  );
}
