import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { eventKeys } from "./assetKeys";
import { IAssetRecord } from "types/IAssetRecord";
import { useAssetEventTypes } from "./useAssetEventTypes";

export function useAssetEvents(org: string, assetId: string) {
  const eventTypesQ = useAssetEventTypes(org);
  const getTypes = useCallback(async () => {
    const res = await axios.get<IAssetRecord[]>(
      `/${org}/assets/${assetId}/events`
    );
    res.data.forEach((d) => {
      const et = eventTypesQ.data.find((e) => e.id === d.eventTypeId);
      d.eventType = et?.name;
    });

    // sort by date
    res.data.sort(
      (a, b) => new Date(a.dateUtc).getTime() - new Date(b.dateUtc).getTime()
    );
    return res.data;
  }, [assetId, eventTypesQ.data, org]);

  return useQuery({
    queryKey: eventKeys.allById(org, assetId),
    queryFn: getTypes,
    enabled: !!org && !!assetId && !!eventTypesQ.data,
  });
}
