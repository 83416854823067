import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useOrgState } from "providers";
import Stack from "@mui/material/Stack";
import LinearProgress from "@mui/material/LinearProgress";
import { useInventoryDispatch } from "providers";
import HarvestedOperationDetails from "./HarvestedOperationDetails";
import PlantedOperationDetails from "./PlantedOperationDetails";
import AppliedOperationDetails from "./AppliedOperationDetails";
import TilledOperationDetails from "./TilledOperationDetails";
import { useOperationsGeodataById } from "api/operations/useOperationsGeodataById";
import { OperationType } from "types/OperationType";
import { OperationPlanted } from "api/operations/types/OperationPlanted";
import { OperationTilled } from "api/operations/types/OperationTilled";

export default function OperationDetails() {
  const { fitInventoryBounds, setInventoryFtrs } = useInventoryDispatch();
  const { org, season } = useOrgState();
  const { itemId, type }: { itemId: string; type: OperationType } = useParams();
  const geodataQ = useOperationsGeodataById<OperationPlanted & OperationTilled>(
    {
      id: itemId,
      org,
      seasonId: season?.id,
      type,
    }
  );

  useEffect(() => {
    if (geodataQ.data) {
      setInventoryFtrs(geodataQ.data);
      fitInventoryBounds({ geojson: geodataQ.data });
    }
  }, [geodataQ.data, fitInventoryBounds, setInventoryFtrs]);

  return (
    <Stack sx={{ position: "relative", height: "100%" }}>
      {geodataQ.isFetching ? (
        <LinearProgress style={{ left: 0, right: 0, position: "absolute" }} />
      ) : null}
      {type === "applied" ? (
        <AppliedOperationDetails />
      ) : type === "harvested" ? (
        <HarvestedOperationDetails />
      ) : type === "planted" ? (
        <PlantedOperationDetails />
      ) : type === "tilled" ? (
        <TilledOperationDetails />
      ) : null}
    </Stack>
  );
}
