import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import lookupKeys from "../lookups/lookupKeys";

export function useConservationTypes(org: string) {
  const getTypes = useCallback(async () => {
    const res = await axios.get<
      {
        id: string;
        name: string;
        label: string;
      }[]
    >(`${org}/lookups/conservationtypes`);
    res.data.forEach((d) => {
      d.label = d.name;
    });
    return res.data;
  }, [org]);

  return useQuery({
    queryKey: lookupKeys.byType(org, "conservationtypes"),
    queryFn: getTypes,
    enabled: !!org,
  });
}
