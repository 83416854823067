import React, { useEffect } from "react";
import AuthView from "./AuthView";
import AppLoading from "./AppLoading";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "@mui/material/Button";
import PrivacyAndTOS from "./PrivacyAndTOS";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useAppState } from "providers/AppProvider";
import Typography from "@mui/material/Typography";

export default function LoginView() {
  const { error, isLoading, loginWithRedirect, user, logout } = useAuth0();
  const { userDataState, organizations } = useAppState();
  const { t } = useTranslation();
  const history = useHistory();

  // error sometimes returned as string
  // TODO: fix hook to always return an error object
  const userDataError = userDataState.error;
  const userError = userDataState.isError
    ? typeof userDataError === "string"
      ? userDataError
      : userDataState.error?.message
    : null;

  useEffect(() => {
    if (!isLoading && user && organizations) {
      history.push(
        window.location.pathname !== "/"
          ? window.location.pathname
          : organizations[0]?.slug || "/"
      );
    }
  }, [history, isLoading, organizations, user]);
  return (
    <AuthView>
      {isLoading ? <AppLoading message={t("auth.signingIn")} /> : null}
      {!isLoading &&
      !(error || userDataState.isError) &&
      user &&
      !organizations ? (
        <AppLoading message={t("common.gettingUserData")} />
      ) : null}
      {error || userDataState.isError ? (
        <>
          {userDataState.isError && userError ? (
            <Typography color="error" sx={{ mb: 2 }}>
              {userError}
            </Typography>
          ) : null}
          {error?.message ? (
            <Typography color="error" sx={{ mb: 2 }}>
              {error?.message ?? null}
            </Typography>
          ) : null}
          <Button
            color="inherit"
            variant="outlined"
            style={{ minWidth: "150px" }}
            onClick={() => {
              logout({ logoutParams: { returnTo: window.location.origin } });
            }}
          >
            {t("common.tryAgain")}
          </Button>
        </>
      ) : null}
      {!(error || userDataState.isError) && !isLoading && !user ? (
        <>
          <Button
            sx={{ mb: 2 }}
            color="inherit"
            variant="outlined"
            style={{ minWidth: "150px" }}
            onClick={() => {
              loginWithRedirect();
            }}
          >
            {t("auth.signIn")}
          </Button>
          <PrivacyAndTOS />
        </>
      ) : null}
    </AuthView>
  );
}
