import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

export default function LegendItem({ data }) {
  return (
    <div
      style={{
        marginBottom: "0.25rem",
        marginRight: "-0.25rem",
      }}
    >
      <table style={{ width: "100%" }}>
        <tbody>
          <tr style={{ display: "flex", alignItems: "center" }}>
            <td style={{ paddingBottom: "0.25rem" }}>
              <Typography
                style={{
                  display: "flex",
                  marginBottom: 0,
                  textTransform: "none",
                }}
              >
                {data?.name}
              </Typography>
            </td>
            <td style={{ paddingBottom: "0.25rem", marginLeft: "auto" }}>
              {data?.legendSymbol}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

LegendItem.defaultProps = {
  data: null,
};

LegendItem.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    legendSymbol: PropTypes.node,
  }),
};
