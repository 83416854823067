/*global REACT_APP_API_ORIGIN  */
import { useRef, useReducer, useEffect, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { IFetchState } from "types";

const contentType = "Content-Type";
const caiLangStorageProp = "cai:lang:preference";

type Action<T> =
  | { type: "FETCH_RESET"; payload: T }
  | { type: "FETCH_INIT"; payload: null }
  | { type: "FETCH_ABORT"; payload: null }
  | { type: "FETCH_SUCCESS"; payload: T }
  | { type: "FETCH_FAILURE"; payload: string };

export interface IFetchHeaders {
  [contentType]: string;
  ["accept-language"]?: string;
}

export interface IFetchDataOpts<T> {
  method?: "GET" | "PUT" | "POST" | "DELETE";
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: any;
  apiOrigin?: string;
  headers?: IFetchHeaders;
  json?: boolean;
  responseType?: "arraybuffer" | "json" | "blob" | "text";
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transformResponse?: (_res: T) => any;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getReducerState<T>(type: string, data: any): IFetchState<T> {
  switch (type) {
    case "FETCH_RESET":
      return {
        isLoading: false,
        isError: false,
        data,
        errorMessage: "",
        status: "idle",
      };
    case "FETCH_INIT":
      return {
        isError: false,
        data: null,
        isLoading: true,
        errorMessage: "",
        status: "pending",
      };
    case "FETCH_ABORT":
      return {
        isError: false,
        data: null,
        isLoading: false,
        errorMessage: "",
        status: "idle",
      };
    case "FETCH_SUCCESS":
      return {
        isLoading: false,
        isError: false,
        data,
        status: "fulfilled",
        errorMessage: "",
      };
    case "FETCH_FAILURE":
      return {
        isError: true,
        isLoading: false,
        data: null,
        status: "failed",
        errorMessage: data,
      };
    default:
      throw new Error("Invalid reducer action type");
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function dataFetchReducer<T = any>() {
  return (state: IFetchState<T>, action: Action<T>): IFetchState<T> => {
    switch (action.type) {
      case "FETCH_RESET":
        return getReducerState("FETCH_RESET", action.payload);
      case "FETCH_INIT":
        return getReducerState("FETCH_INIT", action.payload);
      case "FETCH_ABORT":
        return getReducerState("FETCH_ABORT", action.payload);
      case "FETCH_SUCCESS":
        return getReducerState("FETCH_SUCCESS", action.payload);
      case "FETCH_FAILURE":
        return getReducerState("FETCH_FAILURE", action.payload);
      default:
        throw new Error("Invalid reducer action type");
    }
  };
}
const defaultState = {
  status: "idle",
  isError: false,
  isLoading: false,
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function defaultFormatResponse(data: any) {
  return data;
}
const defaultOpts = {
  abortPrevious: true,
  formatResponse: defaultFormatResponse,
  baseUrl: "",
};

export default function useFetchWithToken<T = unknown>(
  initialData?: T,
  options = defaultOpts
) {
  const { abortPrevious, formatResponse } = {
    ...defaultOpts,
    ...options,
  };
  const didCancel = useRef(false);
  const [state, dispatch] = useReducer(dataFetchReducer<T>(), {
    ...defaultState,
    data: initialData,
  } as IFetchState<T>);

  const fetchAbortController = useRef<AbortController | null>();

  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    didCancel.current = false;
    return function cleanup() {
      didCancel.current = true;
    };
  }, []);
  const resetFetchState = useCallback((data?: T) => {
    dispatch({ type: "FETCH_RESET", payload: data });
  }, []);
  const setDataState = useCallback((data?: T) => {
    dispatch({ type: "FETCH_SUCCESS", payload: data });
  }, []);

  const abort = useCallback(() => {
    if (fetchAbortController.current) {
      fetchAbortController.current.abort();
      fetchAbortController.current = null;
    }
  }, []);
  const fetchData = useCallback(
    async (
      endpoint: string,
      opts: IFetchDataOpts<T> = {
        headers: {
          [contentType]: "application/json",
        },
      }
    ) => {
      const langPref = localStorage.getItem(caiLangStorageProp);
      const { headers, responseType, json, ...rest } = opts;
      const finalHeaders =
        // don't set default content type if json is false
        json !== false
          ? headers && !headers[contentType]
            ? { ...{ [contentType]: "application/json" }, ...headers }
            : !headers
            ? { [contentType]: "application/json" }
            : headers
          : headers || ({} as IFetchHeaders);

      // if multipart/form-data, remove from headers as this is apparently default and it
      // can cause response parsing to fail
      if (finalHeaders[contentType]?.includes("multipart/form-data")) {
        delete finalHeaders[contentType];
      }
      // set language if it has been set in localStorage
      if (langPref) {
        // check languages set on browser, and pull any that match langPref to include subtags
        // eg: langPref === 'en' and user has 'en-CA' before/instead of 'en', this would use the "en-CA" value
        const langs = window.navigator.languages ?? [window.navigator.language];
        const lang = langs.find((l) => l.includes(langPref)) || langPref;
        finalHeaders["accept-language"] = lang;
      }
      const formatMethod =
        opts?.transformResponse ||
        formatResponse ||
        function f(d) {
          return d;
        };

      if (abortPrevious && fetchAbortController.current) {
        fetchAbortController.current.abort();
        fetchAbortController.current = null;
      }
      let req;
      try {
        const token = await getAccessTokenSilently();
        fetchAbortController.current = new window.AbortController();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const origin = opts.apiOrigin ?? REACT_APP_API_ORIGIN;
        req = window.fetch(`${`${origin}`}${endpoint}`, {
          ...rest,
          headers: { ...finalHeaders, Authorization: `Bearer ${token}` },
          signal: fetchAbortController.current.signal,
        });
        if (!didCancel.current) {
          dispatch({
            type: "FETCH_INIT",
            payload: null,
          });
        }
        const res = await req;
        const ct = res.headers.get("content-type");
        if (res.ok && !ct) {
          if (!didCancel.current) {
            dispatch({
              type: "FETCH_SUCCESS",
              payload: formatMethod(null),
            });
          }
          return getReducerState("FETCH_SUCCESS", null) as IFetchState<T>;
        }
        // default to JSON response
        const isJson = !responseType || responseType === "json";
        const isText = responseType === "text" || ct.includes("text/");
        const isBlob = responseType === "blob" || ct.includes("image/");

        let resParsed;
        if (isBlob) {
          resParsed = await res.blob();
        } else if (isText) {
          resParsed = await res.text();
        } else if (isJson) {
          resParsed = await res.json();
          const { status } = res;
          if (status >= 400) {
            const msg = `${
              resParsed.title
                ? `${resParsed.title}${resParsed.detail ? " - " : ""}`
                : ""
            }${resParsed.detail ? `${resParsed.detail}` : ""}`;
            if (!didCancel.current) {
              dispatch({
                type: "FETCH_FAILURE",
                payload: msg,
              });
            }
            return getReducerState("FETCH_FAILURE", msg) as IFetchState<T>;
          }
        }
        // ensure we wait for any async format methods
        const payload = await formatMethod(resParsed);
        if (!didCancel.current) {
          dispatch({
            type: "FETCH_SUCCESS",
            payload: payload,
          });
        }
        return getReducerState("FETCH_SUCCESS", payload) as IFetchState<T>;
      } catch (e) {
        if (e.name === "AbortError") {
          if (!didCancel.current) {
            dispatch({ type: "FETCH_ABORT", payload: null });
          }
          return null;
        }
        if (!didCancel.current) {
          dispatch({ type: "FETCH_FAILURE", payload: e.message });
        }
        throw e;
      }
    },
    [abortPrevious, formatResponse, getAccessTokenSilently]
  );

  return { abort, fetchData, resetFetchState, setDataState, state };
}
