import Typography from "@mui/material/Typography";
import React from "react";

export default function SummaryCardValue({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Typography
      variant="h6"
      component="div"
      sx={{
        whiteSpace: "break-spaces",
      }}
    >
      {children}
    </Typography>
  );
}
