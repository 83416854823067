import { useCallback } from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { collectEventKeys } from "./useCollectEvents";
import { ICollectEventRaster } from "../types/CollectEventRaster";

export function useRasters(org: string) {
  const getData = useCallback(async () => {
    const res = await axios.get<ICollectEventRaster[]>(
      `/${org}/collectevents/rasters`
    );
    return res.data;
  }, [org]);

  return useQuery({
    queryKey: collectEventKeys.rasters(org),
    queryFn: getData,
    enabled: Boolean(org),
    staleTime: 10 * (60 * 1000), // 10 mins
  });
}
