import React from "react";
import { useParams } from "react-router-dom";
import { useOrgState } from "providers/OrgProvider";
import { DamageProvider } from "providers/DamageProvider";
import { DamageView } from "./DamageView";

export function Damage() {
  const { season, org } = useOrgState();
  const { editId } = useParams();

  return (
    <DamageProvider org={org} seasonId={season?.id} editId={editId}>
      <DamageView />
    </DamageProvider>
  );
}
