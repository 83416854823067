import axios from "axios";
import { useCallback } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ParsedImage } from "api/types";
import operationKeys from "./operationKeys";

export function useOperationEventPhotoPost({
  org,
  eventId,
  operationId,
}: {
  org: string;
  eventId?: string;
  operationId?: string;
}) {
  const queryClient = useQueryClient();

  const invalidate = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: operationKeys.eventPhotos(org, eventId),
    });
    queryClient.invalidateQueries({
      queryKey: operationKeys.eventsByOperation(org, operationId),
    });
  }, [eventId, operationId, org, queryClient]);

  const savePhotoFn = useCallback(
    async (image: ParsedImage) => {
      const formData = new window.FormData();
      formData.append("", image.file, image.file.name);
      const res = await axios.post<{ id: string }>(
        `/${org}/operationevents/${eventId}/photos`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    },
    [eventId, org]
  );
  const savePhotoMutation = useMutation({
    mutationFn: savePhotoFn,
    onMutate: async (newPhoto) => {
      const key = operationKeys.eventPhotos(org, eventId);
      await queryClient.cancelQueries({ queryKey: key });
      // Snapshot the previous value
      const previousPhotos = queryClient.getQueryData(key);
      queryClient.setQueryData(key, (old: ParsedImage[]) => [...old, newPhoto]);
      // Return a context object with the snapshotted value
      return { previousPhotos };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (_err, _newTodo, context) => {
      queryClient.setQueryData(
        operationKeys.eventPhotos(org, eventId),
        context.previousPhotos
      );
    },
    // Always refetch after error or success:
    onSettled: () => {
      invalidate();
    },
  });

  return savePhotoMutation;
}
