import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { ThemeProvider } from "lib";
interface Stats {
  payload: {
    name: string;
    statistics: {
      count: number;
      mean: number;
      min: number;
      max: number;
      std: number;
    };
  };
}
export const StatsBarTooltip = ({
  active,
  payload,
}: {
  active: boolean;
  payload: Stats[];
}) => {
  const { t } = useTranslation();
  if (active && payload) {
    const item = payload ? payload[0] : null;
    // const item = payload.find((p) => p.dataKey === bar) || payload[0];
    const properties = item?.payload;
    const { count, mean, min, max, std } = properties?.statistics ?? {};
    // const barArea = bar ? item.payload[bar] : item.payload.area;
    return (
      <ThemeProvider mode="light">
        <Paper style={{ opacity: 0.9 }} elevation={4}>
          <Typography
            variant="h6"
            style={{
              textTransform: "uppercase",
              marginBottom: 0,
              textAlign: "center",
              fontWeight: "bold",
            }}
          >{`${properties.name}`}</Typography>
          <List
            component={Grid}
            container
            sx={{ maxWidth: 300, m: 0, li: { "*": { m: 0 } } }}
          >
            <ListItem component={Grid} sm={"auto"}>
              <ListItemText
                secondary={t("common.count")}
                primary={count?.toLocaleString()}
              />
            </ListItem>
            <ListItem component={Grid} sm={"auto"}>
              <ListItemText secondary={t("stats.mean")} primary={mean} />
            </ListItem>
            <ListItem component={Grid} sm={"auto"}>
              <ListItemText secondary={t("stats.min")} primary={min} />
            </ListItem>
            <ListItem component={Grid} sm={"auto"}>
              <ListItemText secondary={t("stats.max")} primary={max} />
            </ListItem>
            <ListItem component={Grid} sm={"auto"}>
              <ListItemText
                secondary={t("stats.standardDeviation")}
                primary={std}
              />
            </ListItem>
          </List>
        </Paper>
      </ThemeProvider>
    );
  }

  return null;
};
export default StatsBarTooltip;
