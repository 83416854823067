import React, { useState } from "react";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

function InputConfirmLabel({ text }: { text: string }) {
  return (
    <>
      Please type <b>{text}</b> to confirm
    </>
  );
}

export default function ConfirmAlert({
  children,
  confirmText = "Yes",
  message = "Are you sure?",
  title = "Confirm",
  cancelText = "No",
  onConfirm,
  onCancel = () => {},
  show,
  target,
  requireInputConfirmText,
  requireInputConfirmTextLabel,
}: {
  children?: React.ReactNode;
  cancelText?: string;
  confirmText?: string;
  message: React.ReactNode;
  onConfirm: (_e: React.MouseEvent<HTMLButtonElement>) => void;
  onCancel?: () => void;
  requireInputConfirmText?: string;
  requireInputConfirmTextLabel?: React.ReactNode;
  show: boolean;
  target?: HTMLElement | null;
  title?: string;
}) {
  const [verifyText, setVerifyText] = useState("");
  function Buttons() {
    return (
      <Stack direction={"row"} spacing={1} sx={{ float: "right", mt: 3 }}>
        {cancelText ? (
          <Button variant="outlined" color="secondary" onClick={onCancel}>
            {cancelText}
          </Button>
        ) : null}
        <Button
          variant={"contained"}
          disabled={
            requireInputConfirmText && verifyText !== requireInputConfirmText
          }
          onClick={(e) => {
            if (
              requireInputConfirmText &&
              verifyText !== requireInputConfirmText
            ) {
              return;
            }
            onConfirm(e);
            setVerifyText("");
          }}
        >
          {confirmText}
        </Button>
      </Stack>
    );
  }

  return (
    <Popover
      open={show}
      elevation={12}
      anchorEl={target}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      slotProps={{
        paper: {
          sx: { p: 3, width: 300 },
        },
      }}
    >
      <Typography component={"h3"} variant="h6">
        {title}
      </Typography>
      <Box>
        {message}
        {requireInputConfirmText ? (
          <>
            <Typography color="error.main">
              {requireInputConfirmTextLabel || (
                <InputConfirmLabel text={requireInputConfirmText} />
              )}
            </Typography>
            <TextField
              value={verifyText}
              onChange={(e) => {
                setVerifyText(e.target.value);
              }}
            />
          </>
        ) : null}
        <Buttons />
        {children}
      </Box>
    </Popover>
  );
}
