import React, { useEffect } from "react";
import { OperationsView } from "./OperationsView";
import { useInventoryDispatch } from "../../../providers/InventoryProvider";

export function Operations() {
  const { setInventoryFtrs } = useInventoryDispatch();

  // clear inventory features on unmount
  useEffect(() => {
    return () => {
      setInventoryFtrs(null);
    };
  }, [setInventoryFtrs]);

  return <OperationsView />;
}
