import React, { useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { Controller, UseFormMethods } from "react-hook-form";
import Divider from "@mui/material/Divider";
import { ApiStatus, DeleteButton, PromptView, usePrevious } from "components";
import { IFetchResponse } from "types";
import LoadingButton from "components/LoadingButton";
import { Geometry } from "geojson";

export interface IFormWrapper {
  children: unknown;
  methods: UseFormMethods;
  onSubmit: () => void;
  onDelete: () => void;
  data: Record<string, unknown>;
  saveState: IFetchResponse;
  deleteState: IFetchResponse;
  cancelHref: string;
  ignoreGeom: boolean;
  geometryData?: Geometry;
  existingGeom?: Geometry;
  geometryRequired: boolean;
  defaultValues?: Record<string, unknown>;
  style?: React.CSSProperties;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export default function FormWrapper({
  children,
  methods,
  onSubmit,
  onDelete,
  onCancel,
  data,
  saveState,
  deleteState,
  cancelHref,
  ignoreGeom,
  geometryData,
  existingGeom,
  geometryRequired = true,
  defaultValues,
  style,
}: IFormWrapper) {
  const { t } = useTranslation();
  const {
    formState,
    register,
    control,
    setValue,
    clearErrors,
    reset,
    watch,
  } = methods;
  const { id }: { id?: string } = watch();
  const { isDirty }: { isDirty?: boolean } = formState;
  const cancelBtnRef = useRef();
  const formRef = useRef<HTMLFormElement>();
  const prevData = usePrevious(data);
  const prevGeomData = usePrevious(geometryData);

  useEffect(() => {
    if (!geometryData && !prevGeomData) {
      return;
    }
    const geom1 = { ...geometryData };
    const geom2 = { ...existingGeom };
    // prevent checking
    if (geom1?.bbox) {
      delete geom1.bbox;
    }
    if (geom2?.bbox) {
      delete geom2.bbox;
    }
    let geom = geom1 ? JSON.stringify(geom1) : "";
    if (geom === "{}") {
      geom = "";
    }
    // do not set form as dirty if edit feature geom same as geometry data
    const shouldDirty = geom2 ? JSON.stringify(geom2) !== geom : true;
    setValue("geometry", geom, {
      shouldDirty,
    });
    // we have a geometry, so we can clear errors
    if (geom) {
      clearErrors("geometry");
    }
  }, [geometryData, prevGeomData, existingGeom, setValue, clearErrors]);

  // edit feature has changed, reset the form
  useEffect(() => {
    const hasData = data ? Object.keys(data).length : null;
    if (hasData && data.id !== prevData?.id) {
      // console.log('data changed');
      reset({ ...defaultValues, ...data });
    }
  }, [data, prevData, reset, geometryData, defaultValues]);
  return (
    <form ref={formRef} style={style} onSubmit={onSubmit}>
      <input type="hidden" name="id" ref={register} />
      {children}
      {!ignoreGeom ? (
        <>
          <Controller
            name="geometry"
            control={control}
            rules={{ required: geometryRequired }}
            render={() => (
              <input type="hidden" name="geometry" ref={register} />
            )}
          />
        </>
      ) : null}
      <Divider sx={{ my: 2 }} />
      <Box display={"flex"}>
        <LoadingButton
          isLoading={saveState?.isLoading}
          variant="contained"
          type="submit"
          onClick={() => {
            if (formRef.current) {
              formRef.current.reportValidity();
            }
          }}
        >
          {t("common.save")}
        </LoadingButton>

        {cancelHref ? (
          <div ref={cancelBtnRef}>
            <LoadingButton sx={{ ml: 1 }} href={cancelHref} color="secondary">
              {t("common.cancel")}
            </LoadingButton>
          </div>
        ) : onCancel ? (
          <LoadingButton sx={{ ml: 1 }} color="secondary" onClick={onCancel}>
            {t("common.cancel")}
          </LoadingButton>
        ) : null}
        {id ? (
          <DeleteButton
            isLoading={deleteState?.isLoading}
            handleDelete={onDelete}
          />
        ) : null}
      </Box>
      <ApiStatus saveState={saveState} deleteState={deleteState} />
      <PromptView
        when={isDirty}
        target={cancelBtnRef.current}
        message={t("common.confirmUnsavedChanges")}
        cancelText={t("common.no")}
        confirmText={t("common.yes")}
        title={t("common.confirm")}
      />
    </form>
  );
}
