import React from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useOrgState } from "providers/OrgProvider";
import { FormWrapper, useURLSearchParams } from "components";
import Autocomplete from "lib/Select/Autocomplete";
import { DatePicker } from "lib/DatePicker";
import { IUrlParams } from "types";
import { IAssetRecord } from "types/IAssetRecord";
import { useAssetEventDelete } from "api/assets/useAssetEventDelete";
import { useAssetEventsSave } from "api/assets/useAssetEventsSave";
import { AssetEventPostData } from "api/assets/AssetEventPostData";

const defaultValues = {
  id: "",
  assetId: "",
  eventTypeId: "",
  dateUtc: "",
  weightLbs: "",
  notes: "",
  photoCount: "",
};

export default function AssetRecordForm({
  assetEventTypes,
  currentEditRecord,
}: {
  assetEventTypes: { name: string; id: string }[];
  currentEditRecord?: IAssetRecord;
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const query = useURLSearchParams();
  const groupId = query.get("groupId");
  const { org, type, subtype, itemId, fieldId } = useParams<IUrlParams>();
  const { rootUrl } = useOrgState();
  const saveMutation = useAssetEventsSave(org, itemId);
  const deleteMutation = useAssetEventDelete(org, itemId);
  const methods = useForm({
    defaultValues: { ...defaultValues },
  });
  const { handleSubmit, register, reset, formState, watch, control } = methods;
  const { id, weightLbs, notes } = watch();
  const baseRoute = `${rootUrl}/inventory/assets/${type}/${subtype}/${fieldId}/items${
    itemId ? `/${itemId}` : ""
  }`;

  async function handleSave(d: AssetEventPostData) {
    const url = `${baseRoute}${
      currentEditRecord?.id ? `/records/${currentEditRecord?.id}` : ""
    }`;
    if (!formState.isDirty) {
      history.push(`${url}${groupId ? `?groupId=${groupId}` : ""}`);
    }
    if (!d.id) {
      delete d.id;
    }
    if (!d.weightLbs && d.weightLbs !== 0) {
      delete d.weightLbs;
    }
    return saveMutation.mutateAsync(d, {
      onSuccess: (res) => {
        reset({ ...d, ...res.data });
        history.push(`${url}${groupId ? `?groupId=${groupId}` : ""}`);
      },
    });
  }
  return (
    <FormWrapper
      methods={methods}
      data={{ ...currentEditRecord }}
      cancelHref={`${`${baseRoute}${
        currentEditRecord?.id ? `/records/${currentEditRecord?.id}` : ""
      }`}${groupId ? `?groupId=${groupId}` : ""}`}
      saveState={{
        isLoading: saveMutation.isLoading,
        isError: saveMutation.isError,
        errorMessage: saveMutation.error,
      }}
      deleteState={{
        isLoading: deleteMutation.isLoading,
        isError: deleteMutation.isError,
        errorMessage: deleteMutation.error,
      }}
      ignoreGeom
      geometryRequired={false}
      onDelete={async () => {
        deleteMutation.mutate(id, {
          onSuccess: () => {
            reset(defaultValues);
            history.push(`${baseRoute}${groupId ? `?groupId=${groupId}` : ""}`);
          },
        });
        // const res = await deleteRecord(id);
        // if (!res.isError) {
        //   reset(defaultValues);
        //   history.push(`${baseRoute}${groupId ? `?groupId=${groupId}` : ""}`);
        // }
      }}
      onSubmit={handleSubmit(handleSave)}
    >
      <Stack spacing={2}>
        <Controller
          name="eventTypeId"
          control={control}
          render={(props) => (
            <Autocomplete
              label={t("common.type")}
              InputProps={{ required: true }}
              options={assetEventTypes || []}
              getOptionLabel={(o) => (typeof o !== "string" ? o.name : o)}
              // eslint-disable-next-line react/prop-types
              value={assetEventTypes?.find((a) => a.id === props.value) ?? null}
              onChange={(_e, item) => {
                const opt = item as { id: string; name: string };
                // eslint-disable-next-line react/prop-types
                props.onChange(opt?.id || "");
              }}
            />
          )}
        />
        <Controller
          name="dateUtc"
          control={control}
          defaultValue={false}
          rules={{ required: true }}
          render={(props) => (
            <DatePicker
              label={t("common.date")}
              // id="date-select"
              slotProps={{
                textField: {
                  required: true,
                },
              }}
              // eslint-disable-next-line react/prop-types
              value={props.value ? new Date(props.value) : null}
              onChange={(date) => {
                // eslint-disable-next-line react/prop-types
                props.onChange(date || "");
              }}
            />
          )}
        />
        <TextField
          name="weightLbs"
          label={t("common.weight")}
          value={weightLbs ?? ""}
          inputProps={{ ref: register }}
          type="number"
        />
        <TextField
          label={t("common.notes")}
          multiline
          value={notes ?? ""}
          inputProps={{
            name: "notes",
            maxLength: 255,
            ref: register,
          }}
        />
      </Stack>
    </FormWrapper>
  );
}

// AssetRecordForm.defaultProps = {
//   assetEventTypes: null,
//   currentEditRecord: null,
// };

// AssetRecordForm.propTypes = {
//   assetEventTypes: PropTypes.arrayOf(PropTypes.shape({})),
//   currentEditRecord: PropTypes.shape({}),
// };
