import { useCallback } from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { IFieldLookup } from "./types";

const keys = {
  all: (org: string, type: string, seasonId: string) =>
    [
      "organization",
      org,
      "yield-insights-fields",
      type,
      "season",
      seasonId,
    ] as const,
};

export function useInsightsFieldLookup(
  org: string,
  type:
    | "productapplied"
    | "productplanted"
    | "productplantedapplied"
    | "imagery/productplantedapplied",
  seasonId: string
) {
  const getData = useCallback(async () => {
    const res = await axios.post<IFieldLookup[]>(
      `/${org}/insights/yield/${type}/lookups`,
      { seasonIds: [seasonId] }
    );
    return res.data;
  }, [org, seasonId, type]);

  return useQuery({
    queryKey: keys.all(org, type, seasonId),
    queryFn: getData,
    enabled: !!org && !!type && !!seasonId,
  });
}
