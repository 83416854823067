import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useCallback } from "react";
import { assetKeys } from "./assetKeys";
import { IBaseAsset } from "types/IAssetType";
import { sortObjArrByFields } from "components";

export function useAssetsBySeason({
  org,
  assetType,
  seasonId,
}: {
  org: string;
  assetType: string;
  seasonId: string;
}) {
  const fetchListBySeason = useCallback(async () => {
    const res = await axios.get<
      (IBaseAsset & {
        farm?: string;
        fieldFarmGroupKey?: string;
        fieldFarmTypeKey?: string;
        type: string;
        label: string;
        name: string;
        value: string;
      })[]
    >(`/${org}/assets/${assetType}/season/${seasonId}`);
    res.data?.forEach((r) => {
      r.label = r.name;
      r.value = r.id;
      // NOTE: added this hack to support grouped report table
      r.fieldFarmGroupKey = `${r.field}${r.farm ? `:${r.farm}` : ""}`;
      r.fieldFarmTypeKey = `${r.field}${r.farm ? `:${r.farm}` : ""}${
        r.type ? `:${r.type}` : ""
      }`;
    });
    const sorted = sortObjArrByFields(res.data, "farm", "field");

    return sorted ?? null;
  }, [org, seasonId, assetType]);

  return useQuery({
    queryKey: assetKeys.allBySeason(org, assetType, seasonId),
    queryFn: fetchListBySeason,
    enabled: Boolean(assetType && org && seasonId && assetType !== "equipment"),
  });
}
