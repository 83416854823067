import React from "react";
import PropTypes from "prop-types";
import {
  ResponsiveContainer,
  ReferenceLine,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
  BarChart,
} from "recharts";
import { CenteredBarLabel } from "components/Charts/utils";
import { StatsBarTooltip } from "./StatsBarTooltip";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";

function StatsChart({ data, onBarClick }) {
  const { t } = useTranslation();
  const hasNegativeVals = data.find((d) => d.average < 0);
  const theme = useTheme();
  const isLightTheme = theme.palette.mode === "light";
  return (
    <ResponsiveContainer>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          // height={80}
          dataKey="name"
          stroke={isLightTheme ? "#000" : "#fff"}
          padding={{ left: 100, right: 100 }}
          allowDataOverflow
        />
        <YAxis width={80} stroke={isLightTheme ? "#000" : "#fff"}>
          <Label
            dx={-30}
            value={t("stats.mean")}
            angle={-90}
            fill={isLightTheme ? "#000" : "#fff"}
            position="outside"
            fontSize={14}
          />
        </YAxis>
        <Tooltip
          wrapperStyle={{
            position: "absolute",
            zIndex: 9999,
          }}
          position={{ y: -150 }}
          content={StatsBarTooltip}
        />
        {hasNegativeVals ? (
          <ReferenceLine
            isFront
            y={0}
            stroke={isLightTheme ? "#000" : "#fff"}
            strokeWidth={3}
          />
        ) : null}
        <Bar
          label={CenteredBarLabel}
          dataKey={(d) => {
            return d.statistics.mean;
          }}
          barSize={100}
          onClick={onBarClick}
        >
          {data.map((d) => (
            <Cell key={d.operationId || d.fieldName || d.url} fill={d.color} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}

StatsChart.defaultProps = {
  data: null,
  onBarClick: null,
};

StatsChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  onBarClick: PropTypes.func,
};

export { StatsChart };
