import { useQuery } from "@tanstack/react-query";
import useFetchWithToken from "components/useFetchWithToken";
import { useMemo } from "react";
import { IField } from "types";

export function useImageryFields({
  org,
  seasonId,
}: {
  org: string;
  seasonId?: string;
}) {
  const { fetchData: fetchFields } = useFetchWithToken<IField[]>();

  const queryKey = useMemo(
    () => ["organization", org, "fields", "season", seasonId, "imagery", "sb"],
    [seasonId, org]
  );

  const fetchDataFn = () =>
    fetchFields(`/${org}/fields/season/${seasonId}/imagery/sb`);

  return useQuery({
    queryKey: queryKey,
    queryFn: async () => await fetchDataFn(),
    enabled: !!org && !!seasonId,
  });
}
