import React from "react";
import PropTypes from "prop-types";
import SVGIconWrapper from "./IconWrapper";

export default function CAITextStackedIcon({ fill, ...rest }) {
  const fill0 = fill || "#58595b";
  const fill1 = fill || "url(#CAI_GRADIENT_1)";
  const fill2 = fill || "url(#CAI_GRADIENT_2)";
  const fill3 = fill || "url(#CAI_GRADIENT_3)";
  return (
    <SVGIconWrapper {...rest}>
      <svg
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 144 144"
        style={{ enableBackground: "new 0 0 144 144" }}
      >
        <g>
          <g>
            <path
              fill={fill0}
              d="M13.95,125.25c0,2.68,0.76,3.72,4.64,3.72h0.23c1.81,0,3.34-0.2,4.16-0.29c0.59-0.06,0.79,0.94,0.79,1.55
			c0,0.55-3.48,0.73-5.29,0.73h-0.23c-5.23,0-7.75-1.33-7.75-5.35v-3.86c0-4.02,2.57-5.35,7.92-5.35h0.23
			c1.61,0,5.12,0.22,5.12,0.71c0,0.82-0.17,1.59-0.62,1.55c-0.65-0.06-2.43-0.27-4.16-0.27h-0.23c-4.02,0-4.81,1.04-4.81,3.72
			V125.25z"
            />
            <path
              fill={fill0}
              d="M34.53,120.61c0-0.24,1.36-0.34,1.86-0.34c0.35,0,0.62,0.21,0.62,0.43v6.46c0,2.8-1.8,3.73-5.53,3.73h-0.17
			c-3.65,0-5.45-0.93-5.45-3.73v-6.55c0-0.24,1.34-0.34,1.86-0.34c0.33,0,0.62,0.21,0.62,0.43v6.38c0,1.64,0.66,2.29,2.97,2.29h0.17
			c2.37,0,3.06-0.64,3.06-2.29V120.61z"
            />
            <path
              fill={fill0}
              d="M40.66,130.72c-0.5,0-1.11-0.06-1.11-0.29v-9.87c0-0.24,1.34-0.3,1.86-0.3c0.35,0,0.62,0.17,0.62,0.39v8.54
			h5.93c0.27,0,0.5,0.19,0.5,0.42c0,0.31-0.06,1.12-0.39,1.12H40.66z"
            />
            <path
              fill={fill0}
              d="M53.07,130.5c0,0.23-0.31,0.39-0.64,0.39c-0.52,0-1.84-0.06-1.84-0.3v-8.64h-3.8c-0.31,0-0.54-0.19-0.54-0.41
			c0-0.3,0.08-1.12,0.41-1.12h10.18c0.29,0,0.54,0.19,0.54,0.41c0,0.31-0.08,1.12-0.41,1.12h-3.9V130.5z"
            />
            <path
              fill={fill0}
              d="M60.51,130.89c-0.5,0-1.84-0.06-1.84-0.3v-10.02c0-0.24,1.34-0.3,1.84-0.3c0.35,0,0.64,0.17,0.64,0.39v9.85
			C61.15,130.73,60.87,130.89,60.51,130.89z"
            />
            <path
              fill={fill0}
              d="M71.91,120.56c0.39-0.26,0.89-0.3,1.55-0.3c0.56,0,1.24,0.04,1.01,0.49l-4.77,9.91
			c-0.08,0.14-0.33,0.23-0.58,0.23c-0.91,0-1.59-0.06-1.71-0.21l-4.79-9.92c-0.19-0.39,0.43-0.49,0.72-0.49
			c0.83,0,1.75,0.04,1.92,0.36l3.18,7.35c0.1,0.23,0.14,0.5,0.19,0.71c0.02-0.21,0.06-0.49,0.17-0.71L71.91,120.56z"
            />
            <path
              fill={fill0}
              d="M82.48,128.46h-5.49l-0.99,2.13c-0.14,0.29-0.93,0.3-1.73,0.3c-0.37,0-0.78-0.07-0.78-0.3
			c0-0.04,0.02-0.09,0.04-0.13l5.06-9.89c0.12-0.26,1.24-0.3,1.82-0.3c0.25,0,0.47,0.11,0.58,0.31l5,9.88
			c0.02,0.04,0.04,0.09,0.04,0.13c0,0.23-0.43,0.3-0.78,0.3c-0.91,0-1.61-0.03-1.75-0.34L82.48,128.46z M77.62,127.08h4.21
			l-1.94-4.15c-0.06-0.14-0.12-0.34-0.14-0.5c-0.04,0.16-0.08,0.36-0.17,0.5L77.62,127.08z"
            />
            <path
              fill={fill0}
              d="M91.66,130.5c0,0.23-0.31,0.39-0.64,0.39c-0.52,0-1.84-0.06-1.84-0.3v-8.64h-3.8c-0.31,0-0.54-0.19-0.54-0.41
			c0-0.3,0.08-1.12,0.41-1.12h10.18c0.29,0,0.54,0.19,0.54,0.41c0,0.31-0.08,1.12-0.41,1.12h-3.9V130.5z"
            />
            <path
              fill={fill0}
              d="M97.27,120.79c0-0.26,0.29-0.37,0.64-0.37h8.28c0.31,0,0.54,0.19,0.54,0.41c0,0.31-0.08,1.12-0.41,1.12h-6.57
			v2.77h5.66c0.27,0,0.52,0.19,0.52,0.4c0,0.31-0.08,1.13-0.41,1.13h-5.76v2.93h6.44c0.31,0,0.54,0.19,0.54,0.42
			c0,0.3-0.08,1.12-0.41,1.12H98.2c-0.56,0-0.93-0.09-0.93-0.29V120.79z"
            />
            <path
              fill={fill0}
              d="M120.13,127.62h-7.53l-1.36,2.92c-0.2,0.39-1.27,0.41-2.38,0.41c-0.51,0-1.07-0.1-1.07-0.41
			c0-0.06,0.03-0.12,0.06-0.18l6.93-13.56c0.17-0.35,1.7-0.41,2.49-0.41c0.34,0,0.65,0.16,0.79,0.43l6.85,13.54
			c0.03,0.06,0.06,0.12,0.06,0.18c0,0.31-0.59,0.41-1.08,0.41c-1.25,0-2.21-0.04-2.41-0.47L120.13,127.62z M113.48,125.74h5.77
			l-2.66-5.68c-0.08-0.2-0.17-0.47-0.2-0.69c-0.06,0.22-0.11,0.49-0.23,0.69L113.48,125.74z"
            />
            <path
              fill={fill0}
              d="M129.46,130.95c-0.68,0-2.52-0.08-2.52-0.41v-13.73c0-0.33,1.84-0.41,2.52-0.41c0.48,0,0.88,0.24,0.88,0.53
			v13.5C130.34,130.74,129.94,130.95,129.46,130.95z"
            />
          </g>

          <linearGradient
            id="CAI_GRADIENT_1"
            gradientUnits="userSpaceOnUse"
            x1="66.797"
            y1="68.2548"
            x2="30.4562"
            y2="67.2164"
            gradientTransform="matrix(0.9985 -0.0539 0.0539 0.9985 -6.0853 -17.7963)"
          >
            <stop offset="0.1213" style={{ stopColor: "#D5E100" }} />
            <stop offset="0.9881" style={{ stopColor: "#79C80E" }} />
          </linearGradient>
          <path
            fill={fill1}
            d="M51.93,40.15c11.61,3.23,13.05,9.19,13.65,15.98c-2.5-7.13-8.24-11.32-16.85-12.52
		c10.33,3.25,15.21,10.1,17.1,16.54c-0.28,0.02-1.2-3.29-6.29-5.13c-3.11-1.12-11.94-1.12-16.89-6.71
		c-4.86-5.49-2.54-14.35-2.6-14.36C40.05,33.85,40.56,36.99,51.93,40.15z"
          />
          <linearGradient
            id="CAI_GRADIENT_2"
            gradientUnits="userSpaceOnUse"
            x1="17.2153"
            y1="54.0286"
            x2="80.463"
            y2="54.0286"
          >
            <stop offset="0.1213" style={{ stopColor: "#D5E100" }} />
            <stop offset="0.9881" style={{ stopColor: "#79C80E" }} />
          </linearGradient>
          <path
            fill={fill2}
            d="M59.24,86.34c-3.93-0.28-8.86-1.23-12.26-2.86c-3.24-1.55-6.17-3.6-8.7-6.1c-2.5-2.46-4.49-5.02-6.09-7.83
		c-1.76-3.09-2.92-6.64-3.56-10.85c-0.55-3.64-0.4-7.3,0.44-11.2c1.21-5.59,3.05-9.72,5.8-12.99c1.65-1.97,3.65-4.23,5.95-6.11
		c2.78-2.27,6.21-4.02,10.21-5.21c3.89-1.16,7.72-1.54,11.36-1.13c2.91,0.32,5,1.96,6.22,4.88c0.51,1.22,0.58,2.83,0.58,3.93
		c0.06,10.31,0.04,21.42,0.01,31.24c0,0.93,0.88,2.94,2.71,5.42c1.44,1.96,3.44,4.82,3.62,4.9l4.93,6.92l0-21
		c0-9.41,0-18.81,0-28.22c0-3.41-0.69-6.37-2.12-9.05c-1.62-3.04-3.8-5.44-6.67-7.34c-2.32-1.53-4.8-2.52-7.38-2.94
		c-4.19-0.68-8.19-0.08-11.21,0.51c-5.49,1.08-10.47,3.03-15.24,5.96c-3.84,2.36-7.29,5.31-10.27,8.76
		c-1.48,1.72-2.68,3.66-3.84,5.53c-0.28,0.45-0.55,0.89-0.84,1.34c-2.25,3.54-3.81,7.64-4.79,12.53
		c-1.51,7.52-1.07,14.97,1.29,22.13c0.05,0.16,0.09,0.33,0.14,0.5c0.11,0.43,0.23,0.91,0.48,1.41l0.59,1.15
		c1.31,2.53,2.67,5.15,4.12,7.69c0.84,1.47,1.87,2.86,3.04,4.13c1.27,1.37,2.99,3.15,4.97,4.69c2.82,2.21,5.07,3.76,7.31,5.05
		c1.43,0.82,2.95,1.41,4.42,1.98c0.77,0.3,1.5,0.58,2.22,0.9c3.15,1.38,6.43,1.75,9.6,2.1c0.89,0.1,1.78,0.2,2.66,0.32
		c0.46,0.06,0.91,0.09,1.36,0.09c0.96,0,1.9-0.12,2.82-0.23c0.63-0.08,1.22-0.15,1.8-0.19c0.38-0.02,1.38-0.05,1.76-0.09
		C61.98,91.93,61.28,88.64,59.24,86.34z"
          />
          <linearGradient
            id="CAI_GRADIENT_3"
            gradientUnits="userSpaceOnUse"
            x1="47.2869"
            y1="67.455"
            x2="123.7328"
            y2="67.455"
          >
            <stop offset="0" style={{ stopColor: "#00FFFF" }} />
            <stop offset="1" style={{ stopColor: "#0071C1" }} />
          </linearGradient>
          <path
            fill={fill3}
            d="M123.3,63.98c-0.79-1.91-2.3-2.98-4.37-3.09c-2.44-0.13-4.2,1.49-5.01,3.34c-0.72,1.65-1.9,2.98-3.31,3.9
		c0.66-1.34,1.32-2.71,1.85-4.13c0.16-0.42,0.32-0.85,0.49-1.27c0.78-2,1.59-4.07,1.84-6.25c0.83-7.27-1.19-13.4-6-18.24
		c-1.66-1.67-3.55-3.57-5.83-4.73c-3.13-1.59-6.27-2.55-9.25-3.28c-3.69-0.91-5.72-0.58-7.37-0.76v10.45l3.33,0.52
		c1.32,0.2,7.21,1.35,9.9,4.08c2.6,2.63,4.18,5.41,4.19,9.61c0.01,2.69-1.16,5.03-2.4,7.51l-0.4,0.8c-0.63,1.28-1.28,2.57-1.93,3.84
		c-1.71-2.18-2.37-5.16-1.56-8.07c0.15-0.54,0.35-1.07,0.45-1.62c0.46-2.42-1.09-4.97-3.41-5.65c-2.5-0.73-5.13,0.47-6.11,2.79
		c-1.13,2.69,0,5.43,2.77,6.7c3.07,1.41,4.87,3.78,5.38,7.12c0.24,1.55-0.03,3.03-0.52,4.51c-0.02,0.06-0.04,0.13-0.05,0.19
		c-0.4,0.79-0.8,1.57-1.2,2.36c-0.65,1.3-1.29,2.6-1.92,3.91c-1.05-1.97-1.38-4.34-0.77-6.6c0.13-0.49,0.3-0.98,0.41-1.47
		c0.64-2.73-0.86-5.22-3.61-6.02c-2.39-0.7-5.01,0.66-5.93,3.08c-0.88,2.31,0.02,4.84,2.17,6.07c0.44,0.25,0.89,0.49,1.35,0.72
		c1.72,0.84,2.96,2.16,3.81,3.84c0.61,1.19,0.92,2.39,0.99,3.6c-0.19,0.39-0.38,0.79-0.58,1.18c-0.69,1.39-1.39,2.76-2.1,4.14
		l-0.93,1.81c-0.26,0.51-0.51,1.02-0.77,1.53c-0.58,1.17-1.18,2.37-1.82,3.52c-0.06,0.1-0.34,0.27-0.75,0.31
		c-0.39,0.03-0.66-0.08-0.71-0.15c-0.8-1.02-1.63-2.17-2.53-3.54c-0.48-0.73-0.94-1.49-1.39-2.23c-0.5-0.82-1.02-1.67-1.57-2.49
		c-1.04-1.56-2.11-3.12-3.15-4.64c-0.73-1.07-1.46-2.13-2.19-3.2c-0.43-0.63-7.9-12.37-8.25-12.84c-0.94-1.05-2.45-2.27-4.92-3.16
		c-2.3-0.83-7.62-1.07-12.33-3.26c1.1,1.77,8.93,14.98,10.74,17.53c1.7,2.41,3.31,4.69,3.92,5.61l0.06,0.09
		c2.17,3.26,4.34,6.53,6.5,9.8l0.65,0.98c1.48,2.24,3.01,4.56,4.47,6.87c1.57,2.48,3.82,4.24,6.5,5.09
		c1.55,0.49,3.05,0.74,4.49,0.74c4.32,0,8.06-2.19,10.61-6.35c1.23-2.01,2.2-4.18,3.14-6.27c0.42-0.94,0.85-1.91,1.3-2.84l0.06-0.13
		c0.11-0.23,0.22-0.46,0.34-0.69c0.11-0.22,0.22-0.44,0.33-0.67c0.71-1.53,1.82-2.7,3.25-3.57c2.35-1.42,4.85-1.73,7.5-0.95
		c0.91,0.27,1.91,0.48,2.84,0.4c2.38-0.2,4.19-2.19,4.38-4.58c0.19-2.42-1.37-4.55-3.82-5.23c-2.17-0.6-4.6,0.52-5.67,2.59
		c-0.4,0.77-0.75,1.57-1.25,2.27c-1.11,1.53-2.64,2.6-4.31,3.18c0.49-0.97,0.99-1.94,1.47-2.91c0.38-0.76,0.75-1.52,1.12-2.29
		c0.46-0.95,0.92-1.91,1.4-2.85c0.37-0.74,0.77-1.47,1.16-2.2c0.17-0.32,0.34-0.64,0.51-0.96c1.76-1.11,3.89-1.58,6.08-1.25
		c0.75,0.11,1.48,0.38,2.21,0.57c2.01,0.52,3.77,0.07,5.16-1.46C123.8,67.72,124.09,65.9,123.3,63.98z"
          />
        </g>
      </svg>
    </SVGIconWrapper>
  );
}

CAITextStackedIcon.defaultProps = {
  fill: null,
};
CAITextStackedIcon.propTypes = {
  fill: PropTypes.string,
};
