const subfieldKeys = {
  summary: (org: string, seasonId: string) => {
    return [
      "organization",
      org,
      "season",
      seasonId,
      "subfields",
      "summary",
    ] as const;
  },
  geodataByParams: (
    org: string,
    type: string,
    seasonId: string,
    params?: unknown
  ) => {
    return [
      "organization",
      org,
      "season",
      seasonId,
      "subfields",
      type,
      "geodata",
      params,
    ] as const;
  },
  all: (org: string, type: string, seasonId: string) => {
    return [
      "organization",
      org,
      "season",
      seasonId,
      "subfields",
      type,
    ] as const;
  },
};

export default subfieldKeys;
