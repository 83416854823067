import React from "react";
import { useTranslation } from "react-i18next";
import { LegendSymbol } from "lib/MapboxMap";

export default function useFieldLegendLayer() {
  const { t } = useTranslation();
  return {
    id: "fields-lyr",
    title: t("common.field"),
    Symbol: function Symbol() {
      return <LegendSymbol borderColor="#fff" borderWidth={3} />;
    },
  };
}
