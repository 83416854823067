import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { create } from "zustand";

export const settingsStoreId = "cai-agassessor-settings";
export const languagePrefId = "cai:lang:preference";
const supportedLangsArr = ["enUS", "esES", "frFR"];

let fromCache = window.localStorage
  ? window.localStorage.getItem(languagePrefId)
  : null;
// prevent issues with previously saved locale formats
if (fromCache?.length === 2) {
  if (fromCache === "en") {
    fromCache = "enUS";
  } else if (fromCache === "es") {
    fromCache = "esES";
  } else if (fromCache === "fr") {
    fromCache = "frFR";
  } else {
    fromCache = undefined;
  }
}
if (!supportedLangsArr.includes(fromCache)) {
  fromCache = undefined;
}

export type CAISupportedLocale = "enUS" | "esES" | "frFR";
export type SettingsLanguageOption = {
  label: string;
  value: CAISupportedLocale;
}[];
export interface SettingsProps {
  language: CAISupportedLocale;
  languages: SettingsLanguageOption[];
  unitSystem: "imperial" | "metric";
  theme: "dark" | "light";
}

const langId = languagePrefId as string;

const defaults = {
  theme: "dark",
  language: fromCache ?? (navigator?.language?.split("-").join("") || "enUS"),
  unitSystem: "imperial",
} as SettingsProps;

function getCache() {
  if (window.localStorage) {
    const currentSettings = window.localStorage.getItem(settingsStoreId);
    if (currentSettings) {
      try {
        return JSON.parse(currentSettings);
      } catch (e) {
        //
      }
    } else {
      return defaults;
    }
  } else {
    return defaults;
  }
}

function useLanguages() {
  const { t } = useTranslation();
  return [
    { label: t("settings.english"), value: "enUS" },
    { label: t("settings.spanish"), value: "esES" },
    { label: t("settings.french"), value: "frFR" },
  ];
}

export const useSettingsStore = create<
  SettingsProps & {
    saveSettings: (_settings: Partial<SettingsProps>) => void;
  }
>((set) => {
  return {
    ...defaults,
    ...getCache(),
    saveSettings: (settings: SettingsProps) => {
      if (
        document.body.classList.contains("light") &&
        settings.theme === "dark"
      ) {
        document.body.classList.remove("light");
      }
      if (window.localStorage) {
        const { language, ...rest } = settings;
        window.localStorage.setItem(settingsStoreId, JSON.stringify(rest));
        window.localStorage.setItem(langId, language);
      }
      return set((state) => ({
        ...state,
        ...settings,
      }));
    },
  };
});

export const useSettingsState = () => {
  const languages = useLanguages();
  return useSettingsStore((state) => {
    return {
      language: state.language,
      languages,
      theme: state.theme,
      unitSystem: state.unitSystem,
    };
  });
};

export const useSettingsDispatch = () => {
  const queryClient = useQueryClient();

  return useSettingsStore((state) => {
    return (d: Partial<SettingsProps>) => {
      state.saveSettings({
        ...state,
        ...d,
      });
      // force re-fetch for everything to get API provided translations
      queryClient.invalidateQueries({ queryKey: ["organization"] });
    };
  });
};
