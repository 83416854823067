import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import WaterDropIcon from "@mui/icons-material/WaterDrop";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import LinkIcon from "@mui/icons-material/Link";
import { IProduct } from "types";

export default function useProductColumns({
  applicationNameProp = "name",
}: {
  applicationNameProp?: string;
} = {}) {
  const { t } = useTranslation();
  return {
    seedColumns: [
      { Header: t("common.name"), accessor: "name" },
      { Header: t("common.manufacturer"), accessor: "manufacturer" },
      { Header: t("common.type"), accessor: "type" },
    ],
    applicationColumns: [
      {
        Header: t("common.name"),
        accessor: applicationNameProp,
        Cell: ({
          row,
        }: {
          row: { original: IProduct & { isCarrier?: boolean } };
        }) => {
          return (
            <>
              {/* TODO: fix this */}
              {row.original[applicationNameProp as "name"]}
              {row.original.isCarrier ? (
                <Tooltip title={t("inventory.products.carrier")}>
                  <WaterDropIcon sx={{ ml: 1 }} color="primary" />
                </Tooltip>
              ) : null}
            </>
          );
        },
      },
      { Header: t("common.type"), accessor: "type" },
      { Header: t("common.form"), accessor: "productForm" },
      {
        Header: "% N",
        id: "nutrients.n",
        Cell: ({ row }: { row: { original: IProduct } }) => {
          return (
            row.original.nutrients?.find((n) => n.label === "N")?.value ?? null
          );
        },
      },
      {
        Header: "% P",
        accessor: "nutrients.p",
        Cell: ({ row }: { row: { original: IProduct } }) => {
          return (
            row.original.nutrients?.find((n) => n.label === "P")?.value ?? null
          );
        },
      },
      {
        Header: "% K",
        accessor: "nutrients.k",
        Cell: ({ row }: { row: { original: IProduct } }) => {
          return (
            row.original.nutrients?.find((n) => n.label === "K")?.value ?? null
          );
        },
      },
      {
        Header: "% S",
        accessor: "nutrients.s",
        Cell: ({ row }: { row: { original: IProduct } }) => {
          return (
            row.original.nutrients?.find((n) => n.label === "S")?.value ?? null
          );
        },
      },
      {
        Header: "% Mg",
        accessor: "nutrients.mg",
        Cell: ({ row }: { row: { original: IProduct } }) => {
          return (
            row.original.nutrients?.find((n) => n.label === "Mg")?.value ?? null
          );
        },
      },
      {
        Header: "% Ca",
        accessor: "nutrients.ca",
        Cell: ({ row }: { row: { original: IProduct } }) => {
          return (
            row.original.nutrients?.find((n) => n.label === "Ca")?.value ?? null
          );
        },
      },
      {
        Header: t("inventory.products.links.title"),
        isSortable: false,
        accessor: "labelUrl",
        Cell: ({ row }: { row: { original: IProduct } }) => {
          return (
            <Stack spacing={2} direction={"row"}>
              {row.original.labelUrl ? (
                <Stack alignItems={"center"}>
                  <Box>
                    <IconButton
                      target="_blank"
                      component="a"
                      href={row.original.labelUrl}
                    >
                      <LinkIcon />
                    </IconButton>
                  </Box>
                  {t("inventory.products.links.label")}
                </Stack>
              ) : null}
              {row.original.sdsUrl ? (
                <Stack alignItems={"center"}>
                  <IconButton
                    target="_blank"
                    component="a"
                    href={row.original.sdsUrl}
                  >
                    <LinkIcon />
                  </IconButton>
                  {t("inventory.products.links.sds")}
                </Stack>
              ) : null}
            </Stack>
          );
        },
      },
    ],
  };
}
