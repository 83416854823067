import { FeatureCollection } from "geojson";

export function hasProps(
  props: string[],
  value: Record<string, unknown>
): boolean {
  return !props
    .map((p) => {
      return !(value[p] || value[p] === 0 || value[p] === false);
    })
    .filter(Boolean).length;
}

export function validateFeatureCounts(
  fc: FeatureCollection,
  requiredProps: string[]
): { validCount: number; invalidCount: number } {
  const invalid = fc?.features.filter((f) => {
    // const date = new Date(f.properties.bornOnUtc || NaN);
    // const isValidDate = !isNaN(date.getTime());
    const isValidProps = hasProps(requiredProps, f.properties);
    return !isValidProps;
  });
  return {
    validCount: fc?.features.length - invalid?.length,
    invalidCount: invalid?.length,
  };
}
