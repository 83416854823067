import React from "react";
import { useOrgDispatch, useOrgState } from "providers/OrgProvider";
import { useLocation, useHistory } from "react-router-dom";
import Autocomplete from "lib/Select/Autocomplete";
import { Calendar } from "react-feather";
import Box from "@mui/material/Box";

export default function SeasonSelector() {
  const { seasons, season, seasonsState } = useOrgState();
  const { dispatch } = useOrgDispatch();
  const location = useLocation();
  const history = useHistory();

  return (
    <Autocomplete
      sx={{ minWidth: 200 }}
      id="season-selector-select"
      loading={seasonsState?.isLoading}
      options={seasons || []}
      value={season || null}
      onChange={(_e, item: { name: string }) => {
        // update URL if we have switched seasons
        const parts = location?.pathname.split("/");
        parts[2] = item?.name;
        history.push(parts.join("/"));
        dispatch({
          type: "SET_SEASON",
          payload: item,
        });
      }}
      InputProps={{
        startAdornment: (
          <Box sx={{ mx: 1 }}>
            <Calendar />
          </Box>
        ),
      }}
    />
  );
}
