import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Unstable_Grid2";
import BaseCardView from "lib/Card/BaseCardView";
import SummaryCardTitle from "lib/Card/SummaryCardTitle";
import SummaryCardValue from "lib/Card/SummaryCardValue";
import { Feature } from "geojson";

export default function AnimalAssetDetails({ feature }: { feature: Feature }) {
  const currentEditFtr = feature;
  const { t } = useTranslation();
  const details = currentEditFtr?.properties;
  return (
    <>
      <Grid container gap={2}>
        <BaseCardView sx={{ bgcolor: "background.paper" }}>
          <SummaryCardTitle>{t("common.field")}</SummaryCardTitle>
          <SummaryCardValue>{details?.field}</SummaryCardValue>
        </BaseCardView>
        <BaseCardView sx={{ bgcolor: "background.paper" }}>
          <SummaryCardTitle>{t("common.type")}</SummaryCardTitle>
          <SummaryCardValue>{details?.assetAnimalType}</SummaryCardValue>
        </BaseCardView>
        <BaseCardView sx={{ bgcolor: "background.paper" }}>
          <SummaryCardTitle>{t("common.name")}</SummaryCardTitle>
          <SummaryCardValue>{details?.name}</SummaryCardValue>
        </BaseCardView>
        <BaseCardView sx={{ bgcolor: "background.paper" }}>
          <SummaryCardTitle>{t("inventory.assets.sex")}</SummaryCardTitle>
          <SummaryCardValue>
            {details?.isFemale
              ? t("inventory.assets.female")
              : t("inventory.assets.male")}
          </SummaryCardValue>
        </BaseCardView>
        <BaseCardView sx={{ bgcolor: "background.paper" }}>
          <SummaryCardTitle>ID</SummaryCardTitle>
          <SummaryCardValue>{details?.cid || "--"}</SummaryCardValue>
        </BaseCardView>
        <BaseCardView sx={{ bgcolor: "background.paper" }}>
          <SummaryCardTitle>{t("common.group")}</SummaryCardTitle>
          <SummaryCardValue>{details?.assetGroup || "--"}</SummaryCardValue>
        </BaseCardView>
        <BaseCardView sx={{ bgcolor: "background.paper" }}>
          <SummaryCardTitle>{t("common.breed")}</SummaryCardTitle>
          <SummaryCardValue>
            {currentEditFtr?.properties?.assetAnimalBreed || "--"}
          </SummaryCardValue>
        </BaseCardView>
        <BaseCardView sx={{ bgcolor: "background.paper" }}>
          <SummaryCardTitle>{t("inventory.assets.bornOn")}</SummaryCardTitle>
          <SummaryCardValue>
            {currentEditFtr?.properties?.bornOnUtc
              ? new Date(
                  currentEditFtr?.properties?.bornOnUtc
                ).toLocaleDateString()
              : "--"}
          </SummaryCardValue>
        </BaseCardView>
        <BaseCardView sx={{ bgcolor: "background.paper" }}>
          <SummaryCardTitle>{t("common.status")}</SummaryCardTitle>
          <SummaryCardValue>{details?.assetStatus || "--"}</SummaryCardValue>
        </BaseCardView>
        <BaseCardView sx={{ bgcolor: "background.paper" }}>
          <SummaryCardTitle>{t("common.notes")}</SummaryCardTitle>
          <SummaryCardValue>{details?.description || "--"}</SummaryCardValue>
        </BaseCardView>
      </Grid>
    </>
  );
}
