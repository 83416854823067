import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import ReactDOM from "react-dom";
import { useTheme } from "@mui/material/styles";
import MapboxDraw from "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import mapboxgl from "mapbox-gl";
import DrawRectangle from "mapbox-gl-draw-rectangle-mode";
import { getArea, getLength, getCenter, combineFc } from "./utils";
import DrawControl from "./controls/DrawControl";
import drawStyles from "./DrawControlStyles";
import Tooltip from "./Tooltip";

const defaultOpts = {
  displayControlsDefault: false,
  areaUnits: "ac",
  distanceUnits: "ft",
  showAreaCalc: true,
};
/**
 * Mapbox GL Draw JS hook
 *
 * @version 0.0.1
 * @author [Jeremy Folds](https://github.com/jmfolds)
 * @param options options object containing mapbox-gl-draw options
 */
export default function useMapboxDraw(options) {
  const { controls, defaultFeatures, modes, ...rest } = options;
  const { id: distanceUnitsId, label: distanceUnitsLabel } =
    options?.distanceUnits || {};
  const { id: areaUnitsId, label: areaUnitsLabel } = options?.areaUnits || {};
  const ctrlRef = useRef();
  const theme = useTheme();
  const toolbarRef = useRef();
  const mapRef = useRef();
  const [isAdded, setIsAdded] = useState();
  const [mode, setMode] = useState(options.defaultMode || "simple_select");
  const [hasSelection, setHasSelection] = useState();
  const tooltipContainer = React.useRef(document.createElement("div"));
  const finalModes = {
    ...MapboxDraw.modes,
    ...modes,
    ...{ draw_rectangle: DrawRectangle },
  };

  const opts = {
    ...defaultOpts,
    modes: finalModes,
    styles: drawStyles,
    ...rest,
  };

  function setTooltip(area) {
    if (area) {
      ReactDOM.render(
        React.createElement(Tooltip, null, area),
        tooltipContainer.current
      );
    } else {
      ReactDOM.unmountComponentAtNode(tooltipContainer.current);
    }
  }
  function handleDrawChange(e) {
    // need to keep mode updated after deleting selection
    if (e.type === "draw.delete") {
      setMode("simple_select");
      setHasSelection(false);
    }
    let ftrs;
    if (ctrlRef.current) {
      ftrs = ctrlRef.current.getAll();
    }
    if (opts.onDrawChange) {
      opts.onDrawChange(ftrs);
    }
    return ftrs;
  }

  function validateDrawGeom(geom) {
    const type = geom?.features[0] ? geom.features[0]?.geometry?.type : null;
    let isValid = !!type;
    if (geom.features.length) {
      if (type === "Point" || type === "MultiPoint") {
        // NOTE: marking as inValid, since we are not calcuating anything to show in tooltip
        // unless the geometry is a line or polygon
        isValid = false;
      }
      if (type === "Polygon" || type === "MultiPolygon") {
        geom.features[0].geometry.coordinates.forEach((item) => {
          if (item.includes(null) || item.includes(NaN)) {
            isValid = false;
          }
          item.forEach((i) => {
            if (i.includes(null)) {
              isValid = false;
            }
          });
        });
      } else if (type === "LineString" || type === "MultiLineString") {
        if (!geom.features[0].geometry.coordinates.length) {
          isValid = false;
        }
        geom.features[0].geometry.coordinates.forEach((item) => {
          if (!item.length) {
            isValid = false;
          }
        });
      }
    }
    return isValid;
  }

  const changeMode = useCallback((m, modeOpts) => {
    if (ctrlRef.current) {
      ctrlRef.current.changeMode(m, modeOpts);
      setMode(m);
      setHasSelection(false);
    }
  }, []);

  const drawControlOpts = useMemo(
    () => ({
      controls,
      onDelete: () => {
        ctrlRef.current.trash();
      },
      onDrawPolygon: () => {
        changeMode("draw_polygon");
      },
      onDrawRectangle: () => {
        changeMode("draw_rectangle");
      },
      onDrawPoint: () => {
        changeMode("draw_point");
      },
      onDrawLineString: () => {
        changeMode("draw_line_string");
      },
      onCombine: () => {
        ctrlRef.current.combineFeatures();
      },
      onUncombine: () => {
        ctrlRef.current.uncombineFeatures();
      },
      theme,
    }),
    [controls, theme, changeMode]
  );

  useEffect(() => {
    if (toolbarRef.current) {
      toolbarRef.current.updateOptions({ ...drawControlOpts, ...options });
    }
  }, [options, drawControlOpts]);

  const methods = {
    setMap: (map) => {
      mapRef.current = map;
      const tooltip = new mapboxgl.Marker(tooltipContainer.current, {})
        .setLngLat([0, 0])
        .addTo(map);
      if (opts.showAreaCalc) {
        map.on("draw.render", () => {
          const currentFeatures = methods.getFeatures();
          const combined = combineFc(currentFeatures);
          const type = combined.features[0]
            ? combined.features[0]?.geometry?.type
            : null;
          // when first entering draw_polygon mode
          // the draw control returns a feature collection that contains null geometry
          // checking for that here before sending to turf utils to prevent errors
          const isValid = validateDrawGeom(combined);
          if (isValid) {
            const center = getCenter(combined);
            if (center) {
              tooltip.setLngLat(center.geometry.coordinates);
            }
            if (type === "Polygon" || type === "MultiPolygon") {
              const areaUnits = areaUnitsId || defaultOpts.areaUnits;
              const area = getArea({
                geometry: combined.features[0],
                outUnits: areaUnits,
              });
              if (area) {
                setTooltip(
                  `${area.toLocaleString()} ${
                    areaUnitsLabel || areaUnits || ""
                  }`
                );
              }
            } else if (type === "LineString" || type === "MultiLineString") {
              const distanceUnits =
                distanceUnitsId || defaultOpts.distanceUnits;
              const length = getLength(combined, {
                outUnits: distanceUnits,
              });
              if (length) {
                setTooltip(
                  `${length.toLocaleString()} ${
                    distanceUnitsLabel || distanceUnits || ""
                  }`
                );
              }
            } else {
              setTooltip(null);
            }
          } else {
            setTooltip(null);
          }
        });
      }
      // keep track of current mode
      map.on("draw.modechange", ({ mode: m }) => {
        if (opts.onModeChange) {
          opts.onModeChange(m);
        }
        setMode(m);
      });

      if (opts.onDrawChange) {
        map.on("draw.create", handleDrawChange);
        map.on("draw.update", handleDrawChange);
        map.on("draw.delete", handleDrawChange);
        map.on("draw.combine", handleDrawChange);
        map.on("draw.uncombine", handleDrawChange);
      }

      map.on("draw.selectionchange", (e) => {
        setHasSelection(e.features.length);
        if (opts.onSelectionChange) {
          opts.onSelectionChange(e);
        }
      });
    },
    setFeatures: useCallback((ftrs) => {
      if (mapRef.current && ctrlRef.current) {
        ctrlRef.current.set(ftrs);
        if (ftrs?.features?.length) {
          const m = ftrs.features[0].geometry.type.includes("Point")
            ? "simple_select"
            : "direct_select";
          ctrlRef.current.changeMode(m, {
            featureId: ftrs.features[0].id,
          });
          setMode(m);
        }
      }
    }, []),
    changeMode,
    getFeatures: () => {
      if (ctrlRef.current) {
        return ctrlRef.current.getAll();
      }
      return null;
    },
    addDrawControl: () => {
      if (ctrlRef.current && isAdded) {
        return ctrlRef.current;
      }
      if (!ctrlRef.current) {
        const ctrl = new MapboxDraw(opts);
        ctrlRef.current = ctrl;
      }

      if (mapRef.current && !isAdded) {
        setIsAdded(true);
        mapRef.current.addControl(ctrlRef.current, "bottom-left");
        const drawControl = new DrawControl(mapRef.current, drawControlOpts);
        mapRef.current.addControl(drawControl, "top-left");
        toolbarRef.current = drawControl;
        if (defaultFeatures) {
          ctrlRef.current.set(defaultFeatures);
          const m = defaultFeatures.features[0].geometry.type.includes("Point")
            ? "simple_select"
            : "direct_select";
          ctrlRef.current.changeMode(m, {
            featureId: defaultFeatures.features[0].id,
          });
          setMode(m);
        }
      }
      return ctrlRef.current;
    },
    removeDrawControl: () => {
      if (isAdded && mapRef.current && ctrlRef.current) {
        mapRef.current.removeControl(ctrlRef.current);
        mapRef.current.removeControl(toolbarRef.current);
        ctrlRef.current = null;
        toolbarRef.current = null;
        setIsAdded(false);
        // next line fixes issue with boxZoom being disabled after removal of draw tool
        mapRef.current.boxZoom.enable();
      }
      setTooltip(null);
      setMode(opts.defaultMode || "simple_select");
    },
  };

  return {
    ...methods,
    mode,
    isAdded,
    hasSelection,
    setMode: changeMode,
  };
}
