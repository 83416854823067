import { featureCollection } from "@turf/helpers";
import { Feature } from "geojson";
import { fitBounds } from "lib/MapboxMap";
import {
  EventData,
  GeoJSONSource,
  ImageSource,
  Map,
  MapboxEvent,
} from "mapbox-gl";
import { useFieldsState } from "providers";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { IField } from "types";
import BaseMapView from "views/BaseMapView";

const srcId = "rx-raster-src";
const lyrId = "rx-raster-lyr";

// const removeRasterLayer = (mapRef: Map) => {
//   // remove any current extract layers
//   if (mapRef) {
//     const src = mapRef.getSource(srcId);
//     const lyr = mapRef.getLayer(lyrId);
//     if (lyr) {
//       mapRef.removeLayer(lyrId);
//     }
//     if (src) {
//       mapRef.removeSource(srcId);
//     }
//   }
// };

// const addRasterLayer = ({
//   mapRef,
//   url,
//   bbox,
// }: {
//   mapRef: Map;
//   url: string;
//   bbox: [number, number, number, number];
// }) => {
//   // remove any current extract layers
//   removeRasterLayer(mapRef);
//   if (url) {
//     if (mapRef) {
//       mapRef.addSource(srcId, {
//         type: "raster",
//         tiles: [url],
//         tileSize: 256,
//         ...(bbox ? { bounds: bbox } : {}),
//         maxzoom: 24,
//       });
//       mapRef.addLayer(
//         {
//           id: lyrId,
//           source: srcId,
//           type: "raster",
//         },
//         "z-index-layer"
//       );
//     }
//   }
// };

export default function PrescriptionMapView({
  field,
  onLoad,
  selectedSubfields,
  rasterSource,
}: {
  field?: IField;
  onLoad?: (_m: Map) => void;
  selectedSubfields: Feature[];
  rasterSource?: { url: string; coordinates: number[][] };
}) {
  const { fieldsGeodataState } = useFieldsState();
  const [isLoading, setIsLoading] = useState(false);
  const mapRef = useRef<Map>();

  const geo = useMemo(() => {
    return fieldsGeodataState.data?.features?.find(
      (f: Feature) => f.properties.id === field?.id
    );
  }, [field, fieldsGeodataState.data]);

  // useEffect(() => {
  //   if (rasterUrl) {
  //     addRasterLayer({
  //       mapRef: mapRef.current,
  //       url: rasterUrl,
  //       bbox: geo?.geometry.bbox,
  //     });
  //   } else {
  //     removeRasterLayer(mapRef.current);
  //   }
  // }, [geo, rasterUrl]);

  // useEffect(() => {
  //   if (mapRef.current) {
  //     if (geojson) {
  //       if (!mapRef.current.getSource("geojson-src")) {
  //         mapRef.current.addSource("geojson-src", {
  //           type: "geojson",
  //           data: geojson,
  //         });
  //         mapRef.current.addLayer({
  //           id: "geojson-lyr-line",
  //           type: "line",
  //           source: "geojson-src",
  //           paint: {
  //             "line-width": 3,
  //             "line-color": "#000",
  //             // "line-color": [
  //             //   "match",
  //             //   ["get", "value"],
  //             //   3,
  //             //   "red",
  //             //   2,
  //             //   "yellow",
  //             //   1,
  //             //   "green",
  //             //   "black",
  //             // ],
  //           },
  //         });
  //         mapRef.current.addLayer({
  //           id: "geojson-lyr-fill",
  //           type: "fill",
  //           source: "geojson-src",
  //           paint: {
  //             "fill-opacity": 0.75,
  //             "fill-color": ["get", "color"],
  //             // "fill-color": [
  //             //   "match",
  //             //   ["get", "value"],
  //             //   3,
  //             //   "red",
  //             //   2,
  //             //   "yellow",
  //             //   1,
  //             //   "green",
  //             //   "black",
  //             // ],
  //           },
  //         });
  //         // fitBounds({ map: mapRef.current, geojson });
  //       } else {
  //         const src = mapRef.current?.getSource("geojson-src") as GeoJSONSource;
  //         if (src) {
  //           src.setData(geojson);
  //           // fitBounds({ map: mapRef.current, geojson });
  //         }
  //       }
  //     } else {
  //       const src = mapRef.current?.getSource("geojson-src") as GeoJSONSource;
  //       if (src) src.setData({ type: "FeatureCollection", features: [] });
  //     }
  //   }
  // }, [geojson]);

  useEffect(() => {
    if (mapRef.current) {
      if (rasterSource) {
        if (!mapRef.current.getSource(srcId)) {
          mapRef.current.addSource(srcId, { ...rasterSource, type: "image" });
          mapRef.current.addLayer({
            id: lyrId,
            type: "raster",
            source: srcId,
          });
        } else {
          const src = mapRef.current?.getSource(srcId) as ImageSource;
          if (src) {
            src.updateImage(rasterSource);
          }
        }
      } else {
        const src = mapRef.current?.getSource(srcId) as ImageSource;
        if (src) {
          mapRef.current.removeSource(srcId);
        }
      }
    }
  }, [rasterSource]);

  useEffect(() => {
    if (field && fieldsGeodataState.data) {
      if (mapRef.current) {
        if (!mapRef.current.getSource("fields")) {
          mapRef.current.addSource("fields", {
            type: "geojson",
            data: fieldsGeodataState.data,
          });
          mapRef.current.addLayer({
            id: "fields-lyr",
            type: "line",
            source: "fields",
            filter: ["in", "id", field?.id],
            paint: {
              "line-width": 3,
              "line-color": "#fff",
            },
          });
        } else {
          mapRef.current.setFilter("fields-lyr", ["in", "id", field?.id]);
        }
        if (geo?.geometry?.bbox) {
          fitBounds({
            map: mapRef.current,
            geojson: geo,
          });
        }
      }
    }
  }, [field, fieldsGeodataState.data, geo]);

  //  handle subfields layer
  useEffect(() => {
    if (mapRef.current && selectedSubfields) {
      try {
        const src = mapRef.current.getSource("subfields-src") as GeoJSONSource;
        if (src) {
          src.setData(featureCollection(selectedSubfields ?? []));
        }
      } catch (e) {
        //
      }
    }
  }, [selectedSubfields]);

  return (
    <BaseMapView
      isLoading={isLoading}
      events={{
        load: (e: MapboxEvent) => {
          const map = e.target;
          mapRef.current = map;
          map.addSource("subfields-src", {
            type: "geojson",
            data: featureCollection([]),
          });
          map.addLayer({
            id: "subfields-lyr",
            type: "line",
            source: "subfields-src",
            paint: {
              "line-width": 3,
              "line-color": ["get", "color"],
            },
          });
          if (onLoad) {
            onLoad(map);
          }
        },
        dataloading: (e: EventData) => {
          if (e.sourceId === srcId) {
            setIsLoading(true);
          }
        },
        data: (e: EventData) => {
          if (e.sourceId === srcId) {
            setIsLoading(false);
          }
        },
        idle: () => {
          setIsLoading(false);
        },
        error: (e: EventData) => {
          if (e.sourceId === srcId) {
            setIsLoading(false);
          }
        },
      }}
    />
  );
}
