import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { useState } from "react";
import { VerityFieldCI } from "./VerityFieldCI";

export function ApplicationsCell({
  value,
}: {
  value?:
    | VerityFieldCI["otherApplications"]
    | VerityFieldCI["manureApplications"];
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return value?.length ? (
    <Box sx={{ mr: 1 }} style={{ display: "flex", float: "left" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span>{`${value[0].rate} ${value[0].rateUom}`}</span>
        <small>{value[0].product}</small>
      </div>
      {value.length > 1 ? (
        <>
          <IconButton onClick={handleClick}>
            <InfoOutlined />
          </IconButton>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Stack spacing={1} sx={{ p: 2 }}>
              {value.map((v) => (
                <Typography key={v.product}>
                  <div
                    style={{
                      borderBottom: "solid 1px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <span>{`${v.rate} ${v.rateUom}`}</span>
                    <small>{v.product}</small>
                  </div>
                </Typography>
              ))}
            </Stack>
          </Popover>
        </>
      ) : null}
    </Box>
  ) : (
    "--"
  );
}
