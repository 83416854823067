import React from "react";
import SidebarStateContext from "./SidebarStateContext";

export default function SidebarProvider({
  activeKey,
  setActiveKey,
  children,
  open,
  setOpen,
}: {
  activeKey?: string;
  setActiveKey: (_key: string) => void;
  children: React.ReactNode;
  open: boolean;
  setOpen: (_open: boolean) => void;
}) {
  return (
    <SidebarStateContext.Provider
      value={{ activeKey, setActiveKey, open, setOpen }}
    >
      {children}
    </SidebarStateContext.Provider>
  );
}
