import React, { useEffect } from "react";
import { styled, Theme, CSSObject, SxProps } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import SidebarProvider from "./SidebarProvider";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Sidebar({
  activeKey: defaultActiveKey,
  children,
  onToggle,
  open: controlledOpen,
  sx,
}: {
  activeKey?: string;
  children: React.ReactNode;
  onToggle?: (_open: boolean) => void;
  open: boolean;
  sx?: SxProps;
}) {
  const [open, setOpen] = React.useState(controlledOpen);
  const [activeKey, setActiveKey] = React.useState(defaultActiveKey);

  useEffect(() => {
    setOpen(controlledOpen);
  }, [controlledOpen]);

  return (
    <SidebarProvider
      setActiveKey={setActiveKey}
      activeKey={activeKey}
      setOpen={setOpen}
      open={open}
    >
      <Drawer
        className="hide-print"
        sx={{
          ...sx,
        }}
        variant="permanent"
        open={open}
        PaperProps={{
          onTransitionEnd: (e: React.TransitionEvent) => {
            if (e.propertyName === "width" && onToggle) {
              onToggle(open);
            }
          },
          sx(theme) {
            return {
              border: "none",
              backgroundColor:
                theme.palette.mode === "light" ? "#f2f2f2" : "#333",
            };
          },
        }}
      >
        <List
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            mt: 10,
          }}
        >
          {children}
        </List>
      </Drawer>
    </SidebarProvider>
  );
}
