import React, { useState } from "react";
import { SxProps } from "@mui/material/styles";
import Autocomplete from "lib/Select/Autocomplete";
import { useTranslation } from "react-i18next";
import { IField } from "types";

export default function FieldSelect({
  fields,
  onChange,
  required,
  sx,
  value,
}: {
  fields: IField[];
  onChange?: (_item: IField) => void;
  required?: boolean;
  sx?: SxProps;
  value?: IField;
}) {
  const { t } = useTranslation();
  const [selectedField, setSelectedField] = useState<IField>();
  const fieldItem = value !== undefined ? value : selectedField;
  return (
    <Autocomplete
      sx={sx}
      name="fieldId"
      inputProps={{
        required,
        value: fieldItem?.id ?? "",
      }}
      label={`${t("common.field")}${required ? " *" : ""}`}
      disableClearable={false}
      value={fieldItem ?? null}
      getOptionLabel={(row: IField) => {
        return row.name;
      }}
      // see: https://mui.com/material-ui/react-autocomplete/#grouped
      groupBy={(row) => row.farm}
      // sort by same dimension as groupBy, see above
      options={fields?.sort((a, b) =>
        a.farm > b.farm ? 1 : a.farm === b.farm ? 0 : -1
      )}
      onChange={(_e, item: IField) => {
        setSelectedField(item);
        onChange && onChange(item);
      }}
    />
  );
}
