import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import DataTable from "components/DataTable";
import Autocomplete from "lib/Select/Autocomplete";
import Paper from "@mui/material/Paper";

export default function ReportFieldsSelect({
  fields,
  onChange,
  isLoading,
  subtypes,
  onSubtypeChange,
}) {
  const { t } = useTranslation();
  const [fieldsByType, setFieldsByType] = useState(fields);
  const [landAgreementItem, setLandAgreementItem] = useState();
  const [subtypeItem, setSubtypeItem] = useState();

  // set all fields on mount
  useEffect(() => {
    setFieldsByType(fields);
    return () => {
      // reset subtype selection on unmount
      setSubtypeItem(null);
      setLandAgreementItem(null);
    };
  }, [fields]);

  const handleSubtypeChange = useCallback(
    (_e, item) => {
      setSubtypeItem(item);
      onSubtypeChange(item);
      let bySubtype = fields;
      if (item?.value) {
        bySubtype = fields.filter((f) => {
          const y = f.children?.find((c) => c.subtypeId === item.value);
          return y;
        });
      }
      if (landAgreementItem) {
        bySubtype = bySubtype?.filter((c) => {
          return c.landAgreementTypeId === landAgreementItem.value;
        });
      }
      setFieldsByType(bySubtype);
    },
    [fields, onSubtypeChange, landAgreementItem]
  );

  return (
    <Paper
      sx={{ p: 2, height: "100%", display: "flex", flexDirection: "column" }}
    >
      <h3>{t("common.selectFields")}</h3>
      {subtypes ? (
        <Autocomplete
          label={t("insights.reports.filterByType")}
          disableClearable={false}
          id="type-select"
          onChange={handleSubtypeChange}
          options={subtypes}
          getOptionLabel={(o) => o.name}
          value={subtypeItem ?? null}
        />
      ) : null}
      <div style={{ flexGrow: 1, overflow: "auto" }}>
        <DataTable
          isLoading={isLoading}
          isSelectable
          isFilterable={false}
          // selectedRowIds={selectedRows}
          onSelectionChange={(data) => {
            const selectedFields =
              fieldsByType?.filter((co) => {
                return data.find((d) => d?.original?.id === co.id);
              }) || [];
            // setSelectedRows(getDefaultSelectedRows(selectedFields));
            onChange(selectedFields);
          }}
          data={fieldsByType}
          columns={[
            {
              Header: t("common.farm"),
              id: "farm",
              accessor: (row) => {
                return row.farm;
              },
            },
            {
              Header: t("common.field"),
              id: "field",
              accessor: (row) => {
                return row.field || row.name;
              },
            },
          ]}
        />
      </div>
    </Paper>
  );
}

ReportFieldsSelect.defaultProps = {
  fields: null,
  isLoading: false,
  subtypes: null,
  // TODO: make this required when updating organization reports
  // to include the type filter
  onSubtypeChange: () => {},
};

ReportFieldsSelect.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  subtypes: PropTypes.arrayOf(PropTypes.shape({})),
  onSubtypeChange: PropTypes.func,
};
