import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Skeleton from "@mui/material/Skeleton";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useOrgState } from "providers/OrgProvider";
import OperationsSummaryView from "views/InventoryView/Operations/SummaryView";
import OperationsTimeSeries from "components/Charts/OperationsTimeSeries";
import { groupBy, sortByDate } from "components/Charts/utils";
import { useOperations } from "api/operations/useOperations";
import { useOperationsSummary } from "api/operations/useOperationsSummary";
import { useOperationMapThemes } from "api/operations/useOperationMapThemes";

function parseOperations(ops, theme, opTypeColor) {
  const dataKeys = [];
  const dataByOps = [];
  if (ops && ops.length) {
    sortByDate(ops, "beginDateUtc");
    const x = groupBy(ops, "operationId");
    const y = Object.keys(x);
    y.forEach((key) => {
      const data = x[key];
      const dataObj = {
        area: 0,
        totalMass: 0,
        children: data,
      };
      data.forEach((d, idx) => {
        const themeItem = theme?.raw?.find((t) => t.id === d.id);
        // TODO: get first begin date
        d.cropColor = themeItem?.cropColor;
        d.productColor = themeItem?.productColor;
        d.tilledTypeColor = themeItem?.tilledTypeColor;
        dataObj[`area-${idx}`] = Number(d.area.toFixed(2));
        dataObj[`totalMass-${idx}`] = d.totalMass
          ? Number(d.totalMass.toFixed(2))
          : 0;
        dataObj.tilledType = d.tilledType;
        dataObj.cropColor = themeItem?.cropColor;
        dataObj.productColor = themeItem?.productColor;
        dataObj.productTypeColor = themeItem?.productTypeColor;
        dataObj.tilledTypeColor = themeItem?.tilledTypeColor;
        dataObj.color = opTypeColor;
        dataObj.beginDateUtc = d.beginDateUtc;
        dataObj.area += d.area;
        dataObj.totalMass += d.totalMass;
        dataObj.operationId = d.operationId;
        dataObj.farm = d.farm;
        dataObj.field = d.field;
        dataObj.areaUom = d.areaUom;
        dataObj.totalMassUom = d.totalMassUom;
        dataObj.rate = d.rate;
        dataObj.rateUom = d.rateUom;
        dataObj.type = d.type;
        dataObj.product = d.product;
        dataObj.depth = `${d.depth} ${d.depthUom}` || "";
        if (!dataKeys.find((dk) => dk.key === `area-${idx}`)) {
          dataKeys.push({
            operationId: d.operationId,
            key: `area-${idx}`,
            color: themeItem?.opTypeColor,
            cropColor: themeItem?.cropColor,
            productColor: themeItem?.productColor,
            productTypeColor: themeItem?.productTypeColor,
            tilledTypeColor: themeItem?.tilledTypeColor,
            ...d,
          });
        }
      });
      dataObj.area = Number(dataObj.area.toFixed(2));
      dataByOps.push(dataObj);
    });
  }

  return { dataByOps, dataKeys };
}

export default function OperationsOverview({
  operationType,
  setOperationType,
}) {
  const { t } = useTranslation();
  const { org, season, configQuery } = useOrgState();
  const operationsQ = useOperations({
    org,
    seasonId: season?.id,
    type: operationType,
  });
  const themesQ = useOperationMapThemes({
    org,
    seasonId: season?.id,
    type: operationType,
  });
  const { uoms } = configQuery?.data || {};
  const summaryQ = useOperationsSummary(org, season?.id);
  const currColor = summaryQ.data?.find(
    (o) => o?.operationEn?.toLowerCase() === operationType
  )?.color;
  const [chartData, setChartData] = useState(
    parseOperations(operationsQ.data, themesQ.data, currColor)
  );

  useEffect(() => {
    // console.log('parsing chart data');
    setChartData(parseOperations(operationsQ.data, themesQ.data, currColor));
  }, [operationsQ.data, themesQ.data, currColor]);
  return (
    <Paper sx={{ p: 3, position: "relative" }}>
      {operationsQ.isLoading ? (
        <LinearProgress
          style={{ top: 0, left: 0, right: 0, position: "absolute" }}
        />
      ) : null}{" "}
      <Stack spacing={2}>
        <h3>
          {t("inventory.operations.title")} {t("inventory.title")}
        </h3>
        <OperationsSummaryView
          activeType={operationType}
          onSelect={(d) => {
            setOperationType(d);
          }}
        />
        <Stack sx={{ width: "100%", overflow: "visible", height: 250 }}>
          {operationsQ.isLoading ? (
            <Grid
              alignItems={"flex-end"}
              justifyContent={"center"}
              height="100%"
              gap={2}
              sx={{ p: 3, my: 3, width: "100%" }}
              container
            >
              {[2, 3, 4, 5, 6].map((i) => {
                return (
                  <Grid key={i}>
                    <Skeleton
                      sx={{
                        transform: "none",
                        width: 75,
                        height: i * 10,
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          ) : chartData?.dataByOps?.length ? (
            <OperationsTimeSeries
              operationType={operationType}
              data={chartData.dataByOps}
              yDataKey={operationType === "harvested" ? "totalMass" : "area"}
              yDataUnit={
                operationType === "harvested"
                  ? uoms?.mass?.label
                  : uoms?.area?.label
              }
              yDataLabel={
                operationType === "harvested"
                  ? `${t("common.totalMass")} ${
                      uoms?.mass?.label ? `(${uoms?.mass?.label})` : ""
                    }`
                  : `${t("common.area")} ${
                      uoms?.area?.label ? `(${uoms?.area?.label})` : ""
                    }`
              }
              timeDataKey="beginDateUtc"
            />
          ) : (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h5" component={"div"}>
                {t("common.noData")}
              </Typography>
            </div>
          )}
        </Stack>
      </Stack>
    </Paper>
  );
}

OperationsOverview.propTypes = {
  operationType: PropTypes.oneOf(["planted", "applied", "harvested", "tilled"])
    .isRequired,
  setOperationType: PropTypes.func.isRequired,
};
