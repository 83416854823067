import { useCallback } from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import {
  ChartDataItem,
  ChartDataRequestBody,
  ChartDataResponse,
} from "../types";
import { Feature } from "geojson";
import { controlColor } from "views/InsightsView/Yield/utils";
import { generateCollectEventTileUrl } from "../Yield/generateCollectEventTileUrl";

const keys = {
  all: (org: string, type: string, data: ChartDataRequestBody) =>
    ["organization", org, "imagery-insights", type, data] as const,
};

type ImageryInsightsChartData = {
  chartData: ChartDataItem[];
  processedFtrs: Feature[];
};

function parseData(
  d: ChartDataResponse,
  collectEventId: string,
  sensorType: string,
  tenant: string,
  org: string
): ImageryInsightsChartData {
  const parsedData: {
    chartData: ChartDataItem[];
    processedFtrs: Feature[];
  } = {
    chartData: undefined,
    processedFtrs: undefined,
  };
  // get feature for map
  const resFtr: Feature = {
    type: "Feature",
    geometry: d?.geometry,
    properties: {
      color: controlColor,
      operationId: "",
    },
  };

  const arr: ChartDataItem[] = [];
  d?.rasters?.forEach((raster) => {
    const i = { ...raster } as ChartDataItem;
    const url = generateCollectEventTileUrl({
      tenant,
      org,
      collectEventId: collectEventId,
      sensorType: sensorType,
      rasterType: raster.name,
    });
    i.url = url;
    i.name = raster.name?.toUpperCase();
    i.color = raster.color || controlColor;
    arr.push(i);
  });
  parsedData.chartData = arr;
  parsedData.processedFtrs = [resFtr];
  return parsedData;
}

export function useImageryInsightsChartData(
  org: string,
  tenant: string,
  type: string,
  requestBody?: ChartDataRequestBody
) {
  const getData = useCallback(async () => {
    const toSend = { ...requestBody };
    if (type !== "product" && type !== "subfields") {
      throw Error("Invalid report type");
    }
    const res = await axios.post<ChartDataResponse>(
      `/${org}/insights/${
        type === "subfields"
          ? "imagery/subfields"
          : "yield/imagery/productplantedapplied"
      }`,
      {
        ...toSend,
      }
    );

    const x = parseData(
      res.data,
      requestBody.collectEventId,
      requestBody.sensorType,
      tenant,
      org
    );

    return x;
  }, [org, requestBody, tenant, type]);

  return useQuery<ImageryInsightsChartData, Error>({
    queryKey: keys.all(org, type, requestBody),
    queryFn: getData,
    enabled: !!org && !!type && !!requestBody,
  });
}
