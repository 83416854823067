import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DatePicker from "lib/DatePicker/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import isBetweenPlugin from "dayjs/plugin/isBetween";
import { styled } from "@mui/material/styles";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import { Control, Controller, UseFormMethods } from "react-hook-form";

dayjs.extend(isBetweenPlugin);

interface CustomPickerDayProps extends PickersDayProps<Dayjs> {
  isBetween: boolean;
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== "isBetween",
})<CustomPickerDayProps>(({ theme, isBetween }) => ({
  borderRadius: 0,
  ...(isBetween && {
    backgroundColor: theme.palette.primary[theme.palette.mode],
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary[theme.palette.mode],
    },
  }),
  // ...(day.day() === 0 && {
  //   borderTopLeftRadius: "50%",
  //   borderBottomLeftRadius: "50%",
  // }),
  // ...(day.day() === 6 && {
  //   borderTopRightRadius: "50%",
  //   borderBottomRightRadius: "50%",
  // }),
})) as React.ComponentType<CustomPickerDayProps>;

function Day(
  props: PickersDayProps<Dayjs> & {
    hoveredDay?: Dayjs | null;
    startDate?: Dayjs | null;
    endDate?: Dayjs | null;
  }
) {
  const { day, startDate, endDate, hoveredDay, ...other } = props;
  // const isBetweenHovered = day.isBefore(hoveredDay, "day")
  //   ? day.isBetween(startDate, hoveredDay, "day", "[]")
  //   : day.isBetween(hoveredDay, startDate, "day", "[]");
  // TODO: hover
  hoveredDay;
  return (
    <CustomPickersDay
      {...other}
      day={day}
      sx={{ px: 2.5 }}
      disableMargin
      selected={false}
      isBetween={
        startDate && endDate
          ? day.isBetween(startDate, endDate, "day", "[]")
          : undefined
      }
      // isBetween={
      //   hoveredDay
      //     ? isBetweenHovered
      //     : day.isBetween(startDate, endDate, "day", "[]")
      // }
      // isHovered={false}
    />
  );
}
export default function DateRangeInputs2({
  control,
  endProp,
  minDate: defaultMinDate,
  maxDate: defaultMaxDate,
  startDate: defaultStartDate,
  startProp,
  endDate: defaultEndDate,
  onChange,
  required,
  setValue,
}: {
  control: Control;
  endProp: string;
  minDate?: Date;
  maxDate?: Date;
  startDate?: Date;
  startProp: string;
  endDate?: Date;
  onChange?: (_d: { startDate?: Date; endDate?: Date }) => void;
  required?: boolean;
  setValue?: UseFormMethods["setValue"];
}) {
  const { t } = useTranslation();
  const [minDate, setMinDate] = useState(defaultMinDate);
  const [maxDate, setMaxDate] = useState(defaultMaxDate);
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [hoveredDay, setHoveredDay] = useState<Dayjs>();

  useEffect(() => {
    setStartDate(defaultStartDate);
    setMinDate(defaultStartDate);
    setEndDate(defaultEndDate);
  }, [defaultStartDate, defaultEndDate]);

  return (
    <>
      <Controller
        name={startProp}
        control={control}
        rules={{ required: true }}
        render={(props) => {
          return (
            <DatePicker
              sx={{ width: "100%" }}
              showDaysOutsideCurrentMonth
              slots={{ day: Day }}
              slotProps={{
                textField: {
                  label: t("common.startDate"),
                  required,
                },
                day: (ownerState) =>
                  ({
                    startDate,
                    endDate,
                    hoveredDay,
                    onPointerEnter: () => setHoveredDay(ownerState.day),
                    onPointerLeave: () => setHoveredDay(null),
                  } as unknown),
              }}
              minDate={defaultMinDate}
              maxDate={maxDate || defaultMaxDate}
              value={startDate}
              onChange={(e) => {
                setStartDate(e);
                if (!endDate) {
                  setValue?.(endProp, e.toISOString());
                  setEndDate(e);
                }
                setMinDate(e);
                onChange?.({
                  startDate: e,
                  endDate: endDate ?? e,
                });
                props.onChange(e.toISOString());
              }}
            />
          );
        }}
      />
      <Controller
        name={endProp}
        control={control}
        render={(props) => {
          return (
            <DatePicker
              sx={{ width: "100%" }}
              slots={{ day: Day }}
              slotProps={{
                textField: {
                  label: t("common.endDate"),
                  required,
                },
                day: (ownerState) =>
                  ({
                    endDate,
                    startDate,
                    hoveredDay,
                    onPointerEnter: () => setHoveredDay(ownerState.day),
                    onPointerLeave: () => setHoveredDay(null),
                  } as unknown),
              }}
              minDate={minDate || defaultMinDate}
              maxDate={defaultMaxDate}
              value={endDate}
              onChange={(e) => {
                setEndDate(e);
                setMaxDate(e);
                onChange?.({
                  startDate,
                  endDate: e,
                });
                props.onChange(e.toISOString());
              }}
            />
          );
        }}
      />
    </>
  );
}
