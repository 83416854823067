import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";
import { blobToImage } from "lib";
import { assetKeys, eventKeys } from "./assetKeys";
import { ParsedImage } from "api/types";

export function useAssetPhotos({
  org,
  assetId,
  eventId,
  type,
}: {
  org: string;
  assetId?: string;
  eventId?: string;
  type: "asset" | "event";
}) {
  const fetchItemPhotos = useCallback(async () => {
    const url =
      type === "event"
        ? `/${org}/assetevents/${eventId}/photos`
        : `/${org}/assets/${assetId}/photos`;
    const res = await axios.get<string[]>(url);
    const all: ParsedImage[] = [];
    const photoIds: string[] = [];
    const reqs: Promise<AxiosResponse>[] = [];
    res.data.forEach(async (photoUrl) => {
      const imgReq = axios.get(photoUrl, {
        baseURL: "",
        responseType: "blob",
      });
      reqs.push(imgReq);
      photoIds.push(photoUrl.split("photos/")[1]);
    });
    const x = await Promise.all(reqs);
    const imgPromises: Promise<HTMLImageElement>[] = [];
    x.forEach((value) => {
      imgPromises.push(blobToImage(value.data));
    });
    const imgResponses = await Promise.all(imgPromises);
    imgResponses.forEach((image, i) => {
      const pid = photoIds[i];
      // create object to pass to the image viewer
      // but also include source image
      all.push({
        image,
        src: image.src,
        w: image.width,
        h: image.height,
        id: pid,
      });
    });
    return all;
  }, [assetId, eventId, org, type]);

  const query = useQuery({
    queryKey:
      type === "event"
        ? eventKeys.photosById(org, eventId)
        : assetKeys.photosById(org, assetId),
    queryFn: fetchItemPhotos,
    enabled: !!org && !!(type === "event" ? eventId : assetId),
  });

  return query;
}
