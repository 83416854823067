import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Unstable_Grid2";
import { useTranslation } from "react-i18next";
import MapThemeItem from "components/MapThemeItem";
import TotalMassBarChart from "components/MapThemeItem/TotalMassBarChart";
import { useOrgState } from "providers";
import { useOperationMapThemes } from "api/operations/useOperationMapThemes";

const emptyData = [{ name: "--", percent: 100 }];

export default function OperationMapThemes({
  operationType,
  onThemeChange,
  activeType,
}) {
  const { t } = useTranslation();
  function handleThemeSelect(e) {
    onThemeChange(e);
  }
  const { org, season } = useOrgState();
  const themeQ = useOperationMapThemes({
    org,
    seasonId: season?.id,
    type: operationType,
  });

  return (
    <Grid container mx={2}>
      <Grid sm={operationType === "harvested" ? 4 : 6}>
        {operationType === "planted" || operationType === "applied" ? (
          <MapThemeItem
            id="product"
            title={t("common.product")}
            isActive={activeType === "product"}
            data={
              themeQ.data?.rollup?.product?.length
                ? themeQ.data?.rollup?.product
                : emptyData
            }
            showTooltip={Boolean(themeQ.data?.rollup?.product?.length)}
            onClick={handleThemeSelect}
          />
        ) : null}
        {operationType === "harvested" ? (
          <MapThemeItem
            id="crop"
            title={t("common.crop")}
            isActive={activeType === "crop"}
            data={
              themeQ.data?.rollup?.crop?.length
                ? themeQ.data?.rollup?.crop
                : emptyData
            }
            showTooltip={Boolean(themeQ.data?.rollup?.crop?.length)}
            onClick={handleThemeSelect}
          />
        ) : null}
        {operationType === "tilled" ? (
          <MapThemeItem
            id="tilled-type"
            title={t("common.type")}
            isActive
            showTooltip={Boolean(themeQ.data?.rollup?.tilledType?.length)}
            data={
              themeQ.data?.rollup?.tilledType?.length
                ? themeQ.data?.rollup?.tilledType
                : emptyData
            }
            showActiveBorder={false}
            onClick={handleThemeSelect}
          />
        ) : null}
      </Grid>
      <Grid sm={operationType === "harvested" ? 8 : 6}>
        {operationType === "planted" ? (
          <MapThemeItem
            id="crop"
            title={t("common.crop")}
            isActive={activeType === "crop"}
            data={
              themeQ.data?.rollup?.crop?.length
                ? themeQ.data?.rollup?.crop
                : emptyData
            }
            showTooltip={Boolean(themeQ.data?.rollup?.crop?.length)}
            onClick={handleThemeSelect}
          />
        ) : null}
        {operationType === "applied" ? (
          <MapThemeItem
            id="productType"
            title={t("common.type")}
            isActive={activeType === "productType"}
            data={
              themeQ.data?.rollup?.productType?.length
                ? themeQ.data?.rollup?.productType
                : emptyData
            }
            showTooltip={Boolean(themeQ.data?.rollup?.productType?.length)}
            onClick={handleThemeSelect}
          />
        ) : null}
        {operationType === "harvested" ? (
          <TotalMassBarChart
            id="totalMass"
            title={t("common.totalMass")}
            isActive={false}
            data={themeQ.data?.rollup?.crop || emptyData}
          />
        ) : null}
      </Grid>
    </Grid>
  );
}
OperationMapThemes.defaultProps = {
  operationType: "",
  themeData: null,
  activeType: null,
};

OperationMapThemes.propTypes = {
  onThemeChange: PropTypes.func.isRequired,
  activeType: PropTypes.oneOf(["crop", "product", "productType", "tilledType"]),
  operationType: PropTypes.string,
  themeData: PropTypes.shape({
    crop: PropTypes.arrayOf(PropTypes.shape({})),
    product: PropTypes.arrayOf(PropTypes.shape({})),
    productType: PropTypes.arrayOf(PropTypes.shape({})),
    tilledType: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};
