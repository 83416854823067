import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { useParams } from "react-router-dom";
import { ICollectEvent, IField, IRasterType, IUrlParams } from "types";
import { useOrgState } from "providers";
import { GeoJSONSource, Map } from "mapbox-gl";
import LoadingButton from "components/LoadingButton";
import RasterSelects from "./RasterSelects";
import { UseQueryResult } from "@tanstack/react-query";
import { IFieldLookup, ProductLookup, SensorLookup } from "../types";
import CropFarmField from "../Yield/CropFarmField";
import { useInsightsFieldLookup } from "../useInsightsFieldLookup";
import ApplicationAndSeedSelect from "../ApplicationAndSeedSelect";
import { TextField } from "@mui/material";
import { useProductsPlanted } from "../useProductsPlanted";
import { useProductsApplied } from "../useProductsApplied";
import { Feature, FeatureCollection, Geometry, Polygon } from "geojson";
import { useFieldsState } from "providers/FieldsProvider";
import { useProductGeometryIntersection } from "../useProductGeometryIntersection";
import { fitBounds } from "lib/MapboxMap";
import { getBufferGeom } from "../Yield/utils";
import { useOperationAppliedBoundary } from "../useOperationAppliedBoundary";
import { useOperationPlantedBoundaries } from "../useOperationPlantedBoundaries";

function getFc() {
  const fc = {
    type: "FeatureCollection",
    features: [],
  } as FeatureCollection;
  return fc;
}

export default function ImageryInsightsByProduct({
  chartDataQ,
  mapRef,
  onFieldChange,
  onCollectEventChange,
  onProductChange,
  onSubmit,
}: {
  chartDataQ: UseQueryResult<unknown, Error>;
  mapRef?: Map;
  onFieldChange?: (_d: Feature<Polygon, IFieldLookup>) => void;
  onCollectEventChange?: () => void;
  onProductChange?: () => void;
  onSubmit: (_e: {
    fieldId: string;
    fieldBuffer: string;
    sensorType: string;
    collectEventId: string;
    rasterOptions: string[];
  }) => void;
}) {
  const { org } = useParams<IUrlParams>();
  const { season } = useOrgState();
  const { t } = useTranslation();
  const { fieldsGeodataState } = useFieldsState();
  const fieldLookupsQ = useInsightsFieldLookup(
    org,
    "imagery/productplantedapplied",
    season?.id
  );
  const [fieldBufferSize, setFieldBufferSize] = useState<number>();
  const [fieldBufferGeom, setFieldBufferGeom] = useState<Polygon>();
  const [selectedField, setSelectedField] = useState<
    Feature<Geometry, IField>
  >();

  const [selectedCollectEvent, setSelectedCollectEvent] = useState<
    ICollectEvent
  >();
  const [selectedApplication, setSelectedApplication] = useState<
    ProductLookup
  >();
  const [selectedSeed, setSelectedSeed] = useState<ProductLookup>();
  const [selectedSensor, setSelectedSensor] = useState<SensorLookup>();
  const [selectedRasterTypes, setSelectedRasterTypes] = useState<
    IRasterType[]
  >();
  const plantedItemsQ = useProductsPlanted(
    org,
    "imagery",
    selectedField?.properties?.id
  );
  const appliedItemsQ = useProductsApplied(
    org,
    "imagery",
    selectedField?.properties?.id
  );
  const productGeomQ = useProductGeometryIntersection({
    application: selectedApplication,
    seed: selectedSeed,
    org,
  });
  const appliedOpQ = useOperationAppliedBoundary({
    org,
    appliedOperation: selectedApplication,
  });
  const plantedOpQ = useOperationPlantedBoundaries({
    org,
    products: selectedSeed ? [selectedSeed] : null,
  });

  const addBufferLayer = useCallback(
    (bufferSize, geojson, source) => {
      const tolerance = 0.00001;
      if ((bufferSize >= 0 || bufferSize <= 0) && geojson && mapRef) {
        const params = {
          size: bufferSize,
          geojson,
          tolerance,
          units: "meters",
        };
        const bufferFtr = getBufferGeom(params);
        const bufferGeom = bufferFtr?.geometry;

        const src = mapRef.getSource(source) as GeoJSONSource;
        src.setData(bufferGeom || getFc());
        setFieldBufferGeom(bufferGeom);
        return bufferGeom;
      }
      if ((!bufferSize || !geojson) && mapRef) {
        const src = mapRef.getSource(source) as GeoJSONSource;
        src.setData(getFc());
        setFieldBufferGeom(null);
        return null;
      }
      setFieldBufferGeom(null);
      return null;
    },
    [mapRef]
  );

  // handle operations change
  useEffect(() => {
    if (mapRef) {
      const fc = getFc();
      if (appliedOpQ.data) {
        fc.features.push(appliedOpQ.data);
      }
      if (plantedOpQ.data) {
        fc.features.push(...plantedOpQ.data);
      }
      const src = mapRef.getSource("operations-src") as GeoJSONSource;
      if (src) {
        src.setData(fc);
      }
    }
  }, [appliedOpQ.data, mapRef, plantedOpQ.data]);

  return (
    <>
      <h3 style={{ marginTop: "1rem" }}>{t("common.include")}</h3>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          const d = {
            fieldId: selectedField?.properties?.id,
            geometry: productGeomQ.data?.geometry
              ? JSON.stringify(productGeomQ.data?.geometry)
              : "",
            fieldBuffer: fieldBufferGeom
              ? JSON.stringify(fieldBufferGeom)
              : null,
            sensorType: selectedSensor?.slug,
            collectEventId: selectedCollectEvent?.id,
            rasterOptions: selectedRasterTypes?.map((r) => r.slug),
          };
          onSubmit(d);
        }}
      >
        <Stack spacing={2} sx={{ mt: 3 }}>
          <CropFarmField
            fields={fieldLookupsQ.data}
            onFieldChange={(item) => {
              const ftr = fieldsGeodataState.data?.features?.find(
                (d: Feature) => d.properties.id === item?.fieldId
              );
              setSelectedField(ftr);
              onFieldChange(ftr ?? item);
              addBufferLayer(fieldBufferSize * -1, ftr, "field-buff-src");
              const geom = ftr?.geometry;
              if (geom && mapRef) {
                fitBounds({
                  map: mapRef,
                  geojson: geom,
                });
              }
            }}
          />
          <ApplicationAndSeedSelect
            onChange={(d) => {
              if (Object.hasOwn(d, "application")) {
                setSelectedApplication(d.application);
              }
              if (Object.hasOwn(d, "seed")) {
                setSelectedSeed(d.seed);
              }
              onProductChange();
            }}
            value={{
              application: selectedApplication,
              seed: selectedSeed,
            }}
            applicationProducts={appliedItemsQ.data}
            seedProducts={plantedItemsQ.data}
          />
          <RasterSelects
            filterGeometry={selectedField?.geometry}
            onChange={(d) => {
              if (Object.hasOwn(d, "sensor")) {
                setSelectedSensor(d.sensor);
              }
              if (Object.hasOwn(d, "collectEvent")) {
                setSelectedCollectEvent(d.collectEvent);
              }
              if (Object.hasOwn(d, "rasterOptions")) {
                setSelectedRasterTypes(d.rasterOptions);
              }
              onCollectEventChange();
            }}
          />
        </Stack>
        <Divider sx={{ my: 2 }} />
        <div>
          <h3>{t("common.exclude")}</h3>
          <div>
            <TextField
              name="fieldBuffer"
              sx={{ mt: 2 }}
              fullWidth
              value={fieldBufferSize ?? ""}
              id="field-buffer-input"
              InputProps={{
                endAdornment: <Box sx={{ ml: 1 }}>{t("common.meters")}</Box>,
              }}
              inputProps={{
                min: 0,
                max: 1000,
              }}
              label={t("insights.yield.fieldBuffer")}
              onChange={(e) => {
                const newBufferSize = Number(e.target.value);
                setFieldBufferSize(newBufferSize);
                addBufferLayer(
                  newBufferSize * -1,
                  selectedField,
                  "field-buff-src"
                );
              }}
              placeholder="0"
              type="number"
              helperText={t("insights.yield.excludeLowConfidenceYield")}
            />
          </div>
          <Divider sx={{ my: 2 }} />
        </div>
        <Box>
          <LoadingButton
            color="primary"
            variant="contained"
            type="submit"
            isLoading={chartDataQ.isFetching}
          >
            {t("insights.yield.reportIt")}
          </LoadingButton>
        </Box>
        {chartDataQ.isError ? (
          <Alert severity="error">
            {chartDataQ.error ?? t("common.somethingUnexpected")}
          </Alert>
        ) : null}
      </form>
    </>
  );
}
