import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { ISeason } from "types";

export default function useSeasonsByOrg(org: string) {
  async function getSeasons() {
    const res = await axios.get<ISeason[]>(`/${org}/seasons`);
    res.data?.forEach((sr) => {
      sr.value = sr.id;
      sr.label = sr.name;
    });
    return res.data;
  }
  return useQuery({
    queryKey: ["organizations", org, "seasons"],
    queryFn: getSeasons,
    enabled: !!org,
  });
}
