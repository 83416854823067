import Card, { CardProps } from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActionArea } from "@mui/material";
import React from "react";

export default function BaseCardView({
  href,
  children,
  ...rest
}: {
  href?: string;
} & CardProps) {
  return (
    <Card
      sx={{
        bgcolor: !rest.variant ? "background.paper" : undefined,
      }}
      {...rest}
    >
      {href || rest.onClick ? (
        <CardActionArea
          {...(href ? { href } : {})}
          disableRipple
          sx={{ height: "100%" }}
        >
          <CardContent sx={{ height: "100%" }}>{children}</CardContent>
        </CardActionArea>
      ) : (
        <CardContent sx={{ height: "100%" }}>{children}</CardContent>
      )}
    </Card>
  );
}
