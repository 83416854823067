import React from "react";

export default function LegendSymbol({
  geometryType = "Polygon",
  borderColor,
  backgroundColor,
  borderWidth,
}: {
  geometryType?:
    | "Polygon"
    | "MultiPolygon"
    | "Point"
    | "MultiPoint"
    | "LineString"
    | "MultiLineString";
  borderColor?: string;
  backgroundColor?: string;
  borderWidth?: number;
}) {
  return (
    <div
      style={{
        marginLeft: "0.25rem",
        border: `solid ${borderWidth}px ${borderColor}`,
        borderRadius:
          geometryType === "Point" || geometryType === "MultiPoint"
            ? "1rem"
            : 0,
        height:
          geometryType === "LineString" || geometryType === "MultiLineString"
            ? "3px"
            : "16px",

        width: "16px",
        backgroundColor: `${backgroundColor}`,
      }}
    />
  );
}
