import { Theme } from "@mui/material";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";

export default function ButtonControl(props: IconButtonProps) {
  return (
    <IconButton
      sx={{
        borderRadius: "0.25rem",
        height: "32px",
        width: "32px",
        padding: 0,
        "&:hover": (theme: Theme) => ({
          backgroundColor:
            theme.palette.mode === "dark"
              ? "#23272b !important"
              : "#d9d9d9 !important",
        }),
        backgroundColor: (t: Theme) =>
          t.palette.mode === "dark" ? "#343a40 !important" : "#fff !important",
      }}
      {...props}
    />
  );
}
