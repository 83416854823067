import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { useOrgState } from "providers/OrgProvider";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { DataTable } from "components";
import IconButton from "@mui/material/IconButton";
import { EditIconButton } from "components/EditIconButton";
import { ColumnDef } from "@tanstack/react-table";
import { useYieldFilters } from "api/useYieldFilters";

export function DataFiltersTable() {
  const { t } = useTranslation();
  const { org, season } = useOrgState();
  const yieldFiltersQ = useYieldFilters({ org, seasonId: season?.id });
  const columns: Omit<
    ColumnDef<{ id: string }> & {
      isSortable?: boolean;
      Header: unknown;
      accessor:
        | string
        | ((_r: {
            cropTypeId: string;
            yieldMax: number;
            yieldMin: number;
            yieldUom: string;
            speedMin: number;
            speedMax: number;
            speedUom: string;
          }) => void);
    },
    "accesorFn"
  >[] = [
    {
      Header: "",
      id: "actions",
      isSortable: false,
      accessor: function Cell(row) {
        return (
          <EditIconButton
            href={`settings/filters/edit/${row.cropTypeId}`}
            size="small"
            sx={{ m: 1 }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        );
      },
    },
    {
      Header: t("common.cropType"),
      accessor: "crop",
    },
    {
      Header: t("common.yieldMin"),
      id: "yieldMin",
      accessor: (row) => {
        return row.yieldMin ? `${row.yieldMin} ${row.yieldUom}` : "--";
      },
    },
    {
      Header: t("common.yieldMax"),
      id: "yieldMax",
      accessor: (row) => {
        return row.yieldMax ? `${row.yieldMax} ${row.yieldUom}` : "--";
      },
    },
    {
      Header: t("common.speedMin"),
      id: "speedMin",
      accessor: (row) => {
        return row.speedMin ? `${row.speedMin} ${row.speedUom}` : "--";
      },
    },
    {
      Header: t("common.speedMax"),
      id: "speedMax",
      accessor: (row) => {
        return row.speedMax ? `${row.speedMax} ${row.speedUom}` : "--";
      },
    },
  ];

  return (
    <Box sx={{ ".cai-table": { p: "0 !important" } }}>
      <DataTable
        components={{
          HeaderRight: (
            <>
              <IconButton
                sx={{
                  ml: "auto",
                  "&:hover": { color: "success.main" },
                }}
                color="success"
                href={`settings/filters/add`}
              >
                <AddOutlinedIcon />
              </IconButton>
            </>
          ),
        }}
        isLoading={yieldFiltersQ.isLoading}
        isFilterable={false}
        data={yieldFiltersQ.data}
        columns={columns}
      />
    </Box>
  );
}
