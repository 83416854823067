import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { BBox, Feature, FeatureCollection } from "geojson";
import { bbox } from "lib/MapboxMap";
import { Observation } from "types/Observation";
import observationKeys from "./observationKeys";

export function useObservationsGeodata({
  enabled,
  org,
  fieldIds,
  templateId,
  observations,
}: {
  enabled?: boolean;
  org: string;
  templateId?: string;
  fieldIds?: string[];
  observations?: Observation[];
}) {
  const fetchObservationsGeodataFn = useCallback(async () => {
    if (enabled === false) {
      return null;
    }
    if (fieldIds?.length) {
      const reqs = fieldIds?.map((fid) =>
        axios.get<FeatureCollection>(
          `/${org}/observations/geodata/templates/${templateId}/fields${
            fid ? `/${fid}` : ""
          }`
        )
      );
      const responses = await Promise.all(reqs);
      const features: Feature[] = [];
      responses.forEach((res) => {
        const fc = res.data;
        if (fc?.features) {
          const newFtrs = [...res.data?.features].map((f) => {
            const list = observations?.find((o) => o.id === f.properties.id);
            return {
              ...f,
              properties: {
                ...f.properties,
                color: "#000",
                ...list,
              },
            };
          });
          features.push(...newFtrs);
        }
      });
      if (!features.length) {
        return null;
      }
      const newFc = {
        type: "FeatureCollection",
        features,
      } as FeatureCollection;
      const bb = bbox(newFc) as BBox;
      newFc.bbox = bb;
      return newFc;
    } else if (templateId) {
      const res = await axios.get<FeatureCollection>(
        `/${org}/observations/geodata/templates/${templateId}/fields`
      );
      const newFtrs = [...res.data?.features].map((f) => {
        const list = observations?.find((o) => o.id === f.properties.id);
        return {
          ...f,
          properties: {
            ...f.properties,
            color: "#000",
            ...list,
          },
        };
      });
      return {
        type: "FeatureCollection",
        features: newFtrs,
      } as FeatureCollection;
    }
  }, [enabled, fieldIds, observations, org, templateId]);

  return useQuery({
    queryKey: observationKeys.geodata(
      org,
      `${templateId},
      ${fieldIds},
      ${enabled}`
    ),
    queryFn: fetchObservationsGeodataFn,
    enabled: !!(enabled !== false) && !!org && !!templateId,
  });
}
