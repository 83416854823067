import { useCallback, useMemo } from "react";
import useFetchWithToken from "components/useFetchWithToken";
import { useQuery } from "@tanstack/react-query";
import { IRole } from "types";

export function useAccountProfile(org: string) {
  const queryKey = useMemo(() => ["organization", org, "account", "profile"], [
    org,
  ]);
  const { fetchData: fetchAccountProfile } = useFetchWithToken<IRole>();

  const getSubtypeSummaryList = useCallback(async () => {
    const url = `/${org}/myaccount/profile`;
    const res = await fetchAccountProfile(url);
    if (res?.isError) {
      throw Error(res.errorMessage);
    }
    return res?.data ?? null;
  }, [fetchAccountProfile, org]);

  return useQuery<IRole>({
    queryKey,
    queryFn: getSubtypeSummaryList,
    enabled: Boolean(org),
  });
}
