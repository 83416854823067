import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import PieChartComponent from "../Charts/PieChart";

export default function MapThemePie({
  data,
  isActive,
  onClick,
  id,
  title,
  showTooltip,
  showActiveBorder,
}) {
  let borderBottom = "solid 4px transparent";
  if (!showActiveBorder) {
    borderBottom = "none";
  } else if (isActive) {
    borderBottom = "solid 4px";
  }
  return (
    <>
      <Button
        disableRipple
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          color: "inherit",
        }}
        className="no-styles"
        onClick={() => {
          onClick(id);
        }}
      >
        <h3 title={title} style={{ margin: 0, textTransform: "none" }}>
          {title}
        </h3>
        <div
          style={{
            height: "150px",
            width: "150px",
            borderBottom,
          }}
        >
          <PieChartComponent
            isActive={isActive}
            data={data}
            dataKey="percent"
            showTooltip={showTooltip}
          />
        </div>
      </Button>
    </>
  );
}
MapThemePie.defaultProps = {
  data: null,
  isActive: false,
  onClick: () => {},
  showTooltip: true,
  showActiveBorder: true,
};
MapThemePie.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  showTooltip: PropTypes.bool,
  showActiveBorder: PropTypes.bool,
};
