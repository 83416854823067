import { useQuery } from "@tanstack/react-query";
import { useAssetSubtype } from "api/assets/useAssetSubtype";
import { useAssetsBySubtypeSummary } from "api/assets/useAssetSummaries";
import { useCallback } from "react";

export function useFieldData({
  fieldId,
  org,
  seasonId,
  subtypeName,
  type,
}: {
  fieldId: string;
  org: string;
  seasonId: string;
  subtypeName: string;
  type: string;
}) {
  const subtypeQ = useAssetSubtype({
    org,
    type,
    name: subtypeName,
  });

  const assetsBySubtypeQuery = useAssetsBySubtypeSummary(
    org,
    type,
    subtypeQ.data?.subtypeId,
    seasonId
  );

  const getItem = useCallback(async () => {
    const fieldObj =
      fieldId && assetsBySubtypeQuery?.data
        ? assetsBySubtypeQuery?.data?.find(
            (f: { fieldId: string }) =>
              (fieldId === "null" && !f.fieldId) || f.fieldId === fieldId
          )
        : null;

    return fieldObj;
  }, [assetsBySubtypeQuery.data, fieldId]);

  return useQuery({
    queryKey: ["organization", org, type, subtypeName, fieldId, seasonId],
    queryFn: getItem,
    enabled: Boolean(
      assetsBySubtypeQuery.isFetched && org && fieldId && seasonId
    ),
  });
}
