import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import lookupKeys from "../lookups/lookupKeys";

export function useAssetStatuses(org: string) {
  const getData = useCallback(async () => {
    const res = await axios.get<
      { id: string; name: string; label: string; value: string }[]
    >(`/${org}/lookups/assetstatuses`);
    res.data.forEach((d) => {
      d.label = d.name;
      d.value = d.id;
    });
    return res.data;
  }, [org]);

  return useQuery({
    queryKey: lookupKeys.byType(org, "assetStatuses"),
    queryFn: getData,
    enabled: !!org,
  });
}
