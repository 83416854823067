import { useCallback } from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { ProductLookup, YieldType } from "./types";

const keys = {
  all: (org: string, type: string, fieldId: string) =>
    [
      "organization",
      org,
      "yield-insights",
      "products",
      "applied",
      type,
      "field",
      fieldId,
    ] as const,
};

export function useProductsApplied(
  org: string,
  yieldType: YieldType | "imagery",
  fieldId?: string
) {
  const getProductsApplied = useCallback(async () => {
    const url = `/${org}/insights/yield${
      yieldType === "imagery" ? "/imagery" : ""
    }/productplantedapplied/products/applied`;
    const res = await axios.post<ProductLookup[]>(url, { fieldIds: [fieldId] });
    res.data.forEach((r) => {
      r.date = new Date(r.dateUtc).toLocaleDateString();
    });
    return res.data;
  }, [fieldId, org, yieldType]);

  return useQuery<ProductLookup[], Error>({
    queryKey: keys.all(org, yieldType, fieldId),
    queryFn: getProductsApplied,
    enabled:
      !!org &&
      ["productplantedapplied", "imagery"].includes(yieldType) &&
      !!fieldId,
  });
}
