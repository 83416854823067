import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useOrgState } from "providers/OrgProvider";
import {
  useInventoryDispatch,
  useInventoryState,
} from "providers/InventoryProvider";
import { useFieldsState } from "providers/FieldsProvider";
import { FormWrapper } from "components";
import GeodataUpload from "views/InventoryView/GeodataUpload";
import Autocomplete from "lib/Select/Autocomplete";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useLandAgreementTypes } from "api/lookups/useLandAgreementTypes";
import { useFieldMutations } from "api/fields/useFieldMutations";
import { useFarms } from "api/useFarms";

const defaultValues = {
  id: null,
  name: null,
  geometry: null,
  landAgreementTypeId: null,
  farmId: null,
  gpsSourceTypeId: "e9aff444-911c-40a3-8222-614d70162d38",
  seasonId: null,
};

export default function FieldForm() {
  const { t } = useTranslation();
  const history = useHistory();
  const { editId } = useParams();
  const { org, season, rootUrl } = useOrgState();
  const landAgreementTypesQ = useLandAgreementTypes({ org });
  const { fieldsGeodataState } = useFieldsState();
  const currentEditFtr = editId
    ? fieldsGeodataState?.data?.features?.find((f) => f.id === editId)
    : null;

  const farmsQ = useFarms(org);
  // const { fetchFieldsSummary } = useOrgDispatch();
  const { saveMutation, deleteMutation } = useFieldMutations({
    org,
    seasonId: season?.id,
  });
  const { drawData } = useInventoryState();
  const { setOutsideDrawData, fitInventoryBounds } = useInventoryDispatch();
  const [uploadError, setUploadError] = useState();

  const methods = useForm({
    defaultValues: { ...defaultValues, ...{ seasonId: season?.id } },
  });
  const {
    getValues,
    handleSubmit,
    register,
    reset,
    formState,
    watch,
    control,
    errors,
    setValue,
  } = methods;
  const { description, id, cid, name } = watch();

  async function handleSave(d) {
    if (!formState.isDirty) {
      history.push(`${rootUrl}/inventory/fields`);
      return false;
    }
    // d.geometry = d.geometry ? JSON.parse(d.geometry) : "";
    // server doesn't want these props if not set
    if (!d.id) {
      delete d.id;
    }
    if (!d.farmId) {
      delete d.farmId;
    }
    if (!d.landAgreementTypeId) {
      delete d.landAgreementTypeId;
    }
    const res = await saveMutation.mutateAsync(d, {
      onSuccess: () => {
        reset({ ...defaultValues, ...d });
        history.push(`${rootUrl}/inventory/fields`);
      },
    });
    return res;
  }

  const [createType, setCreateType] = useState("draw");

  // cleanup
  useEffect(() => {
    return setOutsideDrawData;
  }, [setOutsideDrawData]);

  return (
    <FormWrapper
      methods={methods}
      data={{ ...currentEditFtr?.properties }}
      geometryData={drawData?.features[0]?.geometry}
      existingGeom={currentEditFtr?.geometry}
      cancelHref={`${rootUrl}/inventory/fields`}
      saveState={{ ...saveMutation, errorMessage: saveMutation.error?.message }}
      deleteState={{
        ...deleteMutation,
        errorMessage: deleteMutation.error,
      }}
      onDelete={() => {
        deleteMutation.mutate(id, {
          onSuccess: () => {
            reset(defaultValues);
            history.push(`${rootUrl}/inventory/fields`);
          },
        });
      }}
      onSubmit={handleSubmit(handleSave)}
    >
      <input ref={register} required type="hidden" name="gpsSourceTypeId" />
      <input ref={register} required type="hidden" name="seasonId" />

      <Stack sx={{ mt: 2 }} gap={2}>
        <TextField
          label={`${t("common.name")}`}
          id="field-name"
          value={name ?? ""}
          inputProps={{
            ref: register,
            maxLength: 50,
            name: "name",
          }}
          required
        />
        <Controller
          name="farmId"
          control={control}
          render={(props) => (
            <Autocomplete
              id="farm-select"
              disableClearable={false}
              label={t("common.farm")}
              options={farmsQ.data}
              // eslint-disable-next-line react/prop-types
              value={farmsQ.data?.find((f) => f.id === props.value) || null}
              onChange={(_e, item) => {
                // eslint-disable-next-line react/prop-types
                props.onChange(item?.id || "");
              }}
            />
          )}
        />
        <Controller
          name="landAgreementTypeId"
          rules={{ required: true }}
          control={control}
          render={(props) => (
            <>
              <Autocomplete
                id="land-agreement-select"
                disableClearable={false}
                InputProps={{ required: true }}
                label={`${t("inventory.fields.landAgreement")} *`}
                options={landAgreementTypesQ.data || []}
                value={
                  landAgreementTypesQ.data?.find(
                    // eslint-disable-next-line react/prop-types
                    (f) => f.id === props.value
                  ) ?? null
                }
                onChange={(_e, item) => {
                  // eslint-disable-next-line react/prop-types
                  props.onChange(item?.id || "");
                }}
              />
            </>
          )}
        />
        <TextField
          fullWidth
          label={`ID`}
          value={cid ?? ""}
          inputProps={{ ref: register, maxLength: 255, name: "cid" }}
          id="cid"
        />
        <TextField
          fullWidth
          value={description ?? ""}
          label={t("common.notes")}
          multiline
          inputProps={{
            name: "description",
            maxLength: 155,
            ref: register,
          }}
          id="desc-notes"
        />
        <FormControl>
          <FormLabel
            sx={{ color: "inherit !important" }}
            id="draw-field-radio-label"
          >
            {t("inventory.fields.createFieldBoundary")}
          </FormLabel>
          <RadioGroup
            aria-labelledby="draw-field-radio-label"
            defaultValue="draw"
            value={createType ?? "draw"}
            onChange={(e) => {
              setUploadError("");
              setCreateType(e.target.value);
            }}
            name="draw-type"
          >
            <FormControlLabel
              value="draw"
              control={<Radio />}
              label={t("inventory.fields.drawField")}
              sx={{ m: 0 }}
            />
            <FormControlLabel
              value="layer"
              control={<Radio />}
              label={`${t("inventory.fields.uploadBoundaryFile")} [.geojson]`}
              sx={{ m: 0 }}
            />
          </RadioGroup>
          {createType === "layer" ? (
            <GeodataUpload
              type="Polygon"
              onChange={(geojson) => {
                setUploadError("");
                if (geojson) {
                  setOutsideDrawData(geojson);
                  fitInventoryBounds({ geojson });
                  const props = geojson.features.length
                    ? geojson.features[0].properties
                    : {};
                  const keys = Object.keys(props);
                  keys.forEach((k) => {
                    if (
                      [
                        "cid",
                        "description",
                        "farmId",
                        "landAgreementTypeId",
                        "name",
                      ].includes(k) &&
                      !getValues(k)
                    )
                      setValue(k, props[k]);
                  });
                }
              }}
              onError={(e) => {
                setUploadError(e);
              }}
            />
          ) : null}
        </FormControl>
        {errors?.geometry || uploadError ? (
          <Alert severity="error">
            {uploadError || t("common.geometryRequired")}
          </Alert>
        ) : null}
      </Stack>
    </FormWrapper>
  );
}
