import axios from "axios";
import { useCallback } from "react";
import {
  MutateOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import observationKeys from "./observationKeys";

export function useObservationDelete({ org }: { org: string }) {
  const queryClient = useQueryClient();
  const deleteObservationFn = useCallback(
    (id) => {
      return axios.delete(`/${org}/observations/geodata/${id}`);
    },
    [org]
  );
  const mutation = useMutation({
    mutationFn: deleteObservationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: observationKeys.all(org),
      });
    },
  });
  return {
    ...mutation,
    deleteObservation: (id: string, opts?: MutateOptions) => {
      mutation.mutate(id, opts);
    },
  };
}
