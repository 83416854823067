import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import { Lock, Unlock } from "react-feather";
import polygonSvg from "../../icons/polygon_icon.svg";
import rectangleSvg from "../../icons/rectangle_icon.svg";
import deleteSvg from "../../icons/delete_icon.svg";
import lineSvg from "../../icons/line_icon.svg";
import pointSvg from "../../icons/point_icon.svg";
import { ThemeProvider } from "lib";

function ControlButton(props) {
  return (
    <IconButton
      sx={{
        borderRadius: "0.25rem",
        height: "32px",
        width: "32px",
        padding: 0,
        img: (theme) => ({
          filter: `brightness(0) invert(${
            theme.palette.mode === "light" ? "0" : "1"
          })`,
        }),

        "&:hover": (theme) => ({
          backgroundColor:
            theme.palette.mode === "dark"
              ? "#23272b !important"
              : "#d9d9d9 !important",
        }),
        backgroundColor: (t) =>
          t.palette.mode === "dark" ? "#343a40 !important" : "#fff !important",
      }}
      {...props}
    />
  );
}

function DrawToolbar({
  onDrawPolygon,
  onDrawRectangle,
  onDrawLineString,
  onDrawPoint,
  onDelete,
  onCombine,
  onUncombine,
  controls,
}) {
  return (
    <Stack
      sx={{
        button: {
          ":not(:first-of-type)": {
            borderTop: (t) =>
              `solid 1px ${t.palette.mode === "dark" ? "#000" : "#ccc"}`,
          },
          ":first-of-type": {
            borderBottomRightRadius: "0 !important",
            borderBottomLeftRadius: "0 !important",
          },
          ":not(:first-of-type):not(:last-child)": {
            borderRadius: 0,
          },
          ":last-child": {
            borderTopRightRadius: "0 !important",
            borderTopLeftRadius: "0 !important",
          },
        },
      }}
      className="mapboxgl-ctrl cai-mapboxgl-ctrl"
    >
      {controls.rectangle && (
        <ControlButton title="Draw rectangle" onClick={onDrawRectangle}>
          <img alt="Draw rectangle" src={rectangleSvg} />
        </ControlButton>
      )}
      {controls.polygon && (
        <ControlButton title="Draw polygon" onClick={onDrawPolygon}>
          <img alt="Draw polygon" src={polygonSvg} />
        </ControlButton>
      )}
      {controls.line_string && (
        <ControlButton title="Draw line" onClick={onDrawLineString}>
          <img alt="Draw line" src={lineSvg} />
        </ControlButton>
      )}
      {controls.point && (
        <ControlButton title="Draw point" onClick={onDrawPoint}>
          <img alt="Draw point" src={pointSvg} />
        </ControlButton>
      )}
      {controls.trash && (
        <ControlButton title="Delete selected" onClick={onDelete}>
          <img alt="Delete selected" src={deleteSvg} />
        </ControlButton>
      )}
      {controls.combine && (
        <ControlButton onClick={onCombine}>
          <Lock size={16} />
        </ControlButton>
      )}
      {controls.uncombine && (
        <ControlButton onClick={onUncombine}>
          <Unlock size={16} />
        </ControlButton>
      )}
    </Stack>
  );
}

DrawToolbar.defaultProps = {
  onDrawPolygon: () => {},
  onDrawRectangle: () => {},
  onDrawLineString: () => {},
  onDrawPoint: () => {},
  onDelete: () => {},
  onCombine: () => {},
  onUncombine: () => {},
  controls: {
    trash: true,
    polygon: true,
    line_string: true,
    point: true,
    combine: true,
    uncombine: true,
  },
};

DrawToolbar.propTypes = {
  onDrawPolygon: PropTypes.func,
  onDrawRectangle: PropTypes.func,
  onDrawLineString: PropTypes.func,
  onDrawPoint: PropTypes.func,
  onDelete: PropTypes.func,
  onCombine: PropTypes.func,
  onUncombine: PropTypes.func,
  controls: PropTypes.shape({
    trash: PropTypes.bool,
    polygon: PropTypes.bool,
    rectangle: PropTypes.bool,
    point: PropTypes.bool,
    line_string: PropTypes.bool,
    combine: PropTypes.bool,
    uncombine: PropTypes.bool,
  }),
};

class DrawControl {
  constructor(map, options) {
    this.options = options;
  }

  renderReactComponent() {
    ReactDOM.render(
      <ThemeProvider theme={this.options.theme}>
        <DrawToolbar
          controls={this.options.controls}
          onDrawPolygon={this.options.onDrawPolygon}
          onDrawRectangle={this.options.onDrawRectangle}
          onDrawLineString={this.options.onDrawLineString}
          onDrawPoint={this.options.onDrawPoint}
          onCombine={this.options.onCombine}
          onUncombine={this.options.onUncombine}
          onDelete={this.onDelete}
        />
      </ThemeProvider>,
      this.container
    );
  }

  onAdd(map) {
    this.map = map;
    this.handleKeydown = this.handleKeydown.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.container = document.createElement("div");
    this.map.getContainer().addEventListener("keydown", this.handleKeydown);
    // this.container.className =
    // "mapboxgl-ctrl cai-mapboxgl-ctrl cai-mapboxgl-ctrl-group";
    this.renderReactComponent();
    return this.container;
  }

  handleKeydown(e) {
    if (e.keyCode === 8 || e.keyCode === 46) {
      e.preventDefault();
      this.onDelete();
    }
  }

  updateOptions(options) {
    this.options = options;
    this.renderReactComponent();
  }

  onDelete() {
    if (this.options.onDelete) {
      this.options.onDelete();
    }
  }

  onRemove() {
    this.map.getContainer().removeEventListener("keydown", this.handleKeydown);
    ReactDOM.unmountComponentAtNode(this.container);
    this.container.parentNode.removeChild(this.container);
    this.map = undefined;
  }
}

export default DrawControl;
