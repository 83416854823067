import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Drawer from "@mui/material/Drawer";
import { Map } from "mapbox-gl";
import Box from "@mui/material/Box";
import MenuOpenOutlinedIcon from "@mui/icons-material/MenuOpenOutlined";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import EnterpriseLayersList from "./EnterpriseLayers/EnterpriseLayersList";
import ImageryTable from "./RasterLayersControl/ImageryTable";
import { useOrgState } from "providers/OrgProvider";
import { Typography } from "@mui/material";

export default function LayersDrawer({
  mapRef,
  onClose,
  show,
}: {
  mapRef: Map;
  onClose: () => void;
  show: boolean;
}) {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<"layers" | "imagery">("layers");
  const { configQuery } = useOrgState();

  const handleChange = (
    _event: React.SyntheticEvent,
    newValue: "layers" | "imagery"
  ) => {
    setActiveTab(newValue);
  };

  return (
    <Drawer
      sx={{
        zIndex: 1201,
      }}
      anchor={"right"}
      PaperProps={{
        sx: {
          maxWidth: "50%",
          width: 600,
          height: "100%",
        },
      }}
      ModalProps={{
        keepMounted: true,
      }}
      open={show}
      onClose={onClose}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          maxHeight: "100%",
          overflow: "auto",
          p: 3,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={onClose} sx={{ mr: 2 }}>
            <MenuOpenOutlinedIcon sx={{ transform: "rotate(180deg)" }} />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflow: "auto",
          }}
        >
          <TabContext value={activeTab}>
            <TabList onChange={handleChange} aria-label="layer tabs">
              <Tab label={t("map.layers")} value="layers" />
              <Tab label={t("imagery.title")} value="imagery" />
            </TabList>
            <TabPanel value="imagery" sx={{ height: "100%", overflow: "auto" }}>
              <ImageryTable map={mapRef} />
            </TabPanel>
            <TabPanel value="layers" sx={{ height: "100%", overflow: "auto" }}>
              <Stack rowGap={4}>
                {/* no enterprise layers, show no data */}
                {!configQuery.data?.layers?.length ? (
                  <Box>
                    <Typography>{t("common.noData")}</Typography>
                  </Box>
                ) : (
                  <EnterpriseLayersList map={mapRef} />
                )}
              </Stack>
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </Drawer>
  );
}
