import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import { useInventoryDispatch, useInventoryState } from "providers";
import FormWrapper from "components/Forms/FormWrapper";
import {
  AssetStatusIDFormField,
  BornOnUTCFormField,
  CIDFormField,
  CropTypeIDFormField,
  DescriptionFormField,
  FieldFormField,
} from "./FormFields";
import { IPlantAsset } from "types/IAssetType";
import { IUrlParams } from "types";
import { useOrgState } from "providers";
import { useParams } from "react-router-dom";
import { useAssetGeodataById } from "api/assets/useAssetGeodataById";
import { FeatureCollection } from "geojson";
import { useAssetStatuses } from "api/assets/useAssetStatuses";
import { useAssetMutation } from "api/assets/useAssetMutation";

const defaultValues = {
  id: "",
  fieldId: "",
  cropTypeId: "",
  bornOnUtc: "",
  description: "",
  cid: "",
  assetStatusId: "",
  geometry: "",
} as IPlantAsset;

export default function PlantAssetForm({
  cancelHref,
  onDelete,
  onSave,
}: {
  onSave?: (_r: FeatureCollection) => void;
  onDelete?: () => void;
  cancelHref: string;
}) {
  const { t } = useTranslation();
  const { setDrawOptions } = useInventoryDispatch();
  const { drawData } = useInventoryState();
  const { org, season } = useOrgState();
  const { itemId } = useParams<IUrlParams>();
  const { deleteMutation, saveMutation } = useAssetMutation({
    org,
    type: "plant",
  });
  const statusesQ = useAssetStatuses(org);
  const geodataQ = useAssetGeodataById({
    type: "plant",
    org,
    seasonId: season?.id,
    id: itemId,
  });
  const currentEditFtr = geodataQ.data?.features[0];

  const methods = useForm({
    defaultValues: { ...defaultValues },
  });
  const { handleSubmit, register, reset, watch, control, errors } = methods;
  const { id } = watch();

  useEffect(() => {
    setDrawOptions({
      controls: {
        trash: true,
        point: true,
      },
      defaultMode: "draw_point",
    });
  }, [setDrawOptions]);

  async function handleSave(d: IPlantAsset) {
    d.geometry = d.geometry ? JSON.parse(d.geometry) : "";
    // server doesn't want these props if not set
    if (!d.id) {
      delete d.id;
    }
    if (!d.fieldId) {
      delete d.fieldId;
    }
    if (!d.assetGroupId) {
      delete d.assetGroupId;
    }
    return saveMutation.mutate(d, {
      onSuccess: (res) => {
        reset(d);
        onSave(res.data);
      },
    });
  }
  return (
    <FormWrapper
      // TODO:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      methods={methods}
      data={{ ...currentEditFtr?.properties }}
      geometryData={drawData?.features[0]?.geometry}
      existingGeom={currentEditFtr?.geometry}
      cancelHref={cancelHref}
      saveState={{
        isLoading: saveMutation.isLoading,
        isError: saveMutation.isError,
        errorMessage: saveMutation?.error as string,
      }}
      deleteState={{
        isLoading: deleteMutation.isLoading,
        isError: deleteMutation.isError,
        errorMessage: deleteMutation?.error as string,
      }}
      onDelete={() => {
        return deleteMutation.mutate(id, {
          onSuccess: () => {
            reset();
            onDelete();
          },
        });
      }}
      onSubmit={handleSubmit(handleSave)}
    >
      <Stack spacing={2}>
        <FieldFormField control={control} />
        <CIDFormField register={register} required />
        <CropTypeIDFormField
          control={control}
          isError={Boolean(errors?.cropTypeId)}
        />
        <BornOnUTCFormField
          control={control}
          label={`${t("inventory.assets.plantedDate")}`}
        />
        <AssetStatusIDFormField
          control={control}
          assetStatusOpts={statusesQ.data}
          isError={Boolean(errors?.assetStatusId)}
        />
        <DescriptionFormField register={register} />
        {errors?.geometry ? (
          <Typography color="error.main">
            {t("common.geometryRequired")}
          </Typography>
        ) : null}
      </Stack>
    </FormWrapper>
  );
}
