import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { ThemeProvider } from "lib";
import Grid from "@mui/material/Unstable_Grid2";

export const CustomTooltip = ({ active, payload }) => {
  const { t } = useTranslation();
  if (active) {
    const { name, area, areaPercent, areaUom, count } = payload[0]?.payload;
    return (
      <ThemeProvider mode="light">
        <Paper
          elevation={4}
          style={{
            minWidth: "200px",
            padding: "0.5rem",
            opacity: 0.9,
          }}
        >
          <Typography
            variant="h6"
            style={{
              textAlign: "center",
              fontWeight: "bold",
            }}
          >{`${name}`}</Typography>
          <List
            component={Grid}
            container
            sx={{ m: 0, li: { "*": { m: 0 }, py: 0 } }}
          >
            <ListItem component={Grid} lg={6}>
              <ListItemText
                secondary={t("common.total")}
                primary={`${payload[0].value}%`}
              />
            </ListItem>
            <ListItem component={Grid} lg={6}>
              <ListItemText
                secondary={t("common.count")}
                primary={`${count}`}
              />
            </ListItem>

            {area ? (
              <>
                <ListItem>
                  <ListItemText
                    primary={
                      <>
                        {`${Number(
                          area
                        ).toLocaleString()} ${areaUom} (${areaPercent}%)`}
                      </>
                    }
                    secondary={t("common.area")}
                  />
                </ListItem>
              </>
            ) : null}
          </List>
        </Paper>
      </ThemeProvider>
    );
  }

  return null;
};
export default CustomTooltip;

CustomTooltip.defaultProps = {
  active: false,
  payload: null,
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      payload: PropTypes.shape({}),
      value: PropTypes.number,
    })
  ),
};
