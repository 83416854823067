﻿import React, { useEffect, useMemo } from "react";
import { Route, Switch } from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useAuth0 } from "@auth0/auth0-react";
import triggerEvent from "./components/gaTriggerEvent";
import { useTranslation } from "react-i18next";
import { useAppState } from "providers/AppProvider";
import AuthView from "views/AuthView";
import InvalidUserView from "views/InvalidUserView";
import { RequireAuth } from "./RequireAuth";
import OrgWrapper from "views/OrgWrapper";

export default function App() {
  const { t } = useTranslation();
  const { user, isLoading } = useAuth0();
  const { organizations, userDataState, userRole } = useAppState();

  const invalidUserMsg = useMemo(() => {
    if (!isLoading && user && organizations) {
      if (!userRole) {
        return t("common.userRoleNotValidated");
      } else if (!organizations[0]) {
        return t("common.noOrgsFoundForUser");
      }
    }
  }, [isLoading, organizations, t, user, userRole]);

  useEffect(() => {
    triggerEvent("page_view");
  }, []);

  if (invalidUserMsg) {
    return (
      <AuthView>
        <InvalidUserView message={invalidUserMsg} />
      </AuthView>
    );
  }

  return user && userDataState.isError ? (
    <AuthView>
      <Typography>
        {userDataState.error?.message || "Could not fetch user data."}
      </Typography>
      <Button sx={{ mt: 2 }} component="a" href="/">
        {t("common.tryAgain")}
      </Button>
    </AuthView>
  ) : (
    <Switch>
      <Route path={"/:org/:season?/:route?"}>
        <RequireAuth>
          <OrgWrapper />
        </RequireAuth>
      </Route>
      <Route path="*">
        <AuthView>
          <h2>Not Found</h2>
        </AuthView>
      </Route>
    </Switch>
  );
}
