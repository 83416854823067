import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import WaterDropIcon from "@mui/icons-material/WaterDrop";
import BaseCardView from "lib/Card/BaseCardView";
import SummaryCardTitle from "lib/Card/SummaryCardTitle";
import SummaryCardValue from "lib/Card/SummaryCardValue";
import { AppliedOperationDetails } from "api/operations/types/AppliedOperationDetails";

export function MixDetails({
  data,
}: {
  data: AppliedOperationDetails["productMix"];
}) {
  const { t } = useTranslation();
  return (
    <>
      <Stack direction={"row"} spacing={2}>
        {data.map((d) => (
          <BaseCardView
            sx={{ bgcolor: "background.paper" }}
            variant="outlined"
            key={d.product}
          >
            <Stack>
              <SummaryCardTitle>
                <div style={{ display: "flex" }}>
                  {d.product}
                  {d.isCarrier ? (
                    <Tooltip title={t("inventory.products.carrier")}>
                      <WaterDropIcon sx={{ ml: "auto" }} color="primary" />
                    </Tooltip>
                  ) : null}
                </div>
              </SummaryCardTitle>
              <SummaryCardValue>
                {`${t("common.rate")}: ${
                  d ? `${d.rate?.toLocaleString()} ${d.rateUom}` : "--"
                }`}
              </SummaryCardValue>
              <SummaryCardValue>
                {`${t("common.total")}: ${
                  d ? `${d.total?.toLocaleString()} ${d.totalUom}` : "--"
                }`}
              </SummaryCardValue>
            </Stack>
          </BaseCardView>
        ))}
      </Stack>
    </>
  );
}
