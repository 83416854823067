import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import lookupKeys from "./lookupKeys";

export function useLandAgreementTypes({ org }: { org: string }) {
  const getLandAgreements = useCallback(async () => {
    const res = await axios.get<
      {
        id: string;
        name: string;
        label: string;
      }[]
    >(`/${org}/lookups/landagreementtypes`);
    res.data.forEach((d) => {
      d.label = d.name;
    });
    return res.data;
  }, [org]);

  return useQuery({
    queryKey: lookupKeys.byType(org, "landagreementtypes"),
    queryFn: getLandAgreements,
    enabled: !!org,
    staleTime: 10 * (60 * 1000), // 10 mins
  });
}
