import axios from "axios";
import { useCallback } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { YieldFilter } from "./types";

export function useYieldFilterMutations({
  org,
  seasonId,
}: {
  org: string;
  seasonId: string;
}) {
  const queryClient = useQueryClient();

  const invalidate = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: ["organization", org, "season", seasonId, "yield-filters"],
    });
  }, [seasonId, org, queryClient]);

  async function saveItem(data: YieldFilter) {
    const method = data.id ? "put" : "post";
    // based on cropTypeId?
    delete data.id;

    const res = await axios[method](
      `/${org}/myaccount/yieldfilters/seasons/${seasonId}`,
      {
        ...data,
        seasonId,
      }
    );
    return res;
  }

  async function deleteItem(data: YieldFilter) {
    const res = await axios({
      url: `/${org}/myaccount/yieldfilters/seasons/${seasonId}`,
      data,
      method: "delete",
    });
    return res;
  }

  const saveMutation = useMutation({
    mutationFn: saveItem,
    onSuccess: () => {
      invalidate();
    },
  });

  const deleteMutation = useMutation({
    mutationFn: deleteItem,
    onSuccess: () => {
      invalidate();
    },
  });

  return {
    saveMutation,
    deleteMutation,
  };
}
