import axios from "axios";
import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { FeatureCollection, MultiPolygon, Polygon } from "geojson";
import { IBaseAsset } from "types/IAssetType";
import { useInventoryMapThemes } from "api/useInventoryMapThemes";
import { assetKeys } from "./assetKeys";

export function useAssetGeodataById<T = IBaseAsset>({
  id,
  org,
  seasonId,
  type,
}: {
  id: string;
  org: string;
  seasonId: string;
  type: "animal" | "plant" | "fixed";
}) {
  const mapThemesQuery = useInventoryMapThemes(
    org,
    type ? `assets/${type}` : "",
    seasonId
  );
  const enabled =
    !!org && !!type && !!seasonId && !!id && !!mapThemesQuery.data;

  const getGeodata = useCallback(async () => {
    const res = await axios.get<FeatureCollection<Polygon | MultiPolygon, T>>(
      `/${org}/assets/${type}/geodata/${id}`
    );
    if (!res.data.features.length) {
      return null;
    }
    res.data.features.forEach((f) => {
      const m = mapThemesQuery.data?.raw?.find((m) => {
        f.id === m.id;
      });
      Object.assign(f.properties, m);
    });
    return res.data;
  }, [id, mapThemesQuery.data, org, type]);

  return useQuery({
    queryFn: getGeodata,
    queryKey: assetKeys
      .geodataById(org, type, id)
      .concat([`${mapThemesQuery.isFetching}`]),
    enabled,
  });
}
