import { useTranslation } from "react-i18next";
import { createFilterOptions } from "@mui/material/Autocomplete";
import Autocomplete, { CustomAutocompleteProps } from "lib/Select/Autocomplete";
import { useOrgState } from "providers";
import { useAssetGroups } from "api/assets/useAssetGroups";

interface AssetGroup {
  id?: string;
  name: string;
  inputValue?: string;
}

const filter = createFilterOptions<AssetGroup>();

export default function AssetGroupSelect({
  value,
  ...props
}: Omit<CustomAutocompleteProps, "onChange"> & {
  onChange: (_id: string) => void;
}) {
  const { t } = useTranslation();
  const { org } = useOrgState();

  const {
    query: assetGroupsQ,
    mutation: { mutate: saveAssetGroup },
  } = useAssetGroups(org);

  return (
    <Autocomplete
      label={t("common.group")}
      selectOnFocus
      clearOnBlur
      disableClearable={false}
      handleHomeEndKeys
      options={assetGroupsQ.data ?? []}
      value={assetGroupsQ.data?.find((f) => f.id === value) ?? null}
      filterOptions={(options: AssetGroup[], params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.name);
        if (inputValue !== "" && !isExisting) {
          filtered.push({
            inputValue,
            name: `Add "${inputValue}"`,
          });
        }

        return filtered;
      }}
      renderOption={(props, option: AssetGroup) => (
        <li {...props}>{option.name}</li>
      )}
      getOptionLabel={(option: AssetGroup) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.name;
      }}
      {...props}
      onChange={(_e, opt: AssetGroup) => {
        if (opt && opt.inputValue) {
          saveAssetGroup(
            { id: opt.id, name: opt.inputValue },
            {
              onSuccess: (d) => {
                props.onChange(d.id);
              },
            }
          );
        } else {
          props.onChange(opt?.id);
        }
      }}
    />
  );
}
