import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useOrgState } from "providers/OrgProvider";
import { useTranslation } from "react-i18next";
import ReportView from "../ReportView";
import ReportFieldsSelect from "../ReportFieldsSelect";
import useAreaColumn from "components/Table/useAreaColumn";
import { useFields } from "api/fields/useFields";
import FieldsIcon from "lib/Icons/FieldsIcon";
import { Grid } from "components/layout/Grid";

export default function FieldsReports() {
  const { t } = useTranslation();
  const { org, rootUrl, orgData, season } = useOrgState();
  const fieldsQ = useFields({ org, seasonId: season?.id });
  // const [expanded, setExpanded] = useState();
  const [selectedFields, setSelectedFields] = useState();
  const areaHeader = useAreaColumn();
  // useEffect(() => {
  //   setExpanded(getDefaultExpandedRows(selectedFields));
  // }, [selectedFields]);

  return (
    <Grid height="100%" container className="cai-reports">
      <Grid
        height="100%"
        display="flex"
        flexDirection={"column"}
        lg={6}
        xl={4}
        className="hide-print"
      >
        <Paper style={{ overflow: "auto" }}>
          <Stack spacing={2} sx={{ p: 2 }}>
            <h3>{t("insights.selectReportType")}</h3>
            <Stack spacing={1} sx={{ alignItems: "center" }}>
              <Box>
                <IconButton
                  size="large"
                  sx={{
                    border: "solid 1px",
                    backgroundColor: "rgb(0, 141, 205)",
                    svg: {
                      fill: "inherit",
                    },
                    "&:hover": {
                      backgroundColor: "rgb(0, 141, 205)",
                    },
                  }}
                  href={`${rootUrl}/insights/reports/fields`}
                >
                  <FieldsIcon fontSize="large" />
                </IconButton>
              </Box>
              <Typography sx={{ textTransform: "uppercase" }} variant="body2">
                {t("inventory.fields.title")}
              </Typography>
            </Stack>
          </Stack>
        </Paper>
        <Paper sx={{ mt: 3 }} style={{ flex: 1, minHeight: "250px" }}>
          <ReportFieldsSelect
            // subtypes={subtypes}
            fields={fieldsQ.data}
            onChange={(data) => {
              // console.log('change', data);
              setSelectedFields(data);
              // const selectedFields = filteredFields?.filter((co) => {
              //   return data.find((d) => d.fieldId === co.fieldId);
              // });
              // // loop selected fields and get their children
              // // (filtered by subtype, if selected)
              // const filteredAssets = selectedFields.reduce((curr, item) => {
              //   let filteredChildren = item.children;
              //   if (subtypeItem) {
              //     filteredChildren = item.children?.filter((c) => {
              //       return c.subtypeId === subtypeItem.value;
              //     });
              //   }
              //   return curr.concat(filteredChildren || []);
              // }, []);
              // setSelectedAssets(filteredAssets);
            }}
          />
        </Paper>
      </Grid>
      <Grid
        height="100%"
        display="flex"
        flexDirection={"column"}
        lg={6}
        xl={8}
        className="print-full-width"
      >
        <ReportView
          org={orgData?.name}
          season={season}
          color="rgb(0, 141, 205)"
          titleIcon={FieldsIcon}
          title={`${t("inventory.fields.title")} ${t(
            "insights.reports.title"
          )}`}
          columns={[
            {
              Header: t("common.field"),
              accessor: "name",
            },
            {
              Header: t("common.farm"),
              accessor: "farm",
            },
            areaHeader,
          ]}
          // columns={getAssetReportCols(currentAssetType?.asset?.toLowerCase())}
          data={selectedFields}
          groupBy={[]}
          // expanded={expanded}
          sortBy={[
            {
              id: "name",
              desc: false,
            },
          ]}
        />
      </Grid>
    </Grid>
  );
}

FieldsReports.defaultProps = {};

FieldsReports.propTypes = {
  // setAssetType: PropTypes.func.isRequired,
};
