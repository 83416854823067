import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { assetKeys } from "./assetKeys";
import { useAssetsByTypeSummary } from "./useAssetSummaries";

export function useAssetSubtype({
  org,
  type,
  name,
}: {
  org: string;
  type: string;
  name: string;
}) {
  const assetsByTypeQuery = useAssetsByTypeSummary(org, type);

  const getItem = useCallback(async () => {
    const subtypeObj = assetsByTypeQuery.data?.find(
      (at: { subtype: string }) => at.subtype === name
    );

    return subtypeObj ?? null;
  }, [assetsByTypeQuery.data, name]);

  return useQuery({
    queryKey: assetKeys.assetSubtype(org, type, name),
    queryFn: getItem,
    enabled: Boolean(assetsByTypeQuery.isFetched && org && type && name),
  });
}
