import Typography from "@mui/material/Typography";
import React from "react";

export default function SummaryCardTitle({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Typography
      component={"div"}
      sx={{
        textTransform: "uppercase",
        fontSize: 14,
      }}
      color="text.secondary"
    >
      {children}
    </Typography>
  );
}
