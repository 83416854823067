import React, { useMemo } from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useOrgState } from "providers/OrgProvider";
import { useInventoryState } from "providers/InventoryProvider";
import { useTranslation } from "react-i18next";
import { FormWrapper } from "components";
import { useOperationMutations } from "api/operations/useOperationMutations";
import FieldsAndSubfields from "../../../FieldsAndSubfields";
import DateRangeInputs from "components/Forms/DateRangeInputs";
import Autocomplete from "lib/Select/Autocomplete";
import { IProduct, IUrlParams } from "types";
import { OperationPlantedOrAppliedPostData } from "api/operations/types/OperationPostData";
import { useOperationsGeodataById } from "api/operations/useOperationsGeodataById";
import { Operation } from "api/operations/types/Operation";
import { useProductsBySeason } from "api/products/useProductsBySeason";
import { parseIds } from "../parseIds";

const defaultValues = {
  area: "",
  id: "",
  fieldIds: "",
  subfieldIds: "",
  beginDateUtc: "",
  endDateUtc: "",
  productId: "",
  rate: "",
  geometry: "",
};

export default function AppliedOperationForm({
  getCancelHref,
}: {
  getCancelHref?: (_onDelete?: boolean) => string;
}) {
  const { t } = useTranslation();
  const { itemId } = useParams<IUrlParams>();
  const { org, season } = useOrgState();
  const { invalidate, saveMutation, deleteMutation } = useOperationMutations({
    org,
    type: "applied",
  });
  const history = useHistory();
  const productsQ = useProductsBySeason({ org, seasonId: season?.id });
  const geodataQ = useOperationsGeodataById<Operation>({
    id: itemId,
    org,
    seasonId: season?.id,
    type: "applied",
  });
  const currentEditFtr = geodataQ.data?.features[0];

  const { drawData } = useInventoryState();

  const methods = useForm({
    defaultValues: { ...defaultValues },
  });
  const {
    handleSubmit,
    register,
    reset,
    formState,
    watch,
    control,
    errors,
    setValue,
  } = methods;
  const {
    area,
    id,
    fieldIds,
    subfieldIds,
    beginDateUtc,
    endDateUtc,
    productId,
    rate,
  } = watch();
  const productOps = useMemo(() => {
    return productsQ.data?.filter((p) => !p.isSeed) || [];
  }, [productsQ.data]);

  const currRateUom = productOps?.find((ao) => ao.id === productId)?.rateUom;

  async function handleSave(d: OperationPlantedOrAppliedPostData) {
    if (!formState.isDirty) {
      history.push(getCancelHref());
      return false;
    }
    const body = { ...d };
    body.geometry = d.geometry ? JSON.parse(d.geometry) : "";
    // need at least 1 fieldId or subfieldId
    // but cannot make either input required
    // therefore custom logic to handle validation here
    if (!itemId && !body.fieldIds?.length && !body.subfieldIds?.length) {
      return false;
    }
    // server doesn't want these props if not set
    if (!body.id) {
      delete body.id;
    }
    if (!body.fieldIds?.length) {
      delete body.fieldIds;
    } else {
      body.fieldIds = JSON.parse(body.fieldIds);
    }
    if (!body.subfieldIds?.length) {
      delete body.subfieldIds;
    } else {
      body.subfieldIds = JSON.parse(body.subfieldIds);
    }
    const res = await saveMutation.mutate(body, {
      onSuccess: () => {
        reset(d);
        history.push(getCancelHref());
      },
    });

    return res;
  }

  return (
    <FormWrapper
      methods={methods}
      defaultValues={defaultValues}
      data={{ ...currentEditFtr?.properties }}
      geometryData={drawData?.features[0]?.geometry}
      existingGeom={currentEditFtr?.geometry}
      cancelHref={getCancelHref()}
      saveState={{
        ...saveMutation,
        errorMessage: saveMutation.error,
      }}
      deleteState={{ ...deleteMutation, errorMessage: deleteMutation.error }}
      // NOTE: ignoring geometry, using fieldIds
      ignoreGeom
      onDelete={async () => {
        const res = await deleteMutation.mutate(id, {
          onSuccess: () => {
            reset(defaultValues);
            history.push(getCancelHref(true));
            // invalidate after navigation to prevent GET of deleted item
            invalidate();
          },
        });
        return res;
      }}
      onSubmit={handleSubmit(handleSave)}
    >
      <Stack spacing={2}>
        {!itemId ? (
          <>
            <FieldsAndSubfields
              control={control}
              ids={{
                fieldIds: parseIds(fieldIds),
                subfieldIds: parseIds(subfieldIds),
              }}
            />
          </>
        ) : null}
        <DateRangeInputs
          required
          control={control}
          setValue={setValue}
          startProp="beginDateUtc"
          endProp="endDateUtc"
          startDate={beginDateUtc ? new Date(beginDateUtc) : null}
          endDate={endDateUtc ? new Date(endDateUtc) : null}
          minDate={season?.beginOnUtc ? new Date(season.beginOnUtc) : null}
          maxDate={season?.endOnUtc ? new Date(season.endOnUtc) : null}
        />
        <Controller
          name="productId"
          control={control}
          rules={{ required: true }}
          htmlFor="product-select"
          render={(props: {
            onChange: (_id: string) => void;
            value: string;
          }) => (
            <Autocomplete
              id="product-select"
              label={`${t("common.product")} *`}
              groupBy={(o) => o.type}
              options={
                productOps?.sort((a, b) =>
                  a.type > b.type ? 1 : a.type === b.type ? 0 : -1
                ) || []
              }
              getOptionLabel={(o: IProduct) => `${o.name}`}
              error={!!errors?.productId}
              inputProps={{ required: true, name: "productId" }}
              value={productOps?.find((p) => p.id === props.value) ?? null}
              filterOptions={(options, params) => {
                return options
                  .filter((o) => {
                    const inputVal = params.inputValue?.toLowerCase();
                    return (
                      o.cropType?.toLowerCase().includes(inputVal) ||
                      o.name?.toLowerCase().includes(inputVal)
                    );
                  })
                  .sort((a, b) => (a.cropType > b.cropType ? 1 : -1));
              }}
              onChange={(_e, item) => {
                const prod = item as IProduct;
                props.onChange(prod?.id || "");
              }}
              disableClearable={false}
            />
          )}
        />
        <TextField
          label={`${t("common.rate")}`}
          fullWidth
          InputProps={{
            endAdornment: <Typography sx={{ ml: 2 }}>{currRateUom}</Typography>,
          }}
          inputProps={{
            name: "rate",
            ref: register,
            step: 0.01,
            min: 0,
          }}
          value={rate}
          required
          id="rate-input"
          type="number"
        />
        {itemId ? (
          <TextField
            fullWidth
            id="area-input"
            type="number"
            label={`${t("common.area")}`}
            name="area"
            required
            value={area ?? ""}
            InputProps={{
              endAdornment: (
                <Typography sx={{ ml: 1 }}>
                  {currentEditFtr?.properties?.areaUom ?? ""}
                </Typography>
              ),
            }}
            inputProps={{
              name: "area",
              ref: register,
              min: 0,
              step: "any",
            }}
          />
        ) : null}
      </Stack>
    </FormWrapper>
  );
}
