import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import lookupKeys from "../lookups/lookupKeys";
import { IFixedAssetType } from "types/IAssetType";

export function useAssetFixedTypes(org: string) {
  const getTypes = useCallback(async () => {
    const res = await axios.get<IFixedAssetType[]>(
      `/${org}/lookups/assetfixedtypes`
    );
    res.data.forEach((d) => {
      d.label = d.name;
    });
    return res.data;
  }, [org]);

  return useQuery({
    queryKey: lookupKeys.byType(org, "assetfixedtypes"),
    queryFn: getTypes,
    enabled: !!org,
  });
}
