import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function FieldsIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="10 0 431 416" {...props}>
      <g>
        <path
          d="M216.97,339.99c61.71,0,115.39-34.38,142.93-85.02c-84.71,23.89-150.88,56.21-190.01,78.07
		C184.8,337.55,200.6,339.99,216.97,339.99z"
        />
        <path
          d="M147.32,324.34c39.45-23.12,118.44-64.1,222.55-91.55c1.16-3.21,2.2-6.48,3.16-9.78
		c-146,14.59-231.11,52.36-267.63,72.61C117.73,307.25,131.84,316.99,147.32,324.34z"
        />
        <path
          d="M331.23,195.76c-16,0-31.27,0.39-45.82,1.12c-15.17,0.76-29.57,1.88-43.24,3.3l-18.45,2.15
		c1.26-16.2,5.31-33.79,15-48.82c5.31,5.19,14.61,8.16,24,8.16c8.72,0,17.52-2.56,23.27-8.3c13.59-13.6,15.17-40.22,15.17-59.06
		c0-1.95-1.58-3.53-3.53-3.53c-18.84,0-45.46,1.57-59.06,15.17c-10.03,10.02-10.36,29.35-4.28,41.34
		c-6.52,9.4-10.86,19.81-13.65,30.34c-2.58-16.73-6.84-29.45-11.68-39.2c9.34-10.68,10.75-34.84-0.88-46.46
		c-13.6-13.59-40.22-15.17-59.06-15.17c-1.95,0-3.53,1.58-3.53,3.53c0,18.84,1.57,45.46,15.17,59.06
		c5.74,5.74,14.54,8.31,23.26,8.31c7.06,0,14.07-1.68,19.37-4.72c6.36,13.32,11.56,32.49,12.67,60.4
		c-11.44,1.61-22.29,3.42-32.55,5.4c-52.35,10.07-89.64,24.28-114.24,36.3c6.02,13.13,13.72,25.34,22.86,36.31
		c36.49-21.24,125.95-62.8,285.42-77.7c0.37-2.26,0.67-4.53,0.95-6.82C362.01,196.13,346.27,195.76,331.23,195.76z M243.56,110.96
		c10.98-10.98,33.28-12.88,50.5-13.09c-0.2,17.23-2.11,39.53-13.09,50.5c-8.85,8.85-29.86,7.56-37.42,0
		c-0.23-0.23-0.45-0.47-0.66-0.72c6.72-8.53,15.6-15.96,27.23-21.43c0-0.01,0.57-0.28,0.57-0.28c1.76-0.83,2.23-2.8,1.4-4.57
		c-0.83-1.77-3.22-2.38-4.98-1.56c-11.68,5.49-20.8,12.75-27.88,21.06C235.97,131.35,236.85,117.66,243.56,110.96z M182.31,107.97
		c-1.15-0.78-2.04-1.36-2.53-1.85c-1.38-1.38-3.62-1.38-5,0c-1.38,1.38-1.38,3.62,0,5c0.7,0.69,1.91,1.56,3.54,2.68
		c4.93,3.37,13.76,9.42,21.65,22.94c-9.13,5.38-26.49,5.5-34.33-2.34c-10.98-10.98-12.88-33.28-13.09-50.5
		c17.22,0.21,39.52,2.11,50.5,13.09c7.94,7.94,7.71,25.64,2.13,34.66C196.8,117.89,187.54,111.54,182.31,107.97z"
        />
      </g>
    </SvgIcon>
  );
}
