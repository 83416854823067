import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import operationKeys from "./operationKeys";

interface OperationSummaryItem {
  operation: string;
  operationEn: string;
  count: number;
  totalArea: number;
  totalAreaUom: string;
  color: string;
}

export function useOperationsSummary(org: string, seasonId: string) {
  const getOperationsSummary = useCallback(async () => {
    const res = await axios.get<OperationSummaryItem[]>(
      `/${org}/operations/season/${seasonId}/summary`
    );
    return res.data;
  }, [seasonId, org]);

  return useQuery({
    queryKey: operationKeys.summaryBySeason(org, seasonId),
    queryFn: getOperationsSummary,
  });
}
