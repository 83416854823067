import turfBbox from "@turf/bbox";
import turfArea from "@turf/area";
import {
  AllGeoJSON,
  convertArea,
  convertLength,
  feature,
  FeatureCollection,
  LineString,
  MultiPolygon,
  // Point,
  Polygon,
  Position,
  round,
  Units,
} from "@turf/helpers";
import center from "@turf/center";
import pointOnFeature from "@turf/point-on-feature";
// import combine from "@turf/combine";
import turfFlatten from "@turf/flatten";
import turfBuffer from "@turf/buffer";
import turfDifference from "@turf/difference";
import turfSimplify from "@turf/simplify";
// import turfRewind from '@turf/rewind';
// import turfDissolve from '@turf/dissolve';
// import turfUnion from '@turf/union';
import turfBooleanClockwise from "@turf/boolean-clockwise";
import turfLength from "@turf/length";
import { Feature, GeoJSON, Geometry } from "geojson";
import { FitBoundsOptions, LngLatBoundsLike, Map } from "mapbox-gl";

const geojsonGeomTypes = [
  "Polygon",
  "MuliPolygon",
  "LineString",
  "MultiLineString",
  "Point",
  "MultiPoint",
];
export function bbox(data: GeoJSON) {
  let ftr = data;
  // if geometry passed, convert to feature
  if (data?.type && geojsonGeomTypes.includes(data.type)) {
    ftr = feature(data as Geometry);
  }
  return turfBbox(ftr) as LngLatBoundsLike;
}

const defaultFitBoundsOpts = { padding: 50, animate: false, maxZoom: 18 };
export function fitBounds({
  bbox: boundingBox,
  geojson,
  map,
  options = defaultFitBoundsOpts,
}: {
  bbox?: LngLatBoundsLike;
  geojson?: GeoJSON;
  map: Map;
  options?: FitBoundsOptions;
}) {
  try {
    if (boundingBox) {
      map.fitBounds(boundingBox, { ...defaultFitBoundsOpts, ...options });
    } else if (geojson) {
      const bb = bbox(geojson);
      map.fitBounds(bb, { ...defaultFitBoundsOpts, ...options });
    }
    return true;
  } catch (e) {
    return e;
  }
}
/**
 *
 * outUnits can be: kilometers, kilometres, meters, metres, centimetres, millimeters, acres, miles, yards, feet, inches, hectares
 */
export function getArea({
  geometry,
  outUnits = "acres",
}: {
  geometry: Geometry & Feature;
  outUnits?: Units;
}) {
  let area;
  try {
    const areaMeters = turfArea(geometry);
    let areaConverted = areaMeters;
    if (outUnits !== "meters") {
      areaConverted = convertArea(areaMeters, "meters", outUnits);
    }
    area = round(areaConverted, 2);
  } catch (e) {
    console.error(e);
  }
  return area;
}

export function getCenter(input: AllGeoJSON) {
  let centerPoint;
  try {
    centerPoint = center(input);
  } catch (e) {
    console.error(e);
  }
  return centerPoint;
}

export function getPointOnFeature(input: AllGeoJSON) {
  let point;
  try {
    point = pointOnFeature(input);
  } catch (e) {
    console.error(e);
  }
  return point;
}

// export function combineFc(fc: FeatureCollection<Point | LineString | Polygon>) {
//   return combine(fc);
// }

export function flatten(fc: AllGeoJSON) {
  return turfFlatten(fc) as FeatureCollection;
}

/**
 *
 * @param {(FeatureCollection|Geometry|Feature)} geojson input to be buffered
 * @param {number} radius distance to draw the buffer
 * @param {*} [opts] turf.buffer options
 * @param {string} [opts.units] supported are miles, feet, kilometers, meters and degrees
 * @param {number} [opts.steps=64] number of steps
 */
export function buffer(
  geojson: FeatureCollection & Geometry & Feature,
  radius: number,
  opts: { units: "miles" | "kilometers" | "degrees" | "meters" | "feet" }
) {
  if (opts?.units === "meters") {
    opts.units = "kilometers";
    radius /= 1000;
  }
  if (opts?.units === "feet") {
    opts.units = "miles";
    radius /= 5280;
  }
  return turfBuffer(
    geojson,
    radius,
    opts as { units: "miles" | "kilometers" | "degrees" }
  ) as FeatureCollection & Feature;
}

export function difference(
  poly1: Polygon | MultiPolygon | Feature<Polygon | MultiPolygon>,
  poly2: Polygon | MultiPolygon | Feature<Polygon | MultiPolygon>
) {
  return turfDifference(poly1, poly2);
}

export function booleanClockwise(
  ring: LineString | Position[] | Feature<LineString>
) {
  return turfBooleanClockwise(ring);
}
/**
 *
 * outUnits can be miles, nauticalmiles, inches, yards, meters, metres, kilometers, centimeters, feet
 */
export function getLength(
  fc: Feature | FeatureCollection,
  { outUnits = "miles" }: { outUnits: Units }
) {
  // defaults to km
  const km = turfLength(fc);
  let finalLength = km;

  if (outUnits !== "kilometers") {
    finalLength = convertLength(km, "kilometers", outUnits);
  }
  return round(finalLength, 2);
}

export function simplify(
  geojson: AllGeoJSON,
  opts: {
    tolerance?: number;
    highQuality?: boolean;
    mutate?: boolean;
  }
) {
  return turfSimplify(geojson, opts);
}
