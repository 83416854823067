import React, { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { fitBounds } from "lib/MapboxMap/utils";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { IUrlParams } from "types";
import DataTable from "components/DataTable";
import { useTranslation } from "react-i18next";
import { Row } from "@tanstack/react-table";
import { useAppState } from "providers/AppProvider";
import { Map } from "mapbox-gl";
import { RasterTileLayer, useRasterLayers } from "./useRasterLayers";
import { useRasterLayersActions } from "./useRasterLayersActions";

export default function ImageryTable({ map }: { map: Map }) {
  const { tenant } = useAppState();
  const { org } = useParams<IUrlParams>();
  const { t } = useTranslation();
  const layers = useRasterLayers(tenant, org);
  const { setLayers } = useRasterLayersActions();
  // local copy so datatable doesnt re-render on visibility change
  const [data] = useState(layers);
  const rowSelection = useMemo(() => {
    return data?.reduce((curr, val, idx) => {
      if (idx > -1 && val.visible) {
        curr[idx] = true;
      }
      return curr;
    }, {} as { [index: number]: boolean });
  }, [data]);

  const ActionCell = useCallback(
    ({ row }: { row: Row<RasterTileLayer> }) => {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              fitBounds({
                map,
                bbox: row.original.bbox,
              });
            }}
          >
            <ZoomInIcon />
          </IconButton>
        </Box>
      );
    },
    [map]
  );

  return (
    <Box
      sx={{
        height: "100%",
        overflow: "auto",
        // remove highlight from row, using checkbox
        ".cai-table tr.selected:not(.grouped)": {
          backgroundColor: "inherit",
        },
      }}
    >
      <DataTable
        data={data}
        isSelectable
        disableGroupSelect
        selectedRowIds={rowSelection}
        onSelectionChange={(selection: Row<RasterTileLayer>[]) => {
          const ids = selection.map((s) => s?.original?.url);
          const newLayers = [...layers];
          newLayers.forEach((l) => {
            if (ids.includes(l.url)) {
              l.visible = true;
            } else {
              l.visible = false;
            }
          });
          setLayers(org, newLayers);
        }}
        columns={[
          {
            Header: "",
            id: "actions",
            Cell: ActionCell,
            isSortable: false,
          },
          {
            Header: t("common.date"),
            accessor: "beginOnUtc",
            Cell: ({ row }: { row: Row<RasterTileLayer> }) => {
              return (
                <>{new Date(row.original.beginOnUtc).toLocaleDateString()}</>
              );
            },
          },
          {
            Header: t("common.sensor"),
            accessor: "sensor.name",
          },
          {
            Header: t("imagery.compare.displayType"),
            accessor: "rasterTypeName",
          },
          {
            Header: t("common.name"),
            accessor: "name",
          },
        ]}
      />
    </Box>
  );
}
