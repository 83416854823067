import React, { useEffect, useRef, useState } from "react";
import { GeoJSON, Feature } from "geojson";
import BaseMapView from "views/BaseMapView";
import { EventData, GeoJSONSource, Map, MapboxEvent } from "mapbox-gl";
import { featureCollection } from "@turf/helpers";
import { useLegendControl } from "components/Map";
import { LegendSymbol } from "lib/MapboxMap";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { RasterLegendItem } from "views/InventoryView/MapView/RasterLayersControl/useRasterLegends";

export function ImageryExtractMapView({
  rasterLegendData,
  extractGeodata,
  onLoad,
  selectedSubfields,
}: {
  rasterLegendData?: { title: string; data: RasterLegendItem[] };
  extractGeodata: GeoJSON;
  onLoad: (_m: Map) => void;
  selectedSubfields: Feature[];
}) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const mapRef = useRef<Map>();
  const { legendCtrl } = useLegendControl({
    theme,
    location: "bottom-right",
    map: mapRef.current,
    layers: [
      {
        id: "subfields-lyr",
        isStatic: true,
        title: t("inventory.subfields.title"),
        dataProp: "type",
        Symbol: function Symbol(props: { data: { color: string } }) {
          return (
            <LegendSymbol
              geometryType="Polygon"
              borderWidth={3}
              borderColor={props?.data?.color}
            />
          );
        },
      },
      {
        id: "extract-raster-lyr",
        isStatic: true,
        title: rasterLegendData?.title,
        data: rasterLegendData?.data?.map((i) => ({
          name: i.label,
          Symbol: function Symbol() {
            return (
              <LegendSymbol geometryType="Polygon" backgroundColor={i.color} />
            );
          },
        })),
      },
    ],
  });

  //  handle subfields layer
  useEffect(() => {
    if (mapRef.current && selectedSubfields) {
      try {
        const src = mapRef.current.getSource("subfields-src") as GeoJSONSource;
        if (src) {
          src.setData(featureCollection(selectedSubfields ?? []));
        }
      } catch (e) {
        //
      }
    }
  }, [selectedSubfields]);
  // handle extract data layer
  useEffect(() => {
    if (mapRef.current) {
      const src = mapRef.current.getSource("extract-src");
      if (src) {
        mapRef.current.removeLayer("extract-layer");
        mapRef.current.removeSource("extract-src");
      }
    }
    if (extractGeodata) {
      if (mapRef.current) {
        mapRef.current.addSource("extract-src", {
          type: "geojson",
          data: extractGeodata,
        });
        mapRef.current.addLayer({
          id: `extract-layer`,
          source: `extract-src`,
          type: "line",
          paint: {
            "line-color": "cyan",
            "line-width": 2,
          },
        });
      }
    }
  }, [extractGeodata]);

  return (
    <div style={{ height: "100%", position: "relative" }}>
      <BaseMapView
        isLoading={isLoading}
        mapRef={null}
        showBasemapSelect={false}
        defaultBounds={undefined}
        events={{
          load: (e: MapboxEvent) => {
            const map = e.target;
            mapRef.current = map;
            onLoad(map);
            map.addSource("subfields-src", {
              type: "geojson",
              data: featureCollection([]),
            });
            map.addLayer({
              id: "subfields-lyr",
              type: "line",
              source: "subfields-src",
              paint: {
                "line-width": 3,
                "line-color": ["get", "color"],
              },
            });
            map.addControl(legendCtrl, "bottom-right");
          },
          dataloading: (e: EventData) => {
            if (e.sourceId === "extract-raster-src") {
              setIsLoading(true);
            }
          },
          data: (e: EventData) => {
            if (e.sourceId === "extract-raster-src") {
              setIsLoading(false);
            }
          },
          idle: () => {
            setIsLoading(false);
          },
          error: (e: EventData) => {
            if (e.sourceId === "extract-raster-src") {
              setIsLoading(false);
            }
          },
        }}
      />
    </div>
  );
}
