export function sortObjArr(arr, prop) {
  try {
    arr.sort((a, b) => {
      const aLower = a[prop]?.toLowerCase();
      const bLower = b[prop]?.toLowerCase();
      return aLower?.localeCompare(bLower, undefined, {
        numeric: true,
        sensitivity: "base",
      });
    });
    return arr;
  } catch (e) {
    console.error(e);
    return arr;
  }
}

export function sortObjArrByFields(arr, field1, field2) {
  try {
    return arr.sort(
      (a, b) =>
        a[field1]?.localeCompare(b[field1], undefined, {
          numeric: true,
          sensitivity: "base",
        }) ||
        a[field2]?.localeCompare(b[field2], undefined, {
          numeric: true,
          sensitivity: "base",
        })
    );
  } catch (e) {
    console.error(e);
    return arr;
  }
}

export function filterListById(list, id, idProp = "id") {
  if (!id) {
    return list;
  }
  return list?.filter((f) => f[idProp] === id);
}

// create object for react-table selectedRows
// export function getSelectedRowObj(data, ids) {
//   if (!data || !ids) {
//     return null;
//   }
//   const obj = {};
//   ids.forEach((id) => {
//     const x = data.find((d) => d.id === id);
//     if (x) {
//       const y = data.indexOf(x);
//       if (y >= -1) {
//         obj[y] = true;
//       }
//     }
//   });
//   return obj;
// }
