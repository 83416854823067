import React, { useCallback, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useOrgState } from "providers/OrgProvider";
import { useInventoryDispatch } from "providers";
import { useURLSearchParams } from "components";
import PlantAssetForm from "./PlantAssetForm";
import FixedAssetForm from "./FixedAssetForm";
import AnimalAssetForm from "./AnimalAssetForm";
import { IUrlParams } from "types";
import { FeatureCollection } from "geojson";

export default function AssetForm() {
  const { rootUrl } = useOrgState();
  const { type, subtype, fieldId, itemId } = useParams<IUrlParams>();
  const history = useHistory();
  const { enableDraw, disableDraw } = useInventoryDispatch();
  const baseRoute = `${rootUrl}/inventory/assets/${type}`;
  const query = useURLSearchParams();
  const groupId = query.get("groupId");
  const url = `${baseRoute}/${subtype}/${fieldId}/items/${itemId}${
    groupId ? `?groupId=${groupId}` : ""
  }`;

  const handleDeleteRoute = useCallback(() => {
    history.push(baseRoute);
  }, [baseRoute, history]);

  const handleSuccessRoute = useCallback(
    (res: FeatureCollection) => {
      if (res.features) {
        const dataProp =
          type === "plant"
            ? "crop"
            : type === "fixed"
            ? "fixedAssetType"
            : type === "animal"
            ? "assetAnimalType"
            : "";
        const assetId = res?.features[0]?.id;
        const ftrType = res?.features[0]?.properties?.[dataProp];
        const fldId = res?.features[0]?.properties?.fieldId;
        const grpId = res?.features[0]?.properties?.assetGroupId;
        const saveUrl = `${baseRoute}/${ftrType}/${fldId}/items/${assetId}${
          grpId ? `?groupId=${grpId}` : ""
        }`;
        history.push(saveUrl);
      }
    },
    [baseRoute, history, type]
  );

  const getCancelHref = useCallback(() => {
    if (!subtype) {
      return baseRoute;
    }
    return url;
  }, [url, baseRoute, subtype]);

  useEffect(() => {
    enableDraw();
    return disableDraw;
  }, [enableDraw, disableDraw]);

  return (
    <>
      {type === "plant" ? (
        <PlantAssetForm
          onSave={handleSuccessRoute}
          onDelete={handleDeleteRoute}
          cancelHref={getCancelHref()}
        />
      ) : type === "fixed" ? (
        <FixedAssetForm
          onSave={handleSuccessRoute}
          onDelete={handleDeleteRoute}
          cancelHref={getCancelHref()}
        />
      ) : type === "animal" ? (
        <AnimalAssetForm
          onSave={handleSuccessRoute}
          onDelete={handleDeleteRoute}
          cancelHref={getCancelHref()}
        />
      ) : null}
    </>
  );
}
