import { useCallback, useMemo } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Polygon } from "geojson";
import { collectEventKeys } from "./useCollectEvents";

export interface ImageryExtractData {
  dem: string;
  dsm: string;
  ht: number;
  typeId: string;
  fieldId: string;
  subfieldIds: string[];
}

interface ImageryExtractSaveData {
  fieldId: string;
  geometry: Polygon;
  lossEventUtc?: string;
  typeId: string;
  subfieldGroupId?: string;
}

export function useImageryExtract({
  org,
  ...props
}: ImageryExtractData & { org: string }) {
  const queryKey = useMemo(
    () => ["organization", org, "insights", "imagery", "extract", { ...props }],
    [org, props]
  );

  const postExtract = useCallback(async () => {
    const body = { ...props };
    const url = `/${org}/imagery/extract`;
    if (body) {
      // NOTE: need to flip string to array value
      const subfieldIds =
        typeof body.subfieldIds === "string"
          ? (body.subfieldIds as string).split(",")
          : body.subfieldIds;
      body.subfieldIds = subfieldIds;
    }
    const res = await axios.post(url, body);

    return res?.data;
  }, [org, props]);

  const q = useQuery<Polygon, Error>({
    queryKey,
    queryFn: postExtract,
    enabled: Boolean(
      props?.dem &&
        props?.dsm &&
        props?.fieldId &&
        props?.ht !== undefined &&
        props?.subfieldIds?.length &&
        props?.typeId
    ),
  });

  return q;
}

export function useSaveImageryExtract(org: string) {
  const saveImageryExtract = useCallback(
    async (body) => {
      const { geometry, ...rest } = body;
      const url = `/${org}/imagery/save`;
      const req = await axios.post(url, {
        ...(geometry && {
          geometry: JSON.stringify(geometry),
        }),
        ...rest,
      });

      return req.data;
    },
    [org]
  );

  const mutation = useMutation<
    { id: string },
    Error,
    ImageryExtractSaveData,
    unknown
  >({
    mutationFn: saveImageryExtract,
  });
  return { ...mutation };
}

export function useCreateCollectEvent(org: string) {
  const save = useCallback(
    async (body) => {
      const url = `/${org}/collectevents/plantheight`;
      const req = await axios.post(url, {
        ...body,
      });

      return req.data;
    },
    [org]
  );

  const mutation = useMutation<
    {
      bbox: [number, number, number, number];
      sensorType: string;
      rasterType: string;
      collectEventId: string;
    },
    Error,
    { demCollectEventId: string; dsmCollectEventId: string; name: string },
    unknown
  >({
    mutationFn: save,
  });
  return {
    ...mutation,
    saveCollectEvent: mutation.mutate,
  };
}

export function usePlantHeightCollectEvent(
  org?: string,
  demId?: string,
  dsmId?: string
) {
  const checkForCollectEvent = useCallback(async () => {
    const res = await axios.get<{
      bbox: [number, number, number, number];
      sensorType: string;
      rasterType: string;
      collectEventId: string;
      collectEventName: string;
    }>(`/${org}/collectevents/plantheight?dem=${demId}&dsm=${dsmId}`);
    return res.data;
  }, [org, demId, dsmId]);

  const query = useQuery({
    queryKey: collectEventKeys.byDemAndDsm(org, demId, dsmId),
    queryFn: checkForCollectEvent,
    enabled: !!org && !!demId && !!dsmId,
  });

  return query;
}
