import { useCallback } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { eventKeys } from "./assetKeys";

export function useAssetEventDelete(org: string, assetId: string) {
  const queryClient = useQueryClient();

  const deleteEvent = useCallback(
    async (id: string) => {
      return axios.delete(`/${org}/assets/${assetId}/events/${id}`);
    },
    [assetId, org]
  );

  return useMutation({
    mutationFn: deleteEvent,
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: eventKeys.allById(org, assetId),
      });
    },
  });
}
