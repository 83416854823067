import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { OperationTilled } from "api/operations/types/OperationTilled";
import { useOrgState } from "providers";
import { useOperationsGeodataById } from "api/operations/useOperationsGeodataById";
import { useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import OperationBreadcrumbs from "./OperationBreadcrumbs";
import { EditIconButton } from "components/EditIconButton";
import BaseCardView from "lib/Card/BaseCardView";
import SummaryCardTitle from "lib/Card/SummaryCardTitle";
import SummaryCardValue from "lib/Card/SummaryCardValue";

export default function TilledOperationDetails() {
  const { org, season, rootUrl } = useOrgState();
  const { itemId } = useParams<{ itemId: string }>();
  const { t } = useTranslation();

  const geodataQ = useOperationsGeodataById<OperationTilled>({
    id: itemId,
    org,
    seasonId: season?.id,
    type: "tilled",
  });
  const details = geodataQ.data?.features[0].properties;
  return (
    <Stack spacing={2} sx={{ p: 3 }}>
      <Stack direction={"row"}>
        <OperationBreadcrumbs type="tilled" />
        {itemId ? (
          <EditIconButton
            sx={{ ml: "auto" }}
            href={`${rootUrl}/inventory/operations/tilled/items/${itemId}/edit`}
          />
        ) : null}
      </Stack>
      <Paper
        elevation={4}
        sx={{ bgcolor: "background.paper", p: 3, width: "100%" }}
      >
        <Stack direction={"row"}>
          <Typography
            sx={{
              mb: 2,
              textTransform: "uppercase",
              fontSize: 14,
            }}
          >
            {t("inventory.operations.operationDetails")}
          </Typography>
          <Typography
            sx={{
              ml: "auto",
            }}
          >
            {`${
              details?.beginDateUtc
                ? new Date(details.beginDateUtc).toLocaleDateString()
                : "--"
            } - ${
              details?.endDateUtc
                ? new Date(details.endDateUtc).toLocaleDateString()
                : "--"
            }`}
          </Typography>
        </Stack>
        <Stack direction={"row"} gap={2} flexWrap={"wrap"}>
          <BaseCardView variant="outlined" sx={{ bgcolor: "background.paper" }}>
            <SummaryCardTitle>{t("common.depth")}</SummaryCardTitle>
            <SummaryCardValue>
              {details ? `${details.depth} ${details.depthUom ?? ""}` : "--"}
            </SummaryCardValue>
          </BaseCardView>
          <BaseCardView variant="outlined" sx={{ bgcolor: "background.paper" }}>
            <SummaryCardTitle>{t("common.type")}</SummaryCardTitle>
            <SummaryCardValue>
              {details ? `${details.tilledType}` : "--"}
            </SummaryCardValue>
          </BaseCardView>
          <BaseCardView variant="outlined" sx={{ bgcolor: "background.paper" }}>
            <Stack>
              <SummaryCardTitle>{t("common.area")}</SummaryCardTitle>
              <SummaryCardValue>
                {details
                  ? `${details.area ?? "--"} ${details.areaUom ?? ""}`
                  : "--"}
              </SummaryCardValue>
            </Stack>
          </BaseCardView>
          <BaseCardView variant="outlined" sx={{ bgcolor: "background.paper" }}>
            <Stack>
              <SummaryCardTitle>
                {t("inventory.operations.mappedArea")}
              </SummaryCardTitle>
              <SummaryCardValue>
                {details
                  ? `${details.geometryArea ?? "--"} ${details.areaUom ?? ""}`
                  : "--"}
              </SummaryCardValue>
            </Stack>
          </BaseCardView>
        </Stack>
      </Paper>
    </Stack>
  );
}
