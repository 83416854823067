import axios from "axios";
import { useCallback } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { assetKeys, eventKeys } from "./assetKeys";
import { ParsedImage } from "api/types";

export function useAssetPhotoPost({
  org,
  assetId,
  eventId,
}: {
  org: string;
  assetId?: string;
  eventId?: string;
}) {
  const queryClient = useQueryClient();

  const invalidate = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: eventId
        ? eventKeys.byId(org, eventId)
        : assetKeys.byId(org, assetId),
    });
    if (eventId) {
      queryClient.invalidateQueries({
        queryKey: eventKeys.allById(org, assetId),
      });
    }
  }, [assetId, eventId, org, queryClient]);

  const savePhotoFn = useCallback(
    async (image: ParsedImage) => {
      const url = eventId
        ? `/${org}/assetevents/${eventId}/photos`
        : `/${org}/assets/${assetId}/photos`;
      const formData = new window.FormData();
      formData.append("", image.file, image.file.name);
      const res = await axios.post<{ id: string }>(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    },
    [assetId, eventId, org]
  );
  const savePhotoMutation = useMutation({
    mutationFn: savePhotoFn,
    onMutate: async (newPhoto) => {
      const key = eventId
        ? eventKeys.photosById(org, eventId)
        : assetKeys.photosById(org, assetId);
      await queryClient.cancelQueries({ queryKey: key });
      // Snapshot the previous value
      const previousPhotos = queryClient.getQueryData(key);
      queryClient.setQueryData(key, (old: ParsedImage[]) => [...old, newPhoto]);
      // Return a context object with the snapshotted value
      return { previousPhotos };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (_err, _newTodo, context) => {
      const key = eventId
        ? eventKeys.byId(org, eventId)
        : assetKeys.byId(org, assetId);
      queryClient.setQueryData(key, context.previousPhotos);
    },
    // Always refetch after error or success:
    onSettled: () => {
      invalidate();
    },
  });

  return savePhotoMutation;
}
