// import PlantIcon from "icons/PlantIcon";
// import AnimalIcon from "icons/AnimalIcon";
// import EquipmentIcon from "icons/EquipmentIcon";
// import FixedIcon from "icons/FixedIcon";
import { AnimalIcon, EquipmentIcon, FixedIcon, PlantIcon } from "lib/Icons";

// parse unique fields list by data
export function parseFieldsFromData(data, fields) {
  const fieldObjs = [];
  if (data) {
    data.forEach((op) => {
      // check if parsing fields from non-field data (assets/operations)
      // and pull landAgreementTypeId from the field object
      if (!op.landAgreementTypeId && fields) {
        const field = fields.find((f) => f.id === op.fieldId);
        op.landAgreementTypeId = field?.landAgreementTypeId;
      }
      // check if we already have the field
      const exists = fieldObjs.find(
        (fo) => fo.field === op.field && fo.farm === op.farm
      );
      // if field not yet in list, add field row object
      if (!exists) {
        fieldObjs.push({
          farm: op.farm,
          field: op.field,
          fieldId: op.fieldId,
          id: op.fieldId,
          landAgreementTypeId: op.landAgreementTypeId,
          // store field assets as children on the field object
          children: [{ ...op }],
        });
      } else {
        // field already in the list, but we still want
        // to append the other asset children to the field obj
        exists.children.push({ ...op });
      }
    });
  }

  return fieldObjs;
}

export function parseSubtypesFromData(d) {
  const data = [];
  if (d) {
    d.forEach((i) => {
      const exists = data.find((datum) => {
        return datum.value === i.subtypeId;
      });
      if (!exists) {
        data.push({
          value: i.subtypeId,
          name: i.subtype,
          geometryType: i.geometryType,
        });
      }
    });
  }

  return data;
}

export function getDataByFields(fields, ops) {
  let finalOps = [];
  if (fields) {
    fields.forEach((item) => {
      const opsByField = ops.filter((op) => item.fieldId === op.fieldId);
      finalOps = finalOps.concat(opsByField);
    });
  }
  return finalOps;
}

export function getDefaultExpandedRows(ops) {
  const expanded = {};
  if (ops) {
    ops.forEach((co) => {
      expanded[`fieldFarmTypeKey:${co.fieldFarmTypeKey}`] = true;
      expanded[`fieldFarmGroupKey:${co.fieldFarmGroupKey}`] = true;
      expanded[
        `field:${co.field}>date:${new Date(
          co.beginDateUtc || co.bornOnUtc
        ).toLocaleDateString()}`
      ] = true;
    });
  }
  return expanded;
}

export function getDefaultSelectedRows(fields) {
  const selected = {};
  if (fields) {
    fields.forEach((co, idx) => {
      selected[idx] = true;
    });
  }
  return selected;
}

export function getAssetIcon(assetType) {
  let Icon;
  switch (assetType) {
    case "plant": {
      Icon = PlantIcon;
      break;
    }
    case "fixed": {
      Icon = FixedIcon;
      break;
    }
    case "equipment": {
      Icon = EquipmentIcon;
      break;
    }
    case "animal": {
      Icon = AnimalIcon;
      break;
    }
    default:
      break;
  }
  return Icon;
}
