import { IDataLoadingAction, setAppDataLoading } from "app-utils";
import { Dispatch, useEffect } from "react";

export default function useSetAppDataLoading({
  dispatch,
  isFetchingData,
}: {
  dispatch: Dispatch<IDataLoadingAction>;
  isFetchingData: boolean;
}) {
  useEffect(() => {
    if (isFetchingData) {
      setAppDataLoading(dispatch, true);
    } else {
      setAppDataLoading(dispatch, false);
    }
    return () => {
      setAppDataLoading(dispatch, false);
    };
  }, [dispatch, isFetchingData]);
}
