import React from "react";
import { useParams } from "react-router-dom";
import { useOrgState } from "providers/OrgProvider";
import { ConservationProvider } from "providers/ConservationProvider";
import { ConservationView } from "./ConservationView";

export function Conservation() {
  const { editId } = useParams();
  const { org, season } = useOrgState();

  return (
    <ConservationProvider org={org} season={season} editId={editId}>
      <ConservationView />
    </ConservationProvider>
  );
}
