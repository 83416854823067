import useAreaColumn from "components/Table/useAreaColumn";
import React from "react";
import { useTranslation } from "react-i18next";
import { IRXSubfield } from "types";

export function useSubfieldColumns() {
  const { t } = useTranslation();
  const fieldGroupHeader = {
    Header: t("common.field"),
    accessor: "subfieldGroupKey",
    Cell: function FieldCol({ value }: { value: string }) {
      const [farm, field, group] = value?.split("|") || [];
      return (
        <div
          style={{ float: "left", display: "flex", flexDirection: "column" }}
        >
          <span>{`${field}`}</span>
          <small>{farm ? `${farm}` : null}</small>
          <small>{group || null}</small>
        </div>
      );
    },
  };

  const areaHeader = useAreaColumn({ aggregate: true });

  const cidHeader = {
    Header: "ID",
    accessor: "cid",
  };

  const subfieldTypeHeader = {
    Header: t("common.type"),
    accessor: "subfieldType",
  };

  const rateHeader = {
    Header: t("common.targetRate"),
    accessor: (row: IRXSubfield) => {
      return `${row.targetRate} ${row.targetRateUom}`;
    },
  };

  const rowSpacingHeader = {
    Header: t("common.rowSpacing"),
    accessor: (row: IRXSubfield) => {
      return `${row.rowSpacing} ${row.rowSpacingUom}`;
    },
  };
  const notesHeader = {
    Header: t("common.notes"),
    accessor: "description",
  };

  const SubfieldsNonRxCols = [
    fieldGroupHeader,
    cidHeader,
    areaHeader,
    notesHeader,
  ];

  const SubfieldsRxCols = [
    fieldGroupHeader,
    cidHeader,
    rateHeader,
    areaHeader,
    rowSpacingHeader,
    notesHeader,
  ];
  return { SubfieldsRxCols, SubfieldsNonRxCols, subfieldTypeHeader };
}
