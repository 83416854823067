import Grid from "@mui/material/Unstable_Grid2";
import { useTranslation } from "react-i18next";
import BaseCardView from "lib/Card/BaseCardView";
import SummaryCardTitle from "lib/Card/SummaryCardTitle";
import SummaryCardValue from "lib/Card/SummaryCardValue";
import { Feature } from "geojson";

export default function FixedAssetDetails({ feature }: { feature: Feature }) {
  const { t } = useTranslation();
  const currentEditFtr = feature;
  const details = currentEditFtr?.properties;

  return (
    <>
      <Grid container gap={2}>
        <BaseCardView sx={{ bgcolor: "background.paper" }}>
          <SummaryCardTitle>{t("common.field")}</SummaryCardTitle>
          <SummaryCardValue>{details?.field}</SummaryCardValue>
        </BaseCardView>
        <BaseCardView sx={{ bgcolor: "background.paper" }}>
          <SummaryCardTitle>{t("common.type")}</SummaryCardTitle>
          <SummaryCardValue>{details?.fixedAssetType}</SummaryCardValue>
        </BaseCardView>
        <BaseCardView sx={{ bgcolor: "background.paper" }}>
          <SummaryCardTitle>{t("common.status")}</SummaryCardTitle>
          <SummaryCardValue>{details?.assetStatus}</SummaryCardValue>
        </BaseCardView>
        <BaseCardView sx={{ bgcolor: "background.paper" }}>
          <SummaryCardTitle>{t("common.name")}</SummaryCardTitle>
          <SummaryCardValue>{details?.name}</SummaryCardValue>
        </BaseCardView>
        <BaseCardView sx={{ bgcolor: "background.paper" }}>
          <SummaryCardTitle>{"ID"}</SummaryCardTitle>
          <SummaryCardValue>{details?.cid}</SummaryCardValue>
        </BaseCardView>
        <BaseCardView sx={{ bgcolor: "background.paper" }}>
          <SummaryCardTitle>
            {t("inventory.assets.createdDate")}
          </SummaryCardTitle>
          <SummaryCardValue>
            {details?.bornOnUtc
              ? new Date(details?.bornOnUtc).toLocaleDateString()
              : "--"}
          </SummaryCardValue>
        </BaseCardView>
        <BaseCardView sx={{ bgcolor: "background.paper" }}>
          <SummaryCardTitle>{t("common.notes")}</SummaryCardTitle>
          <SummaryCardValue>{details?.description || "--"}</SummaryCardValue>
        </BaseCardView>
      </Grid>
    </>
  );
}
