import React from "react";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import { fitBounds } from "lib/MapboxMap/utils";
import { Map } from "mapbox-gl";
import { FormLabel, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEnterpriseLayersActions } from "./useEnterpriseLayersActions";
import { useOrgState } from "providers/OrgProvider";
import { useEnterpriseLayers } from "./useEnterpriseLayers";

export default function EnterpriseLayersList({ map }: { map: Map }) {
  const { t } = useTranslation();
  const { configQuery } = useOrgState();
  const activeLayers = useEnterpriseLayers();
  const { setLayers } = useEnterpriseLayersActions();

  return (
    <FormGroup>
      <FormLabel>{t("map.administrative")}</FormLabel>
      <Stack rowGap={2}>
        {configQuery.data?.layers.length ? (
          configQuery.data?.layers.map((i) => {
            return (
              <Box
                key={`${i.name}`}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IconButton
                  onClick={() => {
                    if (i.bbox) {
                      fitBounds({
                        map,
                        bbox: i.bbox,
                      });
                    }
                  }}
                >
                  <ZoomInIcon />
                </IconButton>
                <Box>
                  <FormControlLabel
                    sx={{
                      m: 0,
                    }}
                    control={
                      <Checkbox
                        checked={
                          activeLayers
                            ?.filter((al) => al.visible)
                            ?.map((al) => al.id)
                            .includes(i.id) ?? false
                        }
                        onChange={(e) => {
                          const checked = e.target.checked;
                          const newLayers = [...configQuery.data.layers];
                          const item = newLayers.find((l) => l.id === i.id);
                          item.visible = !!checked;
                          setLayers(newLayers);
                        }}
                      />
                    }
                    label={`${i.name}`}
                  />
                </Box>
              </Box>
            );
          })
        ) : (
          <>No layers</>
        )}
      </Stack>
    </FormGroup>
  );
}
