import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useOrgState } from "providers/OrgProvider";
import {
  useInventoryState,
  useInventoryDispatch,
} from "providers/InventoryProvider";
import { GeoDataTable } from "components";
import { useTableColumns } from "components/useTableColumns";
import { useOperations } from "api/operations/useOperations";
import { useOperationsGeodataByFields } from "api/operations/useOperationsGeodataByFields";
import { OperationType } from "types/OperationType";
import { useFieldsState } from "providers";
import { FeatureCollection } from "geojson";
import InventoryCollapseAddButtons from "components/InventoryCollapseAddButtons";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

export default function OperationsListView({
  activeType,
  expanded,
  toggleExpanded,
}: {
  activeType: OperationType;
  expanded: boolean;
  toggleExpanded: () => void;
}) {
  const { org, season, rootUrl } = useOrgState();
  const { t } = useTranslation();
  const { operations: operationsCols } = useTableColumns();
  const { inventoryMap, ftrsClicked } = useInventoryState();
  const { fieldsGeodataState } = useFieldsState() as {
    fieldsGeodataState: { data: FeatureCollection };
  };
  const {
    highlightLayerFeatures,
    fitInventoryBounds,
    setInventoryFtrs,
  } = useInventoryDispatch();
  const [selectedFieldIds, setSelectedFieldIds] = useState([]);

  const operationsGeoQ = useOperationsGeodataByFields({
    org,
    type: activeType,
    fieldIds: selectedFieldIds,
    seasonId: season?.id,
  });

  const operationsQ = useOperations({
    org,
    type: activeType,
    seasonId: season?.id,
  });

  function getOpTypeText() {
    switch (activeType) {
      case "planted": {
        return t("inventory.operations.plantedOperations");
      }
      case "applied": {
        return t("inventory.operations.appliedOperations");
      }
      case "harvested": {
        return t("inventory.operations.harvestedOperations");
      }
      case "tilled": {
        return t("inventory.operations.tilledOperations");
      }
      default: {
        return t("inventory.operations.title");
      }
    }
  }

  function getCols() {
    const editCol = {
      Header: "",
      id: "actions",
      isSortable: false,
      accessor: function Cell(row: { id: string }) {
        return (
          <IconButton
            sx={{
              "&:hover": {
                color: "text.primary",
              },
            }}
            href={`${rootUrl}/inventory/operations/${activeType}/items/${row.id}`}
          >
            <InfoOutlined />
          </IconButton>
        );
      },
    };
    if (activeType?.toLowerCase() === "planted") {
      return [editCol, ...operationsCols.planted];
    }
    if (activeType?.toLowerCase() === "applied") {
      return [editCol, ...operationsCols.applied];
    }
    if (activeType?.toLowerCase() === "harvested") {
      return [editCol, ...operationsCols.harvested];
    }
    if (activeType?.toLowerCase() === "tilled") {
      return [editCol, ...operationsCols.tilled];
    }
    return null;
  }

  useEffect(() => {
    setInventoryFtrs(operationsGeoQ.data);
  }, [operationsGeoQ.data, setInventoryFtrs]);

  return (
    <Stack sx={{ px: 2, overflow: "auto" }}>
      <Stack direction={"row"} sx={{ alignItems: "center", p: 2 }}>
        <Typography component={"h3"} variant="h5">
          {getOpTypeText()}
        </Typography>
        <InventoryCollapseAddButtons
          toggleExpanded={toggleExpanded}
          expanded={expanded}
          addUrl={`${rootUrl}/inventory/operations/${activeType}/add`}
        />
      </Stack>
      <Box sx={{ pb: 3, height: "100%", overflow: "auto" }}>
        <GeoDataTable
          isLoading={operationsQ.isLoading || operationsGeoQ.isLoading}
          paging={operationsQ.data?.length > 100}
          map={inventoryMap}
          ftrsClicked={ftrsClicked}
          highlightByIds={highlightLayerFeatures}
          hiddenColumns={["farm"]}
          data={operationsQ.data || []}
          featureCollection={operationsGeoQ.data}
          columns={getCols()}
          onRowExpand={(row: {
            leafRows: { original: { fieldId: string } }[];
          }) => {
            const { fieldId } = row.leafRows[0].original;
            if (!selectedFieldIds?.includes(fieldId)) {
              setSelectedFieldIds([...selectedFieldIds, fieldId]);
            }
            const field = fieldsGeodataState.data?.features?.find(
              (f) => f.properties.id === fieldId
            );
            if (field) {
              fitInventoryBounds({
                geojson: { type: "FeatureCollection", features: [field] },
              });
            }
          }}
          groupBy={["fieldFarmGroupKey"]}
        />
      </Box>
    </Stack>
  );
}
