import axios from "axios";
import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { assetKeys } from "./assetKeys";

export interface IAssetTypeSummaryItem {
  assetType: string;
  asset: string;
  assetEn: string;
  color: string;
  count: number;
  field: string;
  fieldId: string;
  subtype: string;
  subtypeId?: string;
}

export interface IAssetSubtypeSummaryItem extends IAssetTypeSummaryItem {
  subtype: string;
  subtypeId: string;
}

export function useAssetsSeasonSummary(org: string, seasonId: string) {
  const getSummary = useCallback(async () => {
    const res = await axios.get<IAssetTypeSummaryItem[]>(
      `/${org}/assets/season/${seasonId}/summary`
    );
    return res.data;
  }, [seasonId, org]);

  return useQuery({
    queryKey: assetKeys.bySeasonSummary(org, seasonId),
    queryFn: getSummary,
    enabled: Boolean(org && seasonId),
  });
}

export function useAssetsByTypeSummary(org: string, assetType: string) {
  const getTypeSummaryList = useCallback(async () => {
    const res = await axios.get<IAssetTypeSummaryItem[]>(
      `/${org}/assets/${assetType}/summary`
    );

    return res.data;
  }, [assetType, org]);

  return useQuery({
    queryKey: assetKeys.byTypeSummary(org, assetType),
    queryFn: getTypeSummaryList,
    enabled: Boolean(org && assetType && assetType !== "equipment"),
  });
}

export function useAssetsBySubtypeSummary(
  org: string,
  assetType: string,
  subtypeId: string,
  seasonId: string
) {
  const getSubtypeSummaryList = useCallback(async () => {
    const res = await axios.get<IAssetSubtypeSummaryItem[]>(
      `/${org}/assets/${assetType}/${subtypeId}/season/${seasonId}/summary`
    );

    return res?.data;
  }, [assetType, org, seasonId, subtypeId]);

  return useQuery({
    queryKey: assetKeys.bySubtypeSummary(org, assetType, subtypeId, seasonId),
    queryFn: getSubtypeSummaryList,
    enabled: Boolean(
      org && assetType && subtypeId && seasonId && assetType !== "equipment"
    ),
  });
}

export function useAssetsByFieldAndGroup(
  org: string,
  assetType: string,
  subtypeId: string,
  fieldId: string,
  assetGroupId?: string
) {
  const getSubtypeSummaryList = useCallback(async () => {
    let url = `/${org}/assets/${assetType}/${subtypeId}/fields/${fieldId}/summary`;
    if (assetGroupId) {
      url += `?assetGroupId=${assetGroupId}`;
    }
    const res = await axios.get<IAssetSubtypeSummaryItem[]>(url);

    return res?.data;
  }, [assetGroupId, assetType, fieldId, org, subtypeId]);
  return useQuery({
    queryKey: assetKeys.byFieldAndGroupSummary(
      org,
      assetType,
      subtypeId,
      fieldId,
      assetGroupId
    ),
    queryFn: getSubtypeSummaryList,
    enabled: Boolean(
      org && assetType && subtypeId && fieldId && assetType !== "equipment"
    ),
  });
}
