import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useHistory, useParams } from "react-router-dom";
import { useOrgState } from "providers/OrgProvider";
import {
  useInventoryDispatch,
  useInventoryState,
} from "providers/InventoryProvider";
import { useDamageDispatch, useDamageState } from "providers/DamageProvider";
import { DatePicker } from "lib/DatePicker";
import { FormWrapper } from "components";
import { useDamageTypes } from "api/damage/useDamageTypes";
import FieldSelect from "components/FieldSelect";
import Autocomplete from "lib/Select/Autocomplete";
import { IUrlParams } from "types";
import { useFields } from "api/fields/useFields";
import { useQueryClient } from "@tanstack/react-query";

interface Damage {
  id: string;
  fieldId: string;
  farmId: string;
  damageTypeId: string;
  lossEventUtc: string;
  assessedOnUtc: string;
  description: string;
  geometry: string;
  gpsSourceTypeId: "e9aff444-911c-40a3-8222-614d70162d38";
}

const defaultValues = {
  id: "",
  fieldId: "",
  farmId: "",
  damageTypeId: "",
  lossEventUtc: "",
  assessedOnUtc: "",
  description: "",
  geometry: "",
  gpsSourceTypeId: "e9aff444-911c-40a3-8222-614d70162d38",
};

export default function DamageForm() {
  const { t } = useTranslation();
  const { editId } = useParams<IUrlParams>();
  const { org, rootUrl, season } = useOrgState();
  const { zoomToField } = useInventoryDispatch();
  const history = useHistory();
  const {
    currentEditFtr,
    saveDamageState,
    deleteDamageState,
  } = useDamageState();
  const damageTypesQ = useDamageTypes(org);
  const { saveDamage, deleteDamage } = useDamageDispatch();
  const fieldsQ = useFields({ org, seasonId: season?.id });
  const { drawData } = useInventoryState();
  const methods = useForm({
    defaultValues: { ...defaultValues, ...currentEditFtr?.properties },
  });
  const queryClient = useQueryClient();
  const {
    handleSubmit,
    register,
    reset,
    formState,
    watch,
    control,
    errors,
    setValue,
  } = methods;
  const { id, lossEventUtc, assessedOnUtc, description } = watch();

  async function handleSave(d: Damage) {
    if (!formState.isDirty) {
      history.push(`${rootUrl}/inventory/damage`);
      return false;
    }
    d.geometry = d.geometry ? JSON.parse(d.geometry) : "";
    // server doesn't want these props if not set
    if (!d.id) {
      delete d.id;
    }
    if (!d.assessedOnUtc) {
      delete d.assessedOnUtc;
    }
    const final = {
      ...d,
      gpsSourceTypeId: defaultValues.gpsSourceTypeId,
    };
    const res = await saveDamage(final);
    if (!res.isError) {
      queryClient.invalidateQueries({
        queryKey: ["organization", org, "damage", "summary"],
      });
      reset(final);
      history.push(`${rootUrl}/inventory/damage`);
    }
    return res;
  }
  // TODO: draw vs apply to whole field
  // const [createType, setCreateType] = useState('draw');
  // create opts to pass to Select components
  const damageTypeOpts = damageTypesQ?.data
    ? damageTypesQ?.data.map((d) => ({
        label: d.name,
        value: d.id,
        id: d.id,
      }))
    : [];

  return (
    <FormWrapper
      methods={methods}
      data={{ ...currentEditFtr?.properties }}
      geometryData={drawData?.features[0]?.geometry}
      existingGeom={currentEditFtr?.geometry}
      cancelHref={`${rootUrl}/inventory/damage`}
      saveState={saveDamageState}
      deleteState={deleteDamageState}
      onDelete={async () => {
        const res = await deleteDamage(id);
        if (!res.isError) {
          queryClient.invalidateQueries({
            queryKey: ["organization", org, "damage", "summary"],
          });
          reset(defaultValues);
          history.push(`${rootUrl}/inventory/damage`);
        }
      }}
      onSubmit={handleSubmit(handleSave)}
    >
      <input ref={register} required type="hidden" name="gpsSourceTypeId" />
      <Stack spacing={2}>
        <Controller
          name="fieldId"
          htmlFor="field-select"
          control={control}
          rules={{ required: true }}
          render={({
            onChange,
            value,
          }: {
            onChange: (_i: string) => void;
            value: string;
          }) => (
            <FieldSelect
              required
              sx={{ display: editId ? "none" : undefined }}
              value={
                fieldsQ.data?.find((f: { id: string }) => f.id === value) ??
                null
              }
              onChange={(fld) => {
                if (fld?.id) {
                  zoomToField(fld.id);
                }
                console.log(fld);
                onChange(fld?.id ?? "");
              }}
              fields={fieldsQ.data ?? []}
            />
          )}
        />
        <Controller
          name="damageTypeId"
          control={control}
          rules={{ required: true }}
          htmlFor="damage-type-select"
          render={({
            onChange,
            value,
          }: {
            onChange: (_i: string) => void;
            value: string;
          }) => (
            <Autocomplete
              inputProps={{
                required: true,
              }}
              label={`${t("common.type")} *`}
              value={damageTypeOpts.find((dt) => dt.id === value) ?? null}
              options={damageTypeOpts}
              onChange={(_e, item: { value: string }) => {
                onChange(item.value);
              }}
            />
          )}
        />

        <Controller
          name="lossEventUtc"
          control={control}
          rules={{ required: true }}
          render={({
            onChange,
            value,
          }: {
            onChange: (_i: Date) => void;
            value: string;
          }) => (
            <DatePicker
              slotProps={{
                textField: {
                  required: true,
                },
              }}
              label={`${t("inventory.damage.damageDate")}`}
              minDate={season?.beginOnUtc ? new Date(season.beginOnUtc) : null}
              maxDate={season?.endOnUtc ? new Date(season.endOnUtc) : null}
              value={value ? new Date(value) : null}
              onChange={(date) => {
                // reset assessed date if damage date later
                if (assessedOnUtc && date > new Date(assessedOnUtc)) {
                  setValue("assessedOnUtc", "");
                }
                onChange(date);
              }}
            />
          )}
        />

        <Controller
          name="assessedOnUtc"
          control={control}
          render={({
            onChange,
            value,
          }: {
            onChange: (_i: Date) => void;
            value: string;
          }) => (
            <DatePicker
              label={t("inventory.damage.assessedDate")}
              maxDate={new Date()}
              minDate={
                // set min date to current damageDate if set,
                // else use the season begin date as min date
                lossEventUtc
                  ? new Date(lossEventUtc)
                  : season?.beginOnUtc
                  ? new Date(season.beginOnUtc)
                  : null
              }
              value={value ? new Date(value) : null}
              onChange={(date) => {
                // reset assessed date if damage date later
                if (assessedOnUtc && date > new Date(assessedOnUtc)) {
                  setValue("assessedOnUtc", "");
                }
                onChange(date);
              }}
            />
          )}
        />
        <TextField
          value={description}
          ref={register}
          label={t("common.notes")}
          multiline
          inputProps={{
            name: "description",
            ref: register,
            maxLength: 255,
          }}
        />
        {errors?.geometry ? (
          <Typography variant="body2" color="error">
            {t("common.geometryRequired")}
          </Typography>
        ) : null}
      </Stack>
    </FormWrapper>
  );
}
