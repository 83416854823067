export const assetKeys = {
  all: (org: string) => ["organization", org, "assets"] as const,
  allBySeason: (org: string, type: string, seasonId: string) =>
    ["organization", org, "assets", type, "season", seasonId] as const,
  assetSubtype: (org: string, type: string, name: string) =>
    ["organization", org, "assets", type, name] as const,
  byId: (org: string, id: string) => {
    return ["organization", org, "assets", id] as const;
  },
  byFieldAndGroupSummary: (
    org: string,
    assetType: string,
    subtypeId: string,
    fieldId: string,
    assetGroupId: string
  ) => {
    return [
      "organization",
      org,
      `assets`,
      "summary",
      assetType,
      "subtypeId",
      subtypeId,
      "fieldId",
      fieldId,
      "assetGroupId",
      assetGroupId,
    ] as const;
  },
  bySeasonSummary: (org: string, seasonId: string) => {
    return [
      "organization",
      org,
      "assets",
      "summary",
      "seasonId",
      seasonId,
    ] as const;
  },
  byTypeSummary: (org: string, assetType: string) => {
    return ["organization", org, `assets`, "summary", assetType] as const;
  },
  bySubtypeSummary: (
    org: string,
    assetType: string,
    subtypeId: string,
    seasonId: string
  ) => {
    return [
      "organization",
      org,
      `assets`,
      "summary",
      assetType,
      "subtypeId",
      subtypeId,
      "seasonId",
      seasonId,
    ] as const;
  },
  photosById: (org: string, id: string) =>
    ["organization", org, "assets", id, "photos"] as const,
  geodataById: (org: string, type: string, id: string) =>
    ["organization", org, "assets", type, "geodata", id] as const,
  geodataByParams: (
    org: string,
    type: string,
    params: Record<string, unknown>
  ) => ["organization", org, "assets", type, "geodata", params] as const,
};

export const eventKeys = {
  allById: (org: string, id: string) =>
    ["organization", org, "assets", id, "assetevents"] as const,
  photosById: (org: string, id: string) =>
    ["organization", org, "assetevents", id, "photos"] as const,
  byId: (org: string, id: string) => {
    return ["organization", org, "assetevents", id] as const;
  },
};
