import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import { LinkProps } from "react-router-dom";

export function AddIconButton({
  sx,
  ...props
}: IconButtonProps & { href?: LinkProps["href"] }) {
  return (
    <IconButton
      color="success"
      sx={{ "&:hover": { color: "success.main" }, ...sx }}
      {...props}
    >
      <AddIcon />
    </IconButton>
  );
}
