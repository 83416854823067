import { useOperationAppliedMapThemes } from "./useOperationAppliedMapThemes";
import { useOperationHarvestedMapThemes } from "./useOperationHarvestedMapThemes";
import { useOperationPlantedMapThemes } from "./useOperationPlantedMapThemes";
import { OperationType } from "types/OperationType";
import { useOperationTilledMapThemes } from "./useOperationTilledMapThemes";

export function useOperationMapThemes({
  org,
  seasonId,
  type,
}: {
  org: string;
  seasonId: string;
  type: OperationType;
}) {
  const plantedQ = useOperationPlantedMapThemes({
    org,
    seasonId,
    enabled: type === "planted",
  });
  const appliedQ = useOperationAppliedMapThemes({
    org,
    seasonId,
    enabled: type === "applied",
  });
  const harvestedQ = useOperationHarvestedMapThemes({
    org,
    seasonId,
    enabled: type === "harvested",
  });
  const tilledQ = useOperationTilledMapThemes({
    org,
    seasonId,
    enabled: type === "tilled",
  });
  return type === "applied"
    ? appliedQ
    : type === "harvested"
    ? harvestedQ
    : type === "tilled"
    ? tilledQ
    : plantedQ;
}
