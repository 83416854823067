import React, { useEffect, useState } from "react";
import { Route, Switch, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { InventoryProvider } from "providers/InventoryProvider";
import { Fields } from "./Fields";
import { Operations } from "./Operations";
import { Damage } from "./Damage";
import { ProductsView } from "./Products";
import { Assets } from "./Assets";
import InventoryMapView from "./MapView/InventoryMapView";
import { Conservation } from "./Conservation";
import { Scouting } from "./Scouting";
import { useFieldsState } from "providers/FieldsProvider";
import { useCallback } from "react";
import { IUrlParams } from "types";
import { Map } from "mapbox-gl";
import { FeatureCollection } from "geojson";
import { SubfieldsView } from "./Subfields/SubfieldsView";
import { Grid } from "components/layout/Grid";

export default function InventoryView() {
  const [mapRef, setMapRef] = useState<Map>();
  const [inventoryFtrs, setInventoryFtrs] = useState<FeatureCollection>();
  const [isMapLoading, setIsMapLoading] = useState();
  const [drawFtr, setDrawFtr] = useState<FeatureCollection>();
  const [outsideDrawData, setOutsideDrawData] = useState();
  const { fieldsGeodataState } = useFieldsState();
  const [showMap, setShowMap] = useState(true);
  const [showFieldLabels, setShowFieldLabels] = useState(false);
  const [hideFields, setHideFields] = useState(false);
  const [drawOptions, setDrawOptions] = useState();
  const [useDraw, setUseDraw] = useState(false);
  const [colSizes, setColSizes] = useState([5, 7]);
  const [title, setTitle] = useState();
  const [labelProp, setLabelProp] = useState("");
  const { t } = useTranslation();

  const { editId, itemId } = useParams<IUrlParams>();
  // resize to fix container differences when showing/hiding the map
  useEffect(() => {
    if (mapRef) {
      mapRef.resize();
    }
  }, [mapRef, showMap, colSizes]);

  return (
    <Box style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <h2>
        {title} {t("inventory.title")}
      </h2>
      <InventoryProvider
        onHideMap={() => {
          setShowMap(false);
        }}
        onShowMap={() => {
          setShowMap(true);
        }}
        onToggleFieldLabels={setShowFieldLabels}
        onShowFields={() => {
          setHideFields(false);
        }}
        onHideFields={() => {
          setHideFields(true);
        }}
        onEnableDraw={() => {
          setUseDraw(true);
        }}
        onDisableDraw={() => {
          setUseDraw(false);
        }}
        setLabelProp={setLabelProp}
        setColSizes={setColSizes}
        setDrawOptions={setDrawOptions}
        fieldsGeodata={fieldsGeodataState?.data}
        inventoryMap={mapRef}
        inventoryFtrs={inventoryFtrs}
        drawData={drawFtr}
        setOutsideDrawData={setOutsideDrawData}
        setInventoryFtrs={setInventoryFtrs}
        setIsMapLoading={setIsMapLoading}
        setTitle={setTitle}
      >
        <Grid height="100%" container overflow={"auto"}>
          <Grid
            lg={!showMap ? 12 : colSizes[0]}
            md={!showMap ? colSizes[0] : 7}
            xs={12}
            style={{
              height: "100%",
              minHeight: "500px",
            }}
          >
            <Stack
              style={{
                height: "100%",
                overflow: "auto",
              }}
            >
              <Switch>
                <Route path={"/:org/:season/inventory/fields/:editId?"}>
                  <Fields />
                </Route>
                <Route
                  path={
                    "/:org/:season/inventory/subfields/:subfieldType/:editId"
                  }
                >
                  <SubfieldsView />
                </Route>
                <Route
                  exact
                  path={"/:org/:season/inventory/subfields/:subfieldType"}
                >
                  <SubfieldsView />
                </Route>
                <Route exact path={"/:org/:season/inventory/subfields"}>
                  <SubfieldsView />
                </Route>
                <Route path="/:org/:season/inventory/operations/:type/items/:itemId">
                  <Operations />
                </Route>
                <Route path="/:org/:season/inventory/operations/:type?">
                  <Operations />
                </Route>
                <Route path="/:org/:season/inventory/products/:type?">
                  <ProductsView />
                </Route>
                <Route path={`/:org/:season/inventory/damage/:editId?`}>
                  <Damage />
                </Route>
                <Route
                  path={
                    "/:org/:season/inventory/assets/:type/:subtype?/:fieldId?"
                  }
                >
                  <Assets />
                </Route>
                <Route path={"/:org/:season/inventory/conservation/:editId?"}>
                  <Conservation />
                </Route>
                <Route
                  path={"/:org/:season/inventory/scouting/:observationId?"}
                >
                  <Scouting />
                </Route>
              </Switch>
            </Stack>
          </Grid>
          <Grid
            lg={showMap ? colSizes[1] : 0}
            md={showMap ? 5 : 0}
            xs={showMap ? 12 : 0}
            style={{
              display: !showMap ? "none" : undefined,
              height: "100%",
              minHeight: "500px",
              overflow: "hidden",
            }}
          >
            <Route path="/:org/:season/inventory/:type">
              <div style={{ height: "100%", position: "relative" }}>
                <InventoryMapView
                  labelProp={labelProp}
                  hideFields={hideFields}
                  showFieldLabels={showFieldLabels}
                  // editId={editId || itemId}
                  editFeature={
                    useDraw && inventoryFtrs
                      ? inventoryFtrs?.features?.find(
                          (f) => f.id === editId || f.id === itemId
                        )
                      : null
                  }
                  mapRef={(m) => {
                    setMapRef(m);
                  }}
                  onDrawChange={useCallback((fc) => {
                    setDrawFtr(fc);
                  }, [])}
                  outsideDrawData={outsideDrawData}
                  draw={useDraw}
                  isLoading={isMapLoading}
                  inventoryFeatures={inventoryFtrs}
                  fields={fieldsGeodataState.data}
                  drawOptions={drawOptions}
                />
              </div>
            </Route>
          </Grid>
        </Grid>
      </InventoryProvider>
    </Box>
  );
}
