import { AppliedOperationDetails } from "api/operations/types/AppliedOperationDetails";
import useProductColumns from "components/Table/useProductColumns";
import DataTable from "lib/DataTable";

export function NutrientDetails({
  data,
}: {
  data: AppliedOperationDetails["productNutrients"];
}) {
  const { applicationColumns } = useProductColumns({
    applicationNameProp: "product",
  });

  return (
    <>
      <DataTable
        isFilterable={false}
        isSortable={false}
        data={data}
        columns={[applicationColumns[0]].concat(applicationColumns.splice(3))}
      />
    </>
  );
}
