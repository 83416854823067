import { Theme } from "@mui/material/styles";
import { Map } from "mapbox-gl";
import ReactDOM from "react-dom";
import MapLayersIcon from "lib/Icons/MapLayersIcon";
import ButtonControl from "components/Map/controls/ButtonControl";
import { ThemeProvider } from "lib";

interface LayersControlOptions {
  theme: Theme;
  onClick: () => void;
}

function LayersControlButton({ onClick }: LayersControlOptions) {
  return (
    <>
      <ButtonControl onClick={onClick}>
        <MapLayersIcon fill="currentColor" />
      </ButtonControl>
    </>
  );
}

class LayersControl {
  options: LayersControlOptions;
  container: HTMLElement;
  map?: Map;
  constructor(options: LayersControlOptions) {
    this.options = { ...options };
    this.container = document.createElement("div");
    this.container.className = "mapboxgl-ctrl cai-mapboxgl-ctrl";
    this.container.addEventListener("contextmenu", (e) => e.preventDefault());
    return this;
  }

  renderReactComponent() {
    if (this.map) {
      ReactDOM.render(
        <ThemeProvider theme={this.options.theme}>
          <LayersControlButton {...this.options} />
        </ThemeProvider>,
        this.container
      );
    }
  }

  onAdd(map: Map) {
    this.map = map;
    this.renderReactComponent();
    return this.container;
  }

  updateOptions(options: Partial<LayersControlOptions>) {
    this.options = { ...this.options, ...options };
    if (this.container) {
      this.renderReactComponent();
    }
  }

  onRemove() {
    if (this.container) {
      ReactDOM.unmountComponentAtNode(this.container);
    }

    this.map = undefined;
  }
}

export default LayersControl;
