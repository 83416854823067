import { useCallback } from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { ProductLookup, YieldType } from "../types";

const keys = {
  all: (org: string, type: string, fieldId: string) =>
    [
      "organization",
      org,
      "yield-insights",
      "products",
      type,
      "field",
      fieldId,
    ] as const,
};

export function useYieldProducts(
  org: string,
  type: YieldType | "imagery",
  fieldId?: string
) {
  const getYieldProducts = useCallback(async () => {
    const res = await axios.post<ProductLookup[]>(
      `/${org}/insights/yield/${type}/products`,
      { fieldIds: [fieldId] }
    );
    res.data.forEach((r) => {
      if (r.dateUtc && type !== "productplanted") {
        r.date = new Date(r.dateUtc).toLocaleDateString();
      }
    });
    return res.data;
  }, [fieldId, org, type]);

  return useQuery({
    queryKey: keys.all(org, type, fieldId),
    queryFn: getYieldProducts,
    enabled: !!org && !!type && !!fieldId && type !== "imagery",
  });
}
