export const blobToImage = (blob: Blob) => {
  return new Promise<HTMLImageElement>((resolve) => {
    const reader = new window.FileReader();
    reader.readAsDataURL(blob);
    reader.onload = (e) => {
      const img = new window.Image();
      img.onload = () => {
        resolve(img as HTMLImageElement);
      };
      img.src = e.target?.result as string;
    };
  });
};

export default blobToImage;
