import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IField } from "types";
import fieldKeys from "./fieldKeys";
import { useInventoryMapThemes } from "api/useInventoryMapThemes";

export function useFields({
  org,
  seasonId,
  withThemes,
}: {
  org: string;
  seasonId: string;
  withThemes?: boolean;
}) {
  const mapThemesQ = useInventoryMapThemes(org, "fields", seasonId);
  const fetchFields = useCallback(async () => {
    const res = await axios.get<IField[]>(`/${org}/fields/season/${seasonId}`);
    return res.data.map((d) => {
      const item = mapThemesQ.data?.raw?.find((m) => m.id === d.id);
      return { ...item, ...d };
    });
  }, [mapThemesQ.data, org, seasonId]);

  const query = useQuery({
    queryKey: fieldKeys.allBySeason(org, seasonId),
    queryFn: fetchFields,
    enabled: !!(org && seasonId && (withThemes ? mapThemesQ.data : true)),
  });
  return query;
}
