import SvgIcon from "@mui/material/SvgIcon";
import { IconProps } from "./IconProps";

export function VerityLogo({ size, sx }: IconProps) {
  return (
    <SvgIcon fontSize={size} sx={sx}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.21802 13.1406L3.16445 11.5802L3.02468 7.5604L3.02429 7.54284C3.00721 7.05094 3.30305 6.58284 3.78874 6.40496C4.39169 6.18456 5.0587 6.49624 5.27883 7.10322L5.31882 7.2378C5.32154 7.25067 5.32387 7.26276 5.32659 7.27563L5.33552 7.32712C5.34212 7.37237 5.346 7.41684 5.34755 7.4617L5.34949 7.50461V7.50969L5.37706 8.31014L5.4881 11.4991L5.54245 13.059C6.84152 13.3883 7.96044 14.3385 8.45313 15.6984C8.53466 15.923 8.59639 16.1512 8.63715 16.3787L5.06801 15.35C5.06685 15.3496 5.06607 15.3496 5.06491 15.3492L5.04472 15.3426C5.04045 15.3422 5.03734 15.3414 5.03424 15.3407C4.65453 15.2299 4.25697 15.2439 3.88464 15.38C3.15396 15.6473 2.66206 16.3514 2.66089 17.1323C2.66089 17.1374 2.6605 17.1425 2.6605 17.1475L2.66128 17.1822C2.66167 17.183 2.66206 17.1928 2.66206 17.1951C2.66827 17.3956 2.70593 17.5899 2.77348 17.776C2.8026 17.8559 2.83366 17.9289 2.87093 17.9987C2.88375 18.0229 2.8985 18.0502 2.91442 18.0775C2.92917 18.1048 2.94975 18.1372 2.97149 18.1692C2.97227 18.1719 2.97848 18.1805 2.98042 18.1828C3.00061 18.214 3.02196 18.2433 3.04487 18.2737C3.06389 18.2987 3.08292 18.3213 3.10194 18.3443C3.14465 18.3962 3.19745 18.4524 3.25995 18.5101C3.26306 18.5128 3.26578 18.5152 3.26888 18.5179C3.35003 18.5916 3.43505 18.6572 3.52241 18.7126C3.55968 18.736 3.58996 18.7543 3.6218 18.7715C3.72352 18.8288 3.84077 18.8791 3.96617 18.9189C3.97782 18.9236 3.98986 18.9271 4.00189 18.9306L4.04072 18.9423C4.04965 18.945 4.05741 18.947 4.06634 18.9497L7.61219 19.9717C7.17192 20.4578 6.61012 20.8471 5.95205 21.0878C3.78059 21.882 1.37852 20.7566 0.588438 18.5748C-0.20203 16.3931 0.918059 13.9796 3.08952 13.1858C3.13183 13.1702 3.17493 13.1554 3.21802 13.1406Z"
          fill="#FBB20D"
        />
        <path
          d="M20.54 15.7529C19.6296 16.086 18.6796 16.0813 17.825 15.8032L16.9558 17.0975L14.7202 20.4277L14.7074 20.4472C14.575 20.6446 14.3813 20.8041 14.141 20.8919C14.1216 20.8989 14.1021 20.9051 14.0835 20.911C14.0711 20.9153 14.0587 20.9192 14.0459 20.9219C13.9721 20.9418 13.8972 20.9543 13.8238 20.9594C13.8102 20.9605 13.7966 20.9613 13.783 20.9613C13.7815 20.9621 13.7803 20.9621 13.7784 20.9621C13.7776 20.9625 13.7764 20.9617 13.7752 20.9621C13.7586 20.9621 13.7419 20.9625 13.7256 20.9613C13.7081 20.9617 13.6898 20.9613 13.6724 20.9598L13.6386 20.957C13.6196 20.9558 13.6009 20.9539 13.5815 20.95C13.564 20.9484 13.5462 20.9449 13.5275 20.9406L13.5023 20.936C13.479 20.9317 13.4549 20.9254 13.4316 20.9188C13.4285 20.918 13.4258 20.9168 13.4227 20.9161L13.3862 20.9055C13.0566 20.7983 12.7778 20.5455 12.6501 20.1932C12.5177 19.8281 12.5779 19.4392 12.779 19.1396L12.8015 19.1065L13.2515 18.4359L15.0262 15.7923L15.8955 14.498C15.5922 14.1336 15.3461 13.7104 15.1753 13.2392C14.7746 12.1329 14.8647 10.9665 15.3348 9.98313L17.6022 12.8986C18.0875 13.5715 18.9641 13.8423 19.7437 13.5571C20.0916 13.4299 20.3921 13.2025 20.6107 12.9018C20.6161 12.8947 20.6208 12.8881 20.6251 12.8815L20.6484 12.8471C20.9811 12.3506 21.058 11.721 20.8553 11.1616C20.7877 10.9755 20.6926 10.8023 20.5723 10.6486L18.2653 7.68202C20.2663 7.26658 22.3201 8.37676 23.0396 10.3627C23.8316 12.5456 22.7123 14.9583 20.54 15.7529Z"
          fill="#FBB20D"
        />
        <path
          d="M16.5931 6.66894L17.5493 7.89927L20.0193 11.0757L20.0248 11.0828C20.0985 11.1772 20.1579 11.2837 20.2007 11.4022C20.333 11.7674 20.2725 12.1555 20.0721 12.4547L20.0489 12.489C19.9168 12.6704 19.732 12.8167 19.5057 12.8994C19.0033 13.0831 18.4559 12.8974 18.1608 12.4793L17.6724 11.8524L15.7172 9.3379L14.7602 8.10797C14.7179 8.12435 14.6751 8.14112 14.6324 8.15673C13.0892 8.72118 11.429 8.31588 10.3148 7.24978L13.8373 5.96171C14.8013 5.60908 15.2998 4.53478 14.9489 3.56659C14.8844 3.3891 14.7951 3.22409 14.6829 3.07625C14.6806 3.07235 14.6767 3.06767 14.6736 3.06377L14.6627 3.04973C14.1685 2.41506 13.3244 2.17203 12.565 2.44978L9.04248 3.73784C9.21408 2.20129 10.2266 0.818828 11.7699 0.254765C13.9414 -0.539449 16.343 0.585166 17.1335 2.76769C17.627 4.12753 17.3773 5.57787 16.5931 6.66894Z"
          fill="#FBB20D"
        />
        <path
          d="M13.5995 5.30286L8.36285 7.21818C8.47312 8.84211 7.62868 10.4165 6.17548 11.2025L6.04619 7.49826V7.47798L6.04387 7.43507C6.04154 7.36837 6.0361 7.29971 6.02484 7.22715C6.02368 7.22169 6.02368 7.21467 6.02251 7.20921L6.01358 7.15733C6.01125 7.14953 6.01125 7.14134 6.00892 7.13471L6.00116 7.09531C5.9965 7.07619 5.99223 7.05708 5.98757 7.03757L5.94719 6.90299C5.9437 6.88934 5.93826 6.87608 5.9336 6.8636C5.58302 5.89462 4.51379 5.39414 3.54939 5.74678C2.79076 6.02452 2.29963 6.75515 2.32641 7.56731L2.32758 7.58213L2.45803 11.3316C1.46878 10.8823 0.653853 10.0475 0.25396 8.9408C-0.537285 6.75788 0.582027 4.34676 2.75504 3.55138C4.54679 2.89643 6.49578 3.54787 7.56695 5.02278L12.8036 3.10746C13.2905 2.92997 13.8165 3.09849 14.1174 3.48585L14.1287 3.4995C14.1974 3.59 14.2533 3.69259 14.2941 3.8065C14.5134 4.41152 14.2032 5.08247 13.5995 5.30286Z"
          fill="#FBB20D"
        />
        <path
          d="M4.24563 18.2719L4.23903 18.2704L4.2002 18.2587L4.19982 18.2579C4.1144 18.2325 4.03093 18.1982 3.9525 18.1545C3.9327 18.1436 3.91368 18.1319 3.89465 18.1202C3.83797 18.0843 3.78439 18.0422 3.73431 17.9969C3.6982 17.9634 3.66404 17.9283 3.6322 17.89C3.62016 17.8756 3.60774 17.8612 3.59609 17.8456C3.58134 17.8272 3.56892 17.8089 3.5561 17.7898C3.5429 17.7699 3.53009 17.7508 3.51844 17.7301C3.50757 17.711 3.4967 17.6923 3.487 17.6731C3.46448 17.6298 3.44468 17.5842 3.42759 17.5366C3.3845 17.418 3.36198 17.2983 3.3581 17.1781L3.35771 17.1691L3.35693 17.1344C3.35771 16.6589 3.65122 16.2115 4.12217 16.0395C4.36249 15.9517 4.61291 15.9486 4.84042 16.0145L4.86255 16.0211L4.87148 16.0239L4.87458 16.0246L5.65224 16.2482L8.70501 17.1274L10.199 17.5585C10.6757 16.7923 11.4014 16.176 12.311 15.8432C12.9691 15.6026 13.6474 15.5386 14.2969 15.6264L12.2047 18.7431C11.8692 19.2428 11.7916 19.8728 11.9946 20.4338C12.1922 20.9791 12.6205 21.3942 13.1714 21.5736C13.1792 21.5756 13.1858 21.5783 13.1935 21.5802L13.2222 21.5884C13.2312 21.5912 13.2409 21.5935 13.2506 21.5958C13.2871 21.6064 13.3313 21.6169 13.3733 21.6255L13.39 21.6282C13.4199 21.6341 13.4494 21.6391 13.4785 21.6434C13.5154 21.6497 13.5515 21.6536 13.5899 21.6563L13.6163 21.6586C13.6439 21.6606 13.6781 21.6622 13.7145 21.6625C13.7355 21.6629 13.7573 21.6629 13.7798 21.6625C13.7945 21.6622 13.8104 21.6622 13.8256 21.6606C13.8411 21.6598 13.8566 21.659 13.8722 21.6575C13.9844 21.6505 14.0993 21.6313 14.2115 21.6021C14.2418 21.5951 14.2709 21.5861 14.3012 21.5763C14.3268 21.5678 14.3524 21.5596 14.3777 21.5502C14.75 21.4141 15.0637 21.1679 15.2834 20.8383L15.3017 20.8118L17.38 17.716C17.4937 17.9181 17.5927 18.1315 17.6743 18.3562C18.4647 20.5379 17.3454 22.951 15.1732 23.7452C13.0017 24.5394 10.5997 23.414 9.80957 21.2323C9.63875 20.7607 9.55683 20.2777 9.55605 19.8026L8.0648 19.3724L4.24563 18.2719Z"
          fill="#FBB20D"
        />
      </svg>
    </SvgIcon>
  );
}
