import React, { useState } from "react";
import Box from "@mui/material/Box";
import { SxProps } from "@mui/material/styles";
import ListSubheader from "@mui/material/ListSubheader";
import Autocomplete from "lib/Select/Autocomplete";
import { useTranslation } from "react-i18next";
import { IField, ISubfield } from "types";

function getFallbackLabel(option: ISubfield) {
  return `${option.field} - ${`${option.subfieldType}`} (${option.area} ${
    option.areaUom
  })`;
}

export default function SubfieldsSelect({
  fields,
  onFieldChange,
  onSubfieldsChange,
  subfields,
  subfieldsRequired = true,
  sx,
}: {
  fields: IField[];
  onFieldChange?: (_item: IField) => void;
  onSubfieldsChange?: (_item: ISubfield[]) => void;
  subfields: ISubfield[];
  subfieldsRequired?: boolean;
  sx?: SxProps;
}) {
  const { t } = useTranslation();
  const [selectedField, setSelectedField] = useState<IField>();
  const [selectedSubfields, setSelectedSubfields] = useState<ISubfield[]>([]);
  return (
    <Box sx={{ position: "relative", ...sx }}>
      <Autocomplete
        name="fieldId"
        inputProps={{
          required: true,
          value: selectedField?.id ?? "",
        }}
        label={`${t("common.field")} *`}
        disableClearable={false}
        value={selectedField ?? null}
        getOptionLabel={(row: IField) => {
          return row.name;
        }}
        groupBy={(row) => row.farm}
        // sort by same dimension as groupBy, see below:
        options={fields?.sort((a, b) =>
          a.farm > b.farm ? 1 : a.farm === b.farm ? 0 : -1
        )}
        onChange={(_e, item: IField) => {
          // NOTE: make sure that the options are also sorted with the same dimension that they are grouped by
          // see: https://mui.com/material-ui/react-autocomplete/#grouped
          setSelectedSubfields([]);
          setSelectedField(item);
          onFieldChange && onFieldChange(item);
        }}
      />

      <Autocomplete
        name="subfieldIds"
        inputProps={{
          required: subfieldsRequired,
          value: selectedSubfields.map((s) => s.id),
        }}
        sx={{ mt: 2 }}
        label={`${t("inventory.subfields.title")}${
          subfieldsRequired ? " *" : ""
        }`}
        disableClearable={false}
        multiple
        options={
          subfields
            ?.filter((d: ISubfield) => d.fieldId === selectedField?.id)
            .sort((a, b) => (a.subfieldType > b.subfieldType ? 1 : -1))
            .sort((a, b) => (a.subfieldGroup > b.subfieldGroup ? 1 : -1)) ??
          null
        }
        groupBy={(row) => {
          return `${row.subfieldType}-${row.subfieldGroup}`;
        }}
        ListboxProps={{
          sx: { pt: 0 },
        }}
        renderGroup={(params) => {
          return (
            <React.Fragment key={params.key}>
              <ListSubheader>{params.group}</ListSubheader>
              {params.children}
            </React.Fragment>
          );
        }}
        value={selectedSubfields}
        getOptionLabel={(row: ISubfield) => {
          return `${row.cid || getFallbackLabel(row)}`;
        }}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>{`${
            option.cid || getFallbackLabel(option)
          }`}</li>
        )}
        onChange={(_e, items: ISubfield[]) => {
          setSelectedSubfields(items);
          onSubfieldsChange && onSubfieldsChange(items);
        }}
      />
    </Box>
  );
}
