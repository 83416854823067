import { Route, Switch } from "react-router-dom";
import { AssetsView } from "./AssetsView";

export function Assets() {
  return (
    <Switch>
      <Route
        path={
          "/:org/:season/inventory/assets/:type/:subtype?/:fieldId/items/:itemId"
        }
      >
        <AssetsView />
      </Route>
      <Route path={"/:org/:season/inventory/assets/:type/:subtype?/:fieldId?"}>
        <AssetsView />
      </Route>
    </Switch>
  );
}
