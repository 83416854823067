import React, { useState } from "react";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useTranslation } from "react-i18next";
import { useOrgState } from "providers/OrgProvider";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { Control, Controller } from "react-hook-form";
import { ISelectOption } from "types";
import { DatePicker } from "lib/DatePicker";
import Autocomplete from "lib/Select/Autocomplete";
import FieldSelect from "components/FieldSelect";
import { useFields } from "api/fields/useFields";
import { useInventoryDispatch } from "providers";
import { useCropTypes } from "api/lookups/useCropTypes";
/**
 * Field select list for forms
 */

export function FieldFormField({
  control,
  required,
}: {
  control: Control;
  required?: boolean;
}) {
  const { org, season } = useOrgState();
  const fieldsQ = useFields({ org, seasonId: season?.id });
  const { zoomToField } = useInventoryDispatch();

  return (
    <Controller
      name="fieldId"
      control={control}
      rules={{ required }}
      render={({
        onChange,
        value,
      }: {
        onChange: (_i: string) => void;
        value: string;
      }) => (
        <FieldSelect
          required={required}
          value={
            fieldsQ.data?.find((f: { id: string }) => f.id === value) ?? null
          }
          onChange={(fld) => {
            if (fld?.id) {
              zoomToField(fld.id);
            }
            onChange(fld?.id ?? "");
          }}
          fields={fieldsQ.data ?? []}
        />
      )}
    />
  );
}

/**
 * CID input for forms
 */
export function CIDFormField({
  register,
  required,
}: {
  register: () => void;
  required?: boolean;
}) {
  return (
    <TextField
      label={`ID`}
      required={required}
      inputProps={{
        ref: register,
        name: "cid",
        maxLength: 255,
      }}
    />
  );
}

/**
 * Datepicker component for bornOnUtc form field
 */
export function BornOnUTCFormField({
  label,
  control,
}: {
  label: string;
  control: Control;
}) {
  return (
    <Controller
      name="bornOnUtc"
      rules={{ required: true }}
      control={control}
      render={(props: { value: string; onChange: (_item: Date) => void }) => (
        <DatePicker
          label={label}
          slotProps={{
            textField: {
              required: true,
            },
          }}
          maxDate={new Date()}
          value={props.value ? new Date(props.value) : null}
          onChange={(date) => {
            props.onChange(date);
          }}
        />
      )}
    />
  );
}

/**
 * Crop type select for forms, using crops from organization level
 */
export function CropTypeIDFormField({
  control,
  isError,
}: {
  control: Control;
  isError?: boolean;
}) {
  const { org } = useOrgState();
  const cropTypesQ = useCropTypes(org);
  const { t } = useTranslation();
  return (
    <Controller
      name="cropTypeId"
      control={control}
      htmlFor="crop-select"
      rules={{ required: true }}
      render={(props: { value: string; onChange: (_id: string) => void }) => (
        <Autocomplete
          label={`${t("common.crop")} *`}
          error={isError}
          id="crop-select"
          options={cropTypesQ.data || []}
          value={cropTypesQ.data?.find((f) => f.id === props.value) ?? null}
          onChange={(_e, item) => {
            const opt = item as { id: string };
            props.onChange(opt?.id);
          }}
          disableClearable={false}
        />
      )}
    />
  );
}

/**
 * Asset status select for forms
 */
export function AssetStatusIDFormField({
  assetStatusOpts,
  control,
  isError,
}: {
  assetStatusOpts: ISelectOption[];
  control: Control;
  isError?: boolean;
}) {
  const { t } = useTranslation();
  return (
    <Controller
      name="assetStatusId"
      control={control}
      rules={{ required: true }}
      render={(props: { value: string; onChange: (_item: string) => void }) => (
        <Autocomplete
          label={`${t("common.status")} *`}
          error={isError}
          options={assetStatusOpts}
          value={
            assetStatusOpts?.find((ao) => ao.value === props.value) ?? null
          }
          onChange={(_e, item) => {
            const opt = item as { value: string };
            props.onChange(opt?.value);
          }}
          disableClearable={false}
        />
      )}
    />
  );
}

/**
 * Text area input for description form fields
 */
export function DescriptionFormField({ register }: { register: () => void }) {
  const { t } = useTranslation();
  return (
    <TextField
      size="medium"
      label={t("common.notes")}
      multiline
      inputProps={{
        name: "description",
        maxLength: 255,
        ref: register,
      }}
    />
  );
}

export function ManualSwitchFormField({
  children,
  id,
  manualInput,
  onChange,
  useManual,
}: {
  children: React.ReactNode;
  id: string;
  manualInput: React.ReactNode;
  onChange?: (_e: React.ChangeEvent<HTMLInputElement>) => void;
  useManual?: boolean;
}) {
  const { t } = useTranslation();
  const [useManualInput, setUseManualInput] = useState(useManual ?? false);

  return (
    <Stack direction={"row"} spacing={2}>
      <Box flex={1}>{useManualInput ? manualInput : children}</Box>
      <FormControlLabel
        sx={{ textTransform: "uppercase" }}
        control={
          <Switch
            id={id}
            checked={useManualInput}
            onChange={(e) => {
              e.stopPropagation();
              setUseManualInput(e.currentTarget.checked);
              onChange && onChange(e);
            }}
          />
        }
        label={t("common.manualInput")}
      />
    </Stack>
  );
}
