import React from "react";
import Button, { ButtonProps } from "@mui/material/Button";

const ButtonNoRipples = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    return <Button ref={ref} {...props} disableTouchRipple />;
  }
);
ButtonNoRipples.displayName = "ButtonNoRipples";
export default ButtonNoRipples;
