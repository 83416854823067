import React, { useEffect } from "react";
import { useInventoryDispatch } from "providers/InventoryProvider";
import { useFieldsState } from "providers/FieldsProvider";
import FieldsView from "./FieldsView";

export function Fields() {
  const { fieldsGeodataState } = useFieldsState();
  const { setInventoryFtrs } = useInventoryDispatch();
  // set inventory features to fields on mount
  // and reset on cleanup
  useEffect(() => {
    setInventoryFtrs(fieldsGeodataState?.data);
    return () => {
      setInventoryFtrs(null);
    };
  }, [fieldsGeodataState, setInventoryFtrs]);

  return <FieldsView />;
}
