import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useOrgState } from "providers/OrgProvider";
import { useTranslation } from "react-i18next";
import { getAssetIcon } from "../utils";
import ReportView from "../ReportView";
import { useAssetEvents } from "api/assets/useAssetEvents";

export default function RecordsReport({ currentAssetType, currentAssets }) {
  const { t } = useTranslation();
  const { rootUrl, orgData, season } = useOrgState();
  const { org, reportType, assetId } = useParams();
  const assetsEventsQ = useAssetEvents(org, assetId);
  const asset = currentAssets?.find((a) => a.id === assetId);
  const assetType = asset?.assetType?.toLowerCase();
  const ddProp =
    assetType === "animal" || assetType === "plant" ? "cid" : "name";
  const ddVal = asset
    ? `${asset[ddProp]}${asset.description ? ` - ${asset.description}` : ""}`
    : "";

  return (
    <>
      <ReportView
        org={orgData?.name}
        season={season}
        color={currentAssetType?.color}
        titleIcon={getAssetIcon(currentAssetType?.assetEn?.toLowerCase())}
        title={`${reportType} Asset Records Report`}
        columns={[
          {
            Header: t("common.date"),
            id: "dateUtc",
            accessor: (row) => {
              return new Date(row.dateUtc).toLocaleDateString();
            },
          },
          { Header: t("common.type"), accessor: "eventType" },
          { Header: t("common.notes"), accessor: "notes" },
        ]}
        data={assetsEventsQ.data}
        descriptionDetail={{
          dt: t("common.asset"),
          dd: ddVal,
        }}
        isLoading={assetsEventsQ.isLoading}
        goBackUrl={`${rootUrl}/insights/reports/assets/${currentAssetType?.assetEn?.toLowerCase()}`}
      />
    </>
  );
}

RecordsReport.propTypes = {
  currentAssetType: PropTypes.shape({
    color: PropTypes.string,
    assetEn: PropTypes.string,
  }),
  currentAssets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
};
