import React, { useEffect, useState } from "react";
import { Calendar, Camera, Monitor } from "react-feather";
import { useTranslation } from "react-i18next";
import { useOrgState } from "providers/OrgProvider";
import Box from "@mui/material/Box";
import Autocomplete from "lib/Select/Autocomplete";
import Stack from "@mui/material/Stack";
import { MapData } from "./types";
import { ICollectEvent, IRasterType, ISensor } from "types";
import { useCollectEventUtils } from "components";

export default function CollectEventRasterLayerSelects({
  id,
  sensorOptions,
  sensorsLoading,
  onChange,
  mapLoaded,
}: {
  id: number;
  sensorOptions: ISensor[];
  sensorsLoading: boolean;
  onChange: (_d: {
    data: Partial<MapData>;
    id: number;
    zoom?: boolean;
  }) => void;
  mapLoaded?: boolean;
}) {
  const { org } = useOrgState();
  const {
    fetchCollectEvents,
    resetCollectEvents,
    abortFetchCollectEvents,
    fetchRasterInfo,
    resetRasterInfo,
    abortFetchRasterInfo,
    collectEvents,
    rasterTypes,
    collectEventsState,
    rasterInfoState,
  } = useCollectEventUtils();

  const [sensorSelection, setSensorSelection] = useState<ISensor>();
  const [collectionEventSelection, setCollectionEventSelection] = useState<
    ICollectEvent
  >();
  const [rasterTypeSelection, setRasterTypeSelection] = useState<IRasterType>();

  const { t } = useTranslation();
  // abort any reqs when leaving view
  useEffect(() => {
    return () => {
      abortFetchRasterInfo();
      abortFetchCollectEvents();
    };
  }, [abortFetchCollectEvents, abortFetchRasterInfo]);

  function getStateData() {
    return {
      sensorSelection,
      rasterTypeSelection,
      collectionEventSelection,
    };
  }

  return (
    <Stack gap={1}>
      <Autocomplete
        InputProps={{
          startAdornment: (
            <Box sx={{ ml: 2 }}>
              <Camera />
            </Box>
          ),
        }}
        fullWidth
        disableClearable={false}
        value={sensorSelection ?? null}
        loading={sensorsLoading || !mapLoaded}
        getOptionLabel={(o) => (typeof o !== "string" ? o.name : "")}
        // don't supply options until the map has loaded
        options={mapLoaded ? sensorOptions || [] : []}
        onChange={async (_e, item) => {
          const hasChanged = sensorSelection !== item;
          const i = item as ISensor;
          setSensorSelection(i);
          // reset collection event when sensor changes
          setCollectionEventSelection(null);
          setRasterTypeSelection(null);
          if (!item) {
            resetCollectEvents();
            resetRasterInfo();
          } else {
            fetchCollectEvents({ org, sensorTypeId: i.id });
            fetchRasterInfo(org, i.id);
          }

          if (hasChanged) {
            onChange({
              data: { sensorSelection: item as ISensor },
              id,
            });
          }
        }}
      />
      <Autocomplete
        fullWidth
        disableClearable={false}
        InputProps={{
          startAdornment: (
            <Box sx={{ ml: 2 }}>
              <Calendar />
            </Box>
          ),
        }}
        value={collectionEventSelection ?? null}
        loading={collectEventsState.isLoading || !mapLoaded}
        // don't supply options until the map has loaded
        options={mapLoaded ? collectEvents || [] : []}
        onChange={(_e, item) => {
          const hasChanged = collectionEventSelection !== item;
          setCollectionEventSelection(item as ICollectEvent);
          if (hasChanged) {
            const d = getStateData();
            onChange({
              // passing item in with other state since setState func is async
              data: { ...d, collectionEventSelection: item as ICollectEvent },
              // only zooming on first map collect event select
              zoom: id === 1,
              id,
            });
          }
        }}
      />
      <Autocomplete
        fullWidth
        aria-label={t("imagery.compare.displayType")}
        disableClearable={false}
        InputProps={{
          startAdornment: (
            <Box sx={{ ml: 2, mr: 1 }}>
              <Monitor />
            </Box>
          ),
        }}
        loading={rasterInfoState.isLoading}
        value={rasterTypeSelection ?? null}
        // don't supply options until the map has loaded
        options={mapLoaded ? (rasterTypes as IRasterType[]) || [] : []}
        onChange={(_e, item) => {
          const hasChanged = rasterTypeSelection !== item;
          setRasterTypeSelection(item as IRasterType);
          if (hasChanged) {
            const currentState = getStateData();
            onChange({
              // passing item in with other state since setState func is async
              data: {
                ...currentState,
                rasterTypeSelection: item as IRasterType,
              },
              id,
            });
          }
        }}
      />
    </Stack>
  );
}
