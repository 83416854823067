const tileServerOrigin = process.env.REACT_APP_TILESERVER_ORIGIN;

export function generateTileUrl(d: {
  tenant: string;
  org: string;
  collectEventId: string;
  sensorSlug: string;
  rasterSlug: string;
}) {
  return `${tileServerOrigin}/cog/noauth/tile/${d.tenant}/${d.org}/${d.collectEventId}/${d.sensorSlug}/${d.rasterSlug}/{z}/{x}/{y}`;
}
