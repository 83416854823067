import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
// A custom hook that builds on useLocation to parse
// the query string for you.
export default function useURLSearchParams() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export function useSetURLSearchParams() {
  const history = useHistory();

  const setQuery = useCallback(
    (queryParams, overwrite) => {
      // preserve current and add/overwrite any others
      const searchString = overwrite ? "" : window.location.search;
      const params = new URLSearchParams(searchString);
      queryParams.forEach((q) => {
        const [key, val] = q;
        if (!val) {
          params.delete(key);
        } else {
          params.set(key, val);
        }
      });
      history.replace({ search: params.toString() });
    },
    [history]
  );

  return setQuery;
}
