import { useEffect } from "react";
import { useParams, Route, Switch, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import Chip from "@mui/material/Chip";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import { useOrgState } from "providers/OrgProvider";
import { useInventoryDispatch } from "providers/InventoryProvider";
import { useURLSearchParams, NotFoundView } from "components";
import PlantAssetDetails from "./PlantAssetDetails";
import FixedAssetDetails from "./FixedAssetDetails";
import AnimalAssetDetails from "./AnimalAssetDetails";
import AssetRecordsListView from "../AssetRecordsListView";
import AssetsBreadcrumbs from "../ListView/AssetsBreadcrumbs";
import AssetRecordDetails from "./AssetRecordDetails";
import CreateOrEditRecord from "../CreateOrEditRecord";
import { IUrlParams } from "types";
import AssetPhotosStack from "./AssetPhotosStack";
import { useAssetPhotos } from "api/assets/useAssetPhotos";
import { EditIconButton } from "components/EditIconButton";
import { useAssetGeodataById } from "api/assets/useAssetGeodataById";

export default function AssetView() {
  const { t } = useTranslation();
  const { itemId, type, subtype, fieldId } = useParams<
    IUrlParams & { type: "animal" | "fixed" | "plant" }
  >();
  const { org, rootUrl, season } = useOrgState();
  const query = useURLSearchParams();
  const assetPhotosQ = useAssetPhotos({ type: "asset", org, assetId: itemId });
  const { zoomToFtrIds, setInventoryFtrs } = useInventoryDispatch();
  const location = useLocation();
  const isEdit =
    location.pathname.includes("/edit") &&
    !location.pathname.includes("/records");
  const geodataQ = useAssetGeodataById({
    type,
    org,
    seasonId: season?.id,
    id: itemId,
  });
  const currentEditFtr = geodataQ.data?.features[0];
  const outOfSeason =
    currentEditFtr?.properties.seasonId &&
    currentEditFtr?.properties.seasonId !== season?.id;
  const editPhotos = location.pathname.includes(`${itemId}/photos`);
  const groupId = query.get("groupId");

  useEffect(() => {
    zoomToFtrIds([itemId]);
  }, [zoomToFtrIds, itemId]);

  useEffect(() => {
    if (geodataQ.data) {
      const fc = { ...geodataQ.data };
      fc.features = fc.features.filter((f) => f.geometry);
      setInventoryFtrs(fc);
    }
  }, [geodataQ.data, setInventoryFtrs]);

  return (
    <>
      {!currentEditFtr && !geodataQ.isLoading && itemId ? (
        <NotFoundView>
          <Link href={`${rootUrl}/inventory/assets/${type}`}>
            {t("common.back")}
          </Link>
        </NotFoundView>
      ) : geodataQ.isLoading ? (
        <Paper style={{ height: "100%" }}>
          <LinearProgress />
        </Paper>
      ) : (
        <Paper sx={{ p: 3, display: "flex", flexDirection: "column", flex: 1 }}>
          <Stack direction={"row"} sx={{ pb: 2, alignItems: "center" }}>
            <AssetsBreadcrumbs />
            {itemId && !isEdit ? (
              <EditIconButton
                sx={{
                  ml: "auto",
                }}
                href={`${rootUrl}/inventory/assets/${type}/${subtype}/${fieldId}/items/${itemId}/edit${
                  groupId ? `?groupId=${groupId}` : ""
                }`}
                onClick={(e) => {
                  if (!currentEditFtr || outOfSeason) {
                    e.preventDefault();
                  }
                }}
                disabled={!currentEditFtr || outOfSeason}
                title={
                  outOfSeason
                    ? t("inventory.assets.cannotEditOutOfSeason")
                    : t("common.edit")
                }
              />
            ) : null}
          </Stack>
          <Grid container>
            <Grid xs={12} md={assetPhotosQ.data?.length ? 10 : 12}>
              {type === "animal" ? (
                <AnimalAssetDetails feature={currentEditFtr} />
              ) : type === "plant" ? (
                <PlantAssetDetails feature={currentEditFtr} />
              ) : type === "fixed" ? (
                <FixedAssetDetails feature={currentEditFtr} />
              ) : null}
            </Grid>
            {assetPhotosQ.data?.length ? (
              <Grid xs={12} md={2}>
                <Link
                  sx={{ display: "block", p: 2 }}
                  href={`${rootUrl}/inventory/assets/${type}/${subtype}/${fieldId}/items/${itemId}/photos${
                    groupId ? `?groupId=${groupId}` : ""
                  }`}
                >
                  <Box
                    sx={{
                      img: {
                        borderRadius: "0.25rem",
                      },
                      maxWidth: "100%",
                      height: "auto",
                    }}
                  >
                    <img
                      style={{ width: "100%" }}
                      src={assetPhotosQ.data[0].src}
                    />
                  </Box>
                </Link>
              </Grid>
            ) : null}
          </Grid>
          <Box
            sx={{
              mt: 3,
              ".MuiTabPanel-root": {
                px: 0,
                pb: 0,
              },
            }}
          >
            <TabContext value={editPhotos ? "photos" : "records"}>
              <TabList
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  a: {
                    textDecoration: "none",
                  },
                }}
                aria-label="asset record/photos tabs"
              >
                <Tab
                  href={`${rootUrl}/inventory/assets/${type}/${subtype}/${fieldId}/items/${itemId}${
                    groupId ? `?groupId=${groupId}` : ""
                  }`}
                  label={t("common.records")}
                  value="records"
                />
                <Tab
                  href={`${rootUrl}/inventory/assets/${type}/${subtype}/${fieldId}/items/${itemId}/photos${
                    groupId ? `?groupId=${groupId}` : ""
                  }`}
                  label={
                    <Box>
                      {t("common.photos")}
                      <Chip
                        sx={{ ml: 1 }}
                        size="small"
                        color="primary"
                        label={assetPhotosQ.data?.length || 0}
                      />
                    </Box>
                  }
                  value="photos"
                />
              </TabList>
              <TabPanel value="records">
                <Switch>
                  <Route path="/:org/:season/inventory/assets/:type/:subtype/:fieldId/items/:itemId/records/add">
                    <CreateOrEditRecord />
                  </Route>
                  <Route
                    path={`/:org/:season/inventory/assets/:type/:subtype/:fieldId/items/:itemId/records/:recordId/edit`}
                  >
                    <CreateOrEditRecord />
                  </Route>
                  <Route
                    path={`/:org/:season/inventory/assets/:type/:subtype/:fieldId/items/:itemId/records/:recordId`}
                  >
                    <AssetRecordDetails />
                  </Route>
                  <Route
                    path={`/:org/:season/inventory/assets/:type/:subtype/:fieldId/items/:itemId`}
                    exact
                  >
                    <AssetRecordsListView />
                  </Route>
                </Switch>
              </TabPanel>
              <TabPanel value="photos">
                <AssetPhotosStack type="asset" />
              </TabPanel>
            </TabContext>
          </Box>
        </Paper>
      )}
    </>
  );
}
