import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useOrgState } from "providers/OrgProvider";
import { FormWrapper } from "components";
import { useInventoryState } from "providers/InventoryProvider";
import Autocomplete from "lib/Select/Autocomplete";
import { ICropType, IUrlParams } from "types";
import { OperationHarvestedPostData } from "api/operations/types/OperationPostData";
import DateRangeInputs from "components/Forms/DateRangeInputs";
import { Typography } from "@mui/material";
import FieldsAndSubfields from "../../../FieldsAndSubfields";
import { useOperationsGeodataById } from "api/operations/useOperationsGeodataById";
import { useOperationMutations } from "api/operations/useOperationMutations";
import { useCropTypes } from "api/lookups/useCropTypes";
import { parseIds } from "../parseIds";

const defaultValues = {
  area: "",
  id: "",
  fieldIds: "",
  subfieldIds: "",
  cropTypeId: "",
  beginDateUtc: "",
  endDateUtc: "",
  averagePercentMoisture: "",
  count: "",
  averageYield: "",
  totalMass: "",
  geometry: "",
};

export default function HarvestOperationForm({
  getCancelHref,
}: {
  getCancelHref?: (_onDelete?: boolean) => string;
}) {
  const { t } = useTranslation();
  const { org, season } = useOrgState();
  const cropTypesQ = useCropTypes(org);

  const { itemId } = useParams<IUrlParams>();
  const history = useHistory();
  const { drawData } = useInventoryState();
  const { invalidate, saveMutation, deleteMutation } = useOperationMutations({
    org,
    type: "harvested",
  });
  const [massUom, setMassUom] = useState<string>();
  const [yieldUom, setYieldUom] = useState<string>();
  const [countLabel, setCountLabel] = useState<string>();

  const geodataQ = useOperationsGeodataById<
    OperationHarvestedPostData & { areaUom: string }
  >({
    id: itemId,
    org,
    seasonId: season?.id,
    type: "harvested",
  });
  const currentEditFtr = geodataQ.data?.features[0];

  // update uom values if currentEditFtr changes
  useEffect(() => {
    const cropTypeObj = cropTypesQ.data?.find(
      (ct) => ct.id === currentEditFtr?.properties?.cropTypeId
    );
    setYieldUom(cropTypeObj?.yieldUom);
    setMassUom(cropTypeObj?.massUom);
    setCountLabel(cropTypeObj?.countLabel);
  }, [currentEditFtr, cropTypesQ.data]);

  const methods = useForm({
    defaultValues: { ...defaultValues },
  });
  const {
    handleSubmit,
    register,
    reset,
    formState,
    watch,
    setValue,
    control,
    errors,
  } = methods;
  const {
    area,
    averagePercentMoisture,
    averageYield,
    cropTypeId,
    id,
    beginDateUtc,
    endDateUtc,
    fieldIds,
    subfieldIds,
    totalMass,
    count,
  } = watch();

  async function handleSave(d: OperationHarvestedPostData) {
    if (!formState.isDirty) {
      history.push(getCancelHref());
      return false;
    }
    const body = { ...d };
    if (!yieldUom) {
      delete body.averageYield;
    }
    body.geometry = d.geometry ? JSON.parse(d.geometry) : "";
    // body.averagePercentMoisture = Number(body.averagePercentMoisture);
    // body.averageYield = Number(body.averageYield);
    // body.totalMass = Number(body.totalMass);
    // need at least 1 fieldId or subfieldId
    // but cannot make either input required
    // therefore custom logic to handle validation here
    if (!body.id && !body.fieldIds?.length && !body.subfieldIds?.length) {
      return false;
    }
    // server doesn't want these props if not set
    Object.keys(body).forEach((k: keyof OperationHarvestedPostData) => {
      if (body[k] === null || body[k] === "") {
        delete body[k];
      }
    });

    if (!body.fieldIds?.length) {
      delete body.fieldIds;
    } else {
      body.fieldIds = JSON.parse(body.fieldIds);
    }
    if (!body.subfieldIds?.length) {
      delete body.subfieldIds;
    } else {
      body.subfieldIds = JSON.parse(body.subfieldIds);
    }
    return saveMutation.mutateAsync(body, {
      onSuccess: () => {
        reset(d);
        history.push(getCancelHref());
      },
    });
  }

  return (
    <FormWrapper
      methods={methods}
      defaultValues={defaultValues}
      data={{ ...currentEditFtr?.properties }}
      geometryData={drawData?.features[0]?.geometry}
      existingGeom={currentEditFtr?.geometry}
      cancelHref={getCancelHref()}
      saveState={{
        ...saveMutation,
        errorMessage: saveMutation.error,
      }}
      deleteState={{ ...deleteMutation, errorMessage: deleteMutation.error }}
      // NOTE: ignoring geometry, using fieldIds
      ignoreGeom
      onDelete={async () => {
        return deleteMutation.mutateAsync(id, {
          onSuccess: () => {
            reset(defaultValues);
            history.push(getCancelHref(true));
            // invalidate after navigation to prevent GET of deleted item
            invalidate();
          },
        });
      }}
      onSubmit={handleSubmit(handleSave)}
    >
      <Stack spacing={2}>
        {!itemId ? (
          <>
            <FieldsAndSubfields
              control={control}
              ids={{
                fieldIds: parseIds(fieldIds),
                subfieldIds: parseIds(subfieldIds),
              }}
            />
          </>
        ) : null}
        <DateRangeInputs
          required
          control={control}
          startProp="beginDateUtc"
          setValue={setValue}
          endProp="endDateUtc"
          startDate={beginDateUtc ? new Date(beginDateUtc) : null}
          endDate={endDateUtc ? new Date(endDateUtc) : null}
          minDate={season?.beginOnUtc ? new Date(season.beginOnUtc) : null}
          maxDate={season?.endOnUtc ? new Date(season.endOnUtc) : null}
        />
        <Controller
          name="cropTypeId"
          control={control}
          rules={{ required: true }}
          htmlFor="crop-type-select"
          render={(props: {
            value: string;
            onChange: (_id: string) => void;
          }) => (
            <Autocomplete
              label={`${t("common.cropType")} *`}
              id="crop-type-select"
              options={cropTypesQ.data || []}
              error={!!errors.cropTypeId}
              value={
                cropTypesQ.data?.find((ct) => ct.value === cropTypeId) ?? null
              }
              inputProps={{ required: true, name: "cropTypeId" }}
              onChange={(_e, item) => {
                const ct = item as ICropType;
                // setSelectedCrop(item);
                props.onChange(ct?.id || "");
                if (ct?.id) {
                  // set uOm values by crop type
                  setMassUom(ct.massUom);
                  setYieldUom(ct.yieldUom);
                  setCountLabel(ct.countLabel);
                  if (!ct.yieldUom) {
                    setValue("averageYield", "");
                  }
                } else {
                  setMassUom(null);
                  setYieldUom(null);
                  setCountLabel(null);
                }
              }}
              disableClearable={false}
            />
          )}
        />

        <TextField
          fullWidth
          label={t("inventory.operations.averagePercentMoisture")}
          name="averagePercentMoisture"
          ref={register}
          id="avg-moisture-input"
          type="number"
          value={averagePercentMoisture ?? ""}
          inputProps={{
            name: "averagePercentMoisture",
            ref: register,
            min: 0,
            max: 100,
            step: "any",
          }}
        />
        <TextField
          fullWidth
          label={`${t("common.averageYield")}`}
          disabled={!yieldUom}
          name="averageYield"
          InputProps={{
            endAdornment: <Typography sx={{ ml: 1 }}>{yieldUom}</Typography>,
          }}
          value={averageYield ?? ""}
          inputProps={{
            name: "averageYield",
            ref: register,
            min: 0,
            step: "any",
          }}
          id="avg-yld-input"
          type="number"
        />
        <TextField
          fullWidth
          id="total-mass-input"
          type="number"
          label={`${t("common.totalMass")}`}
          disabled={!massUom}
          name="totalMass"
          value={totalMass ?? ""}
          InputProps={{
            endAdornment: <Typography sx={{ ml: 1 }}>{massUom}</Typography>,
          }}
          inputProps={{
            name: "totalMass",
            ref: register,
            min: 0,
            step: "any",
          }}
        />
        {itemId ? (
          <TextField
            fullWidth
            id="area-input"
            type="number"
            label={`${t("common.area")}`}
            name="area"
            required
            value={area ?? ""}
            InputProps={{
              endAdornment: (
                <Typography sx={{ ml: 1 }}>
                  {currentEditFtr?.properties?.areaUom ?? ""}
                </Typography>
              ),
            }}
            inputProps={{
              name: "area",
              ref: register,
              min: 0,
              step: "any",
            }}
          />
        ) : null}
        <TextField
          fullWidth
          disabled={!countLabel}
          name="count"
          label={t("common.count")}
          id="harvestCount"
          type="number"
          value={count ?? ""}
          InputProps={{
            endAdornment: <Typography sx={{ ml: 1 }}>{countLabel}</Typography>,
          }}
          inputProps={{
            name: "count",
            ref: register,
            min: 0,
            step: "any",
          }}
        />
      </Stack>
    </FormWrapper>
  );
}
