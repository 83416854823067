const observationKeys = {
  all: (org: string) => ["organization", org, "observations"] as const,
  byId: (org: string, id: string) => {
    return ["organization", org, "observations", "id", id] as const;
  },
  byDate: (org: string, date1: string, date2: string) =>
    ["organization", org, "observations", "date", date1, date2] as const,
  geodata: (org: string, others?: string) =>
    ["organization", org, "observations", "geodata", others] as const,
  photosById: (org: string, id: string) =>
    ["organization", org, "observations", "id", id, "photos"] as const,
};

export default observationKeys;
