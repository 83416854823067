import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
// import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { useTranslation } from "react-i18next";
import { useOrgState } from "providers/OrgProvider";
import { useInventoryDispatch } from "providers/InventoryProvider";
import ConservationForm from "./ConservationForm";

export function CreateOrEditConservation() {
  const { editId } = useParams<{ editId: string }>();
  const { t } = useTranslation();
  const { rootUrl } = useOrgState();
  const {
    showMap,
    hideMap,
    enableDraw,
    disableDraw,
    setDrawOptions,
    setColSizes,
  } = useInventoryDispatch();

  useEffect(() => {
    if (!editId) {
      setColSizes([12, 0]);
    }
    return () => {
      setColSizes([7, 5]);
    };
  }, [editId, setColSizes]);

  useEffect(() => {
    enableDraw();
    return disableDraw;
  }, [enableDraw, disableDraw]);

  useEffect(() => {
    setDrawOptions({
      controls: {
        trash: true,
        polygon: true,
        rectangle: true,
      },
      defaultMode: "draw_polygon",
    });
  }, [setDrawOptions]);

  useEffect(() => {
    if (!editId) {
      hideMap();
    }
    return showMap;
  }, [editId, hideMap, showMap]);

  return (
    <Stack sx={{ width: "100%" }}>
      <Breadcrumbs
        sx={{ display: "flex", textTransform: "uppercase" }}
        aria-label="carbon-breadcrumbs"
      >
        <Link underline="hover" href={`${rootUrl}/inventory/conservation`}>
          {t("common.carbon")}
        </Link>
        <Typography color="text.primary">
          {!editId ? t("common.add") : t("common.edit")}
        </Typography>
      </Breadcrumbs>
      <ConservationForm />
    </Stack>
  );
}
