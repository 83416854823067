import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { ThemeProvider } from "lib";
import Grid from "@mui/material/Unstable_Grid2";

export const OperationTooltip = ({
  unit,
  dataIdx,
  dataKey,
  active,
  payload,
}) => {
  const { t } = useTranslation();
  if (active && payload) {
    const item = payload.find((p) => p.dataKey === dataKey) || payload[0];
    const barValue = dataKey && item?.payload ? item.payload[dataKey] : null;
    const childItem =
      dataKey && (dataIdx || dataIdx === 0)
        ? item?.payload?.children[dataIdx]
        : null;
    const { product, beginDateUtc, field, rate, rateUom, type } =
      childItem || item?.payload || {};
    return (
      <ThemeProvider mode="light">
        <Paper
          elevation={4}
          // sx={{ p: 1, bgcolor: "Background", color: "common.black" }}
          style={{ padding: "0.5rem", opacity: 0.9 }}
        >
          <Typography
            variant="h6"
            display={"flex"}
            style={{ textAlign: "center", fontWeight: "bold" }}
          >{`${t("common.field")}: ${field}`}</Typography>
          <List
            component={Grid}
            container
            sx={{
              maxWidth: 300,
              m: 0,
              li: { "*": { m: 0 } },
            }}
          >
            {product || type ? (
              <ListItem component={Grid} sm={6}>
                <ListItemText
                  secondary={t("common.product")}
                  primary={`${product ? `${product} - ` : ""}${
                    type ? `${type}` : ""
                  }`}
                />
              </ListItem>
            ) : null}
            {barValue ? (
              <>
                <ListItem component={Grid} sm={"auto"}>
                  <ListItemText
                    secondary={dataKey ? t(`common.${dataKey}`) : ""}
                    primary={`${barValue} ${unit}`}
                  />
                </ListItem>
                {rate ? (
                  <ListItem component={Grid} sm={"auto"}>
                    <ListItemText
                      secondary={t("common.rate")}
                      primary={`${rate?.toLocaleString()} ${rateUom}`}
                    />
                  </ListItem>
                ) : null}
                <ListItem component={Grid} sm={"auto"}>
                  <ListItemText
                    secondary={t("common.date")}
                    primary={new Date(beginDateUtc).toLocaleDateString()}
                  />
                </ListItem>
              </>
            ) : null}
          </List>
        </Paper>
      </ThemeProvider>
    );
  }

  return null;
};
export default OperationTooltip;

OperationTooltip.propTypes = {
  dataKey: PropTypes.string,
  dataIdx: PropTypes.number,
  unit: PropTypes.string,
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
    })
  ),
};
