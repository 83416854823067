import { useEffect, useRef } from "react";
import { useFieldLegendLayer, useLegendControl } from "components/Map";
import { usePrevious } from "components";
import MapView from "../../BaseMapView";
import { GeoJSONSource, Map, MapboxEvent } from "mapbox-gl";
import { FeatureCollection } from "geojson";
import { useFieldsState } from "providers";
import { useTranslation } from "react-i18next";
import { LegendSymbol } from "lib/MapboxMap";
import { useTheme } from "@mui/material/styles";
import { RasterLegendItem } from "views/InventoryView/MapView/RasterLayersControl/useRasterLegends";
// import { controlColor } from "../Yield/utils";

const emptyFc = {
  type: "FeatureCollection",
  features: [],
} as FeatureCollection;

export default function ImageryInsightsMapView({
  geodata,
  onLoad,
  rasterLegend,
}: {
  geodata?: FeatureCollection;
  onLoad?: (_m: Map) => void;
  rasterLegend?: {
    title: string;
    data: RasterLegendItem[];
  };
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const fieldLegendLayer = useFieldLegendLayer();
  const mapRef = useRef<Map>();
  const { fieldsGeodataState } = useFieldsState();
  const prevFields = usePrevious(fieldsGeodataState?.data);
  const { legendCtrl } = useLegendControl({
    theme,
    location: "bottom-right",
    map: mapRef.current,
    layers: [
      fieldLegendLayer,
      // {
      //   id: "geodata-lyr",
      //   isStatic: true,
      //   title: t("raster-canopy"),
      //   // dataProp: "type",
      //   Symbol: function Symbol() {
      //     return (
      //       <LegendSymbol
      //         geometryType="Polygon"
      //         borderWidth={3}
      //         borderColor={controlColor}
      //       />
      //     );
      //   },
      // },
      {
        id: "subfields-lyr",
        isStatic: true,
        title: t("inventory.subfields.title"),
        dataProp: "type",
        Symbol: function Symbol(props: { data: { color: string } }) {
          return (
            <LegendSymbol
              geometryType="Polygon"
              borderWidth={3}
              borderColor={props?.data?.color}
            />
          );
        },
      },
      {
        id: "raster-lyr",
        isStatic: true,
        title: rasterLegend?.title || "none",
        data: rasterLegend?.data?.map(
          (i: { label: string; color: string }) => ({
            name: i.label,
            Symbol: function Symbol() {
              return (
                <LegendSymbol
                  geometryType="Polygon"
                  backgroundColor={i.color}
                />
              );
            },
          })
        ),
      },
    ],
  });

  // const addBufferLayer = useCallback((bufferSize, geojson, source) => {
  //   const tolerance = 0.00001;
  //   if ((bufferSize >= 0 || bufferSize <= 0) && geojson && mapRef?.current) {
  //     const params = {
  //       size: bufferSize,
  //       geojson,
  //       tolerance,
  //       units: "meters",
  //     };
  //     const bufferGeom = getBufferGeom(params);

  //     const src = mapRef.current.getSource(source) as GeoJSONSource;
  //     src.setData(bufferGeom || emptyFc);
  //     return bufferGeom;
  //   }
  //   if ((!bufferSize || !geojson) && mapRef?.current) {
  //     const src = mapRef.current.getSource(source) as GeoJSONSource;
  //     src.setData(emptyFc);
  //     return null;
  //   }
  //   return null;
  // }, []);

  useEffect(() => {
    if (
      fieldsGeodataState.data &&
      fieldsGeodataState.data !== prevFields &&
      mapRef.current
    ) {
      const src = mapRef.current.getSource("fields-src") as GeoJSONSource;
      src && src.setData(fieldsGeodataState.data);
    }
  }, [fieldsGeodataState, prevFields]);

  useEffect(() => {
    if (geodata && mapRef.current) {
      const m = mapRef.current;
      const src = m.getSource("geodata-src") as GeoJSONSource;
      if (src) {
        src.setData(geodata);
      }
    }
  }, [geodata]);
  // handle field buffer if passed in as props
  // useEffect(() => {
  //   addBufferLayer(fieldBufferSize * -1, selectedField, "field-buff-src");
  // }, [selectedField, fieldBufferSize, addBufferLayer]);

  return (
    <MapView
      isLoading={false}
      mapRef={undefined}
      defaultBounds={undefined}
      events={{
        load: (e: MapboxEvent) => {
          mapRef.current = e.target;
          mapRef.current.addControl(legendCtrl, "bottom-right");
          mapRef.current.addSource("field-buff-src", {
            type: "geojson",
            data: emptyFc,
          });
          mapRef.current.addSource("fields-src", {
            type: "geojson",
            data: fieldsGeodataState.data ?? emptyFc,
          });
          mapRef.current.addSource("subfields-src", {
            type: "geojson",
            data: { ...emptyFc },
          });
          mapRef.current.addSource("operations-src", {
            type: "geojson",
            data: { ...emptyFc },
          });
          mapRef.current.addSource("geodata-src", {
            type: "geojson",
            data: geodata ?? emptyFc,
          });
          mapRef.current.addLayer({
            id: "fields-lyr",
            type: "line",
            source: "fields-src",
            paint: {
              "line-width": 3,
              "line-color": "#fff",
            },
          });
          mapRef.current.addLayer({
            id: "field-buff",
            type: "fill",
            source: "field-buff-src",
            paint: {
              "fill-color": "#808080",
              "fill-opacity": 0.75,
            },
          });
          mapRef.current.addLayer({
            id: "subfields-lyr",
            type: "line",
            source: "subfields-src",
            paint: {
              "line-width": 3,
              "line-color": ["get", "color"],
            },
          });
          mapRef.current.addLayer({
            id: "operations-layer",
            type: "fill",
            source: "operations-src",
            paint: {
              "fill-color": ["get", "color"],
            },
          });
          mapRef.current.addLayer({
            id: "geodata-lyr",
            type: "line",
            source: "geodata-src",
            paint: {
              "line-color": ["get", "color"],
              "line-width": 3,
            },
          });
          onLoad(e.target);
        },
      }}
    />
  );
}
