import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { User, useAuth0 } from "@auth0/auth0-react";
import { IOrganization } from "types";
import { UserData } from "./types";

const userKeys = {
  get: (userData: User) => ["user", userData],
};

function setUserProps(user: User) {
  if (user && user["https://schemas.cultivateagi.com/app_metadata"]) {
    user.appMetadata = user["https://schemas.cultivateagi.com/app_metadata"];
  }
}

export function useUserData() {
  const { user } = useAuth0();

  const queryFn = useCallback(async () => {
    setUserProps(user);
    const d = {
      user,
    } as UserData;
    const appRole = user?.appMetadata?.role;
    const tenant = user?.appMetadata?.tenant;
    if (appRole && tenant) {
      d.role = appRole;
      if (
        (appRole && appRole.startsWith("ent")) ||
        appRole === "global:admin"
      ) {
        const res = await axios.get(`/portal/tenants/${tenant.id}`);
        const tenantData = res?.data;
        d.organizations = tenantData?.organizations;
        d.tenantId = tenantData.slug;
      } else if (appRole && appRole.startsWith("org")) {
        d.tenantId = tenant.id;
        const res = await axios.get<IOrganization>(
          `/portal/organizations/${tenant.org.id}`
        );
        d.organizations = [res.data];
      }
    }
    return d;
  }, [user]);

  return useQuery<UserData, Error>({
    queryKey: userKeys.get(user),
    queryFn,
    enabled: Boolean(user),
  });
}
