import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useOrgState } from "providers/OrgProvider";
import { createFilterOptions } from "@mui/material/Autocomplete";
import Alert from "@mui/material/Alert";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { FormWrapper } from "components";
import {
  useInventoryDispatch,
  useInventoryState,
} from "providers/InventoryProvider";
import GeodataUpload from "views/InventoryView/GeodataUpload";
import { IField, IRXSubfield, ISubfield, IUrlParams } from "types";
import Autocomplete from "lib/Select/Autocomplete";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { FieldsByFarm } from "components/Forms/FieldsByFarm";
import { useSubfieldGroups } from "api/useSubfieldGroups";
import { Feature } from "geojson";
import { useSubfieldMutations } from "api/subfields/useSubfieldMutations";
import { useSubfieldTypes } from "api/subfields/useSubfieldTypes";

type SubfieldGroup = {
  id: string;
  name: string;
  inputValue?: string;
};

const filter = createFilterOptions<SubfieldGroup>();

const defaultValues: (ISubfield & IRXSubfield) & { geometry: string } = {
  id: "",
  fieldId: "",
  cid: "",
  subfieldTypeId: "",
  subfieldGroupId: "",
  description: "",
  geometry: "",
  // rx subfieldType only
  targetRate: "",
  rowSpacing: "",
  productAmount: "",
  replicationId: "",
  treatment: "",
  // end rx only
};

export function SubfieldFormView({ feature }: { feature?: Feature }) {
  const { t } = useTranslation();
  const history = useHistory();
  const [uploadError, setUploadError] = useState("");
  const [createType, setCreateType] = useState("draw");

  const { org, rootUrl, season, configQuery } = useOrgState();
  const { uoms } = configQuery.data || {};
  const subfieldTypesQ = useSubfieldTypes(org);
  const { subfieldType } = useParams<IUrlParams>();
  const { deleteMutation, saveMutation } = useSubfieldMutations({
    org,
    seasonId: season?.id,
    type: subfieldType,
  });
  const { drawData } = useInventoryState();
  const {
    query: subfieldGroupQ,
    mutation: subfieldGroupMutation,
  } = useSubfieldGroups(org);
  const {
    setDrawOptions,
    zoomToField,
    fitInventoryBounds,
    setOutsideDrawData,
  } = useInventoryDispatch();
  const typeEncoded = encodeURIComponent(subfieldType);
  const methods = useForm({
    defaultValues: {
      ...defaultValues,
      ...feature?.properties,
      ...{ seasonId: season?.id },
    },
  });
  const {
    getValues,
    setValue,
    handleSubmit,
    register,
    reset,
    formState,
    watch,
    control,
    errors,
  } = methods;
  const {
    cid,
    description,
    id,
    productAmount,
    replicationId,
    rowSpacing,
    subfieldGroupId,
    targetRate,
    treatment,
  } = watch();

  useEffect(() => {
    setDrawOptions({
      controls: {
        trash: true,
        rectangle: true,
        polygon: true,
      },
      defaultMode: "draw_rectangle",
    });
  }, [setDrawOptions]);

  async function handleSave(d: ISubfield & IRXSubfield & { geometry: string }) {
    if (!formState.isDirty) {
      history.push(`${rootUrl}/inventory/subfields/${typeEncoded}`);
      return false;
    }
    d.geometry = d.geometry ? JSON.parse(d.geometry) : "";
    // server doesn't want these props if not set
    if (!d.id) {
      delete d.id;
    }
    if (!d.targetRate && d.targetRate !== 0) {
      delete d.targetRate;
    }
    if (!d.rowSpacing && d.rowSpacing !== 0) {
      delete d.rowSpacing;
    }
    if (!d.productAmount && d.productAmount !== 0) {
      delete d.productAmount;
    }
    if (!d.replicationId) {
      delete d.replicationId;
    }
    if (!d.treatment) {
      delete d.treatment;
    }
    // end cleanup props
    saveMutation.mutate(d, {
      onSuccess: () => {
        reset({ ...defaultValues, ...d });
        history.push(`${rootUrl}/inventory/subfields/${typeEncoded}`);
      },
    });
  }

  return (
    <FormWrapper
      methods={methods}
      data={{ ...feature?.properties }}
      geometryData={drawData?.features[0]?.geometry}
      existingGeom={feature?.geometry}
      cancelHref={`${rootUrl}/inventory/subfields/${typeEncoded}`}
      saveState={{
        isLoading: saveMutation.isLoading,
        isError: saveMutation.error,
        errorMessage: saveMutation.error,
      }}
      onDelete={async () => {
        deleteMutation.mutate(id, {
          onSuccess: () => {
            reset(defaultValues);
            history.push(`${rootUrl}/inventory/subfields/${typeEncoded}`);
          },
        });
      }}
      onSubmit={handleSubmit(handleSave)}
    >
      <input
        ref={register}
        required
        type="hidden"
        name="subfieldTypeId"
        value={
          subfieldTypesQ.data?.find(
            (st) => st.nameEn?.toLowerCase() === subfieldType
          )?.id || ""
        }
      />
      <Stack gap={2}>
        <Controller
          name="fieldId"
          rules={{ required: true }}
          control={control}
          render={(props: {
            onChange: (_val: string) => void;
            value: string;
          }) => (
            <FieldsByFarm
              onChange={(_e, item) => {
                const field = item as IField;
                if (field?.id) {
                  zoomToField(field.id);
                }
                props.onChange(field?.id || "");
              }}
              value={props.value}
            />
          )}
        />
        <Controller
          name="subfieldGroupId"
          rules={{ required: true }}
          control={control}
          render={(props: {
            onChange: (_val: string) => void;
            value: string;
          }) => (
            <>
              <Autocomplete
                label={`${t("common.group")} *`}
                error={subfieldGroupMutation.isError}
                disableClearable={false}
                TextFieldProps={{
                  helperText: subfieldGroupMutation.isError
                    ? subfieldGroupMutation.error
                    : "",
                }}
                InputProps={{
                  required: true,
                }}
                onChange={async (_e, newValue) => {
                  subfieldGroupMutation.reset();
                  const asObj = newValue as SubfieldGroup;
                  if (asObj?.inputValue) {
                    // it is a new option, needs created
                    subfieldGroupMutation.mutate(
                      {
                        name: asObj.inputValue,
                      },
                      {
                        onSuccess: (data) => {
                          // save and get ID
                          props.onChange(data.id);
                        },
                      }
                    );
                  } else {
                    props.onChange(asObj?.id || "");
                  }
                }}
                options={subfieldGroupQ.data ?? []}
                value={
                  subfieldGroupQ.data?.find(
                    (sg) => sg.id === subfieldGroupId
                  ) ?? null
                }
                filterOptions={(options: SubfieldGroup[], params) => {
                  const filtered = filter(options, params);

                  // Suggest the creation of a new value
                  const isExisting = options.some(
                    (option) => params?.inputValue === option.name
                  );
                  if (params?.inputValue !== "" && !isExisting) {
                    filtered.push({
                      id: "0",
                      inputValue: params?.inputValue,
                      name: `${t("common.add")} "${params?.inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                getOptionLabel={(option) => {
                  const asObj = option as SubfieldGroup;
                  // Value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }
                  return asObj.name;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                // freeSolo
              />
            </>
          )}
        />

        <TextField
          fullWidth
          label={"ID"}
          id="subfield-cid"
          value={cid}
          inputProps={{ name: "cid", ref: register, maxLength: 50 }}
          placeholder="ID"
        />

        {subfieldType === "rx" ? (
          <>
            <TextField
              fullWidth
              label={t("common.targetRate")}
              id="target-rate"
              value={targetRate}
              inputProps={{
                type: "number",
                step: 0.01,
                ref: register,
                name: "targetRate",
              }}
              InputProps={{
                endAdornment: <Box sx={{ mx: 1 }}>seeds/ac</Box>,
              }}
            />
            <TextField
              fullWidth
              label={t("common.rowSpacing")}
              id="row-spacing"
              value={rowSpacing}
              inputProps={{
                type: "number",
                step: 0.01,
                ref: register,
                name: "rowSpacing",
              }}
              InputProps={{
                endAdornment: (
                  <Box sx={{ mx: 1 }}>{uoms?.smallDistance?.label}</Box>
                ),
              }}
            />
            <TextField
              label={t("common.productAmount")}
              id="product-amt"
              value={productAmount}
              inputProps={{
                name: "productAmount",
                type: "number",
                step: 0.1,
                ref: register,
              }}
            />
            <TextField
              label={t("common.replicationId")}
              id="replication-id"
              value={replicationId}
              inputProps={{
                maxLength: 50,
                ref: register,
                name: "replicationId",
              }}
            />
            <TextField
              label={t("common.treatment")}
              id="treatment-select"
              value={treatment}
              inputProps={{
                maxLength: 50,
                ref: register,
                name: "treatment",
              }}
            />
          </>
        ) : null}
        <TextField
          fullWidth
          multiline
          id="subfield-desc"
          value={description}
          inputProps={{
            name: "description",
            ref: register,
            maxLength: 255,
          }}
          label={t("common.notes")}
        />
        <FormControl>
          <FormLabel
            sx={{ display: "none", color: "inherit !important" }}
            id="draw-subfield-radio-label"
          >
            {t("inventory.subfields.geometrySrc")}
          </FormLabel>
          <RadioGroup
            aria-labelledby="draw-subfield-radio-label"
            defaultValue="draw"
            value={createType ?? "draw"}
            onChange={(e) => {
              setCreateType(e.target.value);
            }}
            name="draw-type"
          >
            <FormControlLabel
              value="draw"
              control={<Radio />}
              label={t("inventory.fields.drawField")}
              sx={{ m: 0 }}
            />
            <FormControlLabel
              value="layer"
              control={<Radio />}
              label={`${t("inventory.fields.uploadBoundaryFile")} [.geojson]`}
              sx={{ m: 0 }}
            />
          </RadioGroup>

          {createType === "layer" ? (
            <Box ml={2}>
              <GeodataUpload
                type="Polygon"
                onChange={(geojson) => {
                  setUploadError("");
                  if (geojson) {
                    setOutsideDrawData(geojson);
                    fitInventoryBounds({ geojson });
                    const props = geojson.features.length
                      ? geojson.features[0].properties
                      : {};
                    const keys = Object.keys(props);
                    keys.forEach((k: keyof ISubfield) => {
                      if (["cid", "description"].includes(k) && !getValues(k))
                        setValue(k, props[k]);
                    });
                  }
                }}
                onError={(e) => {
                  setUploadError(e);
                }}
              />
            </Box>
          ) : null}
        </FormControl>
        {errors?.geometry || uploadError ? (
          <Alert severity="error">
            {uploadError || t("common.geometryRequired")}
          </Alert>
        ) : null}
      </Stack>
    </FormWrapper>
  );
}
