import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useOrgState } from "providers/OrgProvider";
import { useInventoryDispatch } from "providers/InventoryProvider";
import FieldForm from "./FieldForm";

export default function CreateOrEditField() {
  const { editId } = useParams();
  const { t } = useTranslation();
  const { rootUrl } = useOrgState();
  const {
    enableDraw,
    disableDraw,
    setDrawOptions,
    highlightLayerFeatures,
  } = useInventoryDispatch();

  useEffect(() => {
    // remove highlighted/selected features on edit
    highlightLayerFeatures([]);
    enableDraw();
    return disableDraw;
  }, [enableDraw, disableDraw, highlightLayerFeatures]);

  useEffect(() => {
    setDrawOptions({
      controls: {
        trash: true,
        polygon: true,
        rectangle: true,
      },
      defaultMode: "draw_rectangle",
    });
  }, [setDrawOptions]);

  return (
    <div>
      <Breadcrumbs
        sx={{ display: "flex", textTransform: "uppercase" }}
        aria-label="operation-details-breadcrumb"
      >
        <Link underline="hover" href={`${rootUrl}/inventory/fields`}>
          {t("inventory.fields.fieldList")}
        </Link>
        <Typography color="text.primary">
          {!editId
            ? t("inventory.fields.createField")
            : t("inventory.fields.editField")}
        </Typography>
      </Breadcrumbs>

      <FieldForm />
    </div>
  );
}
