import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import AuthView from "views/AuthView";
import AppLoading from "views/AppLoading";
import { useTranslation } from "react-i18next";

function LoadingView() {
  const { t } = useTranslation();
  return (
    <AuthView>
      <AppLoading message={`${t("auth.signingIn")}...`} />
    </AuthView>
  );
}

function WithAuthWrapper({ children }: { children: React.ReactNode }) {
  return <>{children}</>;
}

const WithAuth = withAuthenticationRequired(WithAuthWrapper, {
  onRedirecting: () => <LoadingView />,
});

export const RequireAuth = ({ children }: { children: React.ReactNode }) => {
  return <WithAuth>{children}</WithAuth>;
};
