import React, { useState, useEffect } from "react";
import { Route, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import { useOrgState } from "providers/OrgProvider";
import { useFieldsState } from "providers/FieldsProvider";
import { useInventoryDispatch } from "providers/InventoryProvider";
import FieldListView from "./FieldListView";
import FieldsSummaryView from "./FieldsSummaryView";
import MapThemesView from "./MapThemes";
import CreateOrEditField from "./CreateOrEditField";
import CardStack from "../../CardStack";

export default function FieldsView() {
  const { editId } = useParams();
  const { t } = useTranslation();

  const {
    setLegendData,
    setColSizes,
    showFields,
    hideFields,
    setTitle,
    setLabelProp,
  } = useInventoryDispatch();
  const [legendProp, setLegendProp] = useState("landAgreementType");
  const { org, season } = useOrgState();
  const { themeRollup } = useFieldsState();
  const [expanded, setExpanded] = useState();

  const seasonId = season?.id;

  useEffect(() => {
    setLabelProp("name");
    setTitle(t("inventory.fields.title"));
    setColSizes([5, 7]);
  }, [setTitle, setColSizes, setLabelProp, t]);

  useEffect(() => {
    hideFields();
    return showFields;
  }, [hideFields, showFields]);

  // set legend data props (NOTE: currently only landAgreementColor used)
  useEffect(() => {
    setLegendData({
      title: t("inventory.fields.landAgreement"),
      colorProp: "landAgreementColor",
      dataProp: "landAgreementType",
    });
    return () => {
      setLegendData(null);
    };
  }, [setLegendData, t]);

  return (
    <CardStack
      expanded={Boolean(editId) || expanded}
      // bottomMinHeight="500px"
      // topHeight={"310px"}
      topChildren={
        <>
          <Paper>
            <FieldsSummaryView org={org} seasonId={seasonId} />
          </Paper>
          <Paper>
            <MapThemesView
              themeType={legendProp}
              onThemeTypeChange={(type) => {
                setLegendProp(type);
              }}
              themeData={themeRollup?.[legendProp]}
            />
          </Paper>
        </>
      }
    >
      <Paper
        sx={{
          p: 3,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          maxHeight: "100%",
        }}
      >
        <Route path="/:org/:season/inventory/fields" exact>
          <FieldListView
            onToggle={() => {
              setExpanded(!expanded);
            }}
            toggled={expanded}
          />
        </Route>
        <Route path={"/:org/:season/inventory/fields/add"}>
          <CreateOrEditField />
        </Route>
        <Route path={"/:org/:season/inventory/fields/:editId/edit"}>
          <CreateOrEditField />
        </Route>
      </Paper>
    </CardStack>
  );
}
