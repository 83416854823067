import { useCallback } from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "./queryKeys";
import { sortObjArr } from "lib";

export function useFarms(org?: string) {
  const getData = useCallback(async () => {
    if (org) {
      const res = await axios.get<
        { name: string; id: string; label: string }[]
      >(`/${org}/farms`);
      res.data.forEach((r) => {
        r.label = r.name;
      });
      sortObjArr(res.data, "name");
      return res.data;
    }
  }, [org]);

  return useQuery({
    queryKey: queryKeys.farms(org),
    queryFn: getData,
    enabled: !!org,
  });
}
