import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { FeatureCollection } from "geojson";
import observationKeys from "./observationKeys";

export function useObservationById({
  org,
  observationId,
}: {
  org: string;
  observationId?: string;
}) {
  const fetchObservationsGeodataFn = useCallback(async () => {
    const geodataRes = await axios.get<FeatureCollection>(
      `/${org}/observations/geodata/${observationId}`
    );
    const ftr = geodataRes.data.features[0];
    const res = await axios.get(`/${org}/observations/${observationId}`);
    Object.assign(ftr.properties, res.data, {
      observedOnDateFormatted: res.data.observedOnUtc
        ? new Date(res.data.observedOnUtc).toLocaleDateString()
        : null,
    });
    return ftr;
  }, [observationId, org]);

  return useQuery({
    queryKey: observationKeys.geodata(org, observationId),
    queryFn: fetchObservationsGeodataFn,
    enabled: !!org && !!observationId,
  });
}
