import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";
import { useTranslation } from "react-i18next";
import RenderActiveShape from "./ActiveShape";

export default function ActiveShapePieChart({
  data,
  isActive,
  dataMapFn,
  dataKey,
  onClick,
  activeIndex,
}) {
  const [activeIdx, setActiveIdx] = useState(activeIndex);
  const { t } = useTranslation();
  useEffect(() => {
    setActiveIdx(activeIndex);
  }, [activeIndex]);
  return (
    <>
      <ResponsiveContainer>
        <PieChart margin={{ top: 25 }}>
          <Pie
            style={{
              cursor: "pointer",
              opacity: isActive ? "1" : "0.5",
            }}
            onClick={(d) => {
              const x = data.indexOf(d.payload.payload);
              setActiveIdx(x);
              if (x > -1) {
                onClick(d.payload.payload);
              }
            }}
            dataKey={dataKey}
            data={data}
            activeIndex={activeIdx}
            // NOTE: isAnimationActive is due to bug with labels not showing
            isAnimationActive={false}
            activeShape={(props) => {
              return <RenderActiveShape label={t("common.count")} {...props} />;
            }}
            minAngle={10}
            innerRadius={45}
            outerRadius={60}
            label={(entry) => (entry.index === activeIdx ? "" : entry.name)}
            // labelLine={false}
          >
            {data?.map(dataMapFn)}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </>
  );
}
ActiveShapePieChart.defaultProps = {
  data: null,
  isActive: true,
  dataMapFn: function CellComponent(entry) {
    return <Cell key={entry.name} fill={entry.color} />;
  },
  onClick: () => {},
  activeIndex: null,
};
ActiveShapePieChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  isActive: PropTypes.bool,
  dataMapFn: PropTypes.func,
  dataKey: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  activeIndex: PropTypes.number,
};
