import axios from "axios";
import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { ConservationItem } from "types/IConservationItem";

export function useConservations({
  enabled,
  org,
  seasonId,
}: {
  enabled?: boolean;
  org: string;
  seasonId: string;
}) {
  const getConservationItems = useCallback(async () => {
    const res = await axios.get<ConservationItem[]>(
      `/${org}/aois/conservation/season/${seasonId}`
    );
    return res.data;
  }, [org, seasonId]);

  return useQuery({
    queryFn: getConservationItems,
    queryKey: ["organization", org, "aois", "conservation", "season", seasonId],
    enabled: !!org && !!seasonId && !!(enabled !== false),
  });
}
