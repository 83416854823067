import React, { useCallback, useState } from "react";
import IconButton from "@mui/material/IconButton";
import MenuOpenOutlinedIcon from "@mui/icons-material/Menu";
// import MenuOpenOutlinedIcon from "@mui/icons-material/MenuOpenOutlined";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Sidebar from "./Sidebar/Sidebar";

export default function AppLayout({
  children,
  sidebarChildren,
  title,
  sidebarOpen = true,
  sidebarProps,
}: {
  children: React.ReactNode;
  sidebarChildren?: React.ReactNode;
  title: React.ReactNode;
  sidebarOpen?: boolean;
  sidebarProps?: { activeKey?: string; onToggled?: (_open: boolean) => void };
}) {
  const [open, setOpen] = useState(sidebarOpen);

  const handleToggleDrawer = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const handleToggled = useCallback(
    (o) => {
      setOpen(o);
      if (sidebarProps?.onToggled) {
        sidebarProps.onToggled(o);
      }
    },
    [sidebarProps]
  );

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <AppBar
        className="hide-print"
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar>
          {sidebarChildren && (
            <IconButton
              aria-label={`${!open ? "open" : "close"} drawer`}
              onClick={handleToggleDrawer}
              edge="start"
              sx={{
                marginRight: 3,
              }}
            >
              <MenuOpenOutlinedIcon
              // sx={{ transform: open ? "none" : "rotate(180deg)" }}
              />
            </IconButton>
          )}
          <Box flexGrow={1}>{title}</Box>
        </Toolbar>
      </AppBar>
      {sidebarChildren && (
        <Sidebar open={open} {...sidebarProps} onToggle={handleToggled}>
          {sidebarChildren}
        </Sidebar>
      )}
      <Box
        component="main"
        sx={() => ({
          // backgroundColor:
          //   theme.palette.mode === "light" ? "#f2f2f2" : "#121212",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          // p: 2,
          // px: 3,
          mt: 8,
        })}
      >
        {children}
      </Box>
    </Box>
  );
}
