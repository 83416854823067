import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { useParams } from "react-router-dom";
import { fitBounds } from "lib/MapboxMap";
import { useFieldsState } from "providers";
import { Feature, Geometry, Polygon } from "geojson";
import { useSubfields } from "api/useSubfields";
import { ICollectEvent, IField, IRasterType, IUrlParams } from "types";
import { useOrgState } from "providers";
import { GeoJSONSource, Map } from "mapbox-gl";
import LoadingButton from "components/LoadingButton";
import { featureCollection } from "@turf/helpers";
import { useSubfieldsByFieldId } from "api/useSubfieldsGeodata";
import RasterSelects from "./RasterSelects";
import SubfieldsSelect from "views/ImageryView/SubfieldsSelect";
import { UseQueryResult } from "@tanstack/react-query";
import { SensorLookup, SubfieldLookup } from "../types";

export default function ImageryInsightsBySubfield({
  chartDataQ,
  mapRef,
  onFieldChange,
  onSubmit,
}: {
  chartDataQ: UseQueryResult<unknown, Error>;
  mapRef?: Map;
  onFieldChange?: (_d: Feature<Polygon, IField>) => void;
  onSubmit: (_e: {
    fieldId: string;
    subfieldIds: string[];
    sensorType: string;
    collectEventId: string;
    rasterOptions: string[];
  }) => void;
}) {
  const { org } = useParams<IUrlParams>();
  const { season } = useOrgState();
  const { t } = useTranslation();
  const { fieldsGeodataState } = useFieldsState();
  const [selectedField, setSelectedField] = useState<
    Feature<Geometry, IField>
  >();

  const [selectedCollectEvent, setSelectedCollectEvent] = useState<
    ICollectEvent
  >();
  const [selectedSensor, setSelectedSensor] = useState<SensorLookup>();
  const [selectedRasterTypes, setSelectedRasterTypes] = useState<
    IRasterType[]
  >();
  const [selectedSubfields, setSelectedSubfields] = useState<
    SubfieldLookup[]
  >();
  const subfieldsQ = useSubfields(org, season?.id);
  const subfieldsGeoQ = useSubfieldsByFieldId({
    org,
    fieldId: (selectedField?.properties?.id as string) ?? "",
    seasonId: season?.id,
  });

  return (
    <>
      <h3 style={{ marginTop: "1rem" }}>{t("common.include")}</h3>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          const d = {
            fieldId: selectedField.properties.id,
            subfieldIds: selectedSubfields?.map((d) => d.id),
            sensorType: selectedSensor?.slug,
            collectEventId: selectedCollectEvent?.id,
            rasterOptions: selectedRasterTypes?.map((r) => r.slug),
          };
          onSubmit(d);
        }}
      >
        <Stack gap={2}>
          <SubfieldsSelect
            subfieldsRequired
            sx={{ mt: 2 }}
            onFieldChange={(fld) => {
              setSelectedSubfields(null);
              const ftr = fieldsGeodataState.data?.features?.find(
                (d: Feature) => d.properties.id === fld?.id
              );
              const geom = ftr?.geometry;
              setSelectedField(ftr);
              onFieldChange(ftr);
              if (geom && mapRef) {
                fitBounds({
                  map: mapRef,
                  geojson: geom,
                });
              }
            }}
            fields={
              fieldsGeodataState.data?.features
                ?.filter((f: Feature) => {
                  const hasSubfields = subfieldsQ.data
                    ?.map((d: SubfieldLookup) => d.fieldId)
                    ?.includes(f.properties.id);
                  return hasSubfields;
                })
                .map((f: Feature) => f.properties) ?? []
            }
            subfields={subfieldsQ.data}
            onSubfieldsChange={(items) => {
              // resetSaveCollectEvent();
              setSelectedSubfields(items);
              if (mapRef) {
                try {
                  const ftrs = subfieldsGeoQ.data?.features?.filter((f) =>
                    items.map((s) => s.id).includes(f.properties.id)
                  );
                  const src = mapRef.getSource(
                    "subfields-src"
                  ) as GeoJSONSource;
                  if (src) {
                    src.setData(featureCollection(ftrs ?? []));
                  }
                } catch (e) {
                  //
                }
              }
              // if (!items?.length) {
              //   reset({
              //     selectedField,
              //   });
              // }
            }}
          />
          <RasterSelects
            onChange={(d) => {
              if (Object.hasOwn(d, "sensor")) {
                setSelectedSensor(d.sensor);
              }
              if (Object.hasOwn(d, "collectEvent")) {
                setSelectedCollectEvent(d.collectEvent);
              }
              if (Object.hasOwn(d, "rasterOptions")) {
                setSelectedRasterTypes(d.rasterOptions);
              }
            }}
            filterGeometry={selectedField?.geometry}
          />
        </Stack>
        <Divider sx={{ my: 2 }} />
        <Box>
          <LoadingButton
            color="primary"
            variant="contained"
            type="submit"
            isLoading={chartDataQ.isFetching}
          >
            {t("insights.yield.reportIt")}
          </LoadingButton>
        </Box>
        {chartDataQ.isError ? (
          <Alert severity="error">
            {chartDataQ.error ?? t("common.somethingUnexpected")}
          </Alert>
        ) : null}
      </form>
    </>
  );
}
