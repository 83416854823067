import SvgIcon from "@mui/material/SvgIcon";
import { IconProps } from "./IconProps";

export default function DashboardIcon({ fill, size, sx }: IconProps) {
  return (
    <SvgIcon fontSize={size} sx={sx}>
      <svg
        width="88.03"
        height="83"
        version="1.1"
        viewBox="0 0 88.03 83"
        xmlSpace="preserve"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="translate(-28.18,-31)">
          <linearGradient
            id="dash-icon-gradient"
            x1="46.521"
            x2="97.476"
            y1="71.537"
            y2="71.537"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D5E100" offset=".1213" />
            <stop stopColor="#79C80E" offset=".9881" />
          </linearGradient>
          <path
            d="m95.08 46.06h-30.78c-1.32 0-2.39 1.07-2.39 2.39v12.99h-12.99c-1.32 0-2.39 1.07-2.39 2.39v30.78c0 1.32 1.07 2.39 2.39 2.39h30.78c1.32 0 2.39-1.07 2.39-2.39v-12.98h12.99c1.32 0 2.39-1.07 2.39-2.39v-30.79c0.01-1.32-1.07-2.39-2.39-2.39zm-17.78 46.17h-25.99v-25.99h25.99zm15.39-15.39h-10.6v-13c0-1.32-1.07-2.39-2.39-2.39h-13v-10.6h25.99z"
            fill={fill ?? "url(#dash-icon-gradient)"}
          />

          <linearGradient
            id="Shape_1_"
            x1="28.178"
            x2="113.69"
            y1="72.657"
            y2="72.657"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#0ff" offset="0" />
            <stop stopColor="#0071C1" offset="1" />
          </linearGradient>
          <path
            id="Shape_11_"
            d="m28.18 108.34v3.77c0 1.04 0.84 1.89 1.89 1.89h77.97c3.13 0 5.66-2.53 5.66-5.66v-77.03h-7.55v72.31c0 1.56-1.27 2.83-2.83 2.83h-73.26c-1.04 0-1.88 0.85-1.88 1.89z"
            fill={fill ?? "url(#Shape_1_)"}
          />
          <linearGradient
            id="Shape_2_"
            x1="30.693"
            x2="116.21"
            y1="72.343"
            y2="72.343"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#0ff" offset="0" />
            <stop stopColor="#0071C1" offset="1" />
          </linearGradient>
          <path
            id="Shape_8_"
            d="m114.32 31h-77.97c-3.13 0-5.66 2.53-5.66 5.66v77.03h7.55v-1.48c-0.11-0.35-0.11-0.73 0-1.08v-69.75c0-1.56 1.27-2.83 2.83-2.83h73.25c1.04 0 1.89-0.84 1.89-1.89v-3.77c0-1.05-0.85-1.89-1.89-1.89z"
            fill={fill ?? "url(#Shape_2_)"}
          />
        </g>
      </svg>
    </SvgIcon>
  );
}
