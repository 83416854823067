import React from "react";
import { Button, ButtonProps, CircularProgress } from "@mui/material";

interface LoadingButtonProps {
  isLoading?: boolean;
}

export default function LoadingButton({
  children,
  isLoading,
  ...rest
}: ButtonProps & LoadingButtonProps) {
  return (
    <Button disabled={isLoading} {...rest}>
      <div style={{ display: "flex", alignItems: "center" }}>
        {children}
        {isLoading ? (
          <CircularProgress color="secondary" sx={{ ml: 1 }} size={16} />
        ) : null}
      </div>
    </Button>
  );
}
