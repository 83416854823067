import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import lookupKeys from "../lookups/lookupKeys";
import { IAnimalBreed } from "types/IAssetType";
import { useAnimalTypes } from "./useAnimalTypes";

export function useAnimalBreeds(org: string) {
  const typesQ = useAnimalTypes(org);
  const getBreeds = useCallback(async () => {
    const res = await axios.get<(IAnimalBreed & { label: string })[]>(
      `/${org}/lookups/animalbreeds`
    );
    res.data.forEach((d) => {
      const type = typesQ.data?.find((t) => t.id === d.assetAnimalTypeId);
      d.label = d.name;
      d.assetAnimalTypeName = type.name;
    });
    return res.data?.sort((first, second) => {
      return first.assetAnimalTypeName > second.assetAnimalTypeName
        ? 1
        : first.assetAnimalTypeName === second.assetAnimalTypeName
        ? 0
        : -1;
    });
  }, [org, typesQ.data]);

  const q = useQuery({
    queryKey: lookupKeys.byType(org, "animalbreeds"),
    queryFn: getBreeds,
    enabled: !!(org && typesQ.data),
  });
  return q;
}
