import axios from "axios";
import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { IOperation } from "types/IOperation";
import operationKeys from "./operationKeys";

export function useOperations({
  enabled,
  org,
  seasonId,
  type,
}: {
  enabled?: boolean;
  org: string;
  seasonId: string;
  type: string;
}) {
  const getOperations = useCallback(async () => {
    const res = await axios.get<
      (IOperation & {
        fieldFarmGroupKey: string;
        fieldFarmTypeKey: string;
      })[]
    >(`/${org}/operations/${type}/season/${seasonId}`);
    res.data.forEach((r) => {
      // NOTE: added this hack to support grouped operations tables
      // with duplicate name values between farms
      r.fieldFarmGroupKey = `${r.field}${r.farm ? `:${r.farm}` : ""}`;
      r.fieldFarmTypeKey = `${r.field}${r.farm ? `:${r.farm}` : ""}${
        r.type ? `:${r.type}` : ""
      }`;
    });
    return res.data;
  }, [org, seasonId, type]);

  return useQuery({
    queryFn: getOperations,
    queryKey: operationKeys.bySeason(org, type, seasonId),
    enabled: !!org && !!seasonId && !!type && !!(enabled !== false),
    staleTime: 10 * (60 * 1000), // 10 mins
  });
}
