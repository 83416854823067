import { useCallback } from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { ProductLookup, YieldType } from "./types";

const keys = {
  all: (org: string, type: string, fieldId: string) =>
    [
      "organization",
      org,
      "yield-insights",
      "products",
      "planted",
      type,
      "field",
      fieldId,
    ] as const,
};

export function useProductsPlanted(
  org: string,
  yieldType: YieldType | "imagery",
  fieldId?: string
) {
  const getProductsPlanted = useCallback(async () => {
    const url =
      yieldType === "imagery"
        ? `/${org}/insights/yield/imagery/productplantedapplied/products/planted`
        : `/${org}/insights/yield/productplantedapplied/products/planted`;

    const res = await axios.post<ProductLookup[]>(url, { fieldIds: [fieldId] });
    res.data.forEach((r) => {
      r.date = new Date(r.dateUtc).toLocaleDateString();
    });
    return res.data;
  }, [fieldId, org, yieldType]);

  return useQuery<ProductLookup[], Error>({
    queryKey: keys.all(org, yieldType, fieldId),
    queryFn: getProductsPlanted,
    enabled:
      !!org &&
      ["productplantedapplied", "imagery"].includes(yieldType) &&
      !!fieldId,
  });
}
