import Grid2, { Grid2Props } from "@mui/material/Unstable_Grid2";

export function Grid({
  children,
  ...rest
}: {
  children: React.ReactNode;
  className?: string;
} & Grid2Props) {
  return (
    <Grid2 spacing={3} {...rest}>
      {children}
    </Grid2>
  );
}
