import axios from "axios";
import { useCallback } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import observationKeys from "./observationKeys";
import { ParsedImage } from "api/types";

export function useObservationPhotoDelete({
  org,
  observationId,
}: {
  org: string;
  observationId?: string;
}) {
  const queryClient = useQueryClient();

  const invalidate = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: observationKeys.byId(org, observationId),
    });
  }, [observationId, org, queryClient]);

  const deletePhoto = useCallback(
    async (id: string) => {
      const res = await axios.delete(
        `/${org}/observations/${observationId}/photos/${id}`
      );
      invalidate();
      return res;
    },
    [invalidate, observationId, org]
  );

  const deletePhotoMutation = useMutation({
    mutationFn: deletePhoto,
    onMutate: async () => {
      const key = observationKeys.photosById(org, observationId);
      await queryClient.cancelQueries({ queryKey: key });
      // Snapshot the previous value
      const previousPhotos = queryClient.getQueryData(key) as ParsedImage[];
      // Return a context object with the snapshotted value
      return { previousPhotos };
    },
    onError: (err, deleteId, context) => {
      const z = context.previousPhotos?.find((y) => y.id === deleteId);
      z.errorMessage = err instanceof Error ? err.message : (err as string);
      queryClient.setQueryData(
        observationKeys.photosById(org, observationId),
        context.previousPhotos
      );
    },
    onSuccess: (_d, id) => {
      queryClient.setQueryData(
        observationKeys.photosById(org, observationId),
        (old: ParsedImage[]) => old?.filter((o) => o.id !== id)
      );
      invalidate();
    },
  });

  return deletePhotoMutation;
}
