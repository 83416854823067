import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function PlantIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="183.55"
      height="234.38"
      version="1.1"
      viewBox="0 0 183.55 234.38"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="m183.38 7.4814a6.86 6.86 0 0 0-5.83-5.33c-28.37-3.54-54.58-2.71-77.9 2.48-29.69 6.62-55.15 20.37-75.66 40.88a82.28 82.28 0 0 0 0 116.15 84 84 0 0 0 9.84 8.41v57.69a6.88 6.88 0 0 0 13.75 0v-49.61a82.29 82.29 0 0 0 92.56-16.49c24.21-24.21 19.2-57.57 17.55-76.29-2.52-28.58-4.7-53.27 22.68-70.59a6.86 6.86 0 0 0 3.01-7.3zm-149.69 47.75c24.21-24.21 55.67-37.88 93.89-40.87a161.85 161.85 0 0 0-46.03 34.23 173.52 173.52 0 0 0-47 104.16 68.49 68.49 0 0 1-0.84-97.52zm110.28 31.34c2.16 24.48 4.2 47.6-13.57 65.37a68.51 68.51 0 0 1-82.73 10.78 159.94 159.94 0 0 1 43.88-104.67 148.57 148.57 0 0 1 42-31.26 114.26 114.26 0 0 1 22-8.17 58.56 58.56 0 0 0-11.06 23.86c-3.15 14.22-1.81 29.4-0.52 44.08z" />
    </SvgIcon>
  );
}
