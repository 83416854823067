import { useCallback } from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { sortObjArr } from "lib";
import { IRasterInfo } from "types";

const keys = {
  all: (org: string, id: string) =>
    ["organization", org, "sensors", id] as const,
};

export function useRasterInfoBySensor(org: string, sensorTypeId?: string) {
  const getRasterInfo = useCallback(async () => {
    const url = `/${org}/sensors/${sensorTypeId}/rasterinfo`;
    const res = await axios.get<IRasterInfo>(url);

    if (res.data) {
      sortObjArr(res.data.types, "name");
      sortObjArr(res.data.bands, "name");
    }
    return res.data;
  }, [org, sensorTypeId]);

  return useQuery({
    queryKey: keys.all(org, sensorTypeId),
    queryFn: getRasterInfo,
    enabled: !!org && !!sensorTypeId,
  });
}
