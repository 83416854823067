import { useLocation, useParams } from "react-router-dom";
import { useOrgState } from "providers";
import { useTranslation } from "react-i18next";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { OperationType } from "types/OperationType";
import { IUrlParams } from "types";

export default function OperationBreadcrumbs({
  type,
}: {
  type: OperationType;
}) {
  const { t } = useTranslation();

  const { rootUrl } = useOrgState();
  const { eventId, itemId } = useParams<IUrlParams & { type: OperationType }>();
  const location = useLocation();
  const isEdit =
    location.pathname.includes("/edit") &&
    !location.pathname.includes("/records/");
  const isAdd =
    location.pathname.includes("/add") &&
    !location.pathname.includes("/records/");
  function getOpTypeText() {
    switch (type) {
      case "planted": {
        return t("inventory.operations.plantedOperations");
      }
      case "applied": {
        return t("inventory.operations.appliedOperations");
      }
      case "harvested": {
        return t("inventory.operations.harvestedOperations");
      }
      case "tilled": {
        return t("inventory.operations.tilledOperations");
      }
      default: {
        return t("inventory.operations.title");
      }
    }
  }

  return (
    <>
      <Breadcrumbs
        sx={{ display: "flex", textTransform: "uppercase" }}
        aria-label="operation-details-breadcrumb"
      >
        <Link
          underline="hover"
          href={`${rootUrl}/inventory/operations/${type}`}
        >
          {getOpTypeText()}
        </Link>
        {(!isAdd && isEdit) ||
        eventId ||
        location.pathname.endsWith("/records/add") ? (
          <Link
            underline="hover"
            href={`${rootUrl}/inventory/operations/${type}/items/${itemId}`}
          >
            {t("common.details")}
          </Link>
        ) : !isAdd ? (
          <Typography color="text.primary">{t("common.details")}</Typography>
        ) : null}
        {isAdd ? (
          <Typography color="text.primary">{t("common.add")}</Typography>
        ) : null}
        {itemId && isEdit ? (
          <Typography color="text.primary">{t("common.edit")}</Typography>
        ) : null}
        {eventId || location.pathname.endsWith("/records/add") ? (
          <Link
            underline="hover"
            href={`${rootUrl}/inventory/operations/${type}/items/${itemId}`}
          >
            {t("common.records")}
          </Link>
        ) : null}
        {eventId || location.pathname.endsWith("/records/add") ? (
          <Typography color="text.primary">
            {!eventId ? t("common.add") : t("common.edit")}
          </Typography>
        ) : null}
      </Breadcrumbs>
    </>
  );
}
