import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function FixedIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="266.39"
      height="268.23"
      version="1.1"
      viewBox="0 0 266.39 268.23"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="m266.39 112.84v-26.61h-17.76v-55.15l-31.08-31.08-31.08 31.08v55.15h-22.2v-55.15l-31.08-31.08-31.07 31.08v55.15h-22.2v-55.15l-31.08-31.08-31.08 31.08v55.15h-17.76v26.64h17.76v84.36h-17.76v26.6h17.76v44.4h62.16v-44.4h22.2v44.4h62.15v-44.4h22.2v44.4h62.16v-44.4h17.76v-26.6h-17.76v-84.39zm-17.76-17.76h8.88v8.88h-8.88zm-230.87 119.87h-8.88v-8.88h8.88zm0-111h-8.88v-8.88h8.88zm53.24 155.4h-44.4v-224.59l22.2-22.2 22.2 22.2zm31.08-44.4h-22.2v-8.88h22.2zm0-17.76h-22.2v-84.35h22.2zm0-93.23h-22.2v-8.88h22.2zm53.27 155.39h-44.35v-224.59l22.2-22.2 22.2 22.2zm31.08-44.4h-22.2v-8.88h22.2zm0-17.76h-22.2v-84.35h22.2zm0-93.23h-22.2v-8.88h22.2zm53.32 93.27v62.16h-44.4v-224.63l22.2-22.2 22.2 22.2zm17.76 8.88v8.88h-8.88v-8.88z" />
    </SvgIcon>
  );
}
