import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import lookupKeys from "./lookupKeys";

export function useTilledTypes(org: string) {
  const getTilledTypes = useCallback(async () => {
    const res = await axios.get<{ id: string; name: string }[]>(
      `${org}/lookups/tilledtypes`
    );
    return res.data;
  }, [org]);

  return useQuery({
    queryKey: lookupKeys.byType(org, "tilledtypes"),
    queryFn: getTilledTypes,
    staleTime: 10 * (60 * 1000), // 10 mins
  });
}
