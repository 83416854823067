import { Calendar, Camera, Monitor } from "react-feather";
import { useTranslation } from "react-i18next";
import { useOrgState } from "providers/OrgProvider";
import Box from "@mui/material/Box";
import Autocomplete from "lib/Select/Autocomplete";
import Stack from "@mui/material/Stack";
import { ICollectEvent, IRasterType, ISensor } from "types";
import { useSensors } from "api/useSensors";
import { bbox } from "components/Map";
import { useCollectEvents } from "api/useCollectEvents";
import { Geometry } from "geojson";
import { useEffect, useState } from "react";
import { useRasterInfoBySensor } from "api/useRasterInfoBySensor";

export default function RasterSelects({
  filterGeometry,
  onChange,
}: {
  filterGeometry: Geometry;
  onChange: (_d: {
    sensor?: ISensor;
    collectEvent?: ICollectEvent;
    rasterOptions?: IRasterType[];
  }) => void;
}) {
  const { t } = useTranslation();
  const { org } = useOrgState();
  const [sensor, setSensor] = useState<ISensor>();
  const [collectEvent, setCollectEvent] = useState<ICollectEvent>();
  const [rasterOptions, setRasterOptions] = useState<IRasterType[]>();

  const sensorsQ = useSensors(
    org,
    filterGeometry
      ? bbox({
          type: "Feature",
          properties: {},
          geometry: filterGeometry,
        })
      : null,
    !!filterGeometry
  );
  const collectEventsQ = useCollectEvents({
    org,
    enabled: !!(filterGeometry && sensor),
    bbox: filterGeometry
      ? bbox({
          type: "Feature",
          properties: {},
          geometry: filterGeometry,
        })
      : null,
    sensorTypeId: sensor?.id,
  });
  const rasterQ = useRasterInfoBySensor(org, sensor?.id);

  // reset selections data changes
  useEffect(() => {
    setSensor(null);
    setCollectEvent(null);
    setRasterOptions(null);
  }, [sensorsQ.data]);

  return (
    <Stack gap={2}>
      <Autocomplete
        name="sensorType"
        inputProps={{
          value: sensor?.id,
          required: true,
        }}
        fullWidth
        InputProps={{
          startAdornment: (
            <Box sx={{ ml: 2 }}>
              <Camera /> *
            </Box>
          ),
        }}
        disableClearable={false}
        value={sensor ?? null}
        loading={sensorsQ.isFetching}
        getOptionLabel={(o) => (typeof o !== "string" ? o.name : "")}
        options={sensorsQ.data ?? []}
        onChange={async (_e, item) => {
          const i = item as ISensor;
          setSensor(i);
          setCollectEvent(null);
          setRasterOptions(null);
          onChange({
            sensor: i,
            collectEvent: null,
            rasterOptions: null,
          });
        }}
      />
      <Autocomplete
        fullWidth
        inputProps={{
          value: collectEvent?.id,
          required: true,
        }}
        disableClearable={false}
        InputProps={{
          startAdornment: (
            <Box sx={{ ml: 2 }}>
              <Calendar /> *
            </Box>
          ),
        }}
        value={collectEvent ?? null}
        loading={collectEventsQ.isFetching}
        options={collectEventsQ.data || []}
        onChange={(_e, item) => {
          const i = item as ICollectEvent;
          setCollectEvent(i);
          onChange({
            collectEvent: i,
          });
        }}
      />
      <Autocomplete
        fullWidth
        inputProps={{
          required: !rasterOptions?.length,
        }}
        multiple
        aria-label={t("imagery.compare.displayType")}
        disableClearable={false}
        InputProps={{
          required: true,
          value: rasterOptions?.map((r) => r.slug).join(),
          startAdornment: (
            <Box sx={{ ml: 2, mr: 1 }}>
              <Monitor /> *
            </Box>
          ),
        }}
        loading={rasterQ.isFetching}
        value={rasterOptions ?? []}
        getOptionLabel={(o) => (typeof o !== "string" ? o.name : "")}
        options={
          rasterQ.data?.types?.filter((t) =>
            ["ndvi", "gndvi", "ndre"].includes(t.slug)
          ) ?? []
        }
        onChange={(_e, items) => {
          const i = items as IRasterType[];
          setRasterOptions(i);
          onChange({
            rasterOptions: i,
          });
        }}
      />
    </Stack>
  );
}
