import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import PropTypes from "prop-types";
import {
  ResponsiveContainer,
  Bar,
  Cell,
  Line,
  ComposedChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
} from "recharts";
import Grid from "@mui/material/Unstable_Grid2";
import { CenteredBarLabel } from "components/Charts/utils";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "lib";
import { useTheme } from "@mui/material/styles";

// eslint-disable-next-line react/prop-types
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload) {
    // const { name } = payload[0]?.payload;
    return (
      <ThemeProvider mode="light">
        <Paper
          elevation={4}
          style={{ padding: "0.5rem", opacity: 0.9, minWidth: "175px" }}
        >
          <Typography
            variant="h6"
            style={{ textAlign: "center", fontWeight: "bold" }}
          >{`${label}`}</Typography>
          <List
            component={Grid}
            container
            sx={{ m: 0, li: { "*": { m: 0 }, py: 0 } }}
          >
            {/* eslint-disable-next-line react/prop-types */}
            {payload.map((p) => (
              <ListItem component={Grid} sm={"auto"} key={p.name}>
                <ListItemText
                  secondary={p.name}
                  primary={p.value?.toLocaleString()}
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      </ThemeProvider>
    );
  }

  return null;
};

function YieldAverage({ data, onMouseEnter, onMouseExit, yAxisUnits }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isLightTheme = theme.palette.mode === "light";
  return (
    <ResponsiveContainer>
      <ComposedChart
        data={data}
        margin={{ top: 25 }}
        // margin={{ top: 5, right: -30, left: -20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="product"
          stroke={isLightTheme ? "#000" : "#fff"}
          padding={{ left: 100, right: 100 }}
        />
        <YAxis
          width={80}
          yAxisId="left"
          stroke={isLightTheme ? "#000" : "#fff"}
        >
          <Label
            dx={-10}
            value={`${t("common.averageYield")}${
              yAxisUnits ? ` (${yAxisUnits})` : null
            }`}
            angle={-90}
            fill={isLightTheme ? "#000" : "#fff"}
            position="outside"
            fontSize={14}
          />
        </YAxis>
        <YAxis
          width={120}
          yAxisId="right"
          orientation="right"
          stroke={isLightTheme ? "#000" : "#fff"}
        >
          <Label
            dx={10}
            value={t("insights.yieldCount")}
            angle={-90}
            fill={isLightTheme ? "#000" : "#fff"}
            position="outside"
            fontSize={14}
          />
        </YAxis>
        <Tooltip
          position={{ y: 0 }}
          content={CustomTooltip}
          labelStyle={{ color: "#333" }}
        />
        <Bar
          label={CenteredBarLabel}
          yAxisId="left"
          name={t("common.averageYield")}
          dataKey="average"
          barSize={100}
          // fill="#79C80E"
        >
          {data.map((d) => (
            <Cell
              key={JSON.stringify(d)}
              fill={d.color}
              onMouseEnter={() => {
                onMouseEnter(d);
              }}
              onMouseLeave={() => {
                onMouseExit(d);
              }}
            />
          ))}
        </Bar>
        <Line
          name={t("insights.yieldCount")}
          yAxisId="right"
          type="monotone"
          dataKey="count"
          stroke="#D5E100"
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

YieldAverage.defaultProps = {
  data: null,
  yAxisUnits: "",
};

YieldAverage.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  onMouseEnter: PropTypes.func.isRequired,
  onMouseExit: PropTypes.func.isRequired,
  yAxisUnits: PropTypes.string,
};

export { YieldAverage };
