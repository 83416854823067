import { useCallback, useMemo } from "react";
import useFetchWithToken from "components/useFetchWithToken";
import { useQuery } from "@tanstack/react-query";
import { ISelectOption, ISubfield } from "types";

type DataType = ISubfield & ISelectOption;

export function useSubfields(org: string, seasonId: string, type?: string) {
  const { fetchData: fetchSubfields } = useFetchWithToken<DataType[]>();
  const queryKey = useMemo(
    () => ["organization", org, "season", seasonId, "subfields", "type", type],
    [org, seasonId, type]
  );

  const fetchSubfieldsFn = useCallback(async () => {
    const res = await fetchSubfields(
      `/${org}/subfields${type ? `/${type}` : ""}/season/${seasonId}`,
      {
        transformResponse: (res) => {
          res.forEach((r) => {
            r.value = r.id;
            // NOTE: added this to remove dependency on API providing it.
            // would be unnecessary if moving to non-grouped tables
            r.subfieldGroupKey = `${r.farm || ""}|${r.field || ""}|${
              r.subfieldGroup || ""
            }`;
          });
          return res;
        },
      }
    );
    return res?.data ?? null;
  }, [fetchSubfields, org, type, seasonId]);

  const query = useQuery({
    queryKey,
    queryFn: fetchSubfieldsFn,
    enabled: !!seasonId,
  });
  return {
    ...query,
    queryKey,
    getSubfieldArea: (ids: string[]) => {
      if (query.data) {
        const filtered = query.data.filter((d) => ids.includes(d.id));
        const total = filtered.reduce((prev, curr) => {
          return (prev += curr.area);
        }, 0);
        return total;
      }
    },
  };
}
