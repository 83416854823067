import axios from "axios";
import { useCallback } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import operationKeys from "./operationKeys";

export function useOperationEventMutations<T extends { id?: string }>({
  eventId,
  org,
}: {
  eventId: string;
  org: string;
}) {
  const queryClient = useQueryClient();

  const invalidate = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: operationKeys.events(org),
    });
    queryClient.invalidateQueries({
      queryKey: operationKeys.eventPhotos(org, eventId),
    });
  }, [eventId, org, queryClient]);

  const saveOperationEvent = useCallback(
    async (d: T) => {
      const method = d.id ? "put" : "post";
      const res = await axios[method](`/${org}/operationevents`, d);
      return res.data;
    },
    [org]
  );

  const deleteOperationEvent = useCallback(
    async (id: string) => {
      const res = await axios.delete(`/${org}/operationevents/${id}`);
      return res.data;
    },
    [org]
  );

  const saveMutation = useMutation({
    mutationFn: saveOperationEvent,
    onSuccess: () => {
      invalidate();
    },
  });

  const deleteMutation = useMutation({
    mutationFn: deleteOperationEvent,
    onSuccess: () => {
      invalidate();
    },
  });

  return {
    saveMutation,
    deleteMutation,
  };
}
