import SvgIcon from "@mui/material/SvgIcon";
import { IconProps } from "./IconProps";

export default function InventoryIcon({ fill, size, sx }: IconProps) {
  return (
    <SvgIcon fontSize={size} sx={sx}>
      <svg
        width="67.98"
        height="67.757"
        version="1.1"
        viewBox="0 0 67.98 67.757"
        xmlSpace="preserve"
        xmlns="http://www.w3.org/2000/svg"
      >
        <linearGradient
          id="SVGID_1_"
          x1="103.63"
          x2="9.6387"
          y1="90.126"
          y2="87.44"
          gradientTransform="matrix(.9985 -.0539 .0539 .9985 -44.335 -51.28)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D5E100" offset=".1213" />
          <stop stopColor="#79C80E" offset=".9881" />
        </linearGradient>
        <path
          fill={fill ?? "url(#SVGID_1_)"}
          d="m32.03 16.047c30.03 8.35 33.75 23.77 35.31 41.32-6.46-18.45-21.31-29.27-43.59-32.38 26.72 8.41 39.34 26.13 44.23 42.77-0.72 0.06-3.1-8.51-16.27-13.26-8.05-2.91-30.87-2.9-43.68-17.34-12.58-14.19-6.58-37.11-6.73-37.15 0.01-0.27 1.31 7.85 30.73 16.04z"
        />
      </svg>
    </SvgIcon>
  );
}
