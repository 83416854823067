import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useConservationState } from "providers/ConservationProvider";
import MapThemeItem from "components/MapThemeItem";
import Grid from "@mui/material/Unstable_Grid2";

export default function ConservationMapThemesView({
  onThemeChange,
}: {
  onThemeChange: (_type: string) => void;
}) {
  const { t } = useTranslation();
  const [themeType, setThemeType] = useState("conservationType");
  const { themeRollup } = useConservationState();
  const typeTheme = themeRollup?.conservationType;
  const statusTheme = themeRollup?.conservationStatus;
  return (
    <Grid container sx={{ height: "100%" }}>
      {typeTheme && typeTheme.length ? (
        <Grid xs={6}>
          <MapThemeItem
            id="conservationType"
            title={t("common.type")}
            isActive={themeType === "conservationType"}
            onClick={() => {
              setThemeType("conservationType");
              onThemeChange("conservationType");
            }}
            data={typeTheme}
          />
        </Grid>
      ) : null}
      {statusTheme && statusTheme.length ? (
        <Grid xs={6}>
          <MapThemeItem
            id="conservationStatus"
            title={t("common.status")}
            isActive={themeType === "conservationStatus"}
            onClick={() => {
              setThemeType("conservationStatus");
              onThemeChange("conservationStatus");
            }}
            data={statusTheme}
          />
        </Grid>
      ) : null}
      {!statusTheme?.length && !typeTheme?.length ? (
        <>
          <Grid xs={6}>
            <MapThemeItem
              id="skeleton"
              title={t("common.type")}
              showTooltip={false}
              data={[{ name: "--", percent: 1 }]}
            />
          </Grid>
          <Grid xs={6}>
            <MapThemeItem
              id="skeleton"
              title={t("common.status")}
              showTooltip={false}
              data={[{ name: "--", percent: 1 }]}
            />
          </Grid>
        </>
      ) : null}
    </Grid>
  );
}
