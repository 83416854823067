import { useCallback, useMemo } from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";

export function useOrgLookup<T = { name: string }[]>(
  org?: string,
  type?: string
) {
  const queryKey = useMemo(() => {
    return ["organization", org, "lookup", type];
  }, [org, type]);

  const getData = useCallback(async () => {
    if (org) {
      const res = await axios.get<T>(`/${org}/lookups/${type}`);
      return res.data;
    }
  }, [org, type]);

  return useQuery({
    queryKey,
    queryFn: getData,
    staleTime: Infinity,
    enabled: !!org && !!type,
  });
}
