import { Redirect, useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { useOrgState } from "providers/OrgProvider";
import { IUrlParams } from "types";
import SummaryCardView from "lib/Card/SummaryCardView";
import { Skeleton } from "@mui/material";
import { useAssetsSeasonSummary } from "api/assets/useAssetSummaries";
// import { AnimalIcon, EquipmentIcon, FixedIcon, PlantIcon } from "lib/Icons";

export default function AssetsSummaryView() {
  const { type } = useParams<IUrlParams>();
  const activeType = type?.toLowerCase();
  const { org, season, rootUrl } = useOrgState();
  const assetsBySeasonQuery = useAssetsSeasonSummary(org, season?.id);

  // redirect to first asset summary type if not specified
  if (
    !activeType &&
    assetsBySeasonQuery.data &&
    assetsBySeasonQuery.data.length
  ) {
    return (
      <Redirect
        to={`${rootUrl}/inventory/assets/${assetsBySeasonQuery.data[0]?.assetEn?.toLowerCase()}`}
      />
    );
  }
  return (
    <>
      <Grid container spacing={2}>
        {assetsBySeasonQuery.data && !assetsBySeasonQuery.data.length ? (
          <Typography>No data</Typography>
        ) : null}
        {assetsBySeasonQuery.isLoading ? (
          <>
            {[1, 2, 3, 4].map((i) => (
              <Grid key={i} xs={6} lg={3}>
                <SummaryCardView
                  variant="outlined"
                  value={<Skeleton />}
                  label={<Skeleton />}
                />
              </Grid>
            ))}
          </>
        ) : assetsBySeasonQuery?.data ? (
          assetsBySeasonQuery?.data?.map((d) => {
            const assetType = d?.assetEn?.toLowerCase();
            return (
              <Grid key={assetType} xs={12} sm={6} xl={3}>
                <SummaryCardView
                  variant="outlined"
                  label={
                    <Stack
                      spacing={1}
                      direction={"row"}
                      sx={{ alignItems: "center" }}
                    >
                      {/* {assetType === "plant" ? (
                        <PlantIcon />
                      ) : assetType === "animal" ? (
                        <AnimalIcon />
                      ) : assetType === "equipment" ? (
                        <EquipmentIcon />
                      ) : assetType === "fixed" ? (
                        <FixedIcon />
                      ) : null} */}
                      <Typography component="span">{d.asset}</Typography>
                    </Stack>
                  }
                  href={`${rootUrl}/inventory/assets/${assetType}`}
                  value={`${d?.count?.toLocaleString()}`}
                  sx={{
                    "a:hover": {
                      color: "inherit",
                      textDecoration: "none",
                    },
                    backgroundColor:
                      activeType === assetType ? `${d.color}40` : undefined,
                    height: "100%",
                    borderColor: d.color,
                  }}
                />
              </Grid>
            );
          })
        ) : null}
      </Grid>
    </>
  );
}
