import React, { useCallback, useState } from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import ImageCard from "./ImageCard";
import ImageUpload from "./ImageUpload";
import { UseMutationResult, UseQueryResult } from "@tanstack/react-query";
import { ParsedImage } from "api/types";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { ArrowLeft, ArrowRight } from "react-feather";
import { Divider } from "@mui/material";

function ImageStack({
  photosQ,
  readonly,
  savePhotoQ,
  deletePhotoQ,
  photoCount = 3,
}: {
  photosQ: UseQueryResult<ParsedImage[]>;
  readonly?: boolean;
  savePhotoQ: UseMutationResult;
  deletePhotoQ: UseMutationResult;
  photoCount?: number;
}) {
  const [index, setIndex] = useState<number>();

  const handleOpen = (i: number) => {
    setIndex(i);
  };

  const goToImage = useCallback(
    (dir: "forward" | "back") => {
      const idx = index ?? 0;
      if (dir === "forward") {
        if (photosQ.data?.[idx + 1]) {
          setIndex(idx + 1);
        } else {
          setIndex(0);
        }
      } else {
        if (photosQ.data?.[idx - 1]) {
          setIndex(idx - 1);
        } else {
          setIndex(photosQ.data.length - 1);
        }
      }
    },
    [index, photosQ.data]
  );

  return (
    <>
      {index !== undefined ? (
        <Dialog
          PaperProps={{
            sx: {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: 8,
              pt: 3,
            },
          }}
          onClose={() => {
            setIndex(undefined);
          }}
          fullScreen
          open={index !== undefined}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
              }}
            >
              <Typography sx={{ mx: 3 }} component="div">{`${index + 1} of ${
                photosQ.data?.length
              }`}</Typography>
              <IconButton
                sx={{ mr: 3, ml: "auto" }}
                onClick={() => {
                  setIndex(undefined);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider sx={{ my: 2, width: "100%" }} />
          </Box>
          <Box
            sx={{
              display: "flex",
              // alignItems: "center",
              flex: 1,
              overflow: "auto",
            }}
          >
            <img
              style={{ maxWidth: "100%", height: "auto" }}
              src={photosQ.data?.[index].src}
            />
          </Box>
          <IconButton
            sx={{
              display: photosQ.data.length < 2 ? "none" : undefined,
              position: "absolute",
              left: 15,
              top: "50%",
            }}
            onClick={() => {
              goToImage("back");
            }}
          >
            <ArrowLeft />
          </IconButton>
          <IconButton
            sx={{
              display: photosQ.data.length < 2 ? "none" : undefined,
              position: "absolute",
              right: 15,
              top: "50%",
            }}
            onClick={() => {
              goToImage("forward");
            }}
          >
            <ArrowRight />
          </IconButton>
        </Dialog>
      ) : null}
      <Stack direction="row" gap={3} overflow={"auto"} flexWrap={"wrap"}>
        {photosQ.isInitialLoading ? (
          Array.from(
            Array(readonly !== true ? photoCount + 1 : photoCount).keys()
          ).map((i) => (
            <Skeleton
              key={i}
              variant="rounded"
              sx={{ height: 175, width: 175 }}
            />
          ))
        ) : photosQ.data?.length ? (
          photosQ.data?.map((item, i) => (
            <ImageCard
              readonly={readonly}
              key={item.id || i}
              onClick={() => {
                handleOpen(i);
              }}
              onDelete={() => {
                deletePhotoQ.mutate(item.id);
              }}
              image={item}
            />
          ))
        ) : (
          <Typography>No photos</Typography>
        )}

        {readonly !== true && !photosQ.isLoading ? (
          <Box
            sx={{
              borderRadius: 1,
              textAlign: "center",
              bgcolor: (theme) =>
                theme.palette.mode === "dark" ? "#000" : "#fff",
              height: 175,
              width: 175,
              minWidth: 175,
            }}
          >
            <ImageUpload
              disabled={photosQ.data?.length > 3}
              onChange={(imgs) => {
                imgs.forEach((i) => {
                  savePhotoQ.mutate(i);
                });
              }}
            />
          </Box>
        ) : null}
      </Stack>
    </>
  );
}

export default ImageStack;
