import { useCallback, useMemo } from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { LngLatBounds } from "mapbox-gl";
import { MultiPolygon, Polygon } from "geojson";
import { ISensor } from "types";

export function useSensors(
  org: string,
  bbox?: LngLatBounds,
  enabled?: boolean
) {
  const queryKey = useMemo(
    () => ["organization", org, "sensors", "bbox", bbox],
    [bbox, org]
  );

  const getSensors = useCallback(async () => {
    const res = await axios.get<ISensor[]>(
      `/${org}/sensors${
        bbox ? `?bbox=${bbox}&calculatedTypes=ndvi,gndvi,ndre` : ""
      }`
    );

    return res.data;
  }, [org, bbox]);

  return useQuery({
    queryKey,
    queryFn: getSensors,
    enabled: !!org && enabled !== false,
    staleTime: 10 * (60 * 1000), // 10 mins
  });
}

export function useSensorsByGeometry(
  org: string,
  geometry: Polygon | MultiPolygon,
  rasterBands?: ("dem" | "dsm")[],
  calculatedTypes?: string[]
) {
  const queryKey = useMemo(
    () => [
      "organization",
      org,
      "sensors",
      "geometry",
      geometry,
      rasterBands,
      calculatedTypes,
    ],
    [calculatedTypes, geometry, org, rasterBands]
  );

  const getSensorsByGeo = useCallback(async () => {
    const res = await axios.post<ISensor[]>(`/${org}/sensors/geometry`, {
      calculatedTypes,
      rasterBands,
      geometry: JSON.stringify(geometry),
    });
    return res.data;
  }, [org, calculatedTypes, rasterBands, geometry]);

  return useQuery({
    queryKey,
    queryFn: getSensorsByGeo,
    enabled: Boolean(org) && Boolean(geometry),
    staleTime: 10 * (60 * 1000), // 10 mins
  });
}
