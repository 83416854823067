import axios from "axios";
import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { OperationType } from "types/OperationType";
import operationKeys from "./operationKeys";
import { OperationEvent } from "./types/OperationEvent";

export function useOperationEvents({
  operationId,
  org,
  seasonId,
  type,
}: {
  operationId: string;
  org: string;
  seasonId: string;
  type: OperationType;
}) {
  const getEvents = useCallback(async () => {
    const res = await axios.get<OperationEvent[]>(
      `/${org}/operations/${operationId}/events`
    );
    return res.data;
  }, [operationId, org]);

  return useQuery({
    queryFn: getEvents,
    queryKey: operationKeys.eventsByOperation(org, operationId),
    enabled: !!org && !!type && !!seasonId && !!operationId,
  });
}
