import SvgIcon from "@mui/material/SvgIcon";
import { IconProps } from "./IconProps";

export default function InsightsIcon({ fill, size, sx }: IconProps) {
  return (
    <SvgIcon fontSize={size} sx={sx}>
      <svg
        width="82.568"
        height="84.234"
        version="1.1"
        viewBox="0 0 82.568 84.234"
        xmlSpace="preserve"
        xmlns="http://www.w3.org/2000/svg"
      >
        <linearGradient
          id="insights-gradient"
          x1="30.51"
          x2="113.08"
          y1="72.883"
          y2="72.883"
          gradientTransform="translate(-30.506 -30.77)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0ff" offset="0" />
          <stop stopColor="#0071C1" offset="1" />
        </linearGradient>
        <path
          d="m41.244 52.3c5.17 0 9.54-3.44 10.66-8.48 0.26-1.19 0.36-2.43 0.46-3.65 0.74-8.62 6.7-15.59 15.12-17.53 1.62-0.37 3.31-0.41 4.97-0.61 4.51-0.55 7.74-2.89 9.33-7.13 1.6-4.26 0.73-8.21-2.42-11.47-3.16-3.26-7.08-4.22-11.39-2.77-5.07 1.7-7.36 6.41-7.51 10.83-0.36 10.28-8.73 18.51-18.72 18.77-10.03 0.26-18.59-7.48-19.66-17.79-0.13-1.22-0.16-2.46-0.39-3.67-1.01-5.3-6.22-9.24-11.49-8.76-5.68 0.52-10.07 5.08-10.2 10.6-0.15 6.39 4.38 11.06 11.05 11.42 7.38 0.4 12.96 3.79 16.69 10.16 1.73 2.96 2.38 6.2 2.56 9.61 0.32 6.05 5.02 10.47 10.94 10.47zm-41.23-9.22c0.06 5.41 3.93 9.84 9.3 10.63 1.11 0.16 2.22 0.29 3.33 0.37 4.17 0.32 7.76 2.02 10.86 4.75 4.44 3.91 6.54 8.89 6.8 14.78 0.26 5.9 4.75 10.31 10.52 10.61 5.46 0.29 10.33-3.77 11.28-9.43 0.17-0.99 0.24-2 0.3-3 0.23-3.69 1.54-6.97 3.75-9.88 3.64-4.79 8.48-7.42 14.52-7.97 2.07-0.19 4.27-0.56 6.11-1.47 4.69-2.33 6.77-7.82 5.23-12.84-1.56-5.08-6.44-8.17-11.98-7.58-4.9 0.52-8.95 4.75-9.47 9.84-0.19 1.88-0.26 3.81-0.73 5.63-2.3 8.93-10.63 14.97-19.32 14.63-9.4-0.37-17.28-7.99-18.34-17.41-0.13-1.11-0.18-2.23-0.34-3.33-0.9-6.08-5.94-9.94-12.2-9.36-5.44 0.48-9.68 5.36-9.62 11.03z"
          fill={fill ?? "url(#insights-gradient)"}
        />
      </svg>
    </SvgIcon>
  );
}
