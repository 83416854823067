import axios from "axios";
import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { FeatureCollection, MultiPolygon, Polygon } from "geojson";
import { mergeProps } from "lib";
import { useFields } from "./useFields";
import fieldKeys from "./fieldKeys";
import { IField } from "types";
import { useInventoryMapThemes } from "api/useInventoryMapThemes";

export function useFieldsGeodata({
  org,
  seasonId,
}: {
  org: string;
  seasonId: string;
}) {
  const mapThemesQuery = useInventoryMapThemes(org, "fields", seasonId);
  const fieldsQ = useFields({
    org,
    seasonId,
  });
  const enabled =
    !!org && !!seasonId && !!mapThemesQuery.data && !!fieldsQ.data;

  const getGeodata = useCallback(async () => {
    const res = await axios.get<
      FeatureCollection<Polygon | MultiPolygon, IField>
    >(`/${org}/fields/geodata/season/${seasonId}`);
    if (!res.data.features.length) {
      return null;
    }
    return mergeProps(res.data, mapThemesQuery.data.raw, fieldsQ.data);
  }, [org, seasonId, mapThemesQuery.data, fieldsQ.data]);

  return useQuery({
    queryFn: getGeodata,
    queryKey: fieldKeys
      .allBySeason(org, seasonId)
      .concat([
        "geodata",
        `${fieldsQ.isFetching}`,
        `${mapThemesQuery.isFetching}`,
      ]),
    enabled,
  });
}
