import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { addAreaPercent, parseOperationData } from "./parseData";
import { OperationTilledMapTheme } from "./types/OperationTilledMapTheme";
import operationKeys from "./operationKeys";

export function useOperationTilledMapThemes({
  org,
  seasonId,
  enabled,
}: {
  org: string;
  seasonId: string;
  enabled?: boolean;
}) {
  const fetchMapThemes = useCallback(async () => {
    const res = await axios.get<OperationTilledMapTheme[]>(
      `/${org}/operations/tilled/season/${seasonId}/mapthemes`
    );
    const items = addAreaPercent(res.data);
    return {
      raw: items,
      rollup: {
        tilledType: parseOperationData({
          dataProp: "tilledType",
          colorProp: "tilledTypeColor",
          data: items,
        }),
      },
    };
  }, [org, seasonId]);

  return useQuery({
    queryKey: operationKeys.mapThemes(org, "tilled", seasonId),
    queryFn: fetchMapThemes,
    enabled: !!(enabled !== false) && !!seasonId,
  });
}
