import React, { useEffect, useState } from "react";
import { Route, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { useOrgState } from "providers/OrgProvider";
import { useTableColumns } from "components/useTableColumns";
import ReportView from "../ReportView";
import {
  getDefaultExpandedRows,
  getDataByFields,
  parseFieldsFromData,
} from "../utils";
import ReportFieldsSelect from "../ReportFieldsSelect";
import { useOperations } from "api/operations/useOperations";
import { useOperationsSummary } from "api/operations/useOperationsSummary";
import { AppliedIcon, HarvestedIcon, PlantedIcon, TilledIcon } from "lib/Icons";
import { Grid } from "components/layout/Grid";

export default function OperationsReports() {
  const { t } = useTranslation();
  const { operationReports } = useTableColumns();
  const { org, rootUrl, season, orgData } = useOrgState();
  const { reportType } = useParams();
  // const prevReportType = usePrevious(reportType);
  const operationsSummaryQ = useOperationsSummary(org, season?.id);
  const operationsQ = useOperations({
    org,
    seasonId: season?.id,
    type: reportType,
  });
  const [expanded, setExpanded] = useState();
  const [fieldsByOperationType, setFieldsByOperationType] = useState();
  const [selectedOperations, setSelectedOperations] = useState();

  const currentOperationType = operationsSummaryQ.data?.find(
    (o) => o.operationEn?.toLowerCase() === reportType
  );
  function getColumnsByReportType(type) {
    const rType = type?.toLowerCase();
    if (rType === "planted") {
      return operationReports.planted;
    }
    if (rType === "applied") {
      return operationReports.applied;
    }
    if (rType === "harvested") {
      return operationReports.harvested;
    }
    if (rType === "tilled") {
      return operationReports.tilled;
    }
    return null;
  }

  useEffect(() => {
    if (operationsQ.data) {
      const newFields = parseFieldsFromData(operationsQ.data);
      // UPDATE EXPANDED OBJECT WITH NEW DATA
      // TO FORCE ALL ROWS EXPANDED BY DEFAULT
      // setExpanded(getDefaultExpandedRows(currentOperations));

      setFieldsByOperationType(newFields);
      // setSelectedOperations(currentOperations);
    }
  }, [
    operationsQ.data,
    // currentOperations,
    // prevOperations,
    // fieldsByOperationType,
    // landAgreementItem,
  ]);

  useEffect(() => {
    setExpanded(getDefaultExpandedRows(selectedOperations));
  }, [selectedOperations]);

  function getCurrentIcon(operationType) {
    let Icon;
    switch (operationType) {
      case "planted": {
        Icon = PlantedIcon;
        break;
      }
      case "harvested": {
        Icon = HarvestedIcon;
        break;
      }
      case "applied": {
        Icon = AppliedIcon;
        break;
      }
      case "tilled": {
        Icon = TilledIcon;
        break;
      }
      default:
        break;
    }
    return Icon;
  }

  return (
    <Grid overflow={"auto"} container height="100%" className="cai-reports">
      <Grid
        style={{ display: "flex", flexDirection: "column", height: "100%" }}
        md={6}
        xl={5}
        xs={12}
        className="hide-print"
      >
        <Paper style={{ overflow: "auto", position: "relative" }}>
          {operationsSummaryQ.isLoading ? (
            <LinearProgress
              style={{ top: 0, left: 0, right: 0, position: "absolute" }}
            />
          ) : null}
          <Stack spacing={2} sx={{ p: 2 }}>
            <Typography component="h3" variant="h6">
              {t("insights.selectReportType")}
            </Typography>
            <Box
              style={{
                justifyContent: "center",
                display: "flex",
                minHeight: "75px",
              }}
            >
              <Stack spacing={2} direction="row">
                {operationsSummaryQ.data?.map((i) => {
                  const operation = i.operationEn?.toLowerCase();
                  const Icon = getCurrentIcon(operation);

                  return (
                    <Stack
                      spacing={1}
                      key={operation}
                      sx={{ alignItems: "center" }}
                    >
                      <Box>
                        <IconButton
                          size="large"
                          sx={{
                            border: `${
                              reportType === operation
                                ? "solid 1px"
                                : "solid 1px transparent"
                            }`,
                            svg: {
                              fill: "inherit",
                            },
                            backgroundColor: i.color,
                            "&:hover": {
                              backgroundColor: `${i.color}80`,
                            },
                          }}
                          href={`${rootUrl}/insights/reports/operations/${operation}`}
                        >
                          {Icon ? <Icon /> : null}
                        </IconButton>
                      </Box>
                      <Typography
                        sx={{ textTransform: "uppercase" }}
                        variant="body2"
                      >
                        {i.operation}
                      </Typography>
                    </Stack>
                  );
                })}
              </Stack>
            </Box>
          </Stack>
        </Paper>
        <Paper sx={{ mt: 3 }} style={{ flex: 1, minHeight: "250px" }}>
          <ReportFieldsSelect
            isLoading={operationsQ.isLoading}
            fields={!operationsQ.isLoading ? fieldsByOperationType : null}
            onChange={(data) => {
              const selectedFields = fieldsByOperationType?.filter((co) => {
                return data.find((d) => d.fieldId === co.fieldId);
              });
              const ops = getDataByFields(selectedFields, operationsQ.data);
              setSelectedOperations(ops);
            }}
          />
        </Paper>
      </Grid>
      <Grid
        height="100%"
        display="flex"
        flexDirection={"column"}
        md={6}
        xl={7}
        xs={12}
        className="print-full-width operation-report"
      >
        <Route
          path="/:org/:season/insights/reports/operations/:reportType?"
          exact
        >
          <ReportView
            org={orgData?.name}
            season={season}
            title={
              currentOperationType
                ? `${currentOperationType.operation} ${t("insights.report")}`
                : ""
            }
            titleIcon={getCurrentIcon(
              currentOperationType?.operation?.toLowerCase()
            )}
            color={currentOperationType?.color}
            data={selectedOperations}
            groupBy={["fieldFarmTypeKey"]}
            columns={getColumnsByReportType(reportType)}
            expanded={expanded}
          />
        </Route>
      </Grid>
    </Grid>
  );
}
