import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useSettingsState } from "views/SettingsView/useSettingsStore";
import { usePrevious } from "components";
import LinearProgress from "@mui/material/LinearProgress";
import { MapView } from "lib/MapboxMap";
import { useAppState } from "providers/AppProvider";
import { useOrgState } from "providers";
import ErrorBoundary from "components/ErrorBoundary";
const CAI_DEFAULT_BOUNDS = [-181.054688, -4.740675, -13.798828, 64.811557];
const mapboxToken =
  // eslint-disable-next-line no-undef
  typeof process.env.REACT_APP_MAPBOX_API_TOKEN !== "undefined"
    ? // eslint-disable-next-line no-undef
      process.env.REACT_APP_MAPBOX_API_TOKEN
    : null;

export default function BaseMapView({
  mapRef = null,
  defaultBounds = null,
  isLoading = false,
  ...rest
}) {
  const mapObj = useRef();
  const { language } = useSettingsState();
  const { sidebarOpen } = useAppState();
  const { season } = useOrgState();
  const prevIsOpen = usePrevious(sidebarOpen);
  const prevDefaultBounds = usePrevious(defaultBounds);
  function handleMapRef(m) {
    mapObj.current = m;
    mapRef(m);
  }
  useEffect(() => {
    if (sidebarOpen !== prevIsOpen) {
      if (mapObj.current) {
        mapObj.current.resize();
      }
    }
  }, [sidebarOpen, prevIsOpen]);

  useEffect(() => {
    if (defaultBounds !== prevDefaultBounds) {
      const defaultAsString = JSON.stringify(defaultBounds);
      const prevAsString = JSON.stringify(prevDefaultBounds);
      if (defaultAsString !== prevAsString) {
        if (mapObj.current && defaultBounds) {
          mapObj.current.fitBounds(defaultBounds, rest?.fitBoundsOptions);
        }
      }
    }
  }, [defaultBounds, prevDefaultBounds, rest.fitBoundsOptions]);

  return (
    <ErrorBoundary>
      <div style={{ height: "100%", position: "relative" }}>
        <MapView
          accessToken={mapboxToken}
          defaultBounds={
            defaultBounds ?? season?.extent?.bbox ?? CAI_DEFAULT_BOUNDS
          }
          mapRef={handleMapRef}
          language={language?.substring(0, 2)}
          {...rest}
          showBasemapSelect={false}
        />
        <div
          style={{
            top: 0,
            left: 0,
            right: 0,
            position: "absolute",
            zIndex: 1,
            opacity: isLoading ? 1 : 0,
            transition: "opacity 1s linear",
          }}
        >
          <LinearProgress />
        </div>
      </div>
    </ErrorBoundary>
  );
}
BaseMapView.defaultProps = {
  mapRef: () => {},
  defaultBounds: null,
  isLoading: false,
};
BaseMapView.propTypes = {
  mapRef: PropTypes.func,
  defaultBounds: PropTypes.arrayOf(PropTypes.number),
  isLoading: PropTypes.bool,
};
