import Grid from "@mui/material/Unstable_Grid2";
import Alert from "@mui/material/Alert";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import SummaryCardView from "lib/Card/SummaryCardView";
import { useDamageSummary } from "api/damage/useDamageSummary";
import { useOrgState } from "providers";

export default function DamageSummaryView() {
  const { t } = useTranslation();
  const { org, season } = useOrgState();
  const summaryQ = useDamageSummary({ org, seasonId: season?.id });

  const count =
    summaryQ?.data?.aoiCount || summaryQ?.data?.aoiCount === 0
      ? summaryQ?.data?.aoiCount
      : "--";
  const area =
    summaryQ?.data?.totalArea || summaryQ?.data?.totalArea === 0
      ? summaryQ?.data?.totalArea
      : "--";

  return (
    <>
      <Grid sx={{ m: 2 }} container spacing={2}>
        <Grid xs={6}>
          <SummaryCardView
            value={summaryQ.isLoading ? <Skeleton /> : count}
            label={
              summaryQ.isLoading ? (
                <Skeleton />
              ) : (
                t("inventory.damage.fieldsAssessed")
              )
            }
          />
        </Grid>
        <Grid xs={6}>
          <SummaryCardView
            value={summaryQ.isLoading ? <Skeleton /> : area?.toLocaleString()}
            label={
              summaryQ.isLoading ? (
                <Skeleton />
              ) : (
                `${summaryQ?.data?.totalAreaUom || t("common.area")}`
              )
            }
          />
        </Grid>
      </Grid>
      {summaryQ.isError ? (
        <Alert style={{ padding: 0 }} sx={{ mx: 3 }} severity="error">
          <Typography style={{ textAlign: "center" }} component="h6">
            {summaryQ.error}
          </Typography>
        </Alert>
      ) : null}
    </>
  );
}
