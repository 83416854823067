import Stack from "@mui/material/Stack";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useOrgState } from "providers/OrgProvider";
import { FormWrapper } from "components";
import { useInventoryState } from "providers/InventoryProvider";
import { useTranslation } from "react-i18next";
import { OperationTilledPostData } from "api/operations/types/OperationPostData";
import { IUrlParams } from "types";
import FieldsAndSubfields from "../../../FieldsAndSubfields";
import DateRangeInputs from "components/Forms/DateRangeInputs";
import Autocomplete from "lib/Select/Autocomplete";
import { TextField, Typography } from "@mui/material";
import { useTilledTypes } from "api/lookups/useTilledTypes";
import { useOperationsGeodataById } from "api/operations/useOperationsGeodataById";
import { useOperationMutations } from "api/operations/useOperationMutations";
import { OperationTilled } from "api/operations/types/OperationTilled";
import { parseIds } from "../parseIds";

const defaultValues = {
  area: "",
  id: "",
  fieldIds: "",
  subfieldIds: "",
  tilledTypeId: "",
  beginDateUtc: "",
  endDateUtc: "",
  depth: "",
  geometry: "",
};

export default function TillOperationForm() {
  const { t } = useTranslation();
  const { itemId } = useParams<IUrlParams>();
  const { org, season, configQuery } = useOrgState();
  const { uoms } = configQuery.data || {};
  const history = useHistory();
  const geodataQ = useOperationsGeodataById<OperationTilled>({
    id: itemId,
    org,
    seasonId: season?.id,
    type: "tilled",
  });
  const currentEditFtr = geodataQ.data?.features[0];
  const { invalidate, saveMutation, deleteMutation } = useOperationMutations<
    OperationTilledPostData
  >({
    org,
    type: "tilled",
  });
  const { drawData } = useInventoryState();
  const tilledTypesQ = useTilledTypes(org);
  const methods = useForm({
    defaultValues: { ...defaultValues },
  });
  const {
    handleSubmit,
    register,
    reset,
    formState,
    watch,
    setValue,
    control,
    errors,
  } = methods;
  const {
    area,
    fieldIds,
    subfieldIds,
    id,
    beginDateUtc,
    endDateUtc,
    depth,
  } = watch();

  async function handleSave(d: OperationTilledPostData) {
    if (!formState.isDirty) {
      history.push(`..${itemId ? `/${itemId}` : ""}`);
      return false;
    }
    const body = { ...d };
    body.geometry = d.geometry ? JSON.parse(d.geometry) : "";
    // body.depth = Number(body.depth);
    // need at least 1 fieldId or subfieldId
    // but cannot make either input required
    // therefore custom logic to handle validation here
    if (!itemId && !body.fieldIds?.length && !body.subfieldIds?.length) {
      return false;
    }
    // server doesn't want these props if not set
    if (!body.id) {
      delete body.id;
    }
    if (!body.fieldIds?.length) {
      delete body.fieldIds;
    } else {
      body.fieldIds = JSON.parse(body.fieldIds);
    }
    if (!body.subfieldIds?.length) {
      delete body.subfieldIds;
    } else {
      body.subfieldIds = JSON.parse(body.subfieldIds);
    }
    return saveMutation.mutate(body, {
      onSuccess: () => {
        reset(d);
        history.push(`${d.id ? `../${d.id}` : "."}`);
      },
    });
  }

  return (
    <FormWrapper
      methods={methods}
      defaultValues={defaultValues}
      data={{ ...currentEditFtr?.properties }}
      geometryData={drawData?.features[0]?.geometry}
      existingGeom={currentEditFtr?.geometry}
      cancelHref={`${itemId ? `../${itemId}` : "."}`}
      saveState={{
        ...saveMutation,
        errorMessage: saveMutation.error,
      }}
      deleteState={{ ...deleteMutation, errorMessage: deleteMutation.error }}
      // NOTE: ignoring geometry, using fieldIds
      ignoreGeom
      onDelete={async () => {
        deleteMutation.mutate(id, {
          onSuccess: () => {
            reset(defaultValues);
            history.push(`../..`);
            // invalidate after navigation to prevent GET of deleted item
            invalidate();
          },
        });
      }}
      onSubmit={handleSubmit(handleSave)}
    >
      <Stack spacing={2}>
        {!itemId ? (
          <>
            <FieldsAndSubfields
              control={control}
              ids={{
                fieldIds: parseIds(fieldIds),
                subfieldIds: parseIds(subfieldIds),
              }}
            />
          </>
        ) : null}
        <DateRangeInputs
          required
          control={control}
          setValue={setValue}
          startProp="beginDateUtc"
          endProp="endDateUtc"
          startDate={beginDateUtc ? new Date(beginDateUtc) : null}
          endDate={endDateUtc ? new Date(endDateUtc) : null}
          minDate={season?.beginOnUtc ? new Date(season.beginOnUtc) : null}
          maxDate={season?.endOnUtc ? new Date(season.endOnUtc) : null}
        />
        <Controller
          name="tilledTypeId"
          control={control}
          rules={{ required: true }}
          htmlFor="tilled-type-select"
          render={(props: {
            onChange: (_id: string) => void;
            value: string;
          }) => (
            <Autocomplete
              label={`${t("common.type")} *`}
              id="tilled-type-select"
              error={!!errors?.tilledTypeId}
              options={tilledTypesQ?.data || []}
              getOptionLabel={(o: { name: string }) => o.name}
              value={
                tilledTypesQ?.data?.find((ct) => ct.id === props.value) ?? null
              }
              onChange={(_e, item) => {
                const tt = item as { name: string; id: string };
                props.onChange(tt?.id ?? "");
              }}
              disableClearable={false}
            />
          )}
        />
        <TextField
          fullWidth
          label={`${t("common.depth")}`}
          name="depth"
          value={depth}
          required
          id="depth-input"
          type="number"
          InputProps={{
            endAdornment: (
              <Typography sx={{ ml: 2 }}>
                {uoms?.smallDistance?.label}
              </Typography>
            ),
          }}
          inputProps={{
            name: "depth",
            ref: register,
            step: "any",
            min: 0,
            max: 100,
          }}
        />

        {itemId ? (
          <TextField
            fullWidth
            id="area-input"
            type="number"
            label={`${t("common.area")}`}
            name="area"
            required
            value={area ?? ""}
            InputProps={{
              endAdornment: (
                <Typography sx={{ ml: 1 }}>
                  {currentEditFtr?.properties?.areaUom ?? ""}
                </Typography>
              ),
            }}
            inputProps={{
              name: "area",
              ref: register,
              min: 0,
              step: "any",
            }}
          />
        ) : null}
      </Stack>
    </FormWrapper>
  );
}
