import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IOrgConfig } from "types/IOrgConfig";

const orgKeys = {
  config: (org: string) => ["organization", org, "config"],
};

export function useOrgConfig(org: string) {
  const queryFn = useCallback(async () => {
    const url = `/${org}/config`;
    const res = await axios.get<IOrgConfig>(url);
    return res?.data ?? null;
  }, [org]);

  return useQuery({
    queryKey: orgKeys.config(org),
    queryFn,
    enabled: Boolean(org),
  });
}
