import React from "react";
import DT from "../lib/DataTable";
import { useTranslation } from "react-i18next";
import { IDataTableProps } from "types";

// datatable wrapper to handle translations in single location
// rather than having to specify translations for each instance

// TODO: sticky header
// th {
//   position: -webkit-sticky;
//   position: sticky;
//   top: 0;
//   z-index: 1;
//   background-color: #333;
// }
export default function DataTable(props: IDataTableProps) {
  const { t } = useTranslation();
  return (
    <DT
      filterPlaceholder={`${t("search")}...`}
      rowCountText={t("common.records")}
      noDataText={t("common.noData")}
      paging={props.data?.length > 150}
      paginationProps={{
        pageRangeDisplayed: 1,
      }}
      {...props}
    />
  );
}
