import { useCallback, useMemo } from "react";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export function useSubfieldGroups(org: string) {
  const queryClient = useQueryClient();
  const queryKey = useMemo(() => ["organization", org, "subfieldgroups"], [
    org,
  ]);

  const fetchSubfieldGroups = useCallback(async () => {
    const res = await axios.get<{ id: string; name: string }[]>(
      `${org}/lookups/subfieldgroups`
    );
    return res?.data;
  }, [org]);

  const query = useQuery({
    queryKey,
    queryFn: fetchSubfieldGroups,
    enabled: !!org,
  });

  const saveSubfieldGroup = useCallback(
    async (d) => {
      const res = await axios.post(`${org}/subfields/groups`, d);
      return res?.data;
    },
    [org]
  );

  const mutation = useMutation({
    mutationFn: saveSubfieldGroup,
    onSuccess() {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return { query, mutation };
}
