import { create } from "zustand";
import { IFieldLookup, ProductLookup } from "../types";
import { Feature, MultiPolygon, Polygon } from "geojson";
import { ICollectEvent, IRasterType, ISensor } from "types";

interface YieldInsightsStore {
  application?: ProductLookup;
  collectEvent?: ICollectEvent;
  field?: IFieldLookup;
  features?: Feature[];
  intersectGeometry?: Polygon | MultiPolygon;
  operationBufferGeometry?: Polygon | MultiPolygon;
  operationBufferSize?: number;
  fieldBufferGeometry?: Polygon | MultiPolygon;
  fieldBufferSize?: number;
  products?: ProductLookup[];
  appliedOperation?: ProductLookup;
  rasterOptions?: IRasterType[];
  sensor?: ISensor;
  seed?: ProductLookup;
  yieldFilter?: { min: number | string; max: number | string };
  actions: {
    setApplication: (l?: ProductLookup) => void;
    // setCollectEvent: (c?: ICollectEvent) => void;
    setSeed: (l?: ProductLookup) => void;
    reset: () => void;
    setFeatures: (_d?: Feature[]) => void;
    setIntersectGeometry: (_d?: Polygon | MultiPolygon) => void;
    setOperationBuffer: (_d?: {
      size: number;
      geometry: Polygon | MultiPolygon;
    }) => void;
    setFieldBuffer: (_d?: {
      size: number;
      geometry: Polygon | MultiPolygon;
    }) => void;
    // setSensor: (l?: ISensor) => void;
    setField: (l: IFieldLookup) => void;
    setProducts: (l: ProductLookup[]) => void;
    setAppliedOperation: (_i: ProductLookup) => void;
    // setRasterOptions: (l: IRasterType[]) => void;
    setYieldFilter: (l: { min: string | number; max: string | number }) => void;
  };
}

export const useYieldInsightsStore = create<YieldInsightsStore>((set) => ({
  application: undefined,
  features: undefined,
  field: undefined,
  seed: undefined,
  products: undefined,
  sensorType: undefined,
  rasterOptions: undefined,
  yieldFilter: undefined,
  actions: {
    setAppliedOperation: (appliedOperation) => {
      return set(() => ({ appliedOperation }));
    },
    // setCollectEvent: (collectEvent) => {
    //   return set(() => ({ collectEvent }));
    // },
    setField: (field) => {
      return set(() => ({ field }));
    },
    setFeatures: (features) => {
      return set(() => ({ features }));
    },
    setIntersectGeometry: (intersectGeometry) => {
      return set(() => ({ intersectGeometry }));
    },
    setFieldBuffer: (data) => {
      return set(() => ({
        fieldBufferGeometry: data?.geometry,
        fieldBufferSize: data?.size,
      }));
    },
    setOperationBuffer: (data) => {
      return set(() => ({
        operationBufferGeometry: data?.geometry,
        operationBufferSize: data?.size,
      }));
    },
    setApplication: (application) => {
      return set(() => ({ application }));
    },
    setSeed: (seed) => {
      return set(() => ({ seed }));
    },
    // setSensor: (sensor) => {
    //   return set(() => ({ sensor }));
    // },
    // setRasterOptions: (rasterOptions) => {
    //   return set(() => ({ rasterOptions }));
    // },
    setProducts: (products) => {
      return set(() => ({ products }));
    },
    setYieldFilter: (yieldFilter) => {
      return set(() => ({ yieldFilter }));
    },
    reset: () => {
      return set(() => ({
        field: undefined,
        collectEvent: undefined,
        features: undefined,
        intersectGeometry: undefined,
        products: undefined,
        application: undefined,
        seed: undefined,
        sensor: undefined,
        rasterOptions: undefined,
        yieldFilter: undefined,
        fieldBufferGeometry: undefined,
        operationBufferSize: undefined,
        operationBufferGeometry: undefined,
        appliedOperation: undefined,
      }));
    },
  },
}));

export const useYieldInsightsActions = () =>
  useYieldInsightsStore((state) => state.actions);

export const useYieldInsightsYieldFilter = () =>
  useYieldInsightsStore((state) => state.yieldFilter);

export const useYieldInsightsState = () =>
  useYieldInsightsStore((state) => ({ ...state }));
