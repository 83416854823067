import React, { useEffect } from "react";
import { Redirect, useParams, useLocation } from "react-router-dom";
import triggerEvent from "../components/gaTriggerEvent";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import { OrgProvider } from "providers/OrgProvider";
import { FieldsProvider } from "providers";
import { useSettingsState } from "views/SettingsView/useSettingsStore";
import { useAppState } from "providers/AppProvider";
import OrganizationView from "views/OrganizationView";
import AppLoading from "./AppLoading";
import AuthView from "./AuthView";

function removeSlashes(str: string) {
  return str.replace(/\/$/, "");
}

export default function OrgWrapper() {
  const { t, ready, i18n } = useTranslation();
  const { org } = useParams<{ org: string }>();
  const { userDataState, organizations } = useAppState();
  const { isLoading, user } = useAuth0();
  const location = useLocation();
  const { theme, language } = useSettingsState();
  const rootUrl = `/${org}`;

  // update page title on location change
  useEffect(() => {
    let title = "CultivateAI AgAssessor";
    if (ready) {
      const path = location.pathname.toLowerCase();
      if (path === rootUrl) {
        title = t("dashboard.title");
      }
      // INVENTORY PATHS
      if (path.includes("/inventory")) {
        title = `${t("inventory.title")}`;
      }
      //    FIELDS
      if (path.includes("/fields")) {
        title += ` - ${t("inventory.fields.title")}`;
      }
      //     OPERATIONS
      if (path.includes("/operations")) {
        const opType = path.split("/operations/")[1]?.split("/")[0];
        title += ` - ${t("inventory.operations.title")}`;
        if (opType) {
          title += ` - ${t(`common.${removeSlashes(opType)}`)}`;
        }
      }
      //     ASSETS
      if (path.includes("inventory/assets")) {
        const assetType = path.split("/assets/")[1]?.split("/")[0];
        title += ` - ${t("inventory.assets.title")}`;
        if (assetType) {
          title += ` - ${t(`common.${removeSlashes(assetType)}`)}`;
        }
      }
      //     PRODUCTS
      if (path.includes("/products")) {
        const prodType = path.split("/products/")[1]?.split("/")[0];
        title += ` - ${t("inventory.products.title")}`;
        if (prodType) {
          title += ` - ${t(`common.${removeSlashes(prodType)}`)}`;
        }
      }
      //     DAMAGE
      if (path.includes("/damage")) {
        title += ` - ${t("inventory.damage.title")}`;
      }
      // IMAGERY PATHS
      if (path === `${rootUrl}/imagery/compare`) {
        title = `${t("imagery.title")} - ${t("imagery.compare.title")}`;
      }
      // INSIGHTS PATHS
      if (path.includes("/insights")) {
        const type = path.split("/insights/")[1]?.split("/")[0];
        title = `${t("insights.title")}`;
        if (type === "yield") {
          title += ` - ${t("insights.yield.title")}`;
        }
        if (type.includes("reports")) {
          // TODO:
          // const reportType = path.split('/reports/')[1]?.split('/')[1];
          title += ` - ${t("insights.reports.title")}`;
          // if (reportType) {
          //   title += ` - ${t(`common.${removeSlashes(reportType)}`)}`;
          // }
        }
      }
      // SETTINGS PATH
      if (path === `${rootUrl}/settings`) {
        title = t("settings.title");
      }

      document.title = title;
      triggerEvent("page_view");
    }
  }, [location.pathname, rootUrl, ready, t]);

  // update i18n language on language setting change
  useEffect(() => {
    if (ready && language && i18n.language !== language) {
      // MUI langs are not separated by `-`, which is standard language-region format
      // i18n / API needs the dash
      i18n.changeLanguage(`${language?.slice(0, 2)}-${language?.slice(2, 4)}`);
      triggerEvent("lang_change");
    }
  }, [language, i18n, ready]);

  // add theme class to DOM body on change
  useEffect(() => {
    const bodyElement = document.body;
    bodyElement.className = theme;
  }, [theme]);

  // URL org does not match user org, redirect
  if (user && organizations && !organizations.find((o) => o.slug === org)) {
    console.log("redirect");
    return <Redirect to="/" />;
  }
  if (isLoading) {
    return (
      <AuthView>
        <AppLoading message={`${t("auth.signingIn")}...`} />
      </AuthView>
    );
  }
  if (!userDataState?.data || userDataState?.isLoading) {
    return (
      <AuthView>
        <AppLoading message={`${t("common.gettingUserData")}...`} />
      </AuthView>
    );
  }

  return (
    <OrgProvider organizations={organizations}>
      <FieldsProvider>
        <OrganizationView />
      </FieldsProvider>
    </OrgProvider>
  );
}
