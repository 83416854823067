import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Popover from "@mui/material/Popover";
import {
  useDeleteCollectEvent,
  useManageCollectEvents,
  useSaveCollectEvent,
} from "api/useManageCollectEvents";
import DataTable from "components/DataTable";
import { ConfirmationDialog } from "lib";
import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Row } from "@tanstack/react-table";
import { Trash2 } from "react-feather";
import { Alert } from "@mui/material";
import { ManageCollectEvent } from "api/types";
import { EditIconButton } from "components/EditIconButton";

export default function ManageImagery() {
  const { t } = useTranslation();
  const { org } = useParams<{ org: string }>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [rasterName, setRasterName] = useState("");
  const [editItem, setEditItem] = useState<Row<ManageCollectEvent>>();
  const nameInputRef = useRef<HTMLInputElement>();
  const saveMutation = useSaveCollectEvent(org);
  const deleteMutation = useDeleteCollectEvent(org);
  const collectEventQ = useManageCollectEvents(org);
  const ActionCell = useCallback(
    ({ row }: { row: Row<ManageCollectEvent> }) => {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <EditIconButton
            onClick={(e) => {
              e.stopPropagation();
              setRasterName(row.original.name);
              setEditItem(row);
            }}
          />
        </Box>
      );
    },
    []
  );

  return (
    <Box
      sx={{
        height: "100%",
        maxHeight: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h2>{t("imagery.manage.manageImagery")}</h2>
      <Paper
        sx={{
          p: 3,
          height: "100%",
          overflow: "auto",
        }}
      >
        <DataTable
          selectedRowIds={editItem ? { [editItem?.id]: true } : {}}
          data={collectEventQ.data}
          columns={[
            {
              Header: "",
              id: "actions",
              Cell: ActionCell,
              isSortable: false,
              styles: {
                width: 125,
              },
            },
            {
              Header: t("common.date"),
              accessor: "beginOnUtc",
              Cell: ({ row }: { row: Row<ManageCollectEvent> }) => {
                return (
                  <>{new Date(row.original.beginOnUtc).toLocaleDateString()}</>
                );
              },
            },
            {
              Header: t("common.sensor"),
              accessor: "sensorType",
            },
            {
              Header: t("common.name"),
              accessor: "name",
            },
          ]}
        />
      </Paper>
      <ConfirmationDialog
        component="form"
        confirmText={t("common.save")}
        cancelButtonProps={{ color: "inherit" }}
        open={!!editItem}
        title={t("common.edit")}
        confirmButtonProps={{ variant: "contained", type: "submit" }}
        onClose={(confirmed) => {
          if (confirmed && !rasterName) {
            nameInputRef.current.reportValidity();
            return;
          }
          if (!confirmed) {
            setEditItem(undefined);
            saveMutation.reset();
            deleteMutation.reset();
          } else {
            saveMutation.mutate(
              {
                id: editItem?.original?.id,
                name: rasterName,
              },
              {
                onSuccess: () => {
                  setEditItem(undefined);
                  setRasterName("");
                },
              }
            );
          }
        }}
        message={
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography>
              {editItem
                ? `${new Date(
                    editItem?.original.beginOnUtc
                  ).toLocaleDateString()}`
                : null}
            </Typography>
            <Typography sx={{ mt: 1 }}>
              {editItem?.original?.sensorType ?? null}
            </Typography>
            <TextField
              sx={{ my: 2 }}
              label={t("common.name")}
              required
              inputProps={{
                ref: nameInputRef,
              }}
              error={!rasterName && !!editItem}
              value={rasterName ?? ""}
              onChange={(e) => {
                setRasterName(e.target.value);
              }}
            />
            {deleteMutation.isError ? (
              <Alert
                severity="error"
                onClose={() => {
                  deleteMutation.reset();
                }}
              >
                {deleteMutation.error ?? t("common.somethingUnexpected")}
              </Alert>
            ) : null}
            {saveMutation.isError ? (
              <Alert
                severity="error"
                onClose={() => {
                  saveMutation.reset();
                }}
              >
                {saveMutation.error ?? t("common.somethingUnexpected")}
              </Alert>
            ) : null}
          </Box>
        }
        actionsChildren={
          <>
            <Popover
              open={!!anchorEl}
              elevation={12}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              slotProps={{
                paper: {
                  sx: { p: 3 },
                },
              }}
            >
              <Box>
                <Typography variant="h6">{t("common.areYouSure")}</Typography>
                <Alert severity="warning">
                  {editItem?.original?.related?.length ? (
                    <>
                      <Typography>
                        {t("imagery.manage.relatedItemsDelete")}
                      </Typography>
                      <ul>
                        {editItem?.original?.related.map((r) => {
                          return (
                            <li key={`${r.name}-${r.beginOnUtc}`}>
                              <Typography>
                                {`${new Date(
                                  r.beginOnUtc
                                ).toLocaleDateString()}${
                                  r.name ? ` (${r.name})` : ""
                                }`}
                              </Typography>
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  ) : null}

                  <Typography fontWeight={"bold"}>
                    *{t("common.cannotUndo")}
                  </Typography>
                </Alert>
              </Box>
              <Box sx={{ mt: 1, display: "flex", gap: 1 }}>
                <Button
                  sx={{ ml: "auto" }}
                  variant="contained"
                  onClick={() => {
                    setAnchorEl(null);
                  }}
                  color="primary"
                >
                  {t("common.no")}
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    setAnchorEl(null);
                    deleteMutation.mutate(editItem.original.id, {
                      onSuccess: () => {
                        setEditItem(undefined);
                        setRasterName("");
                      },
                    });
                  }}
                >
                  {t("common.yes")}
                </Button>
              </Box>
            </Popover>
            <IconButton
              sx={{ mr: "auto" }}
              color="error"
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
              }}
            >
              <Trash2 />
            </IconButton>
          </>
        }
      />
    </Box>
  );
}
