import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export function useEventTemplates({ org }: { org: string }) {
  const fetchEventTemplates = useCallback(async () => {
    const res = await axios.get(`/${org}/templates/operationevent`);
    return res.data;
  }, [org]);

  return useQuery({
    queryKey: ["organization", org, "eventTemplates"],
    queryFn: fetchEventTemplates,
    enabled: !!org,
  });
}
