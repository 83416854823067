import React, { useCallback } from "react";
import { viewport } from "@placemarkio/geo-viewport";
import { useOrgDispatch, useOrgState } from "providers/OrgProvider";
import { featureCollection } from "@turf/helpers";
import ImageOptionsSelects from "./ImageOptionsSelects";
import { fitBounds } from "components/Map";
import { MapBoxZoomEvent, RasterSource } from "mapbox-gl";
import { useSensors } from "api/useSensors";
import { MapData } from "./types";
import Grid2 from "@mui/material/Unstable_Grid2";

export default function ImageOptionsContainer({
  mapData,
  onChange,
}: {
  mapData: MapData[];
  onChange: (_d: MapData[]) => void;
}) {
  const { tenant, org } = useOrgState();
  const { abortFetchRasterLegend, getRasterLegend } = useOrgDispatch();
  const sensorsQ = useSensors(org);
  // const { state: sensorTypesState } = useOrgLookup({
  //   org,
  //   url: `${org}/sensors`,
  // });

  const toggleExtent = useCallback((toggle, { extent, mapRef }) => {
    if (mapRef) {
      if (toggle) {
        const src = mapRef.getSource("extent-source");
        if (!src) {
          mapRef.addSource("extent-source", {
            type: "geojson",
            data: featureCollection([
              { type: "Feature", geometry: extent, properties: {} },
            ]),
          });
          mapRef.addLayer({
            id: `extent-layer`,
            source: `extent-source`,
            type: "line",
            paint: {
              "line-dasharray": [4, 2],
              "line-color": "#FFFF00",
              "line-width": 2,
            },
          });
        } else {
          src.setData(
            featureCollection([
              { type: "Feature", geometry: extent, properties: {} },
            ])
          );
        }
      } else {
        const src = mapRef.getSource("extent-source");
        if (src) {
          mapRef.removeLayer("extent-layer");
          mapRef.removeSource("extent-source");
        }
      }
    }
  }, []);
  const handleZoom = useCallback(
    (e, extent, id) => {
      const lyr = e.target.getLayer(id);
      const zoom = e.target.getZoom();
      if (lyr) {
        if (zoom < lyr.minzoom) {
          toggleExtent(true, { extent, mapRef: e.target });
        } else {
          toggleExtent(false, { mapRef: e.target });
        }
      }
    },
    [toggleExtent]
  );

  async function handleChange({
    data: d,
    zoom,
    id,
  }: {
    id: number;
    data: MapData;
    zoom: boolean;
  }) {
    const zoomFunc = (e: MapBoxZoomEvent) => {
      handleZoom(e, d.collectionEventSelection.extent, `mapsrc-${mv.id}-layer`);
    };
    abortFetchRasterLegend();

    const mv = mapData.find((m) => m.id === id);
    if (
      d.sensorSelection &&
      d.collectionEventSelection &&
      d.rasterTypeSelection
    ) {
      const legend = await getRasterLegend(d.rasterTypeSelection.slug);
      mv.legendData = legend?.data;
      const { value: collectEventVal } = d.collectionEventSelection;
      const tileUrl = `${process.env.REACT_APP_TILESERVER_ORIGIN}/cog/noauth/tile/${tenant}/${org}/${collectEventVal}/${d.sensorSelection.slug}/${d.rasterTypeSelection.slug}/{z}/{x}/{y}`;
      mv.layers = [{ url: tileUrl, data: d }];
      onChange([...mapData]);
    } else {
      mv.legendData = null;
      mv.layers = [];
      onChange([...mapData]);
    }
    if (mv.mapRef) {
      mv.mapRef.off("zoomend", zoomFunc);
      mv.mapRef.resize();
      const source = mv?.mapRef?.getSource(`mapsrc-${mv.id}`) as RasterSource;
      if (mv.layers.length && source && source.tiles) {
        if (source.tiles[0] === mv.layers[0].url) {
          return;
        }
      }
      if (source) {
        mv.mapRef.removeLayer(`mapsrc-${mv.id}-layer`);
        mv.mapRef.removeSource(`mapsrc-${mv.id}`);
      }
      if (mv.layers.length && mv.mapRef) {
        mv.mapRef.addSource(`mapsrc-${mv.id}`, {
          type: "raster",
          tiles: mv.layers.map((l) => l.url),
          tileSize: 256,
          bounds: d.collectionEventSelection?.extent?.bbox,
          maxzoom: 24,
        });
        mv.mapRef.addLayer(
          {
            id: `mapsrc-${mv.id}-layer`,
            source: `mapsrc-${mv.id}`,
            type: "raster",
          },
          "fields-lyr"
        );
      }
      if (d.collectionEventSelection) {
        if (zoom) {
          fitBounds({
            map: mv.mapRef,
            geojson: d.collectionEventSelection.extent,
            // remove animation due to map-sync
            options: { animate: false },
          });
        }
        const container = mv.mapRef.getContainer();
        // convert extent into center/zoom based on map container size
        const centerZoom = viewport(d.collectionEventSelection.extent.bbox, [
          container.offsetHeight,
          container.offsetWidth,
        ]);
        const { zoom: z } = centerZoom || {};
        // store zoom of extent on map data object
        mv.minzoom = z >= 2 ? z - 2 : z;
        mv.mapRef.on("zoomend", zoomFunc);
      }
      const currZoom = mv.mapRef.getZoom();
      if (
        currZoom < mv.minzoom &&
        d.collectionEventSelection &&
        d.rasterTypeSelection
      ) {
        toggleExtent(true, {
          extent: d.collectionEventSelection.extent,
          mapRef: mv.mapRef,
        });
      } else {
        toggleExtent(false, { mapRef: mv.mapRef });
      }
      // get zoom of current map level and subtract 2 for minzoom level
      // this prevents rendering tile layers at smaller scales
      const hasLayer = mv.mapRef.getLayer(`mapsrc-${mv.id}-layer`);
      if (hasLayer) {
        mv.mapRef.setLayerZoomRange(`mapsrc-${mv.id}-layer`, mv.minzoom, 25);
      }
    }
  }

  return (
    <>
      <Grid2 container spacing={3}>
        {mapData.map((md) => (
          <Grid2 xs={12 / mapData.length} key={md.id}>
            <ImageOptionsSelects
              mapLoaded={Boolean(md.mapRef)}
              id={md.id}
              sensorOptions={sensorsQ.data}
              sensorsLoading={sensorsQ.isLoading}
              onChange={handleChange}
            />
          </Grid2>
        ))}
      </Grid2>
    </>
  );
}
