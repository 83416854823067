import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useCallback } from "react";

export function useAssetMutation({
  org,
  type,
}: {
  org: string;
  type: "animal" | "plant" | "fixed";
}) {
  const queryClient = useQueryClient();
  const handleInvalidate = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: ["organization", org, `assets`],
    });
  }, [org, queryClient]);
  const saveItem = useCallback(
    (data) => {
      const { geometry, ...rest } = data;
      const method = data.id ? "put" : "post";
      return axios[method](`/${org}/assets/${type}/geodata`, {
        // need to stringify geometry before send
        ...(geometry && {
          geometry: JSON.stringify(geometry),
        }),
        ...rest,
      });
    },
    [org, type]
  );

  const deleteItem = useCallback(
    (id) => {
      return axios.delete(`/${org}/assets/${type}/geodata/${id}`);
    },
    [org, type]
  );

  const saveMutation = useMutation({
    mutationFn: saveItem,
    onSuccess: handleInvalidate,
  });
  const deleteMutation = useMutation({
    mutationFn: deleteItem,
    onSuccess: handleInvalidate,
  });
  return {
    deleteMutation,
    saveMutation,
  };
}
