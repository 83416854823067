import { Component, ErrorInfo, ReactNode } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { withTranslation } from "react-i18next";
interface Props {
  fallback?: ReactNode;
  children?: ReactNode;
  t: (_s: string) => string;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        this.props.fallback ?? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "8rem",
            }}
          >
            <Typography variant="h4">
              {this.props.t("common.somethingUnexpected")}
            </Typography>
            <Button
              variant="outlined"
              sx={{ mt: 3 }}
              onClick={() => {
                window.location.reload();
              }}
            >
              {this.props.t("common.tryAgain")}
            </Button>
          </div>
        )
      );
    }

    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
