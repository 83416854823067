import { useTranslation } from "react-i18next";
import { useLegendControl } from "lib/MapboxMap";

export default function useLegendControlWrapper(props: {
  layers: unknown[];
  location: string;
  theme: unknown;
}) {
  const { t } = useTranslation();
  return useLegendControl({
    ...props,
    title: t("map.legend"),
    noDataText: t("common.noData"),
  });
}
