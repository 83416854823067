import React from "react";
import Typography from "@mui/material/Typography";
import { SxProps } from "@mui/material/styles";

export default function SectionHeader({
  children,
  sx,
}: {
  children: React.ReactNode;
  sx?: SxProps;
}) {
  return (
    <>
      <Typography sx={{ mb: 1, ...sx }} variant="h6" component={"h3"}>
        {children}
      </Typography>
    </>
  );
}
