import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { LinkProps } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function EditIconButton({
  ...props
}: IconButtonProps & { href?: LinkProps["href"] }) {
  const { t } = useTranslation();
  return (
    <IconButton
      aria-label={t("common.edit")}
      {...props}
      sx={{
        ...props.sx,
        " &:hover": {
          color: "text.primary",
        },
      }}
    >
      <EditIcon />
    </IconButton>
  );
}
