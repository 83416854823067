const operationKeys = {
  all: (org: string) => ["organization", org, "operations"] as const,
  bySeason: (org: string, type: string, seasonId: string) =>
    ["organization", org, "operations", type, "season", seasonId] as const,
  byType: (org: string, type: string) =>
    ["organization", org, "operations", type] as const,
  byId: (org: string, type: string, id: string) =>
    ["organization", org, "operations", type, id] as const,
  geodataById: (org: string, type: string, id: string) =>
    ["organization", org, "operations", type, "geodata", id] as const,
  byFields: (org: string, type: string, fieldIds: string[]) =>
    [
      "organization",
      org,
      "operations",
      type,
      "geodata",
      "field",
      fieldIds,
    ] as const,
  events: (org: string) =>
    ["organization", org, "operations", "events"] as const,
  eventPhotos: (org: string, eventId: string) =>
    ["organization", org, "operations", "events", eventId, "photos"] as const,
  eventsByOperation: (org: string, operationId: string) =>
    ["organization", org, "operations", "events", operationId] as const,
  summary: (org: string) =>
    ["organization", org, "operations", "summary"] as const,
  summaryBySeason: (org: string, seasonId: string) =>
    ["organization", org, "operations", "summary", "season", seasonId] as const,
  mapThemes: (org: string, type: string, seasonId: string) =>
    [
      "organization",
      org,
      "operations",
      type,
      "season",
      seasonId,
      "mapthemes",
    ] as const,
};

export default operationKeys;
