import React, { useEffect, useMemo, useRef, useState } from "react";
import { TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import DoneIcon from "@mui/icons-material/Done";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Autocomplete from "lib/Select/Autocomplete";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import { useSubfields } from "api/useSubfields";
import { useCollectEventsByGeometry } from "api/useCollectEvents";
import { useParams } from "react-router-dom";
import { useOrgState } from "providers/OrgProvider";
import { useFieldsState } from "providers/FieldsProvider";
import { Feature, MultiPolygon, Polygon } from "geojson";
import SubfieldsSelect from "../SubfieldsSelect";
import { useOrgLookup } from "api/useOrgLookup";
import {
  useCreateCollectEvent,
  useImageryExtract,
  usePlantHeightCollectEvent,
  useSaveImageryExtract,
} from "api/useImageryExtract";
import { useSensorsByGeometry } from "api/useSensors";
import { ICollectEvent, IField, ISensor } from "types";
import { Map } from "mapbox-gl";
import { fitBounds } from "lib/MapboxMap";
import { ConfirmationDialog } from "lib";
import { DatePicker } from "lib/DatePicker";
import { useSubfieldGroups } from "api/useSubfieldGroups";
import { useSubfieldsByFieldId } from "api/useSubfieldsGeodata";
import { ISubfield } from "types/ISubfield";
import union from "@turf/union";
import { ImageryExtractMapView } from "./ImageryExtractMapView";
import LoadingButton from "components/LoadingButton";
import { useRasterLegends } from "views/InventoryView/MapView/RasterLayersControl/useRasterLegends";
import SectionHeader from "../SectionHeader";

interface ImageryExtractType {
  id: string;
  name: string;
  nameEn: string;
}

interface SubfieldGroup {
  id?: string;
  inputValue?: string;
  name: string;
}

const filter = createFilterOptions<SubfieldGroup>();

const srcId = "extract-raster-src";
const lyrId = "extract-raster-lyr";

const removeRasterLayer = (mapRef: Map) => {
  // remove any current extract layers
  if (mapRef) {
    const src = mapRef.getSource(srcId);
    const lyr = mapRef.getLayer(lyrId);
    if (lyr) {
      mapRef.removeLayer(lyrId);
    }
    if (src) {
      mapRef.removeSource(srcId);
    }
  }
};

const addRasterLayer = ({
  mapRef,
  data,
  org,
  tenant,
}: {
  mapRef: Map;
  org?: string;
  tenant?: string;
  data?: {
    bbox: [number, number, number, number];
    sensorType: string;
    rasterType: string;
    collectEventId: string;
    // collectEventName: string;
  };
}) => {
  // remove any current extract layers
  removeRasterLayer(mapRef);
  if (data) {
    if (mapRef) {
      const { bbox, collectEventId, sensorType, rasterType } = data;
      const url = `${process.env.REACT_APP_TILESERVER_ORIGIN}/cog/noauth/tile/${tenant}/${org}/${collectEventId}/${sensorType}/${rasterType}/{z}/{x}/{y}`;

      mapRef.addSource(srcId, {
        type: "raster",
        tiles: [url],
        tileSize: 256,
        ...(bbox ? { bounds: bbox } : {}),
        maxzoom: 24,
      });
      mapRef.addLayer(
        {
          id: lyrId,
          source: srcId,
          type: "raster",
        },
        "z-index-layer"
      );
    }
  }
};

export function ImageryExtract() {
  const { t } = useTranslation();
  const { org } = useParams<{ org: string }>();
  const { season, tenant } = useOrgState();
  const { fieldsGeodataState } = useFieldsState();
  const [confirmLossDate, setConfirmLossDate] = useState<boolean>(false);
  const [confirmRasterName, setConfirmRasterName] = useState<boolean>(false);
  const [rasterName, setRasterName] = useState("");
  const [showSubfieldGroupSelect, setShowSubfieldGroupSelect] = useState(false);
  const [lossDate, setLossDate] = useState<Date>();
  const [selectedField, setSelectedField] = useState<
    IField & { geometry: Polygon }
  >();
  const mapRef = useRef<Map>();
  const formRef = useRef<HTMLFormElement>();
  const nameInputRef = useRef<HTMLInputElement>();
  const [type, setType] = useState<ImageryExtractType>();
  const [selectedDemSensor, setSelectedDemSensor] = useState<ISensor>();
  const [selectedDsmSensor, setSelectedDsmSensor] = useState<ISensor>();
  const [selectedDemEvent, setSelectedDemEvent] = useState<ICollectEvent>();
  const [selectedDsmEvent, setSelectedDsmEvent] = useState<ICollectEvent>();
  const [subfieldGroup, setSubfieldGroup] = useState<SubfieldGroup>();
  const [selectedSubfields, setSelectedSubfields] = useState<ISubfield[]>();
  const {
    query: subfieldGroupQ,
    mutation: { mutate: saveSubfieldGroup },
  } = useSubfieldGroups(org);
  const subfieldsQ = useSubfields(org, season?.id);
  const subfieldsGeoQ = useSubfieldsByFieldId({
    org,
    fieldId: selectedField?.id ?? "",
    seasonId: season?.id,
  });

  const {
    data: saveData,
    mutate: handleSave,
    isLoading: isSaving,
    status: saveStatus,
    error: saveError,
    reset: resetSave,
  } = useSaveImageryExtract(org);
  const [extractData, setExtractData] = useState(null);
  const imageryExtractTypesQ = useOrgLookup(org, "imageryextracttypes");
  const imageryExtractQ = useImageryExtract({ org, ...extractData });
  const {
    saveCollectEvent,
    data: collectEventData,
    reset: resetSaveCollectEvent,
    isError: isCollectEventError,
    isSuccess: isCollectEventSuccess,
    isLoading: isCollectEventLoading,
    error,
  } = useCreateCollectEvent(org);

  const collectEventQ = usePlantHeightCollectEvent(
    org,
    selectedDemEvent?.id,
    selectedDsmEvent?.id
  );

  const rasterLegendQ = useRasterLegends({
    tenant,
    org,
    types: collectEventData?.rasterType
      ? [collectEventData?.rasterType]
      : undefined,
  });
  const isCanopy = type?.nameEn.toLowerCase().includes("canopy");
  const isDamage = type?.nameEn.toLowerCase().includes("damage");

  const { subfieldGeom, subfieldFtrs } = useMemo(() => {
    let geom: Polygon | MultiPolygon;
    const ftrs: Feature[] = [];
    try {
      if (selectedSubfields?.length && subfieldsGeoQ.data) {
        selectedSubfields?.map((s) => {
          const f = subfieldsGeoQ.data?.features?.find((sf) => sf.id === s.id);
          if (!f) {
            return {};
          }
          ftrs.push(f);
          if (geom) {
            geom = union(geom, f.geometry as Polygon).geometry;
          } else {
            geom = f.geometry as Polygon;
          }
        });
      }
    } catch (e) {
      console.error("Failed getting subfield geometries");
    }
    return { subfieldGeom: geom, subfieldFtrs: ftrs };
  }, [selectedSubfields, subfieldsGeoQ.data]);

  const demSensors = useSensorsByGeometry(org, subfieldGeom, ["dem"]);
  const dsmSensors = useSensorsByGeometry(org, selectedDemEvent?.extent, [
    "dsm",
  ]);

  const demEvents = useCollectEventsByGeometry(
    org,
    subfieldGeom,
    selectedDemSensor?.id || ""
  );

  const dsmEvents = useCollectEventsByGeometry(
    org,
    selectedDemEvent?.extent,
    selectedDsmSensor?.id || ""
  );

  useEffect(() => {
    if (demSensors.data?.length) {
      setSelectedDemSensor(demSensors.data[0]);
    }
  }, [demSensors.data]);

  useEffect(() => {
    if (dsmSensors.data?.length) {
      setSelectedDsmSensor(dsmSensors.data[0]);
    }
  }, [dsmSensors.data]);

  const reset = (d?: { selectedField?: IField & { geometry: Polygon } }) => {
    setSelectedField(d?.selectedField ?? null);
    setSelectedDsmSensor(null);
    setSelectedDsmEvent(null);
    setSelectedDemSensor(null);
    setSelectedDemEvent(null);
    setExtractData(null);
    resetSave();
  };

  useEffect(() => {
    if (!imageryExtractQ.data) {
      resetSave();
    }
  }, [imageryExtractQ.data, resetSave]);

  useEffect(() => {
    // if no collect event data, remove map layer
    if (!collectEventQ.data && !collectEventData) {
      removeRasterLayer(mapRef.current);
    }
  }, [collectEventData, collectEventQ.data]);

  return (
    <Box
      sx={{
        overflow: "hidden",
        flex: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h2>{t("imagery.extract.extractImagery")}</h2>
      <Grid container flex={1} spacing={4} overflow={"auto"}>
        <Grid
          style={{
            height: "100%",
            overflow: "auto",
          }}
          sm={4}
        >
          <Paper
            ref={formRef}
            component={"form"}
            onSubmit={(e: React.FormEvent) => {
              e.preventDefault();
              const form = e.target as HTMLFormElement;
              const formData = Object.fromEntries(new FormData(form));
              setExtractData(formData);
            }}
            sx={{
              p: 3,
              height: "100%",
              overflowY: "auto",
              backgroundImage: "none",
              backgroundColor: (theme) =>
                theme.palette.mode === "dark" ? "#333" : "#f2f2f2",
            }}
          >
            <Autocomplete
              name="typeId"
              inputProps={{
                required: true,
                value: type?.id ?? "",
              }}
              loading={imageryExtractTypesQ.isLoading}
              disableClearable={false}
              label={t("common.type")}
              options={imageryExtractTypesQ.data ?? []}
              getOptionLabel={(opt: { name: string }) => opt.name}
              onChange={(_e, item: ImageryExtractType) => {
                setType(item);
              }}
            />
            <Box sx={{ mt: 2 }}>
              <SectionHeader>{t("common.include")}</SectionHeader>
              <SubfieldsSelect
                sx={{ mt: 2 }}
                onFieldChange={(fld) => {
                  resetSaveCollectEvent();
                  const geom = fieldsGeodataState.data?.features?.find(
                    (d: Feature) => d.properties.id === fld?.id
                  )?.geometry;
                  if (geom && mapRef.current) {
                    fitBounds({
                      map: mapRef.current,
                      geojson: geom,
                    });
                  }
                  reset({
                    selectedField: fld ? { ...fld, geometry: geom } : null,
                  });
                }}
                fields={fieldsGeodataState.data?.features?.map(
                  (d: Feature) => d.properties
                )}
                subfields={subfieldsQ.data}
                onSubfieldsChange={(items) => {
                  resetSaveCollectEvent();
                  setSelectedSubfields(items);
                  if (!items?.length) {
                    reset({
                      selectedField,
                    });
                  }
                }}
              />
            </Box>
            <Box sx={{ mt: 2, display: "flex", flexDirection: "column" }}>
              <SectionHeader>{`${t(
                "imagery.extract.digitalElevationModel"
              )} (${t("imagery.extract.dem")})`}</SectionHeader>
              <Autocomplete
                disableClearable={false}
                inputProps={{
                  required: true,
                  value: selectedDemEvent?.id ?? "",
                }}
                loading={demEvents.isFetching}
                name="dem"
                options={demEvents?.data}
                getOptionLabel={(row: ICollectEvent) => {
                  return row.label;
                }}
                value={selectedDemEvent ?? null}
                onChange={(_e, item: ICollectEvent) => {
                  resetSaveCollectEvent();
                  setSelectedDemEvent(item);
                  setSelectedDsmEvent(null);
                }}
              />
            </Box>
            <Box sx={{ mt: 2, display: "flex", flexDirection: "column" }}>
              <SectionHeader>{`${t("imagery.extract.digitalSurfaceModel")} (${t(
                "imagery.extract.dsm"
              )})`}</SectionHeader>
              <Autocomplete
                disableClearable={false}
                inputProps={{
                  required: true,
                  value: selectedDsmEvent?.id ?? "",
                }}
                name="dsm"
                loading={dsmEvents.isFetching}
                options={dsmEvents.data ?? []}
                value={selectedDsmEvent ?? null}
                getOptionLabel={(row: ICollectEvent) => {
                  return row.label;
                }}
                onChange={(_e, item: ICollectEvent) => {
                  resetSaveCollectEvent();
                  removeRasterLayer(mapRef.current);
                  setSelectedDsmEvent(item);
                  if (item && mapRef.current) {
                    fitBounds({
                      map: mapRef.current,
                      bbox: item.extent.bbox,
                    });
                  }
                }}
              />
            </Box>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <SectionHeader sx={{ mb: 0 }}>
                  {`${t("imagery.extract.plantHeight")} (${t(
                    "common.optional"
                  )})`}
                </SectionHeader>
                <LoadingButton
                  // variant={collectEventQ.data ? "text" : "contained"}
                  color={isCollectEventSuccess ? "success" : "primary"}
                  isLoading={collectEventQ.isFetching || isCollectEventLoading}
                  sx={{ ml: "auto" }}
                  onClick={() => {
                    if (isCollectEventSuccess || collectEventQ.isFetching) {
                      return;
                    }
                    if (collectEventQ.data) {
                      addRasterLayer({
                        mapRef: mapRef.current,
                        data: collectEventQ.data,
                        org,
                        tenant,
                      });
                      return;
                    }
                    if (formRef.current) {
                      if (!selectedDemEvent || !selectedDsmEvent) {
                        formRef.current.reportValidity();
                        return;
                      }
                    }
                    setConfirmRasterName(true);
                    setTimeout(() => {
                      nameInputRef.current.focus();
                    }, 50);
                  }}
                >
                  {t("imagery.extract.display")}
                  {isCollectEventError ? (
                    <ErrorOutlineIcon sx={{ ml: 1 }} />
                  ) : null}
                  {isCollectEventSuccess ? <DoneIcon sx={{ ml: 1 }} /> : null}
                </LoadingButton>
              </Box>
              <Typography variant="caption">
                {t("imagery.extract.rastersAvailableCompare")}.
              </Typography>
              {isCollectEventError ? (
                <Alert
                  onClose={() => {
                    resetSaveCollectEvent();
                  }}
                  sx={{ mt: 2 }}
                  color={"error"}
                >
                  {error?.message ?? t("imagery.extract.couldNotCreateRaster")}
                </Alert>
              ) : null}
            </Box>
            <Box sx={{ mt: 2, display: "flex", flexDirection: "column" }}>
              <SectionHeader>
                {t("imagery.extract.heightOfInterest")}
              </SectionHeader>
              <TextField
                name="ht"
                InputProps={{
                  sx: {
                    backgroundColor: (theme) =>
                      theme.palette.mode === "light" ? "#fff" : "#121212",
                  },
                  required: true,
                  type: "number",
                  startAdornment: (
                    <Typography sx={{ mr: 2 }} variant="subtitle2">
                      {/* NOTE: hardcode check by type name to flip above/below text */}
                      {isCanopy ? t("common.above") : t("common.below")}
                    </Typography>
                  ),
                  endAdornment: (
                    <Typography sx={{ ml: 2 }} variant="subtitle2">
                      {t("common.meters")}
                    </Typography>
                  ),
                  inputProps: {
                    step: 0.01,
                  },
                }}
              />
            </Box>
            <Box sx={{ mt: 2, display: "flex" }}>
              <Button variant="contained" sx={{ ml: "auto" }} type="submit">
                {t("imagery.extract.extractPolygon")}
                {imageryExtractQ.isFetching ? (
                  <CircularProgress
                    color="secondary"
                    sx={{ ml: 1 }}
                    size={16}
                  />
                ) : null}
              </Button>
              <Button
                disabled={
                  !(
                    !imageryExtractQ.isLoading &&
                    imageryExtractQ.status === "success" &&
                    imageryExtractQ.data
                  )
                }
                variant="contained"
                color="success"
                sx={{ ml: 1 }}
                onClick={() => {
                  if (saveStatus === "success") {
                    return;
                  }
                  if (formRef.current) {
                    const isValid = formRef.current.reportValidity();
                    if (!isValid) {
                      return;
                    }
                  }
                  /* NOTE: hardcode check by type name to grab damage date or subfieldGroupId 
                    before save
                    */
                  if (isDamage) {
                    setConfirmLossDate(true);
                  } else if (isCanopy) {
                    setShowSubfieldGroupSelect(true);
                  }
                }}
              >
                {t("common.save")}
                {isSaving ? (
                  <CircularProgress sx={{ ml: 1 }} size={16} />
                ) : null}
                {saveData && saveStatus === "success" ? (
                  <DoneIcon sx={{ ml: 1 }} />
                ) : null}
              </Button>
            </Box>

            {saveData && saveStatus === "success" ? (
              <Box sx={{ mt: 2, display: "flex" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ ml: "auto", "&:hover": { color: "currentColor" } }}
                  href={`../inventory/${
                    isCanopy ? "subfields/canopy" : "damage"
                  }/${saveData?.id}/edit`}
                >
                  {t("imagery.extract.view")}
                </Button>
              </Box>
            ) : null}
            {saveStatus === "error" || imageryExtractQ.status === "error" ? (
              <Alert sx={{ mt: 2 }} severity="error">
                {saveError?.message ??
                  imageryExtractQ.error ??
                  "Something went wrong"}
              </Alert>
            ) : null}
          </Paper>
        </Grid>
        <Grid sm={8}>
          <Paper
            sx={{
              height: "100%",
              backgroundImage: "none",
              backgroundColor: (theme) =>
                theme.palette.mode === "dark" ? "#333" : "#f2f2f2",
            }}
          >
            <ImageryExtractMapView
              rasterLegendData={
                rasterLegendQ.data?.length ? rasterLegendQ.data[0] : undefined
              }
              onLoad={(map: Map) => {
                mapRef.current = map;
              }}
              extractGeodata={imageryExtractQ.data}
              selectedSubfields={subfieldFtrs}
            />
          </Paper>
        </Grid>
      </Grid>
      <ConfirmationDialog
        open={confirmLossDate}
        title={t("inventory.damage.damageDate")}
        onClose={(confirmed) => {
          setConfirmLossDate(false);
          if (confirmed && formRef.current) {
            const isValid = formRef.current.reportValidity();
            if (!isValid) {
              return;
            }
            handleSave({
              fieldId: selectedField.id,
              typeId: type.id,
              lossEventUtc: lossDate.toISOString(),
              geometry: imageryExtractQ.data,
            });
          }
        }}
        message={
          <>
            <DatePicker
              value={lossDate ?? null}
              onChange={(e) => {
                setLossDate(e);
              }}
            />
          </>
        }
      />
      <ConfirmationDialog
        open={showSubfieldGroupSelect}
        title={t("common.group")}
        onClose={(confirmed) => {
          setShowSubfieldGroupSelect(false);
          if (confirmed && formRef.current) {
            const isValid = formRef.current.reportValidity();
            if (!isValid) {
              return;
            }
            if (!subfieldGroup) {
              setShowSubfieldGroupSelect(true);
              return;
            }
            handleSave({
              fieldId: selectedField.id,
              typeId: type.id,
              subfieldGroupId: subfieldGroup.id,
              geometry: imageryExtractQ.data,
            });
          }
        }}
        message={
          <Box sx={{ p: 1 }}>
            <Autocomplete
              label={t("common.group")}
              error={!subfieldGroup}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={subfieldGroupQ.data}
              value={subfieldGroup}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option.name
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    name: `Add "${inputValue}"`,
                  });
                }

                return filtered;
              }}
              renderOption={(props, option) => (
                <li {...props}>{option.name}</li>
              )}
              getOptionLabel={(option: SubfieldGroup) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.name;
              }}
              onChange={(_e, opt: SubfieldGroup) => {
                if (opt && opt.inputValue) {
                  saveSubfieldGroup(
                    { id: opt.id, name: opt.inputValue },
                    {
                      onSuccess: (d) => {
                        setSubfieldGroup(d);
                      },
                    }
                  );
                } else {
                  setSubfieldGroup(opt);
                }
              }}
            />
          </Box>
        }
      />
      <ConfirmationDialog
        component="form"
        open={confirmRasterName}
        title={t("imagery.extract.saveImage")}
        confirmButtonProps={{ type: "submit" }}
        onClose={(confirmed) => {
          setConfirmRasterName(false);
          if (isCollectEventSuccess) {
            return;
          }
          if (confirmed && !rasterName) {
            nameInputRef.current.reportValidity();
            setConfirmRasterName(true);
            return;
          }
          if (confirmed && formRef.current) {
            saveCollectEvent(
              {
                name: rasterName,
                demCollectEventId: selectedDemEvent.id,
                dsmCollectEventId: selectedDsmEvent.id,
              },
              {
                onSuccess: (r) => {
                  addRasterLayer({
                    mapRef: mapRef.current,
                    data: r,
                    org,
                    tenant,
                  });
                  setRasterName("");
                },
              }
            );
          }
        }}
        message={
          <>
            <TextField
              required
              inputProps={{
                ref: nameInputRef,
              }}
              error={!rasterName}
              placeholder={t("common.name")}
              value={rasterName ?? ""}
              onChange={(e) => {
                setRasterName(e.target.value);
              }}
            />
          </>
        }
      />
    </Box>
  );
}
