import React from "react";
import { useOrgDispatch, useOrgState } from "providers/OrgProvider";
import { useHistory } from "react-router-dom";
import { useAppState } from "providers/AppProvider";
import Autocomplete from "lib/Select/Autocomplete";
import { Users } from "react-feather";
import Box from "@mui/material/Box";

export default function OrganizationSelector() {
  const { organizations } = useAppState();
  const history = useHistory();
  const { org } = useOrgState();
  const { dispatch } = useOrgDispatch();
  const opts = organizations?.map((o: { name: string; slug: string }) => ({
    label: o.name,
    value: o.slug,
  }));

  return (
    <Autocomplete
      id="org-selector-select"
      sx={{ minWidth: 200 }}
      options={opts}
      value={opts?.find((o: { value: string }) => o.value === org) ?? null}
      onChange={(_e, item: { value: string }) => {
        if (!item || item?.value === org) {
          return;
        }
        history.push(`/${item.value}`);
        dispatch({
          type: "SET_ORG",
          payload: item.value,
        });
      }}
      InputProps={{
        startAdornment: (
          <Box sx={{ mx: 1 }}>
            <Users />
          </Box>
        ),
      }}
    />
  );
}
