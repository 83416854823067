import { useCallback } from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";

export function useSubfieldTypes(org?: string) {
  const getData = useCallback(async () => {
    if (org) {
      const res = await axios.get<
        { name: string; nameEn: string; id: string }[]
      >(`/${org}/lookups/subfieldtypes`);
      return res.data;
    }
  }, [org]);

  return useQuery({
    queryKey: ["organization", org, "subfields", "types"],
    queryFn: getData,
    staleTime: Infinity,
    enabled: !!org,
  });
}
