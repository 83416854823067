import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

export default function NestedLegendItem({ item }) {
  return (
    <div>
      <Typography style={{ textTransform: "none" }}>{item.title}</Typography>
      <table style={{ width: "100%", marginLeft: "0.25rem" }}>
        <tbody>
          {item.data.map((d) => {
            return (
              <tr
                style={{
                  paddingBottom: "0.25rem",
                  alignItems: "center",
                  display: "flex",
                }}
                key={`${d.name}-${d.legendSymbol}`}
              >
                <td>{d.name}</td>
                <td style={{ marginLeft: "auto" }}>{d.legendSymbol}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

NestedLegendItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        legendSymbol: PropTypes.node,
      })
    ).isRequired,
  }).isRequired,
};
