import { useCallback } from "react";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

interface AssetGroup {
  id?: string;
  name: string;
  inputValue?: string;
}

export function useAssetGroups(org: string) {
  const queryClient = useQueryClient();

  const fetchAssetGroups = useCallback(async () => {
    const res = await axios.get<AssetGroup[]>(`${org}/lookups/assetgroups`);
    return res?.data;
  }, [org]);

  const query = useQuery({
    queryKey: ["organization", org, "assetgroups"],
    queryFn: fetchAssetGroups,
    enabled: !!org,
  });

  const saveAssetGroup = useCallback(
    async (d) => {
      const res = await axios.post(`${org}/assets/groups`, d);
      return res?.data;
    },
    [org]
  );

  const mutation = useMutation({
    mutationFn: saveAssetGroup,
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["organization", org, "assetgroups"],
      });
    },
  });

  return { query, mutation };
}
