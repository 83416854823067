import { useCallback, useState } from "react";
import useFetchWithToken from "components/useFetchWithToken";
import { parseMapThemes } from "./parseMapThemes";
import { useRef } from "react";
import { useEffect } from "react";

function mergeProps(fc, list, theme) {
  fc.features.forEach((ftr) => {
    // get list item and merge properties onto geojson feature
    const listItem = list?.find((li) => li.id === ftr.id);
    const themeItem = theme?.find((li) => li.id === ftr.id);
    if (listItem) {
      Object.assign(ftr.properties, listItem);
    }
    if (themeItem) {
      Object.assign(ftr.properties, themeItem);
    }
  });
  return fc;
}

function combineFeatures(currentFc, newFc, list, theme) {
  const newGeodata = currentFc?.features ? [...currentFc.features] : [];
  if (newFc) {
    mergeProps(newFc, list, theme);
    newFc.features.forEach((ftr) => {
      // find prev feature, if any, and either replace or add the new feature
      const prevFtr = newGeodata.find((f) => f.id === ftr.id);
      const idx = newGeodata.indexOf(prevFtr);
      if (idx >= 0) {
        newGeodata[idx] = ftr;
      } else {
        newGeodata.push(ftr);
      }
    });
  }

  return {
    type: "FeatureCollection",
    features: newGeodata,
  };
}

export function useInventoryType({
  org,
  seasonId,
  type,
  subType,
  mapThemesIgnoreSubtype = false, // added to handle unique case for subfields
  themeConfig,
}) {
  const didCancel = useRef();
  const {
    state: listState,
    fetchData: fetchList,
    setDataState: setListData,
  } = useFetchWithToken();
  const [themeRollup, setThemeRollup] = useState();
  const {
    state: geodataState,
    fetchData: fetchGeodata,
    setDataState: setGeodata,
  } = useFetchWithToken();
  const {
    fetchData: fetchGeodataByFieldId,
    state: geodataByFieldIdState,
  } = useFetchWithToken();
  const {
    state: saveItemState,
    fetchData: saveItemData,
    resetFetchState: resetSaveItem,
  } = useFetchWithToken();
  const {
    state: deleteItemState,
    fetchData: callDelete,
    resetFetchState: resetDeleteItem,
  } = useFetchWithToken();
  const {
    fetchData: fetchMapThemes,
    state: themeState,
    setDataState: setThemeData,
  } = useFetchWithToken();

  // removes list/geodata/theme item by id
  // and updates theme rollup
  function removeItemFromData(id) {
    const newGeodata = geodataState?.data?.features
      ? [...geodataState?.data?.features]
      : [];
    const newList = [...listState?.data];
    const prevListItem = newList.find((l) => l.id === id);
    const listIdx = newList.indexOf(prevListItem);
    if (listIdx > -1) {
      newList.splice(listIdx, 1);
      setListData(newList);
    }
    const prevFtr = newGeodata.find((f) => f.id === id);
    const idx = newGeodata.indexOf(prevFtr);
    if (idx > -1) {
      newGeodata.splice(idx, 1);
      setGeodata({ type: "FeatureCollection", features: newGeodata });
    }
    const newThemes = [...themeState?.data];
    const prevThemeItem = newThemes.find((l) => l.id === id);
    const themeIdx = newThemes.indexOf(prevThemeItem);
    if (listIdx > -1) {
      newThemes.splice(themeIdx, 1);
      setThemeData(newThemes);
      const rollup = {};
      if (themeConfig?.length) {
        themeConfig.forEach((config) => {
          const parsed = parseMapThemes(newThemes, config);
          rollup[config.dataProp] = parsed;
        });
      }
      setThemeRollup(rollup);
    }
  }

  async function fetchGeodataBySeason(theme, list) {
    const newFc = await fetchGeodata(
      `/${org}/${type}${
        subType ? `/${subType}` : ""
      }/geodata/season/${seasonId}`,
      {
        transformResponse: (res) => {
          if (!list || !theme) {
            return res;
          }
          const combined = mergeProps(res, list, theme);
          return combined;
        },
      }
    );

    return newFc;
  }

  async function fetchItemsByFieldId(fieldId) {
    const res = await fetchGeodataByFieldId(
      `/${org}/${type}${subType ? `/${subType}` : ""}/geodata/fields/${fieldId}`
    );
    if (res?.isError) {
      return res;
    }
    const combined = combineFeatures(
      geodataState?.data,
      res?.data,
      listState?.data,
      themeState?.data
    );

    setGeodata(combined);
    return res;
  }

  // NOTE: refactored this function to use fetchGeodata
  // instead of fetchGeodataById and then updating geodata state
  // which was causing an infinite loop issue in assets table view
  // GEODATA IS NO LONGER APPENDED WITH THIS FUNCTION, ONLY KEEPS
  // LATEST DATA FETCH
  const fetchItemsByQueryParams = useCallback(
    async (params = {}) => {
      const keys = Object.keys(params);
      let queryStr = "";
      keys.forEach((k, i) => {
        queryStr += `${i === 0 ? "?" : "&"}`;
        queryStr += `${k}=${params[k]}`;
      });
      return fetchGeodata(
        `/${org}/${type}${
          subType ? `/${subType}` : ""
        }/geodata/fields${queryStr}`,
        {
          transformResponse: (res) => {
            const combined = mergeProps(res, listState?.data, themeState?.data);
            return combined;
          },
        }
      );
      // if (res.status >= 400) {
      //   return res;
      // }
      // const combined = combineFeatures(
      //   geodataState?.data,
      //   res,
      //   listState?.data,
      //   themeState?.data
      // );

      // setGeodata(combined);
      // return res;
    },
    [
      fetchGeodata,
      org,
      subType,
      type,
      // setGeodata,
      // geodataState?.data,
      listState?.data,
      themeState?.data,
    ]
  );

  const fetchItemById = useCallback(
    (id) => {
      const res = fetchGeodata(
        `/${org}/${type}${subType ? `/${subType}` : ""}/geodata/${id}`,
        {
          transformResponse: (data) => {
            const combined = mergeProps(
              data,
              listState?.data,
              themeState?.data
            );
            return combined;
          },
        }
      );

      return res;
    },
    [fetchGeodata, listState?.data, themeState?.data, org, subType, type]
  );

  const fetchListBySeason = useCallback(() => {
    return fetchList(
      `/${org}/${type}${subType ? `/${subType}` : ""}/season/${seasonId}`,
      {
        transformResponse: (res) => {
          if (res && res.forEach) {
            res.forEach((r) => {
              r.label = r.name;
              r.value = r.id;
              // NOTE: added this hack to support grouped operations tables
              // with duplicate name values between farms
              if (type === "operations" || type === "assets") {
                r.fieldFarmGroupKey = `${r.field}${r.farm ? `:${r.farm}` : ""}`;
                r.fieldFarmTypeKey = `${r.field}${r.farm ? `:${r.farm}` : ""}${
                  r.type ? `:${r.type}` : ""
                }`;
              }
              // NOTE: added this to remove dependency on API providing it.
              // would be unnecessary if moving to non-grouped tables
              r.subfieldGroupKey = `${r.farm || ""}|${r.field || ""}|${
                r.subfieldGroup || ""
              }`;
            });
          }
          return res;
        },
      }
    );
  }, [fetchList, org, seasonId, subType, type]);

  const fetchMapThemesBySeason = useCallback(async () => {
    const themes = await fetchMapThemes(
      `/${org}/${type}${
        subType && !mapThemesIgnoreSubtype ? `/${subType}` : ""
      }/season/${seasonId}/mapthemes`,
      {
        transformResponse: (res) => {
          const rollup = {};
          if (themeConfig?.length) {
            themeConfig.forEach((config) => {
              const parsed = parseMapThemes(res, config);
              rollup[config.dataProp] = parsed;
            });
          }
          if (!didCancel.current) {
            setThemeRollup(rollup);
          }
          return res;
        },
      }
    );
    return themes;
  }, [
    fetchMapThemes,
    org,
    type,
    subType,
    mapThemesIgnoreSubtype,
    seasonId,
    themeConfig,
  ]);

  async function deleteItem(id) {
    const res = await callDelete(
      `/${org}/${type}${subType ? `/${subType}` : ""}/geodata/${id}`,
      {
        method: "DELETE",
      }
    );
    if (!res.isError) {
      removeItemFromData(id);
    }
    return res;
  }
  async function saveItem(newData) {
    const { geometry, ...rest } = newData;
    const method = newData.id ? "PUT" : "POST";
    const res = await saveItemData(
      `/${org}/${type}${subType ? `/${subType}` : ""}/geodata`,
      {
        method,
        body: JSON.stringify({
          ...(geometry && {
            geometry: JSON.stringify(geometry),
          }),
          // geometry: geometry ? JSON.stringify(geometry) : '',
          ...rest,
        }),
      }
    );
    if (res && !res.isError) {
      const d = res.data;
      // array to store the response features
      const resFtrs = [];
      if (d?.type === "Feature") {
        resFtrs.push(d);
      } else if (d?.type === "FeatureCollection") {
        resFtrs.push(...d?.features);
      }
      if (resFtrs.length) {
        const list = await fetchListBySeason();
        const themes = await fetchMapThemesBySeason();
        // get current features
        const currFtrs = geodataState.data
          ? [...geodataState.data.features]
          : [];
        resFtrs.forEach((nf) => {
          // merge theme and feature props onto the feature
          const listItem = list?.data?.find((l) => l.id === nf.id);
          const themeItem = themes?.data?.find((t) => t.id === nf.id);
          Object.assign(nf.properties, listItem, themeItem);
          // update or append data
          const prevFtr = currFtrs.find((f) => f.id === newData.id);
          if (prevFtr) {
            const idx = currFtrs.indexOf(prevFtr);
            currFtrs[idx] = nf;
          } else {
            currFtrs.push(nf);
          }
        });
        // set it
        setGeodata({
          type: "FeatureCollection",
          features: currFtrs,
        });
      }
    }
    return res;
  }

  useEffect(() => {
    didCancel.current = false;
    return function cleanup() {
      didCancel.current = true;
    };
  }, []);

  return {
    fetchList: fetchListBySeason,
    fetchGeodata: useCallback(fetchGeodataBySeason, [
      fetchGeodata,
      org,
      seasonId,
      subType,
      type,
    ]),
    fetchMapThemes: fetchMapThemesBySeason,
    fetchItemsByFieldId,
    fetchItemsByQueryParams,
    fetchItemById,
    // geodataByIdState,
    geodataByFieldIdState,
    themeState,
    themeData: themeState.data,
    themeRollup,
    list: listState.data,
    listState,
    geodata: geodataState.data,
    geodataState,
    saveItem,
    saveItemState,
    resetSaveItem,
    deleteItem,
    deleteItemState,
    resetDeleteItem,
    resetGeodata: useCallback(
      (d) => {
        setGeodata(d);
      },
      [setGeodata]
    ),
    reset: useCallback(
      ({ ignoreThemeData } = {}) => {
        setGeodata(null);
        setListData(null);
        if (!ignoreThemeData) {
          setThemeRollup(null);
          setThemeData(null);
        }
      },
      [setGeodata, setListData, setThemeData]
    ),
  };
}
