import React from "react";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import useSidebarState from "./useSidebarState";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { SxProps } from "@mui/material";

export default function SidebarListTreeItem({
  children,
  eventKey,
  icon,
  sx,
  text,
}: {
  children: React.ReactNode;
  eventKey: string;
  icon?: React.ReactNode;
  open?: boolean;
  sx?: SxProps;
  text: string;
}) {
  const { open, setOpen, activeKey, setActiveKey } = useSidebarState();

  return (
    <li>
      <ListItemButton
        sx={sx}
        disableTouchRipple
        onClick={() => {
          if (!open) {
            setOpen(true);
            setActiveKey(eventKey);
            return;
          }
          if (activeKey === eventKey) {
            setActiveKey("");
          } else {
            setActiveKey(eventKey);
          }
        }}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText
          sx={{
            textWrap: "wrap",
            textTransform: "uppercase",
            opacity: open ? 1 : 0,
          }}
          primary={text}
        />
        <ExpandMore
          sx={{
            transition: "transform 0.2s",
            transform: activeKey !== eventKey ? "rotate(-90deg)" : "none",
          }}
        />
      </ListItemButton>
      <Collapse
        in={activeKey === eventKey && open}
        timeout="auto"
        unmountOnExit
      >
        <List
          sx={{
            a: {
              pl: 4,
            },
            display: "flex",
            flexDirection: "column",
          }}
        >
          {children}
        </List>
      </Collapse>
    </li>
  );
}
