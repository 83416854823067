import { useCallback } from "react";
import useFetchWithToken from "components/useFetchWithToken";
import { useQuery } from "@tanstack/react-query";
import { ISelectOption } from "types";

interface IOrgLookup extends ISelectOption {
  id: string;
  name: string;
}

export default function useOrgLookupRQ<T = IOrgLookup>(
  url: string,
  opts?: { staleTime?: number; initData?: T[] }
) {
  const { initData, staleTime } = opts ?? {};
  const { fetchData } = useFetchWithToken<T[]>(initData);

  const getLookupData = useCallback(async () => {
    const res = await fetchData(url, {
      transformResponse: (res: Record<string, unknown>[] & T[]) => {
        if (res && res.length) {
          res.forEach((r) => {
            r.value = r.id;
            r.label = r.name;
          });
        }

        return res;
      },
    });
    return res?.data as T[];
  }, [fetchData, url]);

  return useQuery({
    queryKey: [url],
    queryFn: getLookupData,
    staleTime: staleTime ?? 10 * (60 * 1000), // 10 mins
  });
}
