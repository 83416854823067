import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Collapse, { CollapseProps } from "@mui/material/Collapse";
import { Theme } from "@mui/material";

export default function DrawerControl({
  children,
  open: defaultOpen,
  ...rest
}: CollapseProps & { open?: boolean }) {
  const [open, setOpen] = useState(defaultOpen);

  useEffect(() => {
    setOpen(defaultOpen);
  }, [defaultOpen]);

  return (
    <>
      <Collapse in={open} {...rest}>
        <Paper
          sx={(theme: Theme) => ({
            py: 2,
            px: 3,
            ...{
              ml: 4,
              height: "100%",
              color: theme.palette.mode === "light" ? "#000" : undefined,
              backgroundColor:
                theme.palette.mode === "light" ? "#f2f2f2" : "#333",
              backgroundImage: "none",
            },
          })}
        >
          {children}
        </Paper>
      </Collapse>
    </>
  );
}
