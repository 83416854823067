import React from "react";
import PropTypes from "prop-types";
import { useOrgState } from "./OrgProvider";
import { useInventoryType } from "views/InventoryView/useInventoryType";
import { useCallback } from "react";
import useFetchWithToken from "components/useFetchWithToken";
import { useFieldsGeodata } from "api/fields/useFieldsGeodata";

const FieldsStateContext = React.createContext();
const FieldsDispatchContext = React.createContext();

const fieldThemeConfig = [
  { dataProp: "landAgreementType", colorProp: "landAgreementColor" },
];

function FieldsProvider({ children }) {
  const { org, seasonId } = useOrgState();
  const {
    fetchData: exportField,
    state: exportFieldState,
    resetFetchState: resetExportFieldState,
  } = useFetchWithToken();
  const fieldsGeodataState = useFieldsGeodata({ org, seasonId });

  const {
    // fetchList,
    // fetchGeodata,
    // fetchMapThemes: fetchFieldMapThemes,
    // geodataState: fieldsGeodataState,
    // themeState,
    themeRollup,
  } = useInventoryType({
    org,
    seasonId,
    type: "fields",
    themeConfig: fieldThemeConfig,
  });

  // const fetchFields = useCallback(async () => {
  //   // fetch theme and list data first, to merge with geodata
  //   const theme = await fetchFieldMapThemes();
  //   const list = await fetchList();
  //   fetchGeodata(theme?.data, list?.data);
  // }, [fetchFieldMapThemes, fetchGeodata, fetchList]);

  return (
    <FieldsStateContext.Provider
      value={{
        exportFieldState,
        fieldsGeodataState,
        // themeState,
        themeRollup,
      }}
    >
      <FieldsDispatchContext.Provider
        value={{
          resetExportFieldState,
          exportField: useCallback(
            (fieldId) => {
              return exportField(`/${org}/fields/geodata/${fieldId}/export`);
            },
            [exportField, org]
          ),
        }}
      >
        {children}
      </FieldsDispatchContext.Provider>
    </FieldsStateContext.Provider>
  );
}
FieldsProvider.defaultProps = {
  fields: null,
  editId: null,
};

FieldsProvider.propTypes = {
  children: PropTypes.any,
};

function useFieldsState() {
  const context = React.useContext(FieldsStateContext);
  if (context === undefined) {
    throw new Error("useFieldsState must be used within a FieldsProvider");
  }
  return context;
}
function useFieldsDispatch() {
  const context = React.useContext(FieldsDispatchContext);
  if (context === undefined) {
    throw new Error("useFieldsDispatch must be used within a FieldsProvider");
  }
  return context;
}

export { FieldsProvider, useFieldsDispatch, useFieldsState };
