import { useCallback, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { Plus, Minus } from "react-feather";
import { useTranslation } from "react-i18next";
import { useOrgState } from "providers/OrgProvider";
import ImageOptionsContainer from "./ImageOptionsContainer";
import SyncedMaps from "./SyncedMaps";
import { useFieldsState } from "providers/FieldsProvider";

export default function ImageryCompare() {
  const { season } = useOrgState();
  const [showThirdImage, setShowThirdImage] = useState(false);
  const [mapViews, setMapViews] = useState([
    { id: 1, layers: [] },
    { id: 2, layers: [] },
  ]);
  const { fieldsGeodataState } = useFieldsState();

  const { t } = useTranslation();

  // method called when map load event fires and
  // the total mapViews === loaded views
  const handleMapSync = useCallback(
    (d) => {
      const withMapRef = mapViews.map((mv, idx) => ({
        mapRef: d[idx],
        ...mv,
      }));
      setMapViews(withMapRef);
    },
    [mapViews]
  );

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <h2>{t("imagery.compare.compareImagery")}</h2>
        <IconButton
          aria-label={t("imagery.compare.toggleMap")}
          sx={{ border: "solid 1px", ml: "auto" }}
          color={showThirdImage ? "error" : "success"}
          onClick={() => {
            setShowThirdImage(!showThirdImage);
            const curr = [...mapViews];
            const exists = curr.find((c) => c.id === 3);
            let newMaps;
            if (exists) {
              newMaps = curr.filter((c) => c.id !== 3);
            } else {
              curr.push({ id: 3, layers: [] });
              newMaps = curr;
            }
            setMapViews(newMaps);
          }}
        >
          {showThirdImage ? <Minus /> : <Plus />}
        </IconButton>
      </div>
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <Paper
          sx={{
            mt: 1,
            p: 3,
            pb: 1,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          <ImageOptionsContainer
            mapData={mapViews}
            onChange={(data) => {
              setMapViews([...data]);
            }}
          />
        </Paper>
        <Paper
          sx={{
            height: "100%",
            px: 3,
            pb: 0,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        >
          <SyncedMaps
            defaultBounds={season?.extent?.bbox}
            mapData={mapViews}
            onMapsSync={handleMapSync}
            fields={fieldsGeodataState?.data}
          />
        </Paper>
      </div>
    </>
  );
}
