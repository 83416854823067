// import React from 'react';

// interface IDataItem {
//   [key: string]: string | number;
//   area: number;
//   totalMass: number;
// }

export interface IDataItem {
  // TODO:
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
  count?: number;
  name?: string;
  percent?: number;
  color?: string;
  area?: number;
  areaPercent?: string;
  areaUom?: string;
  totalMass?: number;
  totalMassUom?: string;
  subfieldType?: string;
  subfieldTypeColor?: string;
}

interface IParseParams {
  labelFunc?: (_i: IDataItem) => string;
  dataProp: string;
  colorProp: string;
}

export function parseMapThemes(
  data: IDataItem[],
  dataProps: IParseParams
): IDataItem[] {
  const totalFields = data?.length;
  let totalArea = 0;
  if (data) {
    data.forEach((i) => {
      if (i.area) {
        totalArea += i.area;
      }
    });
  }

  function parseDataProp({
    labelFunc,
    dataProp,
    colorProp = "color",
  }: IParseParams) {
    const unique: { id: string; label: string | number }[] = [];
    data.forEach((item) => {
      if (!unique.find((u) => u.id === item[dataProp])) {
        unique.push({
          id: item[dataProp] as string,
          label: labelFunc ? labelFunc(item) : (item[dataProp] as string),
        });
      }
    });
    const newTypes: IDataItem[] = [];
    unique.forEach((type) => {
      const items = data.filter((i) => i[dataProp] === type.id);
      let itemArea = 0;
      let itemMass = 0;
      items.forEach((i) => {
        if (i.area) {
          itemArea += i.area;
        }
        if (i.totalMass) {
          itemMass += i.totalMass;
        }
      });
      newTypes.push({
        count: items.length,
        name: type.label || type.label === 0 ? `${type.label}` : "N/A",
        percent: Number(((items.length / totalFields) * 100).toFixed(2)),
        color: items[0] ? (items[0][colorProp] as string) : "",
        area: itemArea ?? Number(itemArea.toFixed(2)),
        areaPercent: totalArea && ((itemArea / totalArea) * 100).toFixed(2),
        areaUom: items[0]?.areaUom,
        totalMass: itemMass ?? Number(itemMass.toFixed(2)),
        totalMassUom: items[0]?.totalMassUom,
      });
    });
    return newTypes;
  }
  if (data) {
    return parseDataProp(dataProps);
  }
}
