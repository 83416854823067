import SvgIcon from "@mui/material/SvgIcon";
import { IconProps } from "./IconProps";

export default function ImageryIcon({ fill, size, sx }: IconProps) {
  return (
    <SvgIcon fontSize={size} sx={sx}>
      <svg
        width="102"
        height="102"
        version="1.1"
        viewBox="0 0 102 102"
        xmlSpace="preserve"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="translate(-20.67,-19)">
          <linearGradient
            id="img-icon-1"
            x1="20.667"
            x2="111.74"
            y1="75.464"
            y2="75.464"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#0ff" offset="0" />
            <stop stopColor="#0071C1" offset="1" />
          </linearGradient>
          <path
            fill={fill ?? "url(#img-icon-1)"}
            d="m20.67 104.61v3.64c0 1.01 0.82 1.82 1.82 1.82h83.79c3.02 0 5.46-2.45 5.46-5.46v-63.75h-7.29v59.2c0 1.51-1.22 2.73-2.73 2.73h-79.23c-1.01 0-1.82 0.81-1.82 1.82z"
          />
          <linearGradient
            id="img-icon-2"
            x1="31.595"
            x2="38.881"
            y1="117.36"
            y2="117.36"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#0ff" offset="0" />
            <stop stopColor="#0071C1" offset="1" />
          </linearGradient>
          <path
            fill={fill ?? "url(#img-icon-2)"}
            d="m37.06 121c1.01 0 1.82-0.82 1.82-1.82v-5.46h-7.28v5.46c0 1.01 0.82 1.82 1.82 1.82z"
          />
          <linearGradient
            id="img-icon-3"
            x1="104.45"
            x2="111.74"
            y1="22.643"
            y2="22.643"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#0ff" offset="0" />
            <stop stopColor="#0071C1" offset="1" />
          </linearGradient>
          <path
            d="m109.92 19h-3.64c-1.01 0-1.82 0.82-1.82 1.82v5.46h7.29v-5.46c-0.01-1-0.83-1.82-1.83-1.82z"
            fill={fill ?? "url(#img-icon-3)"}
          />
          <linearGradient
            id="img-icon-4"
            x1="31.595"
            x2="122.67"
            y1="64.536"
            y2="64.536"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#0ff" offset="0" />
            <stop stopColor="#0071C1" offset="1" />
          </linearGradient>
          <path
            id="Shape_4_"
            d="m120.85 29.93h-83.79c-3.02 0-5.46 2.45-5.46 5.46v63.75h7.29v-1.42c-0.1-0.34-0.1-0.7 0-1.04v-56.73c0-1.51 1.22-2.73 2.73-2.73h79.23c1.01 0 1.82-0.82 1.82-1.82v-3.64c0-1.02-0.82-1.83-1.82-1.83z"
            fill={fill ?? "url(#img-icon-4)"}
          />

          <linearGradient
            id="img-icon-5"
            x1="53.34"
            x2="53.34"
            y1="41.274"
            y2="108.78"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D5E100" offset=".1213" />
            <stop stopColor="#79C80E" offset=".9881" />
          </linearGradient>
          <path
            d="m53.34 95.5c-1.81 0-3.28-1.35-3.28-3.02v-43.26c0-1.67 1.47-3.02 3.28-3.02s3.28 1.35 3.28 3.02v43.25c0 1.68-1.47 3.03-3.28 3.03z"
            fill={fill ?? "url(#img-icon-5)"}
          />
          <linearGradient
            id="img-icon-6"
            x1="71.405"
            x2="71.405"
            y1="41.274"
            y2="108.78"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D5E100" offset=".1213" />
            <stop stopColor="#79C80E" offset=".9881" />
          </linearGradient>
          <path
            d="m71.4 95.5c-1.81 0-3.28-1.35-3.28-3.02v-43.26c0-1.67 1.47-3.02 3.28-3.02s3.28 1.35 3.28 3.02v43.25c0.01 1.68-1.46 3.03-3.28 3.03z"
            fill={fill ?? "url(#img-icon-6)"}
          />
          <linearGradient
            id="img-icon-7"
            x1="89.47"
            x2="89.47"
            y1="41.274"
            y2="108.78"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D5E100" offset=".1213" />
            <stop stopColor="#79C80E" offset=".9881" />
          </linearGradient>
          <path
            d="m89.47 95.5c-1.81 0-3.28-1.35-3.28-3.02v-43.26c0-1.67 1.47-3.02 3.28-3.02s3.28 1.35 3.28 3.02v43.25c0 1.68-1.47 3.03-3.28 3.03z"
            fill={fill ?? "url(#img-icon-7)"}
          />
        </g>
      </svg>
    </SvgIcon>
  );
}
