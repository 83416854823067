import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardActions from "@mui/material/CardActions";
import { visuallyHidden } from "@mui/utils";
import Alert from "@mui/material/Alert";
import { CardActionArea } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ParsedImage } from "api/types";

export default function ImageCard({
  image,
  readonly,
  onClick,
  onDelete,
  isLoading,
}: {
  image: ParsedImage;
  isLoading?: boolean;
  onClick?: (_e: React.MouseEvent<HTMLButtonElement>) => void;
  onDelete?: (_id: string) => void;
  readonly?: boolean;
}) {
  const { t } = useTranslation();
  return (
    <Card
      sx={{
        opacity: isLoading || !image?.id ? 0.5 : 1,
        position: "relative",
        border: (theme) =>
          image.errorMessage
            ? `solid 1px ${theme.palette.error.main}`
            : undefined,
        bgcolor: (theme) =>
          theme.palette.mode === "dark" ? "#000" : undefined,
        display: "flex",
        flexDirection: "column",
        height: 175,
        width: 175,
        minWidth: 175,
      }}
    >
      <CardActionArea sx={{ flex: 1 }} onClick={onClick}>
        <CardMedia
          sx={{ height: "100%", width: "100%" }}
          image={image?.src}
          title={image?.file?.name}
        />
      </CardActionArea>
      {image.errorMessage ? (
        <Alert sx={visuallyHidden} severity="error">
          {image.errorMessage}
        </Alert>
      ) : null}
      {!readonly && image.id ? (
        <CardActions>
          <Button
            sx={{ ml: "auto" }}
            onClick={() => {
              onDelete(image.id);
            }}
            color="error"
          >
            {t("delete")}
          </Button>
        </CardActions>
      ) : null}
    </Card>
  );
}
