import { useCallback } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { ICollectEvent } from "types";
import { collectEventKeys } from "./useCollectEvents";
import { ManageCollectEvent } from "./types";

export function useSaveCollectEvent(org: string) {
  const queryClient = useQueryClient();
  const save = useCallback(
    async (body: Partial<ICollectEvent>) => {
      const method = body.id ? "put" : "post";
      const url = `/${org}/collectevents/manage`;
      const req = await axios[method](url, {
        ...body,
      });

      return req.data;
    },
    [org]
  );

  const mutation = useMutation({
    mutationFn: save,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: collectEventKeys.all(org),
      });
    },
  });
  return { ...mutation };
}

export function useManageCollectEvents(org: string) {
  const getData = useCallback(async () => {
    const url = `/${org}/collectevents/manage`;
    const req = await axios.get<ManageCollectEvent[]>(url);
    return req.data;
  }, [org]);

  return useQuery({
    queryKey: ["organization", org, "collectEvents", "manage"],
    queryFn: getData,
    enabled: !!org,
  });
}

export function useDeleteCollectEvent(org: string) {
  const queryClient = useQueryClient();
  const deleteEvent = useCallback(
    async (id: string) => {
      const url = `/${org}/collectevents/manage/${id}`;
      return axios.delete(url);
    },
    [org]
  );

  const mutation = useMutation({
    mutationFn: deleteEvent,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: collectEventKeys.all(org),
      });
    },
  });
  return { ...mutation };
}
