import { Map } from "mapbox-gl";
import { useCallback, useEffect, useRef } from "react";
import LegendControl from "./LegendControl";

export function useLegendControl({
  title,
  noDataText,
  layers,
  location,
  map,
  theme,
}: {
  title: string;
  noDataText: string;
  layers: unknown[];
  location: unknown;
  map?: Map;
  theme: unknown;
}) {
  const legendCtrlRef = useRef(
    new LegendControl({
      theme,
      title,
      noDataText,
      layers,
      location,
    })
  );
  const handleData = useCallback(() => {
    legendCtrlRef.current.getVisibleFeatures();
  }, []);
  useEffect(() => {
    // NOTE: somewhat concerned with the amount of times this fires
    // but so far performance seems good - see also: https://docs.mapbox.com/mapbox-gl-js/example/cluster-html/
    if (map) {
      map.on("render", handleData);
    }
    return () => {
      if (map) {
        map.off("render", handleData);
      }
    };
  }, [map, handleData]);

  useEffect(() => {
    if (legendCtrlRef.current) {
      legendCtrlRef.current.updateData({ layers });
    }
  }, [layers]);

  return {
    legendCtrl: legendCtrlRef.current,
    getVisibleFeatures: legendCtrlRef.current.getVisibleFeatures,
  };
}
