import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function TilledIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="295.49"
      height="295.5"
      version="1.1"
      viewBox="0 0 295.49 295.5"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="m209.87 151.37a5.75 5.75 0 0 0-3.55-3.8 40.4 40.4 0 0 0-7.62-1.78c18.88-20.09 12.65-43.56 11.17-48.14a5.77 5.77 0 0 0-3.55-3.8 49.69 49.69 0 0 0-31 0.33 51.83 51.83 0 0 0 1.44-12.33c0-28.78-21.79-41.43-26.17-43.68a5.77 5.77 0 0 0-5.2-0.17c-1.08 0.5-26.51 12.67-26.51 43.85a52 52 0 0 0 1.45 12.35 49.68 49.68 0 0 0-31.11-0.35 5.77 5.77 0 0 0-3.55 3.8c-1.48 4.58-7.71 28 11.17 48.14a40.4 40.4 0 0 0-7.62 1.78 5.75 5.75 0 0 0-3.55 3.8c-1.51 4.68-8 29 12.38 49.39a47.88 47.88 0 0 0 34.64 14.58 51.6 51.6 0 0 0 9.29-0.84v37.76a5.77 5.77 0 1 0 11.54 0v-37.76a51.2 51.2 0 0 0 9.37 0.86 47.9 47.9 0 0 0 34.61-14.6c20.35-20.36 13.88-44.71 12.37-49.39zm-67.93 45.13a38.68 38.68 0 0 1-0.85 6.4 38.08 38.08 0 0 1-45.21-45.24 38.74 38.74 0 0 1 19.83 0.94c0.55 0.21 1.1 0.4 1.65 0.58a38.12 38.12 0 0 1 13.41 8.8 36.8 36.8 0 0 1 11.17 24.67zm0-53.72a39 39 0 0 1-0.85 6.41 38.35 38.35 0 0 1-19.43-0.78c-0.83-0.32-1.66-0.61-2.49-0.88a38.12 38.12 0 0 1-13-8.66 37.22 37.22 0 0 1-10.29-34.92 37.43 37.43 0 0 1 34.89 10.32l0.24 0.25c0.51 0.57 1 1.11 1.52 1.63a36.6 36.6 0 0 1 9.41 22.78zm5.85-29a39.38 39.38 0 0 1-7.06-5.83c-0.27-0.3-0.55-0.59-0.83-0.89a36.83 36.83 0 0 1-9.52-25.26 37.24 37.24 0 0 1 17.37-32 37.2 37.2 0 0 1 17.43 32 36.79 36.79 0 0 1-8.68 24.34c-0.93 0.94-1.81 1.88-2.63 2.83a37.92 37.92 0 0 1-6.08 4.86zm5.71 27.14a36.49 36.49 0 0 1 8.92-24.16q1.27-1.27 2.55-2.73a37.23 37.23 0 0 1 34.63-10.08 37.22 37.22 0 0 1-10.29 34.93 38.07 38.07 0 0 1-13 8.65c-0.84 0.27-1.68 0.57-2.53 0.9a38.47 38.47 0 0 1-19.41 0.77 38.23 38.23 0 0 1-0.89-8.13zm35.81 51.67a37.27 37.27 0 0 1-34.91 10.33 37.46 37.46 0 0 1 10.31-34.93 38.23 38.23 0 0 1 13-8.67c0.82-0.26 1.64-0.55 2.48-0.87a38.34 38.34 0 0 1 19.39-0.79 37.21 37.21 0 0 1-10.25 34.93z" />
      <path d="m178.5 3.22a148.26 148.26 0 0 0-30.79-3.22 147.75 147.75 0 0 0-116.49 238.58 21.89 21.89 0 1 0 8.61-7.74 136.42 136.42 0 0 1-28.33-83.09c0-75.1 61.15-136.25 136.25-136.25a137 137 0 0 1 28.4 3 5.77 5.77 0 0 0 2.35-11.28zm-136.18 239.89a10.39 10.39 0 1 1-3 7.34 10.32 10.32 0 0 1 3-7.34z" />
      <path d="m264.28 56.92a21.89 21.89 0 1 0-8.61 7.74 136.42 136.42 0 0 1 28.29 83.09c0 75.1-61.11 136.21-136.21 136.21a138.05 138.05 0 0 1-24.06-2.12 5.77 5.77 0 0 0-2 11.36 148.61 148.61 0 0 0 26.09 2.3 147.75 147.75 0 0 0 116.5-238.58zm-11.1-4.53a10.37 10.37 0 0 1-14.69 0 10.39 10.39 0 1 1 14.69 0z" />
      <path d="m97.037 274.2-2.18 5.35 2.16-5.35a5.77 5.77 0 0 0-4.32 10.7l0.17 0.07a5.81 5.81 0 0 0 7.48-3.32 5.76 5.76 0 0 0-3.31-7.45z" />
      <path d="m203.05 10.7h-0.1a5.7733 5.7733 0 0 0-4.29 10.72h0.07a5.7 5.7 0 0 0 2.16 0.43 5.78 5.78 0 0 0 2.16-11.13z" />
    </SvgIcon>
  );
}
