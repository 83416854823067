import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { ConfirmationDialog } from "lib";
import { useOrgState } from "providers/OrgProvider";
import { FormWrapper } from "components";
import { useInventoryDispatch, useInventoryState } from "providers";
import {
  AssetStatusIDFormField,
  BornOnUTCFormField,
  CIDFormField,
  DescriptionFormField,
  FieldFormField,
} from "./FormFields";
import { IMapboxDrawControls, IUrlParams } from "types";
import { IFixedAsset, IFixedAssetType } from "types/IAssetType";
import Autocomplete from "lib/Select/Autocomplete";
import { useAssetFixedTypes } from "api/assets/useAssetFixedTypes";
import { useAssetGeodataById } from "api/assets/useAssetGeodataById";
import { useAssetMutation } from "api/assets/useAssetMutation";
import { useAssetStatuses } from "api/assets/useAssetStatuses";
import { FeatureCollection } from "geojson";

const defaultValues = {
  id: "",
  fieldId: "",
  cid: "",
  name: "",
  fixedAssetTypeId: "",
  bornOnUtc: "",
  assetStatusId: "",
  description: "",
  geometry: "",
} as IFixedAsset;

function getDrawOpts(geom: "point" | "line" | "polygon") {
  const opts = { trash: true } as IMapboxDrawControls;
  let mode = "simple_select";
  if (geom === "point") {
    opts.point = true;
    mode = "draw_point";
  }
  if (geom === "line") {
    opts.line_string = true;
    mode = "draw_line_string";
  }
  if (geom === "polygon") {
    opts.polygon = true;
    mode = "draw_polygon";
  }
  return { controls: opts, mode } as {
    controls: unknown;
    mode: string;
    features: unknown[];
  };
}
export default function FixedAssetForm({
  cancelHref,
  onDelete,
  onSave,
}: {
  onSave?: (_r: FeatureCollection) => void;
  onDelete?: () => void;
  cancelHref: string;
}) {
  const { org, season } = useOrgState();
  const { itemId } = useParams<IUrlParams>();
  const { t } = useTranslation();
  const confirmRef = useRef();
  const { deleteMutation, saveMutation } = useAssetMutation({
    org,
    type: "fixed",
  });
  const statusesQ = useAssetStatuses(org);
  const geodataQ = useAssetGeodataById({
    type: "fixed",
    org,
    seasonId: season?.id,
    id: itemId,
  });
  const currentEditFtr = geodataQ.data?.features[0];
  const fixedTypesQ = useAssetFixedTypes(org);
  const { drawData } = useInventoryState();
  const { setDrawOptions } = useInventoryDispatch();
  const methods = useForm({
    defaultValues,
  });
  const [geomType, setGeomType] = useState<"point" | "line" | "polygon">();
  const [showConfirmGeomChange, setShowConfirmGeomChange] = useState<
    IFixedAssetType
  >(null);
  const {
    handleSubmit,
    register,
    reset,
    watch,
    control,
    errors,
    setValue,
  } = methods;
  const { id, fixedAssetTypeId, name: assetName } = watch();

  // when fixedAssetTypeId changes, update the draw options
  // based on the asset type's geometry type
  useEffect(() => {
    const fixedAssetType = fixedTypesQ.data?.find(
      (i) => i.id === fixedAssetTypeId
    );
    const opts = getDrawOpts(fixedAssetType?.geometryType);
    setDrawOptions(opts);
  }, [setDrawOptions, fixedAssetTypeId, fixedTypesQ.data]);
  // set the geometry type for edit feature
  // to handle changes
  useEffect(() => {
    if (currentEditFtr) {
      let type: "point" | "line" | "polygon";
      if (currentEditFtr.geometry.type.includes("Point")) {
        type = "point";
      }
      if (currentEditFtr.geometry.type.includes("LineString")) {
        type = "line";
      }
      if (currentEditFtr.geometry.type.includes("Polygon")) {
        type = "polygon";
      }
      setGeomType(type);
    }
  }, [currentEditFtr]);

  async function handleSave(d: IFixedAsset) {
    const data = { ...d, geometry: d.geometry ? JSON.parse(d.geometry) : "" };
    // server doesn't want these props if not set
    if (!data.id) {
      delete data.id;
    }
    if (!data.fieldId) {
      delete data.fieldId;
    }
    return saveMutation.mutate(data, {
      onSuccess: (res) => {
        reset(data);
        onSave(res.data);
      },
    });
  }
  return (
    <FormWrapper
      //TODO:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      methods={methods}
      data={{ ...currentEditFtr?.properties }}
      geometryData={drawData?.features[0]?.geometry}
      existingGeom={currentEditFtr?.geometry}
      cancelHref={cancelHref}
      saveState={{
        isLoading: saveMutation.isLoading,
        isError: saveMutation.isError,
        errorMessage: saveMutation?.error as string,
      }}
      deleteState={{
        isLoading: deleteMutation.isLoading,
        isError: deleteMutation.isError,
        errorMessage: deleteMutation?.error as string,
      }}
      onDelete={() => {
        return deleteMutation.mutate(id, {
          onSuccess: () => {
            reset();
            onDelete();
          },
        });
      }}
      onSubmit={handleSubmit(handleSave)}
    >
      <Stack spacing={2}>
        <Controller
          name="fixedAssetTypeId"
          control={control}
          rules={{ required: true }}
          render={(props: {
            value: string;
            onChange: (_id: string) => void;
          }) => (
            <Box>
              <Autocomplete
                error={!!errors.fixedAssetTypeId}
                label={`${t("common.type")} *`}
                options={fixedTypesQ?.data ?? []}
                value={
                  fixedTypesQ?.data?.find((ct) => ct.id === props.value) ?? null
                }
                onChange={(_e, item: IFixedAssetType) => {
                  const geomChanged = geomType !== item?.geometryType;
                  if (geomChanged && itemId) {
                    // CONFIRM CHANGE FIRST
                    setShowConfirmGeomChange(item);
                  } else {
                    // UPDATE DRAW TOOLS
                    const opts = getDrawOpts(item.geometryType);
                    if (geomChanged) {
                      opts.features = null;
                    }
                    props.onChange(item?.id || "");
                    setGeomType(item?.geometryType);
                    setDrawOptions(opts);
                  }
                }}
                disableClearable={false}
              />
              <Box ref={confirmRef} />
            </Box>
          )}
        />

        <FieldFormField control={control} />
        <TextField
          fullWidth
          required
          label={`${t("common.name")}`}
          value={assetName ?? ""}
          inputProps={{
            name: "name",
            ref: register,
            maxLength: 50,
          }}
        />
        <CIDFormField register={register} />
        <BornOnUTCFormField
          label={`${t("inventory.assets.createdDate")}`}
          control={control}
        />
        <AssetStatusIDFormField
          control={control}
          assetStatusOpts={statusesQ.data}
          isError={Boolean(errors?.assetStatusId)}
        />
        <DescriptionFormField register={register} />
        {errors?.geometry ? (
          <Typography color="error.main">
            {t("common.geometryRequired")}
          </Typography>
        ) : null}
      </Stack>
      <ConfirmationDialog
        open={Boolean(showConfirmGeomChange)}
        message={<>{t("inventory.assets.confirmTypeChange")}</>}
        onClose={(confirmed) => {
          if (confirmed) {
            setShowConfirmGeomChange(null);
            const opts = getDrawOpts(showConfirmGeomChange.geometryType);
            opts.features = null;
            setValue("fixedAssetTypeId", showConfirmGeomChange?.id || "");
            setGeomType(showConfirmGeomChange?.geometryType);
            setDrawOptions(opts);
          } else {
            setShowConfirmGeomChange(null);
          }
        }}
      />
    </FormWrapper>
  );
}
