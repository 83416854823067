const fieldKeys = {
  allBySeason: (org: string, seasonId: string) =>
    ["organization", org, "fields", "season", seasonId] as const,
  summary: (org: string) => ["organization", org, "fields", "summary"] as const,
  summaryBySeason: (org: string, seasonId: string) =>
    ["organization", org, "fields", "summary", "season", seasonId] as const,
  mapThemes: (org: string, seasonId: string) =>
    ["organization", org, "fields", "season", seasonId, "mapthemes"] as const,
};

export default fieldKeys;
