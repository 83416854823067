import Typography from "@mui/material/Typography";

export function SectionHeading({ children }: { children: React.ReactNode }) {
  return (
    <Typography
      sx={{ mb: 2 }}
      style={{ borderBottom: "solid 1px" }}
      component={"h3"}
      variant="h6"
    >
      {children}
    </Typography>
  );
}
