import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { OperationPlantedOrAppliedMapTheme } from "./types/OperationPlantedOrAppliedMapTheme";
import { addAreaPercent, parseOperationData } from "./parseData";
import operationKeys from "./operationKeys";

export function useOperationPlantedMapThemes({
  org,
  seasonId,
  enabled,
}: {
  org: string;
  seasonId: string;
  enabled?: boolean;
}) {
  const fetchMapThemes = useCallback(async () => {
    const res = await axios.get<OperationPlantedOrAppliedMapTheme[]>(
      `/${org}/operations/planted/season/${seasonId}/mapthemes`
    );
    const items = addAreaPercent(res.data);
    return {
      raw: items,
      rollup: {
        crop: parseOperationData({
          dataProp: "crop",
          colorProp: "cropColor",
          data: items,
        }),
        product: parseOperationData({
          dataProp: "product",
          colorProp: "productColor",
          data: items,
        }),
      },
    };
  }, [org, seasonId]);

  return useQuery({
    queryKey: operationKeys.mapThemes(org, "planted", seasonId),
    queryFn: fetchMapThemes,
    enabled: !!(enabled !== false) && !!seasonId,
    staleTime: 10 * (60 * 1000), // 10 mins
  });
}
