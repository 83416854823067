import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useObservationPhotos } from "api/observations/useObservationPhotos";
import { useParams } from "react-router-dom";
import { IUrlParams } from "types";
import { useObservationPhotoPost } from "api/observations/useObservationPhotoPost";
import { useObservationPhotoDelete } from "api/observations/useObservationPhotoDelete";
import ImageStack from "components/ImageStack";
import { useTranslation } from "react-i18next";

function ObservationPhotosStack() {
  const { org, observationId } = useParams<IUrlParams>();
  const photosQ = useObservationPhotos({ org, observationId });
  const savePhotoQ = useObservationPhotoPost({ org, observationId });
  const deleteMutation = useObservationPhotoDelete({
    org,
    observationId,
  });
  const { t } = useTranslation();
  return (
    <Box>
      <Typography component="h6">{t("common.photos")} (Max 4)</Typography>
      <ImageStack
        deletePhotoQ={deleteMutation}
        savePhotoQ={savePhotoQ}
        photosQ={photosQ}
      />
    </Box>
  );
}

export default ObservationPhotosStack;
