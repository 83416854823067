import React from "react";

const SidebarStateContext = React.createContext<{
  activeKey?: string;
  setActiveKey?: (_key: string) => void;
  open: boolean;
  setOpen?: (_open: boolean) => void;
}>({ open: false });

export default SidebarStateContext;
