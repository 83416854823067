import { useCallback, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Observation } from "types/Observation";
import { sortObjArr } from "lib";
import observationKeys from "./observationKeys";

export function useObservationsDisplay({
  org,
  startDateUtc,
  endDateUtc,
  template,
  fieldNames,
}: {
  org: string;
  startDateUtc?: string;
  endDateUtc?: string;
  template?: string;
  fieldNames?: string;
}) {
  const fetchObservationsFn = useCallback(async () => {
    const res = await axios.get<Observation[]>(
      `/${org}/observations/display?startDateUtc=${startDateUtc}&endDateUtc=${endDateUtc}`
    );
    res.data.forEach((d) => {
      d.observedOnDateFormatted = d.observedOnUtc
        ? new Date(d.observedOnUtc).toLocaleDateString()
        : null;
    });
    return sortObjArr(res.data, "label") as Observation[];
  }, [org, startDateUtc, endDateUtc]);

  const query = useQuery({
    queryKey: observationKeys.byDate(org, startDateUtc, endDateUtc),
    queryFn: fetchObservationsFn,
    enabled: !!org && !!startDateUtc && !!endDateUtc,
  });
  const templates = useMemo(() => {
    const uniq = new Set(query.data?.map((o) => o.templateId));
    const uniq2 = Array.from(uniq).map((t) => {
      const i = query.data.find((d) => d.templateId === t);
      return {
        id: i.templateId,
        label: i.template,
      };
    });
    const temps = sortObjArr(uniq2, "label") as {
      id: string;
      label: string;
    }[];
    return temps;
  }, [query.data]);
  const currentTemplate = template ?? templates?.[0]?.label;

  const fields = useMemo(() => {
    return sortObjArr(
      Array.from(new Set(query.data?.map((o) => o.field)))
        .map((t) => {
          const d = query.data.find(
            (d) => d.field === t && d.template === currentTemplate
          );
          if (!d) {
            return null;
          }
          return {
            id: d.fieldId,
            label: d.field,
          };
        })
        .filter(Boolean),
      "field"
    ) as { id: string; label: string }[];
  }, [currentTemplate, query.data]);

  const observationsByTemplateAndField = useMemo(() => {
    return query.data?.filter(
      (d) =>
        // if no template is passed in, we filter by the first available by default
        d.template === currentTemplate &&
        (fieldNames?.length ? fieldNames.includes(d.field) : true)
    );
  }, [currentTemplate, fieldNames, query.data]);

  return {
    ...query,
    templates,
    fields,
    observationsByTemplateAndField,
  };
}
