import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Feature } from "geojson";
import { ProductLookup } from "./types";

export function useOperationPlantedBoundaries({
  org,
  products,
  enabled,
}: {
  org: string;
  products?: ProductLookup[];
  enabled?: boolean;
}) {
  const fetchData = useCallback(async () => {
    const promises = products.map((prod) =>
      axios.get(`/${org}/operations/planted/geodata/${prod.id}/boundary`)
    );
    const results = await Promise.all(promises);
    const ftrs = results.map((res, i) => {
      const prod = products[i];
      const ft: Feature = {
        type: "Feature",
        properties: {
          id: prod.id,
          color: prod.color,
        },
        geometry: res.data,
      };
      return ft;
    });
    return ftrs;
  }, [products, org]);

  return useQuery<Feature[], Error>({
    queryKey: ["organization", org, "operations", "planted", "ids", products],
    queryFn: fetchData,
    enabled: !!(enabled !== false && org && products),
    staleTime: 10 * (60 * 1000), // 10 mins
  });
}
