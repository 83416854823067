import { useParams, useLocation } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useURLSearchParams } from "components";
import { useOrgState } from "providers/OrgProvider";
import { IUrlParams } from "types";
import { useAssetsSeasonSummary } from "api/assets/useAssetSummaries";
import { useFieldData } from "../useFieldData";

export default function AssetsBreadcrumbs() {
  const { t } = useTranslation();
  const location = useLocation();
  const query = useURLSearchParams();
  const isAdd =
    location.pathname.includes("/add") &&
    !location.pathname.includes("/records");
  const isEdit =
    location.pathname.includes("/edit") &&
    !location.pathname.includes("/records");
  const { org, season, rootUrl } = useOrgState();

  const { subtype, fieldId, itemId, type } = useParams<IUrlParams>();
  const groupId = query.get("groupId");
  const assetsBySeasonQuery = useAssetsSeasonSummary(org, season?.id);
  const fieldQ = useFieldData({
    type,
    subtypeName: subtype,
    org,
    seasonId: season?.id,
    fieldId,
  });
  const fieldName = fieldQ.data?.field;
  const currentAssetType = assetsBySeasonQuery?.data?.find(
    (o) => o.assetEn?.toLowerCase() === type
  );

  return (
    <Breadcrumbs
      sx={{ display: "flex", textTransform: "uppercase" }}
      aria-label="operation-details-breadcrumb"
    >
      {!subtype && !isAdd ? (
        <Typography component="h3" variant="h5" color="text.primary">
          {currentAssetType?.asset} {t("inventory.assets.title")}
        </Typography>
      ) : (
        <Link underline="hover" href={`${rootUrl}/inventory/assets/${type}`}>
          {currentAssetType?.asset} {t("inventory.assets.title")}
        </Link>
      )}
      {isAdd ? (
        <Typography color="text.primary">{t("common.add")}</Typography>
      ) : null}
      {subtype && (fieldName || isEdit) ? (
        <Link
          underline="hover"
          href={`${rootUrl}/inventory/assets/${type}/${subtype}`}
        >
          {decodeURIComponent(subtype)}
        </Link>
      ) : subtype ? (
        <Typography color="text.primary">
          {decodeURIComponent(subtype)}
        </Typography>
      ) : null}
      {fieldName && itemId ? (
        <Link
          underline="hover"
          title={fieldName}
          href={`${rootUrl}/inventory/assets/${type}/${subtype}/${fieldId}/items${
            groupId ? `?groupId=${groupId}` : ""
          }`}
        >
          <span className="ellipsis-overflow">{fieldName}</span>
        </Link>
      ) : fieldName && !itemId ? (
        <Typography color="text.primary">
          <span className="ellipsis-overflow">{fieldName}</span>
        </Typography>
      ) : fieldName ? (
        <Typography>
          <span className="ellipsis-overflow">{fieldName}</span>
        </Typography>
      ) : null}
      {fieldName && itemId && isEdit ? (
        <Link
          underline="hover"
          href={`${rootUrl}/inventory/assets/${type}/${subtype}/${fieldId}/items/${itemId}${
            groupId ? `?groupId=${groupId}` : ""
          }`}
        >
          {t("common.details")}
        </Link>
      ) : fieldName && itemId ? (
        <Typography color="text.primary">{t("common.details")}</Typography>
      ) : null}
      {isEdit ? (
        <Typography color="text.primary">{t("common.edit")}</Typography>
      ) : null}
    </Breadcrumbs>
  );
}
