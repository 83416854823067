import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import { useOrgState } from "providers";
import OperationBreadcrumbs from "./OperationBreadcrumbs";
import { EditIconButton } from "components/EditIconButton";
import { useOperationDetails } from "api/operations/useOperationDetails";
import { NutrientDetails } from "./NutrientDetails";
import { MixDetails } from "./MixDetails";
import BaseCardView from "lib/Card/BaseCardView";
import { useTranslation } from "react-i18next";
import SummaryCardTitle from "lib/Card/SummaryCardTitle";
import SummaryCardValue from "lib/Card/SummaryCardValue";
import { OperationApplied } from "api/operations/types/OperationApplied";

export default function AppliedOperationDetails() {
  const { t } = useTranslation();
  const { org, rootUrl } = useOrgState();
  const { itemId } = useParams<{ itemId: string }>();

  const detailsQ = useOperationDetails<OperationApplied>({
    operationId: itemId,
    type: "applied",
    org,
  });

  return (
    <Stack spacing={2} sx={{ p: 3 }}>
      <Stack direction={"row"}>
        <OperationBreadcrumbs type="applied" />
        {itemId ? (
          <EditIconButton
            sx={{ ml: "auto" }}
            href={`${rootUrl}/inventory/operations/applied/items/${itemId}/edit`}
          />
        ) : null}
      </Stack>
      <Paper
        elevation={4}
        sx={{ bgcolor: "background.paper", p: 3, width: "100%" }}
      >
        <Stack direction={"row"}>
          <Typography
            sx={{
              mb: 2,
              textTransform: "uppercase",
              fontSize: 14,
            }}
          >
            {t("inventory.operations.operationDetails")}
          </Typography>
          <Typography
            sx={{
              ml: "auto",
            }}
          >
            {`${
              detailsQ.data?.beginDateUtc
                ? new Date(detailsQ.data.beginDateUtc).toLocaleDateString()
                : "--"
            } - ${
              detailsQ.data?.endDateUtc
                ? new Date(detailsQ.data.endDateUtc).toLocaleDateString()
                : "--"
            }`}
          </Typography>
        </Stack>
        <Stack spacing={2} direction={"row"}>
          <BaseCardView variant="outlined" sx={{ bgcolor: "background.paper" }}>
            <Stack>
              <SummaryCardTitle>{t("common.product")}</SummaryCardTitle>
              <SummaryCardValue>{detailsQ.data?.product}</SummaryCardValue>
            </Stack>
          </BaseCardView>
          <BaseCardView variant="outlined" sx={{ bgcolor: "background.paper" }}>
            <Stack>
              <SummaryCardTitle>{t("common.rate")}</SummaryCardTitle>
              <SummaryCardValue>
                {detailsQ.data
                  ? `${detailsQ.data.rate} ${detailsQ.data.rateUom}`
                  : "--"}
              </SummaryCardValue>
            </Stack>
          </BaseCardView>
          <BaseCardView variant="outlined" sx={{ bgcolor: "background.paper" }}>
            <Stack>
              <SummaryCardTitle>{t("common.area")}</SummaryCardTitle>
              <SummaryCardValue>
                {detailsQ.data
                  ? `${detailsQ.data.area ?? "--"} ${
                      detailsQ.data.areaUom ?? ""
                    }`
                  : "--"}
              </SummaryCardValue>
            </Stack>
          </BaseCardView>
          <BaseCardView variant="outlined" sx={{ bgcolor: "background.paper" }}>
            <Stack>
              <SummaryCardTitle>
                {t("inventory.operations.mappedArea")}
              </SummaryCardTitle>
              <SummaryCardValue>
                {detailsQ.data
                  ? `${detailsQ.data.geometryArea ?? "--"} ${
                      detailsQ.data.areaUom ?? ""
                    }`
                  : "--"}
              </SummaryCardValue>
            </Stack>
          </BaseCardView>
        </Stack>
      </Paper>
      {detailsQ.data ? (
        <Stack spacing={2}>
          {detailsQ.data.productMix ? (
            <Paper
              sx={{
                bgcolor: "background.paper",
                p: 3,
              }}
              elevation={4}
            >
              <Typography
                sx={{
                  textTransform: "uppercase",
                  fontSize: 14,
                  mb: 2,
                }}
              >
                {t("inventory.operations.mixDetails")}
              </Typography>
              <MixDetails data={detailsQ.data?.productMix} />
            </Paper>
          ) : null}
          {detailsQ.data.productNutrients ? (
            <Paper
              sx={{
                bgcolor: "background.paper",
                p: 3,
              }}
              elevation={4}
            >
              <Typography
                sx={{
                  textTransform: "uppercase",
                  fontSize: 14,
                  mb: 2,
                }}
              >
                {t("inventory.operations.nutrientDetails")}
              </Typography>
              <NutrientDetails data={detailsQ.data.productNutrients} />
            </Paper>
          ) : null}
        </Stack>
      ) : null}
    </Stack>
  );
}
