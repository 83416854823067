import { useCallback } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Zone } from "views/ImageryView/types";
import axios from "axios";
import { blobToImage } from "lib";

interface PostData {
  tenant: string;
  organization: string;
  collectEventId: string;
  rasterType: string;
  geometry: string;
  classes: Zone[];
  sensorType: string;
  format: "png" | "tif";
}
const url = `${process.env.REACT_APP_TILESERVER_ORIGIN}/cog/rx`;

export function useCreateRX({
  enabled,
  ...d
}: Partial<PostData & { enabled?: boolean }>) {
  const getData = useCallback(async () => {
    if (!enabled) {
      return null;
    }
    const res = await axios.post<Blob>(url, d, {
      baseURL: null,
      responseType: "blob",
    });
    const img = await blobToImage(res.data);
    const coordinates = JSON.parse(res.headers["x-png-bounds"]);
    const source = {
      type: "image",
      url: img.src,
      coordinates,
    };

    return source;
  }, [d, enabled]);

  const downloadTif = useCallback(async (data: PostData) => {
    const res = await axios.post<Blob>(url, data, {
      baseURL: null,
      responseType: "blob",
    });
    return res.data;
  }, []);

  const tifDownloadMutation = useMutation({
    mutationFn: downloadTif,
  });

  const displayQuery = useQuery({
    queryKey: ["imagery", "rx", "create", d, enabled],
    staleTime: Infinity,
    queryFn: getData,
    enabled: !!(
      d.classes &&
      d.collectEventId &&
      d.format === "png" &&
      d.geometry &&
      d.organization &&
      d.rasterType &&
      d.sensorType &&
      d.tenant
    ),
  });

  return {
    displayQuery,
    downloadMutation: tifDownloadMutation,
  };
}
