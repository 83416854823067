import { useTranslation } from "react-i18next";
import { Grid } from "components/layout/Grid";
import Typography from "@mui/material/Typography";

export function FeatureLockedView({ title }: { title: string }) {
  const { t } = useTranslation();
  return (
    <>
      {title ? <h2>{title}</h2> : null}
      <Grid container>
        <Grid>
          <Typography sx={{ mt: 3 }} style={{ textTransform: "uppercase" }}>
            {t("common.featureLocked")}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
