import { useCallback } from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";

const tileServerOrigin = process.env.REACT_APP_TILESERVER_ORIGIN;

interface RasterLegendFetchParams {
  org: string;
  tenant: string;
  types: string[];
}

export interface RasterLegendItem {
  color: string;
  label: string;
}

const rasterKeys = {
  byType: (p: RasterLegendFetchParams) => [
    "tenant",
    p.tenant,
    "organization",
    p.org,
    "rasterlegends",
    p.types,
  ],
};

export function useRasterLegends({
  org,
  tenant,
  types,
}: RasterLegendFetchParams) {
  const getData = useCallback(async () => {
    const items = types.map((type) => ({
      title: type,
      promise: axios.get<RasterLegendItem[]>(
        `/cog/noauth/${tenant}/${org}/${type}/legend`,
        { baseURL: tileServerOrigin }
      ),
    }));
    const responses = await Promise.all(items.map((p) => p.promise));
    return responses.map((s, i) => {
      return {
        title: items[i].title,
        data: s.data,
      };
    });
  }, [org, tenant, types]);

  return useQuery<{ title: string; data: RasterLegendItem[] }[]>({
    queryKey: rasterKeys.byType({ org, tenant, types }),
    queryFn: getData,
    enabled: !!org && !!tenant && !!types?.length,
    // this data shouldnt update often, so delay re-fetch
    staleTime: 10 * (60 * 1000), // 10 mins
  });
}
