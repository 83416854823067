import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { ProductLookup } from "./types";
import { Feature } from "geojson";

export function useOperationAppliedBoundary({
  org,
  appliedOperation,
  enabled,
}: {
  org: string;
  appliedOperation?: ProductLookup;
  enabled?: boolean;
}) {
  const fetchData = useCallback(async () => {
    const res = await axios.get(
      `/${org}/operations/applied/geodata/${appliedOperation.id}/boundary`
    );
    const ft: Feature = {
      type: "Feature",
      properties: {
        id: appliedOperation.id,
        color: appliedOperation.color,
      },
      geometry: res.data,
    };
    return ft;
  }, [org, appliedOperation]);

  return useQuery<Feature, Error>({
    queryKey: [
      "organization",
      org,
      "operations",
      "applied",
      "geodata",
      appliedOperation?.id,
    ],
    queryFn: fetchData,
    enabled: !!(enabled !== false && org && appliedOperation?.id),
    staleTime: 10 * (60 * 1000), // 10 mins
  });
}
