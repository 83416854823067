import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import MapThemeItem from "components/MapThemeItem";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useOrgState } from "providers";
import { useInventoryMapThemes } from "api/useInventoryMapThemes";
import AddIcon from "@mui/icons-material/Add";

export default function MapThemes({ themeType, onThemeTypeChange }) {
  const { t } = useTranslation();
  const { org, season } = useOrgState();
  const mapThemesQ = useInventoryMapThemes(org, "fields", season?.id);
  const themeData = mapThemesQ.data?.rollup;
  return (
    <Grid container mx={2}>
      {themeData && themeData.length ? (
        <MapThemeItem
          id="landAgreementType"
          title={t("inventory.fields.landAgreement")}
          isActive={themeType === "landAgreementType"}
          onClick={() => {
            onThemeTypeChange("landAgreementType");
          }}
          data={themeData}
        />
      ) : null}
      {themeData && !themeData.length ? (
        <Stack
          sx={{
            p: 3,
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
          spacing={2}
        >
          <Button
            endIcon={<AddIcon />}
            variant="outlined"
            color="success"
            href="fields/add"
            sx={{
              ":hover": {
                color: "success.main",
              },
            }}
          >
            {t("common.add")}
          </Button>
          <Typography>
            {t("inventory.fields.addFieldsToSeeMapThemes")}
          </Typography>
        </Stack>
      ) : null}
      {!themeData ? (
        <MapThemeItem
          id="skeleton"
          title={t("inventory.fields.landAgreement")}
          isActive={false}
          data={[{ percent: 100, name: "" }]}
          showTooltip={false}
        />
      ) : null}
    </Grid>
  );
}
MapThemes.propTypes = {
  themeType: PropTypes.oneOf(["landAgreementType", "cropType"]),
  onThemeTypeChange: PropTypes.func.isRequired,
  themeData: PropTypes.arrayOf(PropTypes.shape({})),
};
