import SvgIcon from "@mui/material/SvgIcon";

export default function DownloadCSVIcon(props) {
  return (
    <SvgIcon fontSize={"large"} viewBox="0 0 431 416" {...props}>
      <path d="M187.7,324.55V298.91h3.11c13.53,0,27.05.1,40.57-.1a11.15,11.15,0,0,0,6.22-2.27c1.92-1.48,2.12-4,1.17-6.39a6.11,6.11,0,0,0-5.8-4c-3.88-.12-7.78-.05-11.67-.07-16.56-.06-29.33-10-33-25.55-4.32-18.61,9.54-37.93,28.63-38.82,15.62-.73,31.29-.28,46.94-.34a6.09,6.09,0,0,1,1.18.26V247.2h-3l-40.29,0a16.08,16.08,0,0,0-3,.21,6.23,6.23,0,0,0-5.22,6.53,6.12,6.12,0,0,0,5.83,6c4.25.23,8.52.05,12.78.15,13,.31,23.06,5.82,29.23,17.39,11.27,21.12-3.2,46.06-27.68,47.17-15,.68-30,.15-45,.16A5.65,5.65,0,0,1,187.7,324.55Z" />
      <path d="M174.56,221.54v25.67h-2.95c-11.38,0-22.77,0-34.16,0-9.25,0-14.47,5.19-14.49,14.4,0,7.77,0,15.55,0,23.32,0,8.69,5.33,14,14.05,14,12.4,0,24.8,0,37.44,0v25.56a1.62,1.62,0,0,1-.65.29c-13.23,0-26.5.51-39.7-.18-20.91-1.1-35.8-16.22-36.94-37.14a255.09,255.09,0,0,1,0-28.87C98.42,237,114,222.11,135.89,221.39c12.39-.41,24.81-.08,37.21-.08A10.33,10.33,0,0,1,174.56,221.54Z" />
      <path d="M316.82,271.45c2-8.23,3.86-15.76,5.72-23.29,2-8.26,4.09-16.51,6.08-24.79.32-1.33.71-2.11,2.3-2.1,7.88.06,15.75,0,23.62,0a6.06,6.06,0,0,1,1.14.33c-8.66,34.31-17.32,68.6-26.06,103.21h-3.75c-6.67,0-13.34,0-20,0-1.39,0-2.38-.05-2.82-1.82q-12.18-50.13-24.5-100.23c-.08-.35-.12-.7-.26-1.51h4.48c6.12,0,12.24.09,18.34-.09,2.31-.07,3.16.67,3.7,2.88,3.66,15,7.47,30,11.23,44.94C316.17,269.57,316.37,270.08,316.82,271.45Z" />
      <path d="M229.26,25c2,.88,4.1,1.49,5.83,2.69a12.7,12.7,0,0,1,5.49,11q0,23.27,0,46.54,0,27.26,0,54.5v2c.84-.77,1.4-1.25,1.91-1.76,7.57-7.56,15.05-15.19,22.71-22.65a13.33,13.33,0,0,1,22.51,6.29c1.22,5.1-.34,9.48-4,13.17Q266.36,154,249.12,171.32c-4,4-7.89,7.91-11.86,11.85-6.1,6-14,6.12-20.08.1Q193.8,160,170.49,136.57c-5.59-5.61-5.68-14-.33-19.33s13.7-5.2,19.33.39q11.16,11.07,22.23,22.24a20.54,20.54,0,0,1,1.45,1.76l.58-.24V139c0-32.35.14-64.71-.1-97.06-.07-8.45,2.64-14.34,11-17Z" />
      <path d="M76,373.92c.4-1,.83-2,1.2-3,2.88-7.84,10-12.74,18.81-12.76q38.56,0,77.14,0H363.27c14.1,0,18.74,3.21,23.73,16.41v6.07c-.68,1.86-1.2,3.8-2.06,5.58-2.92,6-8.08,9.18-14.34,10.82H92.4c-5.27-1.42-10-3.76-13-8.55A65.66,65.66,0,0,1,76,381.21Z" />
    </SvgIcon>
  );
}
