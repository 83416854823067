import Alert from "@mui/material/Alert";
import { IFetchResponse } from "types";
import Box from "@mui/material/Box";

export default function ApiStatus({
  saveState,
  deleteState,
}: {
  saveState: IFetchResponse;
  deleteState: IFetchResponse;
}) {
  return (
    <>
      {saveState?.isError ? (
        <Box sx={{ mt: 3 }}>
          <Alert severity="error">
            {saveState.errorMessage ||
              "Unable to save. Please try again or contact an administrator."}
          </Alert>
        </Box>
      ) : null}
      {deleteState?.isError ? (
        <Box sx={{ mt: 3 }}>
          <Alert severity="error">
            {deleteState.errorMessage ||
              "Unable to delete. Please try again or contact an administrator."}
          </Alert>
        </Box>
      ) : null}
    </>
  );
}
