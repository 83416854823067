import axios from "axios";
import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { IBaseAsset } from "types/IAssetType";
import { assetKeys } from "./assetKeys";

export function useAssetGeodataByQuery<T = IBaseAsset>({
  org,
  seasonId,
  type,
  params,
  subtypeId,
}: {
  org: string;
  seasonId: string;
  type: "animal" | "plant" | "fixed";
  subtypeId: string;
  params: {
    cropTypeId?: string;
    fieldId?: string;
    assetGroupId?: string;
    assetAnimalTypeId?: string;
    assetFixedTypeId?: string;
  };
}) {
  const enabled = !!org && !!type && !!subtypeId && !!seasonId && !!params;

  const getGeodata = useCallback(async () => {
    const p = {
      ...params,
      ...(type === "fixed"
        ? {
            assetFixedTypeId: subtypeId,
          }
        : {}),
      ...(type === "plant"
        ? {
            cropTypeId: subtypeId,
          }
        : {}),
      ...(type === "animal"
        ? {
            assetAnimalTypeId: subtypeId,
          }
        : {}),
      ...(params.assetGroupId
        ? {
            assetGroupId: params.assetGroupId,
          }
        : {}),
    };
    const keys = Object.keys(p) as [
      "fieldId",
      "assetFixedTypeId",
      "assetGroupId",
      "assetAnimalTypeId",
      "cropTypeId"
    ];
    let queryStr = "";
    keys.forEach((k, i) => {
      queryStr += `${i === 0 ? "?" : "&"}`;
      queryStr += `${k}=${p[k]}`;
    });
    const res = await axios.get<T[]>(
      `/${org}/assets/${type}/geodata/fields${queryStr}`
    );
    return res.data;
  }, [org, params, subtypeId, type]);

  return useQuery({
    queryFn: getGeodata,
    queryKey: assetKeys.geodataByParams(org, type, params),
    enabled,
  });
}
