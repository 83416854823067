import { Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import OperationsReports from "./OperationsReports";
import "./index.scss";
import AssetsReports from "./AssetsReports";
import FieldsReports from "./FieldsReports";

export default function ReportsView() {
  const { t } = useTranslation();
  return (
    <>
      <Route path={"/:org/:season/insights/reports/fields/:subfields?"}>
        <h2 className="hide-print">
          {t("inventory.fields.title")} {t("insights.reports.title")}
        </h2>
        <FieldsReports />
      </Route>
      <Route path="/:org/:season/insights/reports/operations/:reportType?">
        <h2 className="hide-print">
          {t("inventory.operations.title")} {t("insights.reports.title")}
        </h2>

        <OperationsReports />
      </Route>
      <Route path="/:org/:season/insights/reports/assets/:reportType?">
        <h2 className="hide-print">
          {t("inventory.assets.title")} {t("insights.reports.title")}
        </h2>
        <AssetsReports />
      </Route>
    </>
  );
}
