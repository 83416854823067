import axios from "axios";
import { useCallback } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import observationKeys from "./observationKeys";
import { ParsedImage } from "api/types";

export function useObservationPhotoPost({
  org,
  observationId,
}: {
  org: string;
  observationId?: string;
}) {
  const queryClient = useQueryClient();

  const invalidate = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: observationKeys.byId(org, observationId),
    });
  }, [observationId, org, queryClient]);

  const savePhotoFn = useCallback(
    async (image: ParsedImage) => {
      const formData = new window.FormData();
      formData.append("", image.file, image.file.name);
      const res = await axios.post<{ id: string }>(
        `/${org}/observations/${observationId}/photos`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    },
    [observationId, org]
  );
  const savePhotoMutation = useMutation({
    mutationFn: savePhotoFn,
    onMutate: async (newPhoto) => {
      const key = observationKeys.photosById(org, observationId);
      await queryClient.cancelQueries({ queryKey: key });
      // Snapshot the previous value
      const previousPhotos = queryClient.getQueryData(key);
      queryClient.setQueryData(key, (old: ParsedImage[]) => [...old, newPhoto]);
      // Return a context object with the snapshotted value
      return { previousPhotos };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (_err, _newTodo, context) => {
      queryClient.setQueryData(
        observationKeys.byId(org, observationId),
        context.previousPhotos
      );
    },
    // Always refetch after error or success:
    onSettled: () => {
      invalidate();
    },
  });

  return savePhotoMutation;
}
