import { useCallback, useMemo } from "react";
import useFetchWithToken from "components/useFetchWithToken";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { FeatureCollection } from "geojson";
import { useInventoryMapThemes } from "./useInventoryMapThemes";
import { useSubfields } from "./useSubfields";
import { IFetchResponse, ISubfield } from "types";
import downloadFile from "components/downloadFile";
import subfieldKeys from "./subfields/subfieldKeys";

interface IOrgTypeSeason {
  org: string;
  type: string;
  seasonId?: string;
}

export interface ISubfieldMutateParams {
  method?: "PUT" | "POST" | "DELETE";
  body: ISubfield;
}

function mergeProps(
  fc: FeatureCollection,
  themes: { id: string }[],
  list?: { id: string }[]
) {
  fc.features.forEach((ftr) => {
    const listItem = list?.find((li) => li.id === ftr.id);
    const themeItem = themes?.find((li) => li.id === ftr.id);
    if (themeItem) {
      Object.assign(ftr.properties, themeItem);
    }
    if (listItem) {
      Object.assign(ftr.properties, listItem);
    }
  });
  return fc;
}

export function useSubfieldsByFieldId({
  org,
  seasonId,
  fieldId,
}: {
  org: string;
  seasonId?: string;
  fieldId?: string;
}) {
  const { fetchData: fetchGeodata } = useFetchWithToken<FeatureCollection>();
  const mapThemesQuery = useInventoryMapThemes(org, "subfields", seasonId);
  const queryKey = useMemo(
    () => [
      "organization",
      org,
      "season",
      seasonId,
      "fields",
      fieldId,
      "subfields",
    ],
    [fieldId, org, seasonId]
  );

  const fetchGeodataFn = useCallback(async () => {
    const res = await fetchGeodata(
      `/${org}/subfields/geodata/fields/${fieldId}`
    );
    if (mapThemesQuery.data) {
      mergeProps(res.data, mapThemesQuery.data.raw);
    }
    return res?.data ?? null;
  }, [fetchGeodata, fieldId, mapThemesQuery.data, org]);

  return useQuery<FeatureCollection>({
    queryKey,
    queryFn: fetchGeodataFn,
    enabled: !!fieldId,
  });
}

export function useSubfieldsGeodata({
  org,
  type,
  seasonId,
  fieldId,
  groupId,
  subfieldId,
  onSuccess,
}: {
  org: string;
  type: string;
  seasonId?: string;
  fieldId?: string;
  groupId?: string;
  subfieldId?: string;
} & UseQueryOptions) {
  const { fetchData: fetchGeodata } = useFetchWithToken<FeatureCollection>();
  const mapThemesQuery = useInventoryMapThemes(org, "subfields", seasonId);
  const subfieldsQuery = useSubfields(org, seasonId, type);

  const fetchGeodataFn = useCallback(async () => {
    const url = subfieldId
      ? `/${org}/subfields/${type}/geodata/${subfieldId}`
      : `/${org}/subfields/${type}/geodata/fields/${fieldId}/groups/${groupId}`;
    const res = await fetchGeodata(url, {
      transformResponse: (res) => {
        if (mapThemesQuery.data) {
          mergeProps(res, mapThemesQuery.data.raw, subfieldsQuery.data);
        }
        return res;
      },
    });
    return res?.data ?? null;
  }, [
    fetchGeodata,
    fieldId,
    groupId,
    mapThemesQuery.data,
    org,
    subfieldId,
    subfieldsQuery.data,
    type,
  ]);

  const q = useQuery<FeatureCollection>({
    queryKey: subfieldKeys.geodataByParams(
      org,
      type,
      seasonId,
      JSON.stringify({
        fieldId,
        groupId,
        subfieldId,
      })
    ),
    queryFn: fetchGeodataFn,
    enabled:
      // need either subfieldId OR fieldId & groupId
      // also waiting until we have map theme and list data
      (Boolean(subfieldId) || (!!fieldId && !!groupId)) &&
      Boolean(type && mapThemesQuery.data && subfieldsQuery.data),
    onSuccess,
  });
  return q;
}

interface IExportProps {
  fieldId: string;
  groupId: string;
  filename: string;
}

export function useSubfieldsGeodataExport({
  org,
  type,
  onSettled,
}: IOrgTypeSeason &
  UseMutationOptions<IFetchResponse<ISubfield>, Error, IExportProps>) {
  const { fetchData } = useFetchWithToken(null, {
    abortPrevious: false,
    baseUrl: "",
    formatResponse: null,
  });

  const mutation = useMutation({
    mutationFn: async ({ fieldId, groupId, filename }: IExportProps) => {
      const url = `/${org}/subfields/${type}/geodata/fields/${fieldId}/groups/${groupId}/export`;
      const req = fetchData(url);
      req.then((res) => {
        if (res.isError) {
          return;
        }
        try {
          downloadFile({
            data: res.data,
            filetype: "text/plain",
            filename: `${filename}.geojson`,
          });
        } catch (err) {
          console.error(err);
        }
      });
      const res = await req;
      if (res.isError) {
        throw Error(res.errorMessage);
      }
      return res?.data;
    },
    onSettled,
  });
  return { exportData: mutation.mutate, ...mutation };
}
