import { useReducer, useEffect, useCallback } from "react";
import mapboxgl, { Map, MapboxEvent, MapboxOptions } from "mapbox-gl";

const mapboxGlReducer = (
  state: { map: Map; options: MapboxOptions },
  action: { type: "SET_MAP"; payload: Map }
) => {
  switch (action.type) {
    case "SET_MAP":
      return { ...state, map: action.payload };
    default:
      throw new Error("Invaid reducer action type");
  }
};

/**
 * Mapbox GL JS hook
 *
 * @version 0.0.1
 * @author [@cultivate-ai](https://github.com/cultivate-ai)
 * @param options options object containing mapbox-gl Map options
 * @param events events object that contains events to listen for. keys must match mapbox Map event names
 */
export default function useMapboxGl(
  options: MapboxOptions = null,
  events: {
    [key: string]: (_e: MapboxEvent) => void;
  }
) {
  const [state, dispatch] = useReducer(mapboxGlReducer, {
    options,
    map: null,
  });

  const cleanup = useCallback(() => {
    if (state.map) {
      if (events) {
        Object.keys(events).forEach((k) => {
          state.map.off(k, events[k]);
        });
      }
      // state.map.remove();
    }
  }, [events, state.map]);

  const methods = {
    initMap: (container: HTMLDivElement) => {
      const opts = { ...options };
      opts.container = container;
      const m = new mapboxgl.Map(opts);
      dispatch({ type: "SET_MAP", payload: m });
      return m;
    },
  };

  useEffect(() => {
    // set events
    if (events && state.map) {
      Object.keys(events).forEach((k) => {
        state.map.on(k, events[k]);
      });
    }
    // cleanup when unmounting
    return () => {
      cleanup();
    };
  }, [events, cleanup, state.map]);
  return { state, ...methods };
}
